import styled from 'styled-components'

import { IColumnProps } from './types'

export const Container = styled.div`
  flex-direction: column;
  display: flex;
`

export const Column = styled.div<IColumnProps>`
  width: ${({ width }) => width || 'auto'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  display: flex;
  gap: 8px;
  flex: 1 0 fit-content;
`
