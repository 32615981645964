import { FC } from 'react'

import { Modal } from '~/components/Modal'
import { TextButton } from '~/components/TextButton'

import { MobileUtils } from '~/utils/mobileUtils'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { ModalDescription } from './styles'
import { ISizingTipModal } from './types'

export const SizingTipModal: FC<ISizingTipModal> = ({
  primaryButtonClick,
  secondaryButtonClick,
  isVisible,
  videoUrl,
}) => {
  // Set video autoplay to false when running on IOS, because video starts automatically even hidden on HomePage
  const isIos = MobileUtils.isIos()

  return (
    <Styled.Container data-testid="sizing-tip-modal">
      <Modal
        isVisible={isVisible}
        primaryButton={
          <TextButton variant="default" onClick={primaryButtonClick} testID="sizing-tip-modal-primary-button">
            {translate('MODELS_BACKDROP_USE_MY_PHOTO_TAB_BUTTON_TEXT')}
          </TextButton>
        }
        secondaryButton={
          <TextButton variant="phantom" onClick={secondaryButtonClick} testID="sizing-tip-modal-secondary-button">
            {translate('WALKTHROUGH_SKIP_BUTTON_TEXT')}
          </TextButton>
        }
      >
        <Styled.Video
          autoPlay={!isIos}
          testId="sizing-tip-modal-video"
          style={{ borderRadius: '16px', overflow: 'hidden' }}
        >
          <source data-testid="sizing-tip-modal-video-src" src={videoUrl} type="video/mp4" />
        </Styled.Video>
        <ModalDescription>{translate('MODAL_SIZING_INFORMATION_TITLE')}</ModalDescription>
      </Modal>
    </Styled.Container>
  )
}
