import { useContextSelector } from 'use-context-selector'

import { HistoryContext } from '.'

export const useHistory = () => {
  const fetchHistoryFromStorage = useContextSelector(
    HistoryContext,
    ({ fetchHistoryFromStorage }) => fetchHistoryFromStorage,
  )
  const addItem = useContextSelector(HistoryContext, ({ addItem }) => addItem)
  const updateItem = useContextSelector(HistoryContext, ({ updateItem }) => updateItem)
  const items = useContextSelector(HistoryContext, ({ items }) => items)
  const index = useContextSelector(HistoryContext, ({ index }) => index)
  const getPreviousItem = useContextSelector(HistoryContext, ({ getPreviousItem }) => getPreviousItem)
  const getCurrentItem = useContextSelector(HistoryContext, ({ getCurrentItem }) => getCurrentItem)
  const getNextItem = useContextSelector(HistoryContext, ({ getNextItem }) => getNextItem)
  const restartHistory = useContextSelector(HistoryContext, ({ restartHistory }) => restartHistory)

  return {
    fetchHistoryFromStorage,
    addItem,
    updateItem,
    items,
    index,
    getPreviousItem,
    getCurrentItem,
    getNextItem,
    restartHistory,
  }
}
