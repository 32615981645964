import { BrowserUrlApi } from '~/utils/browserUrlApi'

const isWebviewRendering = BrowserUrlApi.isWebviewRendering()

const theme = {
  colors: {
    primary: '#00A2FF',
    textPrimary: '#222B45',
    textSecondary: '#7a7a7a',
    white: '#FFF',
    black: '#000',
    accent: '#FFF000',
    error: '#FF0000',
    palette: {
      whiteLilac: '#f7f9fc',
      scorpion: '#5a5a5a',
      athensGray: '#f6f7f9',
      alto: '#cfcfcf',
      webOrange: '#ffaa00',
      gray95: '#F2F2F2',
      black: '#000',
    },
    mischka: '#d6dae3',
    silverChalice: '#B2B2B2',
    quickSilver: '#979797',
    darkOpaqueBackground: 'rgba(0, 0, 0, 0.5)',
    yellowSea: '#ffae00',
    goldenYellow: '#FFEE00',
    coralRed: '#FF433D',
    growingNature: '#5bbf22',
    silverChaliceDark: '#9E9E9E',
    lightSilver: '#D8D8D8',
    neutral: {
      black600: '#626262',
      black500: '#7C7C7C',
      black400: '#9e9e9e',
      black200: '#EAEAEA',
      black100: '#f6f6f6',
    },
    status: {
      success: '#5bbf22',
      warning: '#ffaa00',
      error: '#ff433d',
    },
  },
  transitions: {
    spring: 'cubic-bezier(0.67, 0.02, 0.36, 1.37)',
    ease: property => `${property} ease 0.3s`,
  },
  tokens: {
    spacingUnit: 8,
  },
  fontFamily: 'Montserrat, sans-serif',
  typography: {
    fontWeight: {
      bold: 700,
      semiBold: 600,
      medium: 500,
      regular: 400,
    },
    default: {
      fontSize: '13px',
    },
  },
  media: {
    responsive: {
      laptop: 'screen and (max-width: 1400px) and (max-height: 768px) and (orientation: landscape)',
      tablet: '(max-height: 810px) and (min-width: 428px)',
      mobile: '(max-width: 767px)',
    },
    devices: {
      tablet: {
        landscape:
          'screen and (min-width: 769px) and (max-width: 1366px) and (min-height: 768px) and (max-height: 1024px) and (orientation: landscape)',
      },
      mobile: {
        portrait: 'screen and (max-width: 428px) and (orientation: portrait)',
        smallPortrait: 'screen and (max-width: 379px) and (orientation: portrait)',
        landscape: 'screen and (max-height: 479px) and (orientation: landscape)',
        shortPortrait: 'screen and (max-height: 668px) and (orientation: portrait)',
      },
    },
  },
  opacity: {
    default: 0.3,
  },
  position: { x: 'left' },
  measures: {
    margin: '16px',
    borderRadius: '25px',
  },
  isWebview: isWebviewRendering,
}

export default theme
