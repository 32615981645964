import styled from 'styled-components'

import { Paragraph } from '~/components/Typography/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 20px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const FooterText = styled(Paragraph)`
  margin: 0 20px 38px;
  font-weight: 700;
  object-position: center bottom;
`
