import React from 'react'

import { TIconProps } from '.'

const BeachCover: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 26 40"
      data-testid="icon-beach-cover"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <rect id="Rectangle" />
        <path
          d="M25.6937345,29.82 L25.6937345,29.82 L21.8737345,16.62 C21.8137345,16.45 21.7037345,16.34 21.5937345,16.28 C21.4837345,16.22 21.3137345,16.17 21.1437345,16.22 C15.4737345,17.96 9.91373449,17.96 4.57373449,16.22 C4.40373449,16.16 4.29373449,16.16 4.12373449,16.28 C4.01373449,16.34 3.90373449,16.45 3.84373449,16.62 L0.0237344913,29.82 C-0.0362655087,29.99 0.0237344913,30.21 0.133734491,30.38 C0.243734491,30.55 0.473734491,30.6 0.693734491,30.55 C2.78373449,30.09 4.84373449,31.44 5.25373449,33.55 L5.30373449,33.81 C5.36373449,33.98 5.47373449,34.15 5.64373449,34.2 C5.81373449,34.26 5.98373449,34.26 6.15373449,34.2 L6.89373449,33.79 C8.56373449,32.87 10.6537345,33.32 11.7937345,34.85 L12.3937345,35.66 C12.5037345,35.88 12.6737345,36 12.9037345,36 C13.1337345,36 13.2937345,35.89 13.4137345,35.72 L14.0637345,34.84 C15.2037345,33.31 17.2937345,32.86 18.9537345,33.79 L19.6937345,34.2 C19.8637345,34.31 20.0337345,34.31 20.2037345,34.2 C20.3737345,34.14 20.4837345,33.98 20.5437345,33.81 L20.5937345,33.55 C20.9937345,31.45 23.0537345,30.09 25.1537345,30.55 C25.3237345,30.61 25.5437345,30.55 25.7137345,30.38 C25.7137345,30.21 25.7737345,30.04 25.7137345,29.82 L25.6937345,29.82 Z"
          id="Path"
          fill={color}
        />
        <path
          d="M8.17373449,13.7 C8.95373449,13.7 9.83373449,13.24 10.6437345,12.65 C11.3037345,12.16 12.0837345,11.92 12.8737345,11.91 C13.6537345,11.91 14.4337345,12.16 15.1037345,12.65 C15.9137345,13.24 16.7837345,13.7 17.5737345,13.7 C19.8237345,13.7 21.6137345,11.9 21.6737345,9.71 C21.6737345,8.53 21.1537345,7.49 20.3337345,6.76 C19.5337345,6.04 19.1437345,4.96 19.1437345,3.88 L19.1437345,0.56 C19.1437345,0.22 18.9237345,1.77635684e-15 18.5837345,1.77635684e-15 C18.2437345,1.77635684e-15 18.0237345,0.22 18.0237345,0.56 L18.0237345,2.74 C18.0237345,4.18 17.2837345,5.55 16.0137345,6.22 C15.7437345,6.37 15.4637345,6.54 15.2037345,6.73 C14.5037345,7.23 13.6937345,7.49 12.8837345,7.49 C12.0737345,7.49 11.2637345,7.23 10.5637345,6.73 C10.3037345,6.54 10.0237345,6.37 9.75373449,6.22 C8.48373449,5.55 7.74373449,4.17 7.74373449,2.74 L7.74373449,0.56 C7.74373449,0.22 7.52373449,1.77635684e-15 7.18373449,1.77635684e-15 C6.84373449,1.77635684e-15 6.62373449,0.22 6.62373449,0.56 L6.62373449,3.88 C6.62373449,4.96 6.23373449,6.04 5.43373449,6.76 C4.62373449,7.49 4.09373449,8.53 4.09373449,9.71 C4.15373449,11.9 5.94373449,13.7 8.19373449,13.7 L8.17373449,13.7 Z"
          id="Path"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default BeachCover
