import { useContextSelector } from 'use-context-selector'

import { HandleBuyButtonContext } from '~/context/HandleBuyButton'

export const useHandleBuyButton = () => {
  const handleBuyButtonRef = useContextSelector(HandleBuyButtonContext, value => value?.handleBuyButtonRef)

  return {
    handleBuyButtonRef,
  }
}
