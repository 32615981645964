import { useCallback } from 'react'

import { QueryLazyOptions, TypedDocumentNode, useLazyQuery } from '@apollo/client'

import { IProduct } from '~/entities'

import {
  GET_PRODUCTS_COMBINE,
  GET_PRODUCTS_RELATED_COMBINE,
  IFetchQueryVars,
  IQueryData,
  IUseQueryProductsParams,
  IUseQueryProductsReturn,
  TTypeQuery,
} from '.'

export const useQueryProducts = ({
  notifyOnNetworkStatusChange,
  typeQuery = 'default',
  fetchPolicy = 'no-cache',
}: IUseQueryProductsParams): IUseQueryProductsReturn => {
  const typeQueryMap: Record<TTypeQuery, TypedDocumentNode> = {
    default: GET_PRODUCTS_COMBINE,
    related: GET_PRODUCTS_RELATED_COMBINE,
  }

  const [fetchProducts, { data, loading, called, error, fetchMore, refetch }] = useLazyQuery<
    IQueryData,
    IFetchQueryVars
  >(typeQueryMap[typeQuery], {
    fetchPolicy,
    notifyOnNetworkStatusChange,
  })

  const fetch = useCallback(
    (options: QueryLazyOptions<IFetchQueryVars>): Promise<IProduct[]> =>
      new Promise(async (resolve, reject) => {
        const { data, error } = await fetchProducts({
          ...options,
        })

        if (error) {
          reject(error)

          return
        }

        resolve(data?.product || [])
      }),
    [fetchProducts],
  )

  return {
    fetch,
    fetchMore,
    refetch,
    data: data?.product || [],
    dataWithRelated: data?.product_with_related || [],
    dataWithoutRelated: data?.product_without_related || [],
    called,
    loading,
    error,
  }
}
