import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { IRecommendationStateError } from '~/hooks-queries/recommendation'
import { useCurrentModel } from '~/hooks/useCurrentModel'
import { usePartner } from '~/hooks/usePartner'
import { useRecommendation } from '~/hooks/useRecommendation'
import { TGetRecommendationState, TUpdateRecommendationState } from '~/hooks/useRecommendation/types'
import { useTryon } from '~/hooks/useTryon'
import { useUser } from '~/hooks/useUser'

import { useNavigation } from '~/context/Navigation'
import { useRecommendationHistory } from '~/context/RecommendationHistory'
import { ITryonProvider, useTryonContext } from '~/context/Tryon'
import { useUserContext } from '~/context/User'

import { Icon } from '~/components/Icon'
import { TimeoutError } from '~/components/TimeoutError'

import theme from '~/theme'

import { Sentry } from '~/clients/sentry'
import { ICategoryItem, IProduct, IRecommendation } from '~/entities'
import { MobileUtils } from '~/utils/mobileUtils'
import { toCurrency } from '~/utils/toCurrency'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { IRecommendationCardProps, TCardActionsOnClickParams } from './components/card/types'
import { List } from './components/list'
import { TListOnAfterChangeParams, TListOnBeforeChangeParams } from './components/list/types'
import { AmplitudeDispatch } from './helpers'
import {
  THandleGetRecommendation,
  THandleTryonParams,
  THandleUpdateRecommendation,
  THandleUpdateRecommendationError,
  THanleUpdateRecommendationSuccess,
  TRedirectParams,
  TSendTrackingParams,
  TSetLoadingParams,
  TShowCardAnimationParams,
  TShowPriceTagsParams,
} from './types'

export const Recommendation: FC = () => {
  const { getRecommendation, updateRecommendation } = useRecommendation()
  const { recommendationListRef, updateRecommendationHistory, addRecommendationHistory, fetchHistoryFromStorage } =
    useRecommendationHistory()
  const { getCurrentModel } = useCurrentModel()
  const { getPartner } = usePartner()
  const { createTryon } = useTryon()
  const { updateUser } = useUser()

  const isMobile = useMemo(() => MobileUtils.isMobile(), [])

  const { stateCurrentUser, setCurrentUserState } = useUserContext()
  const { stateCurrentTryon, setCurrentTryonState } = useTryonContext()
  const { navigate } = useNavigation()

  const [stateRecommendation, setStateRecommendation] = useState<TGetRecommendationState>()
  const [stateUpdateRecommendation, setStateUpdateRecommendation] = useState<TUpdateRecommendationState>()

  const [cardList, setCardList] = useState<Array<IRecommendationCardProps>>([])
  const [showTimeoutError, setShowTimeoutError] = useState(false)

  const isUserFirstTime = useRef<boolean>()

  const sendTracking = useCallback(
    ({ type, idRecommendation }: TSendTrackingParams) => {
      AmplitudeDispatch({
        payload: {
          type,
          values: {
            model: getCurrentModel(),
            recommendation: recommendationListRef.current.find(
              recommendation => recommendation.id === idRecommendation,
            ),
          },
        },
      })
    },
    [getCurrentModel, recommendationListRef],
  )

  const resetRecommendationState = useCallback(
    () => setStateRecommendation({ isLoading: false, called: false, data: undefined, error: undefined }),
    [],
  )

  const resetUpdateRecommendationState = useCallback(
    () => setStateUpdateRecommendation({ isLoading: false, called: false, data: undefined, error: undefined }),
    [],
  )

  const redirect = useCallback(
    (params?: TRedirectParams) => {
      setCardList(current => current.map(item => ({ ...item, animationActions: true })))

      setTimeout(() => {
        if (!params) {
          navigate('Home')

          return
        }

        navigate('Home', { states: { [params.to]: true }, measurements: { init: true, products: params.products } })
      }, 300)
    },
    [navigate],
  )

  const setLoading = useCallback(({ id, state }: TSetLoadingParams) => {
    setCardList(currentState => currentState.map(item => (item.id === id ? { ...item, loading: state } : item)))
  }, [])

  const setTryon = useCallback(
    (recommendation: IRecommendation) => {
      createTryon({
        data: {
          from: 'recommendation',
          model: getCurrentModel(),
          tryon: recommendation.tryon,
          products: recommendation.products,
        },
        setState: setCurrentTryonState,
      })
    },
    [createTryon, getCurrentModel, setCurrentTryonState],
  )

  const handleTryon = useCallback(
    ({ idRecommendation, from }: THandleTryonParams) => {
      const recommendation = recommendationListRef.current.find(item => item.id === idRecommendation) as IRecommendation
      const to: { [key in THandleTryonParams['from']]: TRedirectParams['to'] } = {
        combine: 'homeProductsBackdrop',
        cart: 'homeCombinedBackdrop',
      }

      setTryon(recommendation)

      redirect({ to: to[from], products: recommendation.products })
    },
    [redirect, setTryon, recommendationListRef],
  )

  const showPriceTags = useCallback(
    ({ id, state }: TShowPriceTagsParams) =>
      setCardList(currentState =>
        currentState.map(item =>
          item.id === id ? ({ ...item, tags: { ...item.tags, show: state } } as IRecommendationCardProps) : item,
        ),
      ),
    [],
  )

  const hasCardShowPriceTags = useCallback((): boolean => !!cardList.filter(card => card.tags?.show).length, [cardList])

  const hiddenAllCardsPriceTags = useCallback(
    () =>
      setCardList(current =>
        current.map(item =>
          item.id ? ({ ...item, tags: { ...item.tags, show: false } } as IRecommendationCardProps) : item,
        ),
      ),
    [],
  )

  const showCardAnimation = useCallback(
    ({ id, status }: TShowCardAnimationParams) => {
      if (status) {
        setTimeout(() => {
          setCardList(current =>
            current.map(card =>
              card.id === id ? { ...card, animation: { show: true, type: isMobile ? 'mobile' : 'desktop' } } : card,
            ),
          )
        }, 500)

        return
      }

      setCardList(current =>
        current.map(card => (card.animation?.show ? { ...card, animation: { ...card.animation, show: false } } : card)),
      )
    },
    [isMobile],
  )

  const handleGetRecommendation = useCallback(
    ({ newList, products }: THandleGetRecommendation) => {
      setLoading({ id: 0, state: true })

      const fetchRecommendation = async () => {
        const partner = await getPartner()

        getRecommendation({
          data: {
            user: { uuid: stateCurrentUser?.uuid as string },
            model: getCurrentModel(),
            upscale: partner?.data?.upscale,
            newList,
            products,
          },
          setState: setStateRecommendation,
        })
      }

      fetchRecommendation()
    },
    [setLoading, getPartner, getRecommendation, stateCurrentUser?.uuid, getCurrentModel],
  )

  const handleUpdateRecommendation = useCallback(
    ({ id, liked }: THandleUpdateRecommendation) => {
      updateRecommendation({
        data: { idRecommendation: id, values: { liked } },
        setState: setStateUpdateRecommendation,
      })

      updateRecommendationHistory({ id, liked })
    },
    [updateRecommendation, updateRecommendationHistory],
  )

  const handleLike = useCallback(
    ({ id }: TCardActionsOnClickParams) =>
      setCardList(currentState => {
        const nextState = currentState.map(item =>
          item.id === id ? { ...item, like: { ...item.like, active: !item.like.active } } : item,
        )
        const liked = !!nextState.find(item => item.id === id)?.like.active

        handleUpdateRecommendation({ id, liked })

        return nextState
      }),
    [handleUpdateRecommendation],
  )

  const handleCart = useCallback(
    ({ id }: TCardActionsOnClickParams) => {
      sendTracking({ type: 'cart_open', idRecommendation: id })

      handleTryon({ from: 'cart', idRecommendation: id })
    },
    [handleTryon, sendTracking],
  )

  const handleCombine = useCallback(
    ({ id }: TCardActionsOnClickParams) => {
      sendTracking({ type: 'combine_open', idRecommendation: id })

      handleTryon({ from: 'combine', idRecommendation: id })
    },
    [handleTryon, sendTracking],
  )

  const handleBeforeListChange = useCallback(
    ({ id, direction }: TListOnBeforeChangeParams) => {
      hiddenAllCardsPriceTags()

      if (id) {
        sendTracking({ type: direction === 'next' ? 'navigation_redo' : 'navigation_undo' })

        return
      }

      if (stateRecommendation?.isLoading) return

      handleGetRecommendation({ newList: false })
    },
    [handleGetRecommendation, sendTracking, stateRecommendation, hiddenAllCardsPriceTags],
  )

  const handleAfterListChange = useCallback(
    ({ id }: TListOnAfterChangeParams) => {
      if (isUserFirstTime.current) {
        isUserFirstTime.current = false

        showCardAnimation({ id, status: false })
      }

      if (!id) return

      showPriceTags({ id, state: true })
    },
    [showPriceTags, showCardAnimation],
  )

  const handleCardOnLoad = useCallback(
    ({ id }: TCardActionsOnClickParams) => {
      setLoading({ id, state: false })

      if (!hasCardShowPriceTags()) {
        showPriceTags({ id, state: true })
      }

      if (!isUserFirstTime.current) return

      showCardAnimation({ id, status: true })
    },
    [showPriceTags, showCardAnimation, setLoading, hasCardShowPriceTags],
  )

  const handleTimeoutReloadButton = useCallback(() => {
    sendTracking({ type: 'timeout_reload' })

    handleGetRecommendation({ newList: false })

    setShowTimeoutError(false)
  }, [handleGetRecommendation, sendTracking])

  const handleTimeoutReturnButton = () => navigate('Home')

  const getNewEmptyCard = useCallback(
    (): IRecommendationCardProps => ({
      id: 0,
      like: { active: false, onClick: handleLike },
      cart: { onClick: handleCart },
      combine: { onClick: handleCombine },
      onLoad: handleCardOnLoad,
      placeholder: getCurrentModel().stage_image,
      loading: false,
    }),
    [getCurrentModel, handleCardOnLoad, handleCart, handleCombine, handleLike],
  )

  const getFormattedCard = useCallback(
    async (recommendation: IRecommendation): Promise<IRecommendationCardProps> => {
      const partner = await getPartner()

      const products = recommendation.products as ITryonProvider & { [key: string]: IProduct | undefined }
      const card: IRecommendationCardProps = {
        id: recommendation.id,
        like: { active: recommendation.liked, onClick: handleLike },
        cart: { onClick: handleCart },
        combine: { onClick: handleCombine },
        onLoad: handleCardOnLoad,
        placeholder: getCurrentModel().stage_image,
        loading: false,
        background: recommendation.tryon.image_url,
      }

      card.tags = {
        hasCrawler: !!partner.data?.crawler_api_key,
        show: false,
        data: Object.keys(products).map(categoryType => ({
          category: products[categoryType]?.category as ICategoryItem,
          in_stock: !!recommendation.products.top?.product_options?.filter(option => option.has_stock).length,
          price:
            products[categoryType]?.selling_price || products[categoryType]?.list_price
              ? toCurrency({
                  value: products[categoryType]?.selling_price || (products[categoryType]?.list_price as number),
                })
              : undefined,
        })),
      }

      return card
    },
    [getCurrentModel, getPartner, handleCardOnLoad, handleCart, handleCombine, handleLike],
  )

  const onGetRecommendationError = useCallback(
    (error: IRecommendationStateError) => {
      // eslint-disable-next-line no-console
      console.error(error)
      Sentry.captureException({
        errorName: error.name,
        errorMessage: error.message,
        filePath: 'src/screens/Recommendation/index.tsx',
        functionName: 'onGetRecommendationError',
      })

      sendTracking({ type: 'timeout_open' })

      setShowTimeoutError(true)
    },
    [sendTracking],
  )

  const onGetRecommendationSuccess = useCallback(
    async (data: IRecommendation) => {
      const card = await getFormattedCard(data)

      setCardList(currentState => {
        const newState = currentState.map((item, index) =>
          index === currentState.length - 1 ? { ...card, loading: true } : item,
        )

        newState.push(getNewEmptyCard())

        return newState
      })

      addRecommendationHistory(data)

      sendTracking({ type: 'get_recommendation', idRecommendation: data.id })
    },
    [getFormattedCard, getNewEmptyCard, sendTracking, addRecommendationHistory],
  )

  const onUpdateRecommendationError = useCallback(
    ({ data, error }: THandleUpdateRecommendationError) => {
      setCardList(currentState =>
        currentState.map(item =>
          item.id === data.id ? { ...item, like: { ...item.like, active: !data.liked } } : item,
        ),
      )

      updateRecommendationHistory({ id: data.id, liked: !data.liked })

      // eslint-disable-next-line no-console
      console.error(error)

      Sentry.captureException<THandleUpdateRecommendation>({
        errorName: error.name,
        errorMessage: error.message,
        filePath: 'src/screens/Recommendation/index.tsx',
        functionName: 'onGetRecommendationError',
        payload: data,
      })
    },
    [updateRecommendationHistory],
  )

  const onUpdateRecommendationSuccess = useCallback(
    ({ data }: THanleUpdateRecommendationSuccess) => {
      if (!data.liked) return

      sendTracking({ type: 'update_recommendation', idRecommendation: data.id })
    },
    [sendTracking],
  )

  const onGetRecommendation = useCallback(() => {
    if (!stateRecommendation?.called || stateRecommendation?.isLoading) return

    stateRecommendation?.error
      ? onGetRecommendationError(stateRecommendation.error)
      : onGetRecommendationSuccess(stateRecommendation.data as IRecommendation)

    resetRecommendationState()
  }, [stateRecommendation, onGetRecommendationError, onGetRecommendationSuccess, resetRecommendationState])

  const onUpdateRecommendation = useCallback(() => {
    if (!stateUpdateRecommendation?.called || stateUpdateRecommendation?.isLoading) return
    const data = {
      id: stateUpdateRecommendation.data?.idRecommendation as number,
      liked: !!stateUpdateRecommendation.data?.values.liked,
    }

    stateUpdateRecommendation?.error
      ? onUpdateRecommendationError({
          data,
          error: stateUpdateRecommendation.error,
        })
      : onUpdateRecommendationSuccess({ data })

    resetUpdateRecommendationState()
  }, [
    stateUpdateRecommendation,
    resetUpdateRecommendationState,
    onUpdateRecommendationError,
    onUpdateRecommendationSuccess,
  ])

  const onScreenRender = useCallback(async () => {
    isUserFirstTime.current = stateCurrentUser?.showRecommendationAnimation

    updateUser({
      values: {
        showRecommendationTooltip: false,
        showRecommendationBackdrop: false,
        showRecommendationAnimation: false,
      },
      setState: setCurrentUserState,
    })

    fetchHistoryFromStorage()

    const recommendationHistoryPromises = recommendationListRef.current.map(recommendation =>
      getFormattedCard(recommendation),
    )

    const recommendationHistory: IRecommendationCardProps[] = []
    for await (const card of recommendationHistoryPromises) {
      recommendationHistory.push(card)
    }

    setCardList(recommendationHistory.concat({ ...getNewEmptyCard() }))

    handleGetRecommendation({ newList: true, products: stateCurrentTryon?.products })

    // We don't need listen to variables changes, just for the first time the component is render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onGetRecommendation()
  }, [onGetRecommendation])

  useEffect(() => {
    onUpdateRecommendation()
  }, [onUpdateRecommendation])

  useEffect(() => {
    onScreenRender()
  }, [onScreenRender])

  return (
    <Styled.Container data-testid="recommendation-screen">
      <Styled.IconButtonClose
        onClick={() => redirect()}
        icon={<Icon name="arrowDown" size="25px" color={theme.colors.white} />}
        size={32}
        testID="close-button"
      />
      <List data={cardList} onBeforeChange={handleBeforeListChange} onAfterChange={handleAfterListChange} />
      {showTimeoutError && (
        <Styled.Timeout>
          <TimeoutError
            description={translate('RECOMMENDATION_TIMEOUT_DESCRIPTION')}
            onPrimaryButtonClick={handleTimeoutReloadButton}
            onSecondaryButtonClick={handleTimeoutReturnButton}
          />
        </Styled.Timeout>
      )}
    </Styled.Container>
  )
}
