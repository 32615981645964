import { FC } from 'react'

import { TIconProps } from '.'

export const Sizing: FC<Omit<TIconProps, 'color'>> = ({ size }) => {
  return (
    <svg
      viewBox="0 0 57 88"
      data-testid="icon-sizing"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <path
        d="M0.5 84.763V15.5897C0.5 13.8049 1.92949 12.3527 3.68682 12.3527H53.3132C55.0704 12.3527 56.5 13.8049 56.5 15.5897V75.6736C56.5 77.4585 55.0704 78.9106 53.3132 78.9106L3.68682 88C1.92949 88 0.500002 86.5479 0.500004 84.763H0.5ZM55.9227 15.5897C55.9227 14.1258 54.7544 12.9391 53.3132 12.9391H3.68681C2.24563 12.9391 1.07732 14.1258 1.07732 15.5897V84.763C1.07732 86.2269 2.24563 87.4136 3.68681 87.4136C3.68681 87.4136 55.9227 67.0724 55.9227 53.1927V15.5897Z"
        fill="#EAEAEA"
      />
      <path
        d="M22.959 0.872004C23.8276 0.81972 24.5754 1.47922 24.9206 1.99066C25.2518 2.48119 25.7518 2.46012 26.0415 3.71888C26.3528 5.07069 25.94 7.52874 25.6212 8.20541C25.3088 8.86846 26.2574 9.34998 26.0415 10.3184C25.9244 10.8442 25.4954 11.3726 24.9206 11.5614C24.644 11.6522 24.2393 11.6954 22.959 11.1885C21.339 10.547 20.529 10.2263 20.1567 9.57266C19.7859 8.92162 20.1528 8.58726 20.4369 6.83816C20.8575 4.24856 20.2872 3.54043 21.1375 2.23925C21.4001 1.83735 21.993 0.930162 22.959 0.872004V0.872004Z"
        fill="#414141"
      />
      <path
        d="M5.34361 42.7981C5.49263 42.2863 5.74311 41.851 6.03908 41.5419L7.15545 36.5749L9.46675 37.1836L8.08545 42.1566C8.16828 42.5795 8.14543 43.0831 7.99641 43.595C7.65598 44.7643 6.78616 45.5338 6.05361 45.3137C5.32106 45.0937 5.00318 43.9674 5.34361 42.7981L5.34361 42.7981V42.7981Z"
        fill="#FFB6B6"
      />
      <path
        d="M38.4502 13.1992C38.4089 13.7313 38.2527 14.2098 38.0262 14.5746L37.9478 19.6688L35.5625 19.56L35.8988 14.4042C35.7315 14.0078 35.6511 13.5104 35.6924 12.9783C35.7868 11.7627 36.4806 10.8268 37.2422 10.8878C38.0037 10.9488 38.5446 11.9837 38.4502 13.1992V13.1992Z"
        fill="#FFB6B6"
      />
      <path d="M9.86523 83.7479L11.6043 83.7896L12.5737 75.8536L10.0073 75.7919L9.86523 83.7479Z" fill="#FFB6B6" />
      <path
        d="M15.8321 87.4333C15.8321 87.7318 15.6236 87.9737 15.3664 87.9737H11.9145C11.9145 87.9737 11.5748 86.5867 10.1898 85.9898L10.0942 87.9737H8.31348L8.52923 84.7837C8.52923 84.7837 8.05288 83.077 9.04216 82.2046C10.0314 81.3322 9.23016 81.4536 9.23016 81.4536L9.61933 79.4792L12.3102 79.8007L12.33 82.9L13.6358 85.9763L15.5512 86.9374C15.7217 87.0229 15.832 87.2178 15.832 87.4333L15.8321 87.4333H15.8321H15.8321Z"
        fill="black"
      />
      <path d="M18.7715 80.2907L20.4569 80.7283L23.157 73.2164L20.6699 72.5706L18.7715 80.2907Z" fill="#FFB6B6" />
      <path
        d="M23.7701 85.2437C23.704 85.5345 23.4474 85.7226 23.1968 85.6639L19.8331 84.8761C19.8331 84.8761 19.8089 83.447 18.5914 82.5493L18.0594 84.4606L16.3242 84.0542L17.2401 80.995C17.2401 80.995 17.1535 79.2232 18.3105 78.5989C19.4675 77.9746 18.6598 77.9101 18.6598 77.9101L19.4758 76.075L22.0267 77.0024L21.3604 80.0269L21.9523 83.3227L23.6061 84.6963C23.7533 84.8186 23.8177 85.0337 23.77 85.2437L23.7701 85.2437L23.7701 85.2437V85.2437Z"
        fill="black"
      />
      <path
        d="M23.1689 29.9773L23.8695 42.0747L30.2065 59.87L24.0349 73.9261H18.3834L23.5928 61.025L15.8128 43.2844L13.4662 80.082L8.73699 80.0748L7.96636 42.2882C7.96636 42.2882 6.49514 31.4005 12.0998 26.9885L23.1689 29.9773H23.1689L23.1689 29.9773Z"
        fill="#414141"
      />
      <path
        d="M22.6067 10.9604C21.8584 10.6549 21.093 10.3455 20.2877 10.3026C19.4823 10.2597 18.6152 10.5308 18.1373 11.1907C17.7302 11.7527 17.6567 12.5096 17.25 13.0719C16.253 14.4503 13.9713 13.9243 12.6902 15.0343C11.5154 16.0522 11.6981 17.9334 12.1381 19.4368C12.5782 20.9402 13.1662 22.5964 12.4987 24.0107C11.9773 25.1155 10.7251 25.9829 10.8723 27.199C10.9996 28.2506 12.108 28.832 13.0735 29.2331L18.5963 31.5274C19.6662 31.9719 20.7754 32.4241 21.9309 32.3907C23.0864 32.3573 24.3083 31.7028 24.6414 30.5785C24.9555 29.5184 24.4227 28.3709 24.6031 27.2792C24.7426 26.4348 25.2873 25.7289 25.7601 25.0202C27.4932 22.4226 28.4434 19.2938 28.4524 16.156C25.7769 15.6091 23.5085 13.3796 22.8823 10.6814"
        fill="#DADADA"
      />
      <path
        d="M26.4287 19.9692L33.9176 26.8393C34.7855 27.6354 35.936 28.4889 37.0157 28.0329C37.6038 27.7845 37.9908 27.1842 38.1513 26.5582C38.3118 25.9321 38.2794 25.274 38.2459 24.628C38.1056 21.9227 38.5916 19.1081 38.4513 16.4029C37.705 16.0886 36.3325 15.8838 35.5862 15.5695L34.6461 21.6336L29.2709 16.7531C28.6485 16.188 27.9078 15.5838 27.0815 15.7043C26.2163 15.8305 25.6388 16.7552 25.5954 17.6419C25.552 18.5286 25.9125 19.3791 26.2797 20.1847"
        fill="#DADADA"
      />
      <path
        d="M13.5014 15.7125C13.0531 15.1264 12.0827 15.2579 11.5643 15.7811C11.0459 16.3043 10.8614 17.0693 10.6987 17.793L5.84766 39.3776L8.83093 40.4408L12.6666 28.6723C13.3657 26.5271 14.0683 24.3674 14.3741 22.1292C14.68 19.8909 14.5694 17.5455 13.6878 15.4708"
        fill="#DADADA"
      />
      <path
        d="M20.9273 10.2657C23.3649 10.2657 25.3409 8.25857 25.3409 5.78259C25.3409 3.30662 23.3649 1.29944 20.9273 1.29944C18.4897 1.29944 16.5137 3.30662 16.5137 5.78259C16.5137 8.25857 18.4897 10.2657 20.9273 10.2657Z"
        fill="#FFB6B6"
      />
      <path
        d="M24.3594 1.44168C24.2323 0.687953 23.3191 0.129507 22.5379 0.0184548C21.8789 -0.0752042 21.7128 0.206139 20.1559 0.445422C19.3236 0.573342 19.3609 0.494501 18.8949 0.587744C18.3576 0.695251 16.94 0.978905 16.0926 2.15329C15.456 3.03551 14.0935 5.11465 14.4988 6.24338C14.7178 6.85322 16.1655 6.65503 15.9525 7.13457C15.8149 7.4442 15.5425 7.4699 15.2519 7.70386C14.5197 8.29327 14.2973 9.70063 14.6914 10.5503C15.1577 11.5556 16.4588 11.7195 18.4745 11.9735C20.5512 12.2352 22.4958 12.0467 22.5379 11.6889C22.5594 11.5063 22.0648 11.4536 21.8373 10.9773C21.5708 10.4193 21.946 9.85581 22.1175 9.26941C22.6526 7.43974 20.1691 8.13457 20.7057 6.78178C20.7245 6.73434 20.7462 6.67445 20.7702 6.60451C21.1673 5.44546 21.9306 3.62783 21.9306 3.62783C21.9306 3.62783 22.4341 4.73182 22.4377 4.72933C23.0396 4.30899 25.2316 5.26546 25.6129 4.72933C26.2263 3.86692 24.388 1.61175 24.3594 1.44168L24.3594 1.44168V1.44168Z"
        fill="#414141"
      />
    </svg>
  )
}
