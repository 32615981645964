import { FC, useCallback, useRef, useState } from 'react'

import { useStartProducts } from '~/hooks/useStartProducts'

import { getPartnerAPIKey } from '~/utils/getPartnerAPIKey'

import { HistoryContext, IHistoryItem } from '.'
import { THistoryUpdateItem } from './context'
import { filterUsingCurrentAPIKey } from './utils'

const historyStorageKey = '@doris:tryon_history'

export const HistoryProvider: FC = ({ children }) => {
  const { getStartProducts } = useStartProducts()
  const itemsRef = useRef<Array<IHistoryItem>>([])
  const [items, setItems] = useState<Array<IHistoryItem>>([])
  const [index, setIndex] = useState(0)

  const fetchHistoryFromStorage = useCallback(() => {
    const storage = localStorage.getItem(historyStorageKey)

    if (!storage) return

    itemsRef.current = filterUsingCurrentAPIKey({ items: JSON.parse(storage) })

    setItems(itemsRef.current)
    setIndex(getStartProducts() ? itemsRef.current.length : itemsRef.current.length + 1)
  }, [getStartProducts])

  const syncToLocalStorage = useCallback((history: IHistoryItem | IHistoryItem[]) => {
    let newStorageState = JSON.stringify(history)

    if (!Array.isArray(history)) {
      const storage = localStorage.getItem(historyStorageKey)
      const parsedStorage = storage ? JSON.parse(storage) : []
      newStorageState = JSON.stringify([...parsedStorage, history])
    }

    localStorage.setItem(historyStorageKey, newStorageState)
  }, [])

  const addItem = useCallback(
    (item: IHistoryItem) => {
      const lastTryOn = itemsRef.current[itemsRef.current.length - 1]

      item.apiKey = getPartnerAPIKey()

      if (lastTryOn?.imageUrl === item.imageUrl) return

      itemsRef.current = [...itemsRef.current, item]
      setItems([...itemsRef.current])
      syncToLocalStorage(item)
      setIndex(itemsRef.current.length)
    },
    [syncToLocalStorage],
  )

  const updateItem = useCallback(
    (data: THistoryUpdateItem) => {
      if (!itemsRef.current[index - 1]) return

      const { products, ...history } = { products: data?.products, ...data }

      itemsRef.current[index - 1] = { ...itemsRef.current[index - 1], ...history, ...products }

      const dataToSync = [...itemsRef.current]
      setItems(dataToSync)
      syncToLocalStorage(dataToSync)
    },
    [setItems, syncToLocalStorage, index],
  )

  const getPreviousItem = useCallback(() => {
    if (index <= 1) return

    const newIndex = index - 1
    const previousItem = items[newIndex - 1]

    setIndex(newIndex)

    return previousItem
  }, [index, items])

  const getCurrentItem = useCallback(() => {
    return items[items.length - 1]
  }, [items])

  const getNextItem = useCallback(() => {
    if (index === items.length) return

    const newIndex = index + 1
    const nextItem = items[index]

    setIndex(newIndex)

    return nextItem
  }, [index, items])

  const restartHistory = useCallback(() => {
    const storage = localStorage.getItem(historyStorageKey)
    const parsedStorage = storage ? JSON.parse(storage) : []

    setIndex(parsedStorage.length ? parsedStorage.length + 1 : 0)
  }, [])

  const value = {
    fetchHistoryFromStorage,
    itemsRef,
    items,
    addItem,
    index,
    getPreviousItem,
    getCurrentItem,
    getNextItem,
    updateItem,
    restartHistory,
  }

  return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
}
