import { useCallback, useRef, useState } from 'react'

import { useLazyQuery, useSubscription } from '@apollo/client'

import { AxiosClient } from '~/clients/axios'

import { defaultErrorMessage } from './constants'
import { CATEGORY_TO_PARTNER, SUBSCRIPTION_TRYON_FIT } from './graphql'
import {
  ICategoryToPartnerData,
  IFitQueryData,
  IFitQueryVars,
  IFitSubscribeParams,
  IGenerateTryonFitPayload,
  IUseTryOnFitQuery,
  TFitOnError,
  TFitOnSuccess,
} from './types'

export const useTryOnFitQuery = (): IUseTryOnFitQuery => {
  const [variables, setVariables] = useState<IFitQueryVars | null>(null)
  const tryonFitLimitRef = useRef(0)

  const onSuccessRef = useRef<{ call: TFitOnSuccess }>()
  const onErrorRef = useRef<{ call: TFitOnError }>()

  const [fetchCategoryToPartner] = useLazyQuery<ICategoryToPartnerData>(CATEGORY_TO_PARTNER)

  useSubscription<IFitQueryData, IFitQueryVars>(SUBSCRIPTION_TRYON_FIT, {
    variables: variables as IFitQueryVars,
    skip: !variables,
    onData: ({ data }) => {
      const result = data.data?.tryon || []

      if (result && result?.length < tryonFitLimitRef.current) return

      onSuccessRef.current?.call(result)
    },
    onError: () => {
      onErrorRef.current?.call?.(new Error(defaultErrorMessage))
    },
  })

  const unsubscribe = useCallback(() => {
    onSuccessRef.current = undefined
    onErrorRef.current = undefined

    setVariables(null)
  }, [])

  const subscribe = useCallback(
    ({ parameters, onSuccess, onError, tryonFitLimit }: IFitSubscribeParams) => {
      tryonFitLimitRef.current = tryonFitLimit
      unsubscribe()

      setVariables(parameters)

      onSuccessRef.current = { call: onSuccess }
      onErrorRef.current = { call: onError }
    },

    [unsubscribe],
  )

  const generateTryonFit = async (payload: IGenerateTryonFitPayload) => {
    const { status } = await AxiosClient().post('/tryon/fit', payload)

    return status
  }

  return { subscribe, unsubscribe, generateTryonFit, fetchCategoryToPartner }
}
