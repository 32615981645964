import { gql } from '@apollo/client'

export const GET_TRYON = gql`
  subscription tryon($tryon_uuid: String!) {
    tryon(where: { tryon_uuid: { _eq: $tryon_uuid } }) {
      id
      image_url
      status
      tryon_uuid
    }
  }
`
