import { useContextSelector } from 'use-context-selector'

import { TryOnFitContext } from '.'

export const useTryOnFitContext = () => {
  const isLoading = useContextSelector(TryOnFitContext, state => state.isLoading)
  const data = useContextSelector(TryOnFitContext, state => state.data)
  const setData = useContextSelector(TryOnFitContext, state => state.setData)
  const setIsLoading = useContextSelector(TryOnFitContext, state => state.setIsLoading)

  return {
    isLoading,
    data,
    setData,
    setIsLoading,
  }
}
