import { FC } from 'react'

import { TIconProps } from '.'

export const TurnCamera: FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      data-testid="icon-turn-camera"
      style={{
        width: 'auto',
        height: size,
      }}
      id="turnCamera"
      data-name="turn-camera"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.999985218 15.999874115"
    >
      <g id="_05-Symbols" data-name="05-Symbols">
        <g id="_02_Icons_turn-camera" data-name="02)Icons/turn-camera">
          <g id="_-Icon-color" data-name="_-Icon-color">
            <path
              id="path-1"
              fill={color}
              d="M15.7653884888,12.2343273163c.312795639,.312795639,.312795639,.8183898926,0,1.1311855316l-2.3999662399,2.3999652863c-.1559977531,.15599823-.3607950211,.2343959808-.5655918121,.2343959808-.2047972679,0-.4095945358-.0783977509-.565592289-.2343959808-.312795639-.312795639-.312795639-.8183879852,0-1.1311836243l1.0343856812-1.0343856812H2.8719601631C1.2879815102,13.5999088287,.0000004768,12.3335266113,.0000004768,10.7767486572v-1.9767723083C.0000004768,8.3575820923,.3575959206,7.9999876022,.7999882698,7.9999876022c.4423942566,0,.7999897003,.3575944901,.7999897003,.7999887466v1.9767723083c0,.6743907928,.5703926086,1.2231826782,1.271982193,1.2231826782H13.2686238289l-1.0343856812-1.0343856812c-.312795639-.312795639-.312795639-.8183879852,0-1.1311836243s.8183884621-.312795639,1.1311841011,0l2.3999662399,2.3999652863Zm.2345967293-5.0342283249c0,.4423933029-.3575949669,.7999887466-.7999887466,.7999887466s-.7999887466-.3575954437-.7999887466-.7999887466v-1.9767723083c0-.6735906601-.5703921318-1.2231826782-1.271982193-1.2231826782H2.731361866l1.0343856812,1.0343847275c.3119955063,.312795639,.3119955063,.8183889389,0,1.1311845779-.15599823,.15599823-.3607959747,.2343969345-.5655918121,.2343969345-.2047977448,0-.4095954895-.0783987045-.5655918121-.2343969345L.2345967293,3.7657470703C-.0781989098,3.4529514313-.0781989098,2.9473586082,.2345967293,2.6345629692L2.6345639229,.2345967293C2.9465584755-.0781989098,3.4529519081-.0781989098,3.7657475471,.2345967293c.3119955063,.312795639,.3119955063,.8183884621,0,1.1311841011l-1.0343856812,1.0343852043H13.1280255318c1.5831780434,0,2.8719596863,1.2663822174,2.8719596863,2.8231606483v1.9767723083Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
