import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { Icon } from '~/components/Icon'

import theme from '~/theme'

import { getSimplifiedLanguageCode } from '~/utils/translate'

import * as Styled from './styles'
import { audioList } from './constants'
import { ICameraControlsProps, TAudios } from './types'

export const CameraControls: FC<ICameraControlsProps> = ({ onSwitchButtonClick, getAudioPlayer }) => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false)
  const [currentAudio, setCurrentAudio] = useState<TAudios>()
  const [hasAudioStopped, setHasAudioStoppend] = useState(false)
  const audioRef = useRef<HTMLAudioElement>()

  const audioStop = useCallback(() => {
    audioRef.current?.pause()

    setHasAudioStoppend(true)
    setIsAudioPlaying(false)
  }, [])

  const audioPlay = useCallback(
    async (name: TAudios): Promise<TAudios> =>
      new Promise(resolve => {
        /* istanbul ignore next */
        if (!audioRef.current) return

        const regionalLanguage = getSimplifiedLanguageCode()

        audioRef.current?.pause()
        audioRef.current.src = audioList[name][regionalLanguage]

        setCurrentAudio(name)
        setHasAudioStoppend(false)
        setIsAudioPlaying(true)

        audioRef.current.play()

        audioRef.current.onended = () => {
          setIsAudioPlaying(false)
          resolve(name)
        }
      }),
    [],
  )

  useEffect(() => {
    audioRef.current = audioRef.current || new Audio()

    getAudioPlayer({ play: audioPlay, stop: audioStop, isAudioPlaying, hasAudioStopped, currentAudio })
  }, [audioPlay, audioStop, getAudioPlayer, isAudioPlaying, hasAudioStopped, currentAudio])

  return (
    <Styled.Container data-testid="camera-controls-container">
      <Styled.SwitchButton
        onClick={onSwitchButtonClick}
        size={33}
        backgroundColor="transparent"
        icon={<Icon name="turnCamera" size="33px" color={theme.colors.white} />}
        testID="switch-camera-button"
      />
    </Styled.Container>
  )
}
