import styled, { css } from 'styled-components'

import { TextButton } from '~/components/TextButton'
import { Paragraph, Title } from '~/components/Typography/styles'
import { IArrowDownStyles, IBalloonStyles, IDotStyles, IOverlayStyles } from '~/components/Walkthrough/types'

const FullAbsolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const Container = styled(FullAbsolute)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 2;
`

export const Overlay = styled(FullAbsolute)<IOverlayStyles>`
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  transform: translate3d(0, 0, 0);

  ${({ startAnimation }) =>
    startAnimation &&
    css`
      background-color: rgba(0, 0, 0, 0.5);
    `};
`

export const Balloon = styled.div<IBalloonStyles>`
  position: relative;
  margin: 0 30px 95px;
  padding: 15px;
  border-radius: 25px;
  text-align: center;
  background-color: white;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.3s ease 0.2s;
  transform: translate3d(0, 0, 0);

  ${({ startAnimation }) =>
    startAnimation &&
    css`
      opacity: 1;
    `};
`

const arrowPositionMap = {
  0: css`
    left: 45px;
  `,
  1: css`
    margin-left: calc(-15px / 2);
    left: 50%;
  `,
  2: css`
    right: 45px;
  `,
}

export const ArrowDown = styled.span<IArrowDownStyles>`
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid white;
  z-index: 1;

  ${({ position }) => arrowPositionMap[position]};
`

export const TipTitle = styled(Title)`
  margin: 15px 0 10px !important;
  color: black;
`

export const Description = styled(Paragraph)`
  font-size: 12px;
  margin-bottom: 25px !important;
  color: ${({ theme }) => theme.colors.textPrimary};
`

export const TextButtonStyled = styled(TextButton)`
  font-weight: 700;
  font-size: 13px;

  &:first-child {
    margin-right: 6px;
  }

  &:last-child {
    margin-left: 6px;
  }

  &[disabled] {
    opacity: ${props => props.theme.opacity.default};
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DotList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0;
`

export const Dot = styled.li<IDotStyles>`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  opacity: 0.1;
  border: 1px solid ${({ theme }) => theme.colors.quickSilver};

  &:last-child {
    margin-right: 0;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      opacity: 1;
      border-color: ${({ theme }) => theme.colors.primary};
    `};
`
