import { FC } from 'react'

import * as Styled from './styles'
import { IImageSlotListProps } from './types'

export const ImageSlotList: FC<IImageSlotListProps> = ({ slotSize = 3, slotList = [] }) => (
  <Styled.ImageSlotList data-testid="image-slot-list">
    {Array.from({ length: slotSize }, (_, index) => {
      const hasImage = slotList && slotList[index]

      return (
        <Styled.ImageSlotListItem key={index} data-testid="image-slot-list-item">
          {hasImage && <Styled.Image src={hasImage} alt={hasImage} data-testid="image-slot-item-image" />}
          <Styled.ImageSlotBorder hasImage={Boolean(hasImage)} data-testid="image-slot-item-border" />
          <Styled.ImageSlotCounter data-testid="image-slot-item-counter">{index + 1}</Styled.ImageSlotCounter>
        </Styled.ImageSlotListItem>
      )
    })}
  </Styled.ImageSlotList>
)
