import styled from 'styled-components'

import { ISizingButtonProps } from './types'

export const Container = styled.button<Pick<ISizingButtonProps, 'active' | 'inStock'>>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 999px;
  padding: 8px 8px 8px 24px;
  width: 204px;
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  transition: ${({ theme }) => theme.transitions.ease('border-color')};
  border: ${({ active, theme }) => `2px solid ${active ? theme.colors.primary : theme.colors.neutral.black100}`};
  background-color: ${({ inStock, theme }) => (inStock ? theme.colors.white : theme.colors.neutral.black100)};
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
`

export const FitMessage = styled.span<Pick<ISizingButtonProps, 'inStock'> & { color: string }>`
  display: block;
  font-size: 12px;
  line-height: 12px;
  color: ${({ inStock, color, theme }) => (inStock ? color : theme.colors.neutral.black400)};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`

export const DescriptionMessage = styled.span<{ color: string }>`
  display: block;
  font-size: 10px;
  line-height: 14px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ color }) => color};
`
