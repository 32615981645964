import React from 'react'

import { TIconProps } from '.'

const Bra: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      data-testid="icon-bra"
      style={{
        width: 'auto',
        height: size,
      }}
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-49.000000, -40.000000)">
          <g id="Icon/Tops" transform="translate(40.000000, 40.000000)">
            <rect id="Rectangle" x="0" y="0" width="66" height="66" />
            <g id="tops" transform="translate(9.000000, 0.000000)">
              <path
                d="M14.4000759,37 C19.4700358,37 22.3050134,34.3939145 22.7100102,29.5094548 L25.2899898,29.5094548 C25.6949866,34.3939145 28.4999644,37 33.5999241,37 C38.2390794,37 42,33.0874301 42,28.2610306 C42.0095493,24.6841056 40.0595578,21.4139679 36.9748975,19.8341673 L34.7399151,12.8585971 C34.5286679,12.2035858 33.8470268,11.8507559 33.2174272,12.0705294 C32.5878276,12.2903029 32.2486859,12.9994562 32.4599331,13.6544675 L34.7849148,20.8797226 C34.7849148,24.3478636 30.9504272,26.1704372 26.967595,26.7620298 C25.9683294,26.9104563 24.9597259,26.9813958 24,26.9813958 C19.2150378,26.9813958 13.2150852,25.2179967 13.2150852,20.8797226 L15.5400669,13.6544675 C15.6601958,13.3440656 15.6601958,12.9973407 15.5400669,12.6869388 C15.2333452,12.0766798 14.5174071,11.8284016 13.9200797,12.1251479 C13.6378694,12.2815671 13.42338,12.5437644 13.3200844,12.8585971 L11.0251025,19.8341673 C7.94044215,21.4139679 5.99045067,24.6841056 6,28.2610306 C6,33.0874301 9.76092064,37 14.4000759,37 Z"
                id="Path"
                fill={color}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Bra
