import styled from 'styled-components'

import { IconButton } from '~/components/IconButton'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
`

export const Timeout = styled.div`
  z-index: 4;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`

export const IconButtonClose = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
`
