import { useCallback, useState } from 'react'

import { IListMeasurements } from '~/hooks/useAvatar/types'

import { IFormData } from './types'

export const useForm = (measurements?: IListMeasurements) => {
  const [formData, setFormData] = useState<IFormData>({
    height: 0,
    waist: 0,
    chest: 0,
    hip: 0,
  })
  const [formChanged, setFormChanged] = useState(false)

  const enableFormSave = useCallback((status: boolean) => setFormChanged(status), [])

  const clearForm = useCallback(
    () =>
      setFormData({
        height: 0,
        waist: 0,
        chest: 0,
        hip: 0,
      }),
    [],
  )

  const handleSelectChange = useCallback(
    (name: string, value: number) => {
      const currentValues = measurements?.current.map((item, index) => ({
        ...item,
        value: item.value || measurements?.original[index].value,
      }))

      const nextValues = { ...formData, [name]: value }

      let isFormChanged = false

      currentValues?.forEach(current => {
        if (!nextValues[current.name] || current.value === nextValues[current.name]) return
        isFormChanged = true
      })

      setFormData(nextValues)

      setFormChanged(isFormChanged)
    },
    [formData, measurements],
  )

  const saveFormData = useCallback(
    (send: () => void = () => ({})) => {
      send()
      enableFormSave(false)
    },
    [enableFormSave],
  )

  return {
    formData,
    formChanged,
    handleSelectChange,
    saveFormData,
    clearForm,
    enableFormSave,
  }
}
