import React from 'react'

import { TIconProps } from '.'

const Plus: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg viewBox="0 0 20 20" data-testid="icon-plus" style={{ width: size, height: size }}>
      <defs>
        <path
          d="M14.106383,10.3446809 L12.2255319,10.3446809 L12.2255319,8.46382979 C12.2255319,7.94659574 11.8023404,7.52340426 11.2851064,7.52340426 C10.7678723,7.52340426 10.3446809,7.94659574 10.3446809,8.46382979 L10.3446809,10.3446809 L8.46382979,10.3446809 C7.94659574,10.3446809 7.52340426,10.7678723 7.52340426,11.2851064 C7.52340426,11.8023404 7.94659574,12.2255319 8.46382979,12.2255319 L10.3446809,12.2255319 L10.3446809,14.106383 C10.3446809,14.623617 10.7678723,15.0468085 11.2851064,15.0468085 C11.8023404,15.0468085 12.2255319,14.623617 12.2255319,14.106383 L12.2255319,12.2255319 L14.106383,12.2255319 C14.623617,12.2255319 15.0468085,11.8023404 15.0468085,11.2851064 C15.0468085,10.7678723 14.623617,10.3446809 14.106383,10.3446809 M11.2851064,18.8085106 C7.13688936,18.8085106 3.76170213,15.4333234 3.76170213,11.2851064 C3.76170213,7.13688936 7.13688936,3.76170213 11.2851064,3.76170213 C15.4333234,3.76170213 18.8085106,7.13688936 18.8085106,11.2851064 C18.8085106,15.4333234 15.4333234,18.8085106 11.2851064,18.8085106 M11.2851064,1.88085106 C6.0996,1.88085106 1.88085106,6.0996 1.88085106,11.2851064 C1.88085106,16.4706128 6.0996,20.6893617 11.2851064,20.6893617 C16.4706128,20.6893617 20.6893617,16.4706128 20.6893617,11.2851064 C20.6893617,6.0996 16.4706128,1.88085106 11.2851064,1.88085106"
          id="icon-plus"
        />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1555.000000, -532.000000)">
          <g transform="translate(233.000000, 190.000000)">
            <g transform="translate(1000.000000, 146.000000)">
              <g transform="translate(-57.000000, 165.000000)">
                <g transform="translate(338.553191, 0.000000)">
                  <g transform="translate(39.497872, 30.093617)">
                    <use fill={color} fillRule="evenodd" xlinkHref="#icon-plus" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Plus
