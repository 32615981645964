import { TCurrentMeasurementsState } from '~/context/Measurements'

const getPerfectMeasurement = (currentMeasurements?: TCurrentMeasurementsState, productType?: string) => {
  const measurements = productType && currentMeasurements?.products[productType.toLowerCase()]?.measurements

  if (!measurements || !measurements.length) return

  return (
    measurements.find(item => item.fit_summary === 'FIT') ||
    measurements.find(item => item.fit_summary === 'SOME_TIGHT')
  )
}

export const getPerfectSize = (currentMeasurements?: TCurrentMeasurementsState, productType?: string) =>
  getPerfectMeasurement(currentMeasurements, productType)?.label || 'not_available'

export const getPerfectStock = (currentMeasurements?: TCurrentMeasurementsState, productType?: string) => {
  const perfectStock = getPerfectMeasurement(currentMeasurements, productType)?.has_stock

  return perfectStock === undefined ? 'not_available' : perfectStock
}
