import { FlattenSimpleInterpolation, css } from 'styled-components'

import { keyframesMap } from '~/components/Animations/Fade/constants'
import { TKeyframesMap } from '~/components/Animations/Fade/types'

export const getAnimation = (direction: keyof TKeyframesMap): FlattenSimpleInterpolation => {
  const name = keyframesMap[direction]
  const duration = '0.5s'
  const timingFunction = 'ease-in'
  const fillMode = 'forwards'

  return css`
    animation: ${name};
    animation-duration: ${duration};
    animation-timing-function: ${timingFunction};
    animation-fill-mode: ${fillMode};
  `
}
