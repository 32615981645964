import { gql } from '@apollo/client'

export const GET_SKINS = gql`
  query skins($order_by: [skin_tone_order_by!]) {
    skin_tone(order_by: $order_by) {
      id
      order
      value
    }
  }
`
