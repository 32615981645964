import { keyframes } from 'styled-components'

import { TKeyframesMap } from './types'

const keyframeMoveUp = keyframes`
  from {
    transform: translate(0, 40px);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
`

const keyframeMoveDown = keyframes`
  from {
    transform: translate(0 0);
    opacity: 1;
  }

  to {
    transform: translate(0, 40px);
    opacity: 0;
  }
`

export const keyframesMap: TKeyframesMap = {
  fadeIn: keyframeMoveUp,
  fadeOut: keyframeMoveDown,
}
