import styled from 'styled-components'

import { Prefix, Title } from '~/components/Typography/styles'

import theme from '~/theme'

export const Container = styled.form`
  display: grid;
  width: 100%;
  gap: ${theme.tokens.spacingUnit * 2}px;
  padding-top: ${theme.tokens.spacingUnit * 3}px;
`

export const CustomTitle = styled(Title)`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin: 0;
`

export const Text = styled(Prefix)`
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: left;
  margin: 0;
`

export const ContainerButton = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: auto auto auto;
  gap: ${theme.tokens.spacingUnit * 2}px;

  button:nth-child(1) {
    grid-column: span 1;
  }

  button:nth-child(2) {
    grid-column: span 2;
  }
`

export const Disclaimer = styled.p`
  display: block;
  text-align: center;
  font-size: 10px;
  line-height: 150%;
  margin: 0;
  color: ${theme.colors.neutral.black400};
`

export const Link = styled.a`
  display: block;
  text-decoration: underline !important;
  color: ${theme.colors.neutral.black400};
`

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${theme.tokens.spacingUnit * 8}px;
`

export const FeedbackTitle = styled(Title)`
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  line-height: 120%;
  margin: ${theme.tokens.spacingUnit * 2.5}px 0 ${theme.tokens.spacingUnit * 2}px;
`

export const FeedbackDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  margin: 0 0 ${theme.tokens.spacingUnit * 5}px;
  text-align: center;
`
