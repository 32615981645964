import { FC, useEffect, useState } from 'react'

import * as Styled from './styles'
import { IAnimationFadeProps } from './types'

export const AnimationFade: FC<IAnimationFadeProps> = ({ children, startAnimation, direction, testID = 'fade' }) => {
  const [enable, setEnable] = useState(false)

  useEffect(() => {
    if (!startAnimation && direction === 'fadeInOut') return

    setEnable(true)
  }, [direction, startAnimation])

  return (
    <>
      {enable && (
        <Styled.Container
          direction={direction}
          startAnimation={startAnimation}
          data-testid={`animation-${testID}-container`}
        >
          {children}
        </Styled.Container>
      )}
    </>
  )
}
