import { useContextSelector } from 'use-context-selector'

import { TermsContext } from '~/context/Terms/index'

export const useTermsContext = () => {
  const terms = useContextSelector(TermsContext, state => state.terms)
  const acceptTerms = useContextSelector(TermsContext, state => state.acceptTerms)

  return {
    terms,
    acceptTerms,
  }
}
