import { FC } from 'react'

import { TIconProps } from '.'

export const Padlock: FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: 'auto',
        height: size,
      }}
      data-testid="icon-padlock"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6258 9.21101H4.8545H4.85406C4.49252 9.21101 4.12921 9.25364 3.77371 9.33735L3.3221 9.44392V6.47309C3.3221 2.90341 6.31756 0 9.9996 0C13.6816 0 16.6771 2.90363 16.6771 6.47309V9.44392L16.2255 9.33735C15.87 9.25381 15.5067 9.21101 15.1448 9.21101H13.3735V6.47279C13.3735 4.66889 11.8598 3.20205 9.99982 3.20205C8.13896 3.20205 6.6258 4.66907 6.6258 6.47279V9.21101ZM4.85492 9.84814H15.1455L15.1455 9.84823C17.4586 9.84823 19.3337 11.6659 19.3333 13.9077V19.5342C19.3333 22.0006 17.2708 24 14.7266 24H5.27342C2.72916 24 0.666672 22.0006 0.666672 19.5342V13.9082C0.666672 11.6658 2.54174 9.84814 4.85492 9.84814ZM11.5933 19.7469V17.4041H11.5934C12.2955 16.9204 12.7546 16.1262 12.7546 15.2272C12.7546 13.7527 11.5214 12.5564 10.0002 12.5564C8.47908 12.5564 7.2459 13.7526 7.2459 15.2272C7.2459 16.1258 7.70457 16.9206 8.40637 17.4041V19.7469C8.40637 20.5967 9.12409 21.2912 10.0002 21.2912C10.8765 21.2912 11.5933 20.5961 11.5933 19.7469Z"
        fill={color}
      />
    </svg>
  )
}
