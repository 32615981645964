import styled, { css } from 'styled-components'

import { ChildWrapper } from '../styles'

export const Container = styled.div<{ withoutPadding: boolean }>`
  ${ChildWrapper} {
    overflow-y: hidden;

    ${({ withoutPadding }) =>
      withoutPadding &&
      css`
        padding: 0;
      `};
  }
`

export const LoadingIconWrapper = styled.span<{ $loading: boolean }>`
  transition: opacity ease 0.5s;
  opacity: ${({ $loading }) => ($loading ? '1' : '0')};
`
