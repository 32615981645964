export type TAgeGroup = 'CHILDREN' | 'ADULTS'

type TAgeGroupMap = {
  [key: string]: TAgeGroup
}

export const AgeGroupMap: TAgeGroupMap = {
  CHILDREN: 'CHILDREN',
  ADULTS: 'ADULTS',
}
