import { FC, useEffect, useRef, useState } from 'react'

import * as Styled from './styles'
import { IAnimation, IAnimationPulseProps } from './types'

export const AnimationPulse: FC<IAnimationPulseProps> = ({ children, testID = 'pulse', startAnimation, color }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [styles, setStyles] = useState<IAnimation>()

  useEffect(() => {
    const child = containerRef.current?.firstChild as HTMLElement

    setStyles({
      width: child.clientWidth,
      height: child.clientHeight,
      borderRadius: getComputedStyle(child).borderRadius,
    })
  }, [])

  return (
    <Styled.Container ref={containerRef} data-testid={`animation-${testID}-container`}>
      {children}
      {styles?.width && styles.height && styles.borderRadius && (
        <Styled.Animation
          {...styles}
          startAnimation={startAnimation}
          color={color}
          data-testid={`animation-${testID}`}
        />
      )}
    </Styled.Container>
  )
}
