import { modelsWithInvertedCameraList } from './constants'
import { IShouldUseSecondBackCameraParams } from './types'

export const shouldUseSecondBackCamera = async ({
  navigatorObject,
}: IShouldUseSecondBackCameraParams): Promise<boolean> => {
  if (!navigatorObject?.userAgentData) {
    return false
  }

  const result = await navigatorObject.userAgentData.getHighEntropyValues(['model'])

  return modelsWithInvertedCameraList.includes(result.model)
}
