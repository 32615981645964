import { IGender } from '~/entities'
import { IOption, TOptionMap } from '~/screens/SizingScreen/types'

export const heightOptions: IOption[] = Array.from({ length: 131 }, (_, index) => (index + 80) / 100).map(value => ({
  label: value.toFixed(2).replace('.', ','),
  value: parseInt(value.toFixed(2).replace('.', '')),
}))

export const weightOptions: IOption[] = Array.from({ length: 191 }, (_, index) => index + 10).map((value: number) => ({
  label: `${value}kg`,
  value,
}))

export const ageOptions: IOption[] = Array.from({ length: 96 }, (_, index) => index + 4).map((value: number) => ({
  label: `${value}`,
  value,
}))

export const chestOptions: IOption[] = Array.from({ length: 124 }, (_, index) => index + 50).map((value: number) => ({
  label: `${value}`,
  value,
}))

export const waistOptions: IOption[] = Array.from({ length: 117 }, (_, index) => index + 50).map((value: number) => ({
  label: `${value}`,
  value,
}))

export const hipOptions: IOption[] = Array.from({ length: 127 }, (_, index) => index + 50).map((value: number) => ({
  label: `${value}`,
  value,
}))

export const gendersList: Array<IGender> = [
  {
    id: 1,
    name: 'MALE',
  },
  {
    id: 2,
    name: 'FEMALE',
  },
]

export const optionsMap: TOptionMap = {
  height: heightOptions,
  waist: waistOptions,
  hip: waistOptions,
  chest: chestOptions,
}
