import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Slider from 'react-slick'

import { SlickSlider } from '~/components/SlickSlider'

import { Card } from '~/screens/Recommendation/components/card'
import { MobileUtils } from '~/utils/mobileUtils'

import * as Styled from './styles'
import { IRecommendationCardProps } from '../card/types'
import { sliderSettings } from './constants'
import { IListProps, TArrowStyled } from './types'

export const List: FC<IListProps> = ({ data, onAfterChange = () => ({}), onBeforeChange = () => ({}) }) => {
  const isMobile = useMemo(() => MobileUtils.isMobile(), [])

  const [isRendered, setIsRendered] = useState(false)
  const [arrowdisabled, setArrowDisabled] = useState<TArrowStyled>('prev')
  const [currentState, setCurrentState] = useState(0)
  const [slider, setSlider] = useState<Slider>()

  const listRef = useRef<HTMLDivElement>(null)
  const slickRenderRef = useRef(false)
  const preventTouchEdgeFriction = useRef(false)

  const renderItem = (data: unknown) => {
    const values = data as IRecommendationCardProps

    return <Card {...values} height={`${listRef.current?.clientHeight}px`} />
  }

  const afterChange = useCallback(
    (current: number) => {
      setCurrentState(current)
      /* istanbul ignore if: it was ignored because we can't simulate a touch screen to test a prevent edge friction on slick list */
      if (preventTouchEdgeFriction.current) return

      onAfterChange({ id: data[current].id })
    },
    [data, onAfterChange],
  )

  const beforeChange = useCallback(
    (current: number, next: number) => {
      /* istanbul ignore if: it was ignored because we can't simulate a touch screen to test a prevent edge friction on slick list */
      if (current === next) {
        preventTouchEdgeFriction.current = true

        return
      }

      preventTouchEdgeFriction.current = false

      onBeforeChange({ id: data[next].id, direction: current < next ? 'next' : 'prev' })
    },
    [data, onBeforeChange],
  )

  useEffect(() => {
    if (!listRef.current?.clientHeight) return

    setIsRendered(true)
  }, [])

  useEffect(() => {
    let arrowDisabled: TArrowStyled = currentState === 0 ? 'prev' : 'next'

    if (currentState !== 0 && currentState !== data.length - 1) {
      arrowDisabled = null
    }

    setArrowDisabled(arrowDisabled)
  }, [currentState, data.length])

  useEffect(() => {
    if (!slider || slickRenderRef.current) return

    const timer = setTimeout(() => {
      slider.slickGoTo(data.length - 1, true)
      setCurrentState(data.length - 1)
      slickRenderRef.current = true
    }, 1)

    return () => clearTimeout(timer)
  }, [slider, data.length])

  return (
    <Styled.Container
      ref={listRef}
      data-testid="recommendation-list"
      arrowDisabled={arrowdisabled}
      arrowAnimation={!!data.find(item => item.animationActions)}
    >
      {!!data.length && isRendered && (
        <SlickSlider
          data={data}
          renderItem={renderItem}
          onSlickRender={setSlider}
          settings={{
            ...sliderSettings,
            arrows: !isMobile,
            touchMove: isMobile,
            draggable: isMobile,
            swipe: isMobile,
            afterChange,
            beforeChange,
            initialSlide: data.length - 1,
          }}
        />
      )}
    </Styled.Container>
  )
}
