/* eslint-disable no-console */

const generalStyles = [
  'font-size: 10px',
  'margin: 5px 0',
  'border: 1px solid rgba(0,0,0,0.1)',
  'text-transform: uppercase',
  'font-weight: bold',
]

const stylesA = [
  ...generalStyles,
  'padding: 5px 0 5px 0',
  'color: #ffffff',
  'background: #00a2ff',
  'border-radius: 5px 0 0 5px',
].join(';')

const stylesB = [
  ...generalStyles,
  'padding: 5px 10px 5px 0',
  'color: #666',
  'background: #fff',
  'border-radius: 0 5px 5px 0',
  'border-left: none',
].join(';')

export const dorisLog = (message: string): void => {
  console.log(`%c DORIS %c %s`, stylesA, stylesB, message)
}
