import { useCallback } from 'react'

import { useQueryTryon } from '~/hooks-queries/tryon'
import { IFetchParameters } from '~/hooks-queries/tryon/types'
import { type ITryonUpscaleSubscribeParameters, useQueryTryonUpscale } from '~/hooks-queries/tryonUpscale'

import { useHistory } from '~/context/History'
import { useTryOnFitContext } from '~/context/TryOnFit'
import { ITryonData, ITryonStateError, TCurrentTryonState } from '~/context/Tryon'

import { Sentry } from '~/clients/sentry'
import { IProduct } from '~/entities'
import {
  extractProduct as extractBaseProduct,
  removeProduct as removeBaseProduct,
  setProduct as setBaseProduct,
} from '~/utils/baseProduct'

import { getTryonOutfits } from './helpers'
import { TryonStorageInstance } from './storage'
import {
  IUseTryon,
  TCreateTryon,
  TRemoveTryon,
  TResetTryon,
  TSetTryon,
  TStartTryon,
  TStartTryonUpscale,
  TUpdateTryon,
} from './types'

export const useTryon = (): IUseTryon => {
  const { fetch } = useQueryTryon()
  const { subscribe: subscribeUpscale, unsubscribe: finishTryonUpscale } = useQueryTryonUpscale()
  const { addItem: historyAddItem, updateItem: historyUpdateItem, restartHistory } = useHistory()

  const { setData } = useTryOnFitContext()

  const setTryon = useCallback(({ data, setState }: TSetTryon) => {
    const { imageUrl, products, baseProduct, origin, tryonId, upscaledImageUrl } = data
    const productsBaseMap: TSetTryon['data']['products'] & { [key: string]: IProduct | undefined } = {
      TOP: products?.top,
      BOTTOM: products?.bottom,
      FULL: products?.full,
    }

    setState({ imageUrl, upscaledImageUrl, tryonId, products, origin })

    setBaseProduct(baseProduct ? productsBaseMap[baseProduct] : extractBaseProduct(products))

    TryonStorageInstance.set(products)
  }, [])

  const createTryon = useCallback(
    ({ data, setState }: TCreateTryon) => {
      const { model, products, tryon, baseProduct, from } = data

      historyAddItem({
        top: products.top,
        bottom: products.bottom,
        full: products.full,
        imageUrl: tryon.image_url,
        upscaledImageUrl: tryon.upscaled_image,
        tryonId: tryon.id,
        model,
      })

      setTryon({
        data: {
          products: products,
          imageUrl: tryon.image_url,
          upscaledImageUrl: tryon.upscaled_image,
          tryonId: tryon.id,
          origin: from,
          baseProduct,
        },
        setState,
      })

      if (typeof setData === 'function') setData([])
    },
    [historyAddItem, setData, setTryon],
  )

  const updateTryon = useCallback(
    ({ data, setState }: TUpdateTryon) => {
      setState(current => ({ ...(current as TCurrentTryonState), ...data }))
      historyUpdateItem(data)
    },
    [historyUpdateItem],
  )

  const startTryon = useCallback(
    ({ data, setState }: TStartTryon) => {
      const tryon: IFetchParameters = {
        payload: {
          avatarUuid: data.idModel,
          upscale: data?.upscale,
          outfits: getTryonOutfits(data.products),
        },
        callbackOnSuccess: tryon => {
          setState(current => ({
            ...current,
            data: {
              ...data,
              tryon: {
                id: tryon.id,
                image_url: tryon.image_url,
                tryon_uuid: tryon.tryon_uuid,
                upscaled_image: tryon?.upscaled_image,
              },
            },
          }))
        },
        callbackOnError: error => {
          const response: ITryonStateError = {
            name: error.message.includes('Timeout') ? 'PROCESSING_TIMEOUT' : 'PROCESSED_ERROR',
            message: error.message,
          }

          Sentry.captureException<ITryonData>({
            errorName: response.name,
            errorMessage: response.message,
            filePath: 'src/hooks/useTryon/index.ts',
            functionName: 'startTryon',
            payload: data,
          })

          // eslint-disable-next-line no-console
          console.error(response)

          setState(current => ({
            ...current,
            data,
            error: response,
          }))
        },
        callbackOnProcessing: status => {
          setState(current => ({
            ...current,
            called: true,
            data: status ? undefined : current?.data,
            error: status ? undefined : current?.error,
            isLoading: status,
          }))
        },
      }

      fetch(tryon)
    },
    [fetch],
  )

  const startTryonUpscale = useCallback(
    ({ tryonId, setState }: TStartTryonUpscale) => {
      const subscribeParams: ITryonUpscaleSubscribeParameters = {
        tryonId,
        onSuccess: data => {
          setState(current => ({ ...current, data }))
        },
        onError: error => {
          const response: ITryonStateError = {
            name: error.message.includes('Timeout') ? 'PROCESSING_TIMEOUT' : 'PROCESSED_ERROR',
            message: error.message,
          }

          Sentry.captureException<string>({
            errorName: response.name,
            errorMessage: response.message,
            filePath: 'src/hooks/useTryon/index.ts',
            functionName: 'startTryonUpscale',
            payload: `TryOnID: ${tryonId}`,
          })

          // eslint-disable-next-line no-console
          console.error(response)

          setState(current => ({
            ...current,
            data: undefined,
            error: response,
          }))
        },
        onProcessing: ({ isLoading, unsub }) => {
          if (unsub) {
            setState(undefined)
            return
          }

          setState(current => ({
            ...current,
            data: isLoading ? undefined : current?.data,
            error: isLoading ? undefined : current?.error,
            called: true,
            isLoading,
          }))
        },
      }

      subscribeUpscale(subscribeParams)
    },
    [subscribeUpscale],
  )

  const resetTryon = useCallback(({ data, setState }: TResetTryon) => setState(data), [])

  const removeTryon = useCallback(
    ({ setState }: TRemoveTryon) => {
      TryonStorageInstance.remove()

      removeBaseProduct()
      restartHistory()
      resetTryon({ data: ['stateCurrentTryon'], setState })
    },
    [resetTryon, restartHistory],
  )

  return {
    startTryon,
    startTryonUpscale,
    finishTryonUpscale,
    resetTryon,
    createTryon,
    updateTryon,
    setTryon,
    removeTryon,
  }
}
