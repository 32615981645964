import { useCallback, useEffect, useRef, useState } from 'react'

import { useAvatar } from '~/hooks/useAvatar'
import { TSendAvatarImage, TSendAvatarInfos } from '~/hooks/useAvatar/types'
import { TryonStorageInstance } from '~/hooks/useTryon/storage'

import { useAvatarContext } from '~/context/Avatar'
import { useNavigation } from '~/context/Navigation'

import { Sentry } from '~/clients/sentry'
import { IAvatar, TAgeGroup, TGender } from '~/entities'
import { screenPositionsValues } from '~/screens/CameraScreen/constants'
import { TStepPhotoWasSent } from '~/screens/CameraScreen/types'

import { IUseSubscriptionProcessingSizing } from './types'

export const useSubscriptionProcessingSizing: () => IUseSubscriptionProcessingSizing = () => {
  const { sendAvatarInfos, updateAvatar, sendAvatarImage } = useAvatar()
  const { stateAvatar, statePositions, setAvatarState, setPositionsState } = useAvatarContext()
  const { params } = useNavigation()

  const [listen, setListen] = useState(false)
  const [sizingProcessedStatus, setSizingProcessedStatus] = useState<
    IUseSubscriptionProcessingSizing['sizingProcessedStatus']
  >({ ended: false, error: false })

  const stepPhotoWasSent = useRef<TStepPhotoWasSent>({
    firstStep: !!statePositions?.positions.front?.called,
    secondStep: !!statePositions?.positions.side?.called,
  })

  const onSizingProcessed = useCallback(() => {
    const frontPositionError = statePositions?.positions.front?.error
    const sidePositionError = statePositions?.positions.side?.error
    const currentStorage = TryonStorageInstance.get() || {}
    const avatar = stateAvatar?.data as IAvatar

    let logError: string | undefined
    let trackError: string | undefined

    if (!listen) return

    if (!stepPhotoWasSent.current.firstStep || !stepPhotoWasSent.current.secondStep) {
      const slotList = params?.base64SlotList as Array<string>

      slotList.forEach((image, index) => {
        const currentPosition = index === 0 ? 'firstStep' : 'secondStep'

        if (stepPhotoWasSent.current[currentPosition]) return

        const data: TSendAvatarImage['data'] = {
          avatar_uuid: avatar.avatar_uuid as string,
          type: screenPositionsValues[currentPosition].type,
          image,
        }

        stepPhotoWasSent.current[currentPosition] = true

        sendAvatarImage({ data, setState: setPositionsState })
      })

      return
    }

    if (statePositions?.positions.front?.isLoading || statePositions?.positions.side?.isLoading) return

    if (!frontPositionError && !sidePositionError) {
      const data: TSendAvatarInfos['data'] = {
        avatar_uuid: avatar.avatar_uuid,
        age: avatar.age as number,
        gender: avatar.gender as TGender,
        age_group: avatar.age_group as TAgeGroup,
        height: avatar.height as number,
        weight: avatar.weight as number,
        top_product_id: currentStorage.top?.id,
        bottom_product_id: currentStorage.bottom?.id,
        full_product_id: currentStorage.full?.id,
      }

      if (avatar.age_group !== 'CHILDREN') {
        sendAvatarInfos({ data, setState: setAvatarState })
      }

      updateAvatar(avatar.id, {
        image_url_side: statePositions?.positions.side?.data,
        image_url_front: statePositions?.positions.front?.data,
      })
    } else {
      if (frontPositionError) {
        logError = `${frontPositionError.name} - ${frontPositionError.message}`
        trackError = frontPositionError.message
      }

      if (sidePositionError) {
        logError = `${logError ? `${logError} | ` : ''}${sidePositionError.name} - ${sidePositionError.message}`
        trackError = `${trackError ? `${trackError} | ` : ''}${sidePositionError.message}`
      }

      // eslint-disable-next-line no-console
      console.error(logError)
      Sentry.captureException({
        errorName: 'PROCESSING_TIMEOUT',
        errorMessage: trackError as string,
        filePath: 'src/hooks/useAvatar/useSubscriptionProcessingSizing/index.ts',
        functionName: 'onSizingProcessed',
      })
    }

    setListen(false)
    setSizingProcessedStatus({ ended: true, error: !!logError })
  }, [
    statePositions,
    stateAvatar,
    listen,
    sendAvatarInfos,
    updateAvatar,
    setAvatarState,
    sendAvatarImage,
    setPositionsState,
    params,
  ])

  const subscriptionSizing = useCallback(() => setListen(true), [])

  useEffect(onSizingProcessed, [onSizingProcessed])

  return {
    subscriptionSizing,
    sizingProcessedStatus,
  }
}
