import { FC, useMemo } from 'react'

import { ModelCard } from '~/components/ModelCard'
import { SlickSlider } from '~/components/SlickSlider'

import { getImageWidthUsingRatio } from '~/utils/getImageWidthUsingRatio'
import { MobileUtils } from '~/utils/mobileUtils'

import * as Styled from './styles'
import { settings } from './constants'
import { IModelsListItemProps, IModelsListProps } from './types'

const ModelsList: FC<IModelsListProps> = ({ onClick, testID = 'models-list', data, initialIndex }) => {
  const isMobile = useMemo(() => MobileUtils.isMobile(), [])

  const renderItem = (values: unknown) => {
    const item = values as IModelsListItemProps
    const itemImageSuggestedWidth = getImageWidthUsingRatio({ size: 286 })
    const itemImageUrl = `${item.url}?w=${itemImageSuggestedWidth}`

    return <ModelCard onClick={onClick} active={item.active} id={item.id} testID={testID} url={itemImageUrl} />
  }

  return (
    <Styled.ContainerSlick data-testid={testID}>
      <SlickSlider
        settings={{ ...settings, arrows: !isMobile, initialSlide: initialIndex }}
        data={data}
        renderItem={renderItem}
      />
    </Styled.ContainerSlick>
  )
}

export default ModelsList
