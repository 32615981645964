import { FC } from 'react'

import { Icon } from '~/components/Icon'

import theme from '~/theme'

import * as Styled from './styles'
import { IImageCardProps } from './types'

export const ImageCard: FC<IImageCardProps> = ({
  hasOverlay,
  imageUrl,
  text,
  color = theme.colors.white,
  iconName,
  testID = 'image-card',
  onClick,
  borderRadius = '',
}) => (
  <Styled.Container data-testid={`${testID}-container`} borderRadius={borderRadius} onClick={onClick}>
    <Styled.Image borderRadius={borderRadius} data-testid={`${testID}-image`} src={imageUrl} />
    {hasOverlay && <Styled.Overlay borderRadius={borderRadius} data-testid={`${testID}-overlay`} />}
    <Styled.Content>
      {text && (
        <Styled.Text data-testid={`${testID}-text`} onClick={onClick} color={color} align="center">
          {text}
        </Styled.Text>
      )}
      {iconName && <Icon name={iconName} size={20} color={color} />}
    </Styled.Content>
  </Styled.Container>
)
