export const defaultImages = [
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/F02.png',
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/M01.png',
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/F03.png',
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/M02.png',
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/F04.png',
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/M03.png',
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/F08.png',
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/M04.png',
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/F09.png',
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/M05.png',
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/F13.png',
  'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/doris-trigger-default-images/M06.png',
]

export const INTERVAL_VALUE = 2000
