import styled from 'styled-components'

import { Prefix } from '~/components/Typography/styles'

const Container = styled(Prefix)<{ color?: string; disabled?: boolean }>`
  font-weight: 600;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
`

export { Container }
