import styled, { css } from 'styled-components'

import { Element as Skeleton } from '~/components/SkeletonLoader/styles'

import { IBoxProps, IContainerStyles } from './types'

const Container = styled.div<IContainerStyles>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height || 'auto'};
`
const Content = styled.div<IBoxProps>`
  display: flex;
  align-items: flex-start;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  height: 100%;
  padding: ${({ padding }) => padding || '0px'};
  border-radius: ${({ borderRadius }) => borderRadius || 0};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};

  ${({ borderWidth, borderColor }) =>
    borderWidth &&
    borderColor &&
    css`
      border-color: ${borderColor};
      border-style: solid;
      border-width: ${borderWidth};
    `};
`

const SkeletonLoader = styled(Skeleton)<{ borderRadius?: string; width?: string; height?: string }>`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
`

export { Container, Content, SkeletonLoader }
