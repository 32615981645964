import React from 'react'

import { TIconProps } from '.'

const Audio: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 50 49"
      data-testid="icon-audio"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <path
        id="audio"
        fill={color}
        d="M30.556 48.737V44.43c4.49-1.296 8.16-3.785 11.006-7.465 2.848-3.68 4.271-7.859 4.271-12.535s-1.412-8.866-4.236-12.57c-2.824-3.703-6.504-6.18-11.041-7.43V.125c5.74 1.297 10.416 4.202 14.027 8.716C48.194 13.355 50 18.55 50 24.43c0 5.88-1.806 11.076-5.417 15.59-3.61 4.514-8.287 7.42-14.027 8.716ZM0 32.834V16.167h11.111L25 2.278v44.445l-13.889-13.89H0Zm29.167 3.333V12.764c2.546.787 4.571 2.269 6.076 4.445S37.5 21.815 37.5 24.5c0 2.64-.764 5.047-2.292 7.223-1.527 2.176-3.541 3.657-6.041 4.444Zm-8.334-23.333-7.847 7.5h-8.82v8.333h8.82l7.847 7.57V12.834Z"
      />
    </svg>
  )
}

export default Audio
