import { FC } from 'react'

import { IIllustrationProps } from '../types'

export const SideArmsClosedErrorMembers: FC<IIllustrationProps> = ({ width, height }) => (
  <svg
    style={{ width, height }}
    data-testid="illustration-side-arms-closed-error-members"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 401"
  >
    <g fill="none" fillRule="nonzero">
      <path
        fill="#FFF"
        fillOpacity=".75"
        d="M15.5 52.9c-.7 1-.5 1.8.4 2.3.7.5.8 1.1.7 1.8-.2 1.1-.3 2.2-.3 3.3 0 2 1.1 3.4 3.2 3.3 2.5-.1 4.9-.6 7.4-1 1-.2 2-.5 3-.9.9-.3 1.2 0 1.1.9 0 .4-.1.8-.2 1.2-.4 1.5 0 2.9.6 4.2.4.8.6 1.7.7 2.6.2 1-.3 1.8-1.2 2.5-10.7 8.5-19.4 18.5-25.2 30.7-.8 1.7-1.3 3.5-1.8 5.3-.7 2.5-.5 4.9.6 7.3.6 1.4.8 2.9.4 4.4-1.5 6-1.2 12-.2 18 1.3 7.5 2.5 15.1 3.4 22.6.4 3.4 0 7-.1 10.4-.3 8.9.2 17.8 3.2 26.3 1.2 3.3 1 6.8.9 10.3-.1 5.5-.4 11-.3 16.4.1 7.2 1.8 14.2 3.6 21.1 2.8 11.1 5.6 22.1 9.8 32.8.6 1.6 1.5 3.1 2.2 4.6.3.6.3 1.4.1 2.1-.4 1.7-1 3.4-1.3 5.2-.6 3.6 0 7.1 1.9 10.2 2 3.2 2.7 6.3 2.2 10.1-.8 6.1.4 12.3 1.4 18.4 1.5 9.1 3.1 18.2 4.5 27.3.5 3.5.5 7 .4 10.6-.1 7.2-3.1 13.1-8.4 18-4.3 3.9-9.2 6.5-14.9 8.1-3.1.9-6.2.8-9.2 1.3-1.9.3-2.9 1.3-2.4 2.9.2.8 1 1.7 1.7 2 1.1.6 2.4.9 3.7 1 7.7.4 15.4.6 22.9-.9 5.2-1.1 10-1.1 15 .6 2.6.9 5.4.8 8-.5 2.2-1 3.2-2.7 3-5-.1-1.1-.3-2.3-.6-3.3-2.3-7.3-2.3-14.7-.9-22 1.2-6.3 2.9-12.4 4.4-18.6 1.8-7.5 3.1-15 3.2-22.7.1-8.7-1-17.2-4.3-25.3-.5-1.3-.7-2.8-.7-4.2 0-4-.3-7.9-1.8-11.7-.5-1.3-.4-2.9-.1-4.3 1.5-6.9 3-13.8 3.6-20.9.5-6.3 0-12.5-1.7-18.6-.6-2.1-1-4.2-1.5-6.3-1-4.1-.4-7.9 1.3-11.8 1.8-4.1 3.6-8.2 5.2-12.4 2.4-6.6 2.3-13.2-.6-19.6-1.4-3.1-2.7-6.3-4.6-9.2-2.2-3.4-2.9-7-2.9-10.8.1-14.6 2.7-28.8 8-42.5 2-5.3 4.2-10.5 6-15.9 2.9-8.7 3.1-17.5 1.6-26.5-.6-3.4-2.2-6.3-4.2-9.2-3-4.3-6-8.6-8.8-13-.8-1.3-1.5-2.9-1.8-4.4-.5-2.4-.6-4.8-.7-7.3-.2-2.8.9-5.3 2.3-7.6.1-.1.2-.3.3-.4 2.7-1.4 3.5-4.1 4.7-6.5 3.6-7.3 4.5-15 2.7-22.9-.8-3.7-3.2-6.5-5.9-9.1-8.7-8.3-24.4-9.4-33.9-2.4-2 1.5-3.8 3.3-4.9 5.6-.5 1.1-.8 2.1 0 3.2.2.2 0 .9-.2 1.1-2.9 3.7-4.1 8.1-4.9 12.6-.1.6.1 1.4.3 2 .2.8.5 1.6.8 2.3.9 2.2.6 4.2-1 6.1-1.1 1.4-2.1 2.9-2.9 4.4-.8 1.7-.2 2.6 1.6 3 .7.1 1.8.3 1.9.6.2 1-.1 2-.2 3 0 .2-.2.4-.3.5-.5 1.7-.4 2.2 1 3.2Z"
      />
      <path fill="#FF433D" d="M36 181.5c7.7 0 14 6.3 14 14s-6.3 14-14 14-14-6.3-14-14 6.3-14 14-14Z" />
      <path
        fill="#FF433D"
        d="M36 159.5c19.9 0 36 16.1 36 36s-16.1 36-36 36-36-16.1-36-36 16.1-36 36-36Z"
        opacity=".2"
      />
      <path fill="#FF433D" d="M36 310c7.7 0 14 6.3 14 14s-6.3 14-14 14-14-6.3-14-14 6.3-14 14-14Z" />
      <path fill="#FF433D" d="M36 288c19.9 0 36 16.1 36 36s-16.1 36-36 36-36-16.1-36-36 16.1-36 36-36Z" opacity=".2" />
    </g>
  </svg>
)
