import React from 'react'

import { TIconProps } from '.'

const Blouse: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 38 28"
      data-testid="icon-blouse"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M36.9 23.25L30.51 3.31C30.1075 2.21937 29.287 1.33408 28.23 0.849999C25.06 -0.520001 23.36 0.679999 23.36 0.679999C23.156 0.790386 23.0236 0.99844 23.01 1.23C23 5.55 21.07 9 19 9C16.93 9 15 5.57 15 1.26C14.9864 1.02844 14.854 0.820386 14.65 0.709999C14.65 0.709999 12.95 -0.490001 9.78 0.879999C8.72304 1.36408 7.90253 2.24937 7.5 3.34L1.1 23.25C0.837554 23.8647 1.08406 24.5783 1.67 24.9L4.09 26.19C4.37656 26.3424 4.71386 26.3678 5.02 26.26C5.32943 26.1542 5.58226 25.9266 5.72 25.63L10 12C11.6036 16.0804 11.7379 20.5914 10.38 24.76C10.38 24.76 10.09 25.55 10.09 25.65C10.09 26.85 14.09 27.82 19 27.83C23.91 27.84 27.91 26.83 27.91 25.65C27.91 25.55 27.62 24.76 27.62 24.76C26.2621 20.5914 26.3965 16.0804 28 12L32.28 25.66C32.4177 25.9566 32.6706 26.1842 32.98 26.29C33.2861 26.3978 33.6234 26.3724 33.91 26.22L36.33 24.93C36.932 24.6068 37.181 23.8728 36.9 23.25Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Blouse
