import { FC, useCallback, useMemo } from 'react'

import { CategoryButton } from '~/components/CategoryButton'
import { ICategoryListProps } from '~/components/CategoryList/types'
import FlatList from '~/components/FlatList'
import { CategoriesSkeletonLoader } from '~/components/ProductsBackdrop/styles'
import { SkeletonLoader } from '~/components/SkeletonLoader'

import theme from '~/theme'

import { ICategoryItem } from '~/entities'
import { MobileUtils } from '~/utils/mobileUtils'

import * as Styled from './styles'

const CategoryList: FC<ICategoryListProps> = ({ data, activeItem, selectedItems, onClick, disabled = false }) => {
  const isMobile = useMemo(() => MobileUtils.isMobile(), [])

  const renderItem = useCallback(
    (item: ICategoryItem) => (
      <CategoryButton
        name={item.name}
        isActive={item.id === activeItem?.id}
        hasPin={!!selectedItems.find(category => category.id === item.id)}
        onClick={() => onClick(item)}
        disabled={disabled}
      />
    ),
    [activeItem?.id, disabled, onClick, selectedItems],
  )

  const renderCategoryList = () => (
    <Styled.Container data-testid="category-list">
      {data?.length ? (
        <FlatList
          testID="category-flat-list"
          data={data}
          renderItem={renderItem}
          styles={{
            item: { padding: 0 },
            container: {
              width: '350px',
              margin: '0 auto',
              padding: 0,
              ...(isMobile && { width: 'auto', padding: '0 10px', margin: '0 -20px' }),
            },
          }}
          arrowNavigationItems={4}
          showEndScrollSpace={isMobile}
          endScrollSpaceSize="20px"
          disabled={disabled}
        />
      ) : (
        <Styled.Label color={theme.colors.silverChalice}>Nenhum item encontrado</Styled.Label>
      )}
    </Styled.Container>
  )

  const renderCategoriesSkeleton = useCallback(
    () => (
      <CategoriesSkeletonLoader data-testid="categories-skeleton-loader">
        {Array.apply(0, Array(6)).map((_, index) => (
          <SkeletonLoader
            key={`skeleton-${index}`}
            width="64px"
            height="100%"
            borderRadius="10px"
            styles={{ margin: index === 0 ? '0 10px 0 0' : '0 10px', flexShrink: 0 }}
          />
        ))}
      </CategoriesSkeletonLoader>
    ),
    [],
  )

  return data ? renderCategoryList() : renderCategoriesSkeleton()
}

export default CategoryList
