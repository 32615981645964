import { node } from 'prop-types'

import { useRef } from 'react'

import { HandleBuyButtonContext, TCallbackRef } from '.'

export const HandleBuyButtonProvider: React.FC = ({ children }) => {
  const handleBuyButtonRef = useRef<TCallbackRef>()

  const value = {
    handleBuyButtonRef,
  }

  return <HandleBuyButtonContext.Provider value={value}>{children}</HandleBuyButtonContext.Provider>
}

HandleBuyButtonProvider.propTypes = {
  children: node.isRequired,
}
