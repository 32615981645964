import { useCallback, useEffect, useState } from 'react'

import { useAvatar } from '~/hooks/useAvatar'

import { useAvatarContext } from '~/context/Avatar'

import { Sentry } from '~/clients/sentry'
import { IAvatar } from '~/entities'

import { IUseSubscriptionProcessingInfos } from './types'

export const useSubscriptionProcessingInfos: () => IUseSubscriptionProcessingInfos = () => {
  const { updateAvatar } = useAvatar()
  const { stateAvatar } = useAvatarContext()

  const [listen, setListen] = useState(false)
  const [infosProcessedStatus, setInfosProcessedStatus] = useState<
    IUseSubscriptionProcessingInfos['infosProcessedStatus']
  >({ ended: false, error: false })

  const onInfosProcessed = useCallback(() => {
    let logError: string | undefined

    if (!listen || stateAvatar?.isLoading) return

    if (!stateAvatar?.errors?.update) {
      const avatar = stateAvatar?.data as IAvatar

      updateAvatar(avatar.id, {
        age: avatar.age as number,
        height: avatar.height as number,
        weight: avatar.weight as number,
      })
    } else {
      logError = `${stateAvatar?.errors?.update.name} - ${stateAvatar?.errors?.update.message}`

      // eslint-disable-next-line no-console
      console.error(logError)

      Sentry.captureException<IAvatar>({
        errorName: stateAvatar?.errors?.update.name,
        errorMessage: stateAvatar?.errors?.update.message,
        filePath: 'src/hooks/useAvatar/useSubscriptionProcessingAvatar/index.ts',
        functionName: 'onInfosProcessed',
        payload: stateAvatar.data as IAvatar,
      })
    }

    setListen(false)
    setInfosProcessedStatus({ ended: true, error: !!logError })
  }, [stateAvatar, listen, updateAvatar])

  const subscriptionInfos = useCallback(() => setListen(true), [])

  useEffect(onInfosProcessed, [onInfosProcessed])

  return {
    subscriptionInfos,
    infosProcessedStatus,
  }
}
