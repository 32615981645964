import { FC } from 'react'

import { AnimationPulse } from '~/components/Animations'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { Tooltip } from '~/components/Tooltip'
import Typography from '~/components/Typography'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import { pinStyles } from './constants'
import { Container } from './styles'
import { ISizingButtonInfoProps } from './types'

export const SizingInfoButton: FC<ISizingButtonInfoProps> = ({
  onClick,
  showButton,
  type = 'default',
  showTooltip,
}) => (
  <Container data-testid="sizing-info-button-container">
    <Tooltip
      title={translate('SIZING_INFO_TITLE')}
      id="sizing-info"
      active={!!(showTooltip && showButton)}
      place="left"
      width={260}
      offset={25}
      forceWidth={true}
      content={
        <Typography align="center" type="prefix">
          {translate('SIZING_INFO_DESCRIPTION')}
        </Typography>
      }
    >
      <AnimationPulse
        startAnimation={type === 'notification' && showButton}
        color={theme.colors.white}
        testID="sizing-button"
      >
        <IconButton
          disabled={!showButton}
          testID="sizing-info-button"
          size={40}
          icon={<Icon name="tape" color={theme.colors.white} size="22px" />}
          backgroundColor={theme.colors.primary}
          onClick={onClick}
          pin={pinStyles[type]}
        />
      </AnimationPulse>
    </Tooltip>
  </Container>
)
