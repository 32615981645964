import { FC } from 'react'

import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { IPermissionsRequestProps } from './types'

export const PermissionsRequestCamera: FC<IPermissionsRequestProps> = ({ onClick }) => (
  <Styled.Container data-testid="permission-request-container">
    <Styled.WrapperContent>
      <Styled.Title color={theme.colors.white} align="center">
        {translate('PERMISSION_REQUEST_SCREEN_TITLE')}
      </Styled.Title>

      <Styled.Text color={theme.colors.white} align="center">
        {translate('PERMISSION_REQUEST_SCREEN_DESCRIPTION')}
      </Styled.Text>

      <TextButton background={theme.colors.white} textColor="#000" testID="enable-camera-button" onClick={onClick}>
        {translate('PERMISSION_REQUEST_ENABLE_CAMERA_BUTTON')}
      </TextButton>
    </Styled.WrapperContent>
  </Styled.Container>
)
