import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 20px;
`

export const Content = styled.div`
  height: 100%;
  overflow: auto;
  padding: 20px 20px 0 20px;
  gap: 0;
  display: flex;
  flex-direction: column;

  > div {
    margin: 20px 0;
  }
`

export const Footer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 0;

  button {
    margin-bottom: 20px;
  }
`

export const Message = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 125%;
  margin: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  padding: ${({ theme }) => theme.tokens.spacingUnit * 2}px;
  border-radius: ${({ theme }) => theme.tokens.spacingUnit}px;
  background: ${({ theme }) => theme.colors.neutral.black100};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`
