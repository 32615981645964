import { FC } from 'react'

import { Icon } from '~/components/Icon'

import theme from '~/theme'

import * as Styled from './styles'
import { ITextAndIconButtonProps } from './types'

export const TextAndIconButton: FC<ITextAndIconButtonProps> = ({
  children,
  icon,
  textTransform = 'uppercase',
  onClick,
  disabled,
  iconDirection = 'left',
  variant = 'default',
  className = '',
  testID = 'text-icon-button',
}) => {
  return (
    <Styled.Button className={className} variant={variant} onClick={onClick} disabled={disabled} data-testid={testID}>
      <Styled.Content
        variant={variant}
        data-testid="text-icon-button-content"
        textTransform={textTransform}
        iconDirection={iconDirection}
      >
        <Icon name={icon} size={16} color={variant !== 'default' ? theme.colors.primary : theme.colors.white} />
        {children}
      </Styled.Content>
    </Styled.Button>
  )
}
