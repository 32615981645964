export class HandleGoToCartInstance {
  private static ref: () => void

  static set({ ref }: { ref: () => void }) {
    if (!ref) return

    this.ref = ref
  }

  static call(): void {
    this.ref()
  }

  static hasRef() {
    return !!this.ref
  }
}
