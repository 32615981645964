import { FC } from 'react'

import { Backdrop } from '~/components/Backdrop'
import { Icon } from '~/components/Icon'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { IAudioConfirmPromptProps } from './types'

export const AudioConfirmPrompt: FC<IAudioConfirmPromptProps> = ({ visible, handleConfirm }) => (
  <Backdrop layerIndex={4} visible={visible} testID="audio-confirm-prompt">
    <Styled.Container>
      <Styled.ContainerIcon>
        <Icon name="audio" color={theme.colors.primary} size={'40px'} />
      </Styled.ContainerIcon>
      <Styled.TitleAlert>{translate('VOLUME_BACKDROP_TITLE')}</Styled.TitleAlert>
      <Styled.Text align="center">{translate('VOLUME_BACKDROP_DESCRIPTION')}</Styled.Text>
      <Styled.ContainerButton>
        <TextButton testID="continue-button" onClick={handleConfirm}>
          {translate('VOLUME_BACKDROP_BUTTON_TEXT')}
        </TextButton>
      </Styled.ContainerButton>
    </Styled.Container>
  </Backdrop>
)
