import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  .doris-widget-start-button {
    div,
    button,
    svg,
    span,
    h2 {
      all: revert;
    }
  }
`
