import { useContextSelector } from 'use-context-selector'

import { WidgetStateContext } from '~/context/WidgetState'

export const useWidgetState = () => {
  const state = useContextSelector(WidgetStateContext, ({ state }) => state)
  const closeWidget = useContextSelector(WidgetStateContext, state => state.closeWidget)
  const openWidget = useContextSelector(WidgetStateContext, state => state.openWidget)
  const setReady = useContextSelector(WidgetStateContext, state => state.setReady)
  const ready = useContextSelector(WidgetStateContext, state => state.ready)
  const firstInteraction = useContextSelector(WidgetStateContext, state => state.firstInteraction)
  const setFirstInteraction = useContextSelector(WidgetStateContext, state => state.setFirstInteraction)
  const isEnableFirstInteraction = useContextSelector(WidgetStateContext, state => state.isEnableFirstInteraction)
  const setEnableFirstInteraction = useContextSelector(WidgetStateContext, state => state.setEnableFirstInteraction)
  const shouldCallTryonFit = useContextSelector(WidgetStateContext, state => state.shouldCallTryonFit)
  const setShouldCallTryonFit = useContextSelector(WidgetStateContext, state => state.setShouldCallTryonFit)

  return {
    state,
    closeWidget,
    openWidget,
    setReady,
    ready,
    firstInteraction,
    setFirstInteraction,
    isEnableFirstInteraction,
    setEnableFirstInteraction,
    shouldCallTryonFit,
    setShouldCallTryonFit,
  }
}
