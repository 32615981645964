import { FC, useCallback, useMemo } from 'react'

import { useCurrentModel } from '~/hooks'

import { useNavigation } from '~/context/Navigation'
import { useTermsContext } from '~/context/Terms'

import { AppButtonClose } from '~/components/AppButtonClose'
import { Terms } from '~/components/Terms'

import { getSimplifiedLanguageCode } from '~/utils/translate'

import * as Styled from './styles'
import { VIDEO_BASE_URL } from './constants'

export const TermsScreen: FC = () => {
  const { acceptTerms } = useTermsContext()
  const { navigate } = useNavigation()
  const { getCurrentModel } = useCurrentModel()
  const { gender } = getCurrentModel()

  const videoData = useMemo(() => {
    const baseUrl = `${VIDEO_BASE_URL}-${(gender as string).toLocaleLowerCase()}`
    const videoUrl = `${baseUrl}-${getSimplifiedLanguageCode()}.mp4`
    const posterUrl = `${baseUrl}.png`

    return { videoUrl, posterUrl }
  }, [gender])

  const handleOnAcceptTerms = useCallback(() => {
    acceptTerms()
    navigate('Home')
  }, [acceptTerms, navigate])

  return (
    <Styled.Container data-testid="terms-screen">
      <AppButtonClose />

      <Styled.Video testId="terms-intro-video" poster={videoData.posterUrl}>
        <source data-testid="terms-intro-video-src" src={videoData.videoUrl} type="video/mp4" />
      </Styled.Video>

      <Terms onAccept={handleOnAcceptTerms} active />
    </Styled.Container>
  )
}
