import { FC } from 'react'

import { AnimationZoomOut } from '~/components/Animations'
import CombinedItemsButton from '~/components/CombinedItemsButton'
import { Icon } from '~/components/Icon'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { hexToRgba } from '~/utils/colors'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { IActionsProps } from './types'

export const Actions: FC<IActionsProps> = ({ cart, combine, like, animation }) => (
  <Styled.Container data-testid="recommendation-actions">
    <Styled.Row align="flex-end">
      <AnimationZoomOut visible={!animation}>
        <CombinedItemsButton combinedItemsCount={1} onClick={cart.onClick} />
      </AnimationZoomOut>
    </Styled.Row>
    <Styled.Row align="space-between">
      <Styled.BoxCombineButton>
        <AnimationZoomOut visible={!animation}>
          <TextButton testID="combine-button" textTransform="capitalize" onClick={combine.onClick}>
            {translate('BOTTOM_ACTIONS_COMBINE')}
          </TextButton>
        </AnimationZoomOut>
      </Styled.BoxCombineButton>
      <AnimationZoomOut visible={!animation}>
        <Styled.HeartButton
          testID="like-button"
          size={40}
          icon={<Icon name="heart" color={like.active ? theme.colors.white : 'transparent'} size="20px" />}
          backgroundColor={like.active ? theme.colors.coralRed : `rgba(${hexToRgba(theme.colors.white, 0.35)})`}
          onClick={like.onClick}
        />
      </AnimationZoomOut>
    </Styled.Row>
  </Styled.Container>
)
