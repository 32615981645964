export const poseLandmarks = {
  NOSE: 0,
  LEFT_EYE: 1,
  RIGHT_EYE: 2,
  LEFT_EAR: 3,
  RIGHT_EAR: 4,
  LEFT_SHOULDER: 5,
  RIGHT_SHOULDER: 6,
  LEFT_ELBOW: 7,
  RIGHT_ELBOW: 8,
  LEFT_WRIST: 9,
  RIGHT_WRIST: 10,
  LEFT_HIP: 11,
  RIGHT_HIP: 12,
  LEFT_KNEE: 13,
  RIGHT_KNEE: 14,
  LEFT_ANKLE: 15,
  RIGHT_ANKLE: 16,
}

export const LEFT_KNEE_ANGLE_MIN_LIMIT = 170
export const LEFT_KNEE_ANGLE_MAX_LIMIT = 195

export const RIGHT_KNEE_ANGLE_MIN_LIMIT = 170
export const RIGHT_KNEE_ANGLE_MAX_LIMIT = 195

export const LEFT_SHOULDER_ANGLE_MIN_LIMIT = 10
export const LEFT_SHOULDER_ANGLE_MAX_LIMIT = 35

export const RIGHT_ELBOW_ANGLE_MIN_LIMIT = 130
export const RIGHT_ELBOW_ANGLE_MAX_LIMIT = 230

export const LEFT_ELBOW_ANGLE_MIN_LIMIT = 130
export const LEFT_ELBOW_ANGLE_MAX_LIMIT = 230

export const DISTANCE_ARMS_MIN_LIMIT = 120
export const DISTANCE_ARMS_MAX_LIMIT = 250

export const DISTANCE_SHOULDERS_LIMIT_FRONT = 80
export const DISTANCE_SHOULDERS_LIMIT_SIDE = 120

export const DISTANCE_HIPS_MIN_LIMIT = 0
export const DISTANCE_HIPS_MAX_LIMIT = 120

export const DISTANCE_KNEES_MIN_LIMIT = 0
export const DISTANCE_KNEES_MAX_LIMIT = 120

export const DISTANCE_ANKLES_MIN_LIMIT = 0
export const DISTANCE_ANKLES_MAX_LIMIT = 250

export const DISTANCE_FROM_WRIST_AND_HIP_MIN_LIMIT = 0
export const DISTANCE_FROM_WRIST_AND_HIP_MAX_LIMIT = 400
