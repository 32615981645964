import { FC } from 'react'

import { translate } from '~/utils/translate'
import { TTranslateKey } from '~/utils/translate/types'

import * as Styled from './styles'
import { colors } from './constants'
import { IInstructionalBalloonsProps } from './types'

export const InstructionalBalloons: FC<IInstructionalBalloonsProps> = ({
  description,
  width,
  status = 'default',
  testID = 'instructional-ballons',
}) => {
  return (
    <Styled.Container data-testid={`${testID}-container`} status={status} width={width}>
      <Styled.Text data-testid={`${testID}-description`} align="center" color={colors[status].description}>
        {translate(description as TTranslateKey)}
      </Styled.Text>
    </Styled.Container>
  )
}
