import { FC } from 'react'

import { Icon } from '~/components/Icon'

import theme from '~/theme'

import { Container } from './styles'
import { ITagProps } from './types'

export const Tag: FC<ITagProps> = ({ children, icon, text, background, onClick, testID }) => {
  return (
    <Container
      data-testid={testID || 'tag-container'}
      onClick={onClick}
      color={text?.color || theme.colors.white}
      size={text?.size || '12px'}
      background={background || theme.colors.black}
      transform={text?.transform || 'none'}
      cursorPointer={!!onClick}
    >
      {icon && <Icon name={icon.name} size={icon.size || '16px'} color={icon.color || theme.colors.white} />}
      {children}
    </Container>
  )
}
