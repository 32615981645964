import { useContextSelector } from 'use-context-selector'

import { AvatarContext } from '.'

export const useAvatarContext = () => {
  const stateAvatar = useContextSelector(AvatarContext, state => state.stateAvatar)
  const statePositions = useContextSelector(AvatarContext, state => state.statePositions)
  const setAvatarState = useContextSelector(AvatarContext, state => state.setAvatarState)
  const setPositionsState = useContextSelector(AvatarContext, state => state.setPositionsState)
  const clearStates = useContextSelector(AvatarContext, state => state.clearStates)

  return {
    stateAvatar,
    statePositions,
    setAvatarState,
    setPositionsState,
    clearStates,
  }
}
