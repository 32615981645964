import { useQuery } from '@apollo/client'

import { GET_CATEGORIES_TO_PARTNERS, IQueryCategories, IQueryData, IQueryVars } from '.'

export const useQueryCategories = ({ where }: IQueryVars): IQueryCategories => {
  const { data, called, loading, error } = useQuery<IQueryData, IQueryVars>(GET_CATEGORIES_TO_PARTNERS, {
    variables: { where },
  })

  const dataResponse = data?.category_to_partner?.map(
    category =>
      category.category && {
        id: category.id,
        category_order: category.category_order,
        category: {
          id: category.category.id,
          name: category.category.name,
          type: category.category.type,
        },
        hasProducts: !!category.category?.products_aggregate?.aggregate?.count,
      },
  )

  return {
    data: dataResponse || [],
    called,
    loading,
    error,
  }
}
