import { useCallback } from 'react'

import { useQueryRecommendation } from '~/hooks-queries/recommendation'

import { IUseRecommendation, TGetRecommendation, TUpdateRecommendation } from './types'

export const useRecommendation = (): IUseRecommendation => {
  const { getRecommendation: getRecommendationQuery, updateRecommendation: updateRecommendationQuery } =
    useQueryRecommendation()

  const getRecommendation = useCallback(
    ({ data, setState }: TGetRecommendation) =>
      getRecommendationQuery({
        payload: {
          avatar_uuid: data.model.id,
          user_uuid: data.user.uuid,
          newList: data.newList,
          upscale: data?.upscale,
          products: {
            top: {
              outfit_uuid: data.products?.top?.outfit_uuid,
            },
            bottom: {
              outfit_uuid: data.products?.bottom?.outfit_uuid,
            },
            full: {
              outfit_uuid: data.products?.full?.outfit_uuid,
            },
          },
        },
        callbackOnError: error =>
          setState(current => ({
            ...current,
            error,
          })),
        callbackOnSuccess: response =>
          setState(current => ({
            ...current,
            data: {
              ...response,
            },
          })),
        callbackOnProcessing: status =>
          setState(current => ({
            ...current,
            called: true,
            data: status ? undefined : current?.data,
            error: status ? undefined : current?.error,
            isLoading: status,
          })),
      }),
    [getRecommendationQuery],
  )

  const updateRecommendation = useCallback(
    ({ data, setState }: TUpdateRecommendation) =>
      updateRecommendationQuery({
        payload: { idRecommendation: data.idRecommendation, liked: data.values.liked },
        callbackOnError: error =>
          setState(current => ({
            ...current,
            error,
          })),
        callbackOnSuccess: () =>
          setState(current => ({
            ...current,
            data,
          })),
        callbackOnProcessing: status =>
          setState(current => ({
            ...current,
            called: true,
            data: status ? undefined : data,
            error: status ? undefined : current?.error,
            isLoading: status,
          })),
      }),
    [updateRecommendationQuery],
  )

  return {
    getRecommendation,
    updateRecommendation,
  }
}
