import styled, { keyframes } from 'styled-components'

import { hexToRgba } from '~/utils/colors'

const fadeIn = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`

export const UpscaleTag = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
`

export const Label = styled.span`
  font-weight: 700;
  line-height: normal;
  font-size: 8px;
  line-height: 110%;
  text-shadow: ${({ theme }) => `0 2px 4px 0 rgba(${hexToRgba(theme.colors.black, 0.1)})`};
  animation: ${fadeIn} 1s ease-in-out infinite;
  animation-fill-mode: both;
`

export const LoadingIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
`
