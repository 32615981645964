import styled from 'styled-components'

export const Button = styled.button`
  width: 42px;
  height: 3px;
  border-radius: 14px;
  background: ${({ theme }) => theme.colors.lightSilver};
  border: none;
  margin: 0 auto;
`
