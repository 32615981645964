import { createContext } from 'use-context-selector'

import { type MutableRefObject } from 'react'

import { IRecommendation } from '~/entities'

export type TUpdateRecommendationHistoryItem = Omit<Partial<IRecommendation>, 'id'> & { id: number }

export interface IRecommendationHistoryContext {
  recommendationListRef: MutableRefObject<Array<IRecommendation>>
  addRecommendationHistory: (item: IRecommendation) => void
  updateRecommendationHistory: (item: TUpdateRecommendationHistoryItem) => void
  clearRecommendationHistory: () => void
  fetchHistoryFromStorage: () => void
}

export const RecommendationHistoryContext = createContext<IRecommendationHistoryContext>(
  {} as IRecommendationHistoryContext,
)
