import { FC, useEffect } from 'react'

import AppContainer, { IAppConfigProps } from '~/components/AppContainer'
import AppProvider from '~/components/AppProvider'

import GlobalStyles from '~/theme/GlobalStyles'

import { injectFonts } from '~/utils/injectFonts'

const App: FC<IAppConfigProps> = ({ handleBuyButton }) => {
  useEffect(() => {
    injectFonts()
  }, [])

  return (
    <AppProvider>
      <GlobalStyles />
      <AppContainer handleBuyButton={handleBuyButton} />
    </AppProvider>
  )
}

export default App
