import React from 'react'

import { TIconProps } from '.'

const Dots: React.FC<TIconProps> = ({ color, size }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 20 4"
    data-testid="icon-dots"
    style={{ width: size, height: size }}
  >
    <g transform="translate(-321.000000, -85.000000)" fill="none">
      <g transform="translate(314.000000, 70.000000)">
        <g transform="translate(5.000000, 5.000000)">
          <path
            id="icon-dots"
            d="M4,10 C5.1045695,10 6,10.8954305 6,12 C6,13.1045695 5.1045695,14 4,14 C2.8954305,14 2,13.1045695 2,12 C2,10.8954305 2.8954305,10 4,10 Z M12,10 C13.1045695,10 14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 Z M20,10 C21.1045695,10 22,10.8954305 22,12 C22,13.1045695 21.1045695,14 20,14 C18.8954305,14 18,13.1045695 18,12 C18,10.8954305 18.8954305,10 20,10 Z"
          />
          <use xlinkHref="#icon-dots" fill={color} />
        </g>
      </g>
    </g>
  </svg>
)

export default Dots
