import { LOCAL_STORAGE_AVATARS_KEY } from '~/hooks/useAvatar/constants'
import { LOCAL_STORAGE_MODELS_KEY } from '~/hooks/useCurrentModel/constants'
import { LOCAL_STORAGE_NEWSLETTER_KEY } from '~/hooks/useNewsletter/constants'

import { Config } from '~/config'

const exceptionKeys = [LOCAL_STORAGE_AVATARS_KEY, LOCAL_STORAGE_MODELS_KEY, LOCAL_STORAGE_NEWSLETTER_KEY]

const checkIfHasDeprecatedStorage = () => {
  const newVersion = Config().GIT_HASH

  const currentVersion = localStorage.getItem('@doris:version')

  if (currentVersion != newVersion) {
    clearDeprecatedStorage()
    localStorage.setItem('@doris:version', newVersion)
  }
}

const clearDeprecatedStorage = () => {
  Object.keys(localStorage).forEach(key => {
    if (key.includes('@doris') && !exceptionKeys.includes(key)) {
      localStorage.removeItem(key)
    }
  })
}

export default checkIfHasDeprecatedStorage
