import { FC, useCallback } from 'react'

import theme from '~/theme'

import Tracking from '~/utils/tracking'
import { getSimplifiedLanguageCode, translate } from '~/utils/translate'

import * as Styled from './styles'

export const TermsDescription: FC = () => {
  const termsUrl =
    getSimplifiedLanguageCode() === 'pt'
      ? 'https://www.doris.mobi/termos-e-condicoes-e-politica-de-privacidade'
      : 'https://www.doris.mobi/en/privacy-policy'
  const openTerms = useCallback(() => {
    Tracking.logEvent('READY_PRIVACY_OR_TERMS', { widget: true })
  }, [])
  return (
    <Styled.ParagraphContainer>
      <Styled.Text data-testid="terms-description" color={theme.colors.neutral.black400}>
        {translate('TERMS_DESCRIPTION_1')}
      </Styled.Text>
      <Styled.Link data-testid="terms-accept-link" href={termsUrl} rel="external" target="_blank" onClick={openTerms}>
        {translate('TERMS_LINK_COPY')}
      </Styled.Link>
    </Styled.ParagraphContainer>
  )
}
