import { createClient } from 'graphql-ws'

import { ApolloClient as Apollo, HttpLink, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'

import { getHeaders } from './utils'

const httpsLink = new HttpLink({
  uri: process.env.REACT_APP_HASURA_HTTPS_URL,
})

const wssLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_HASURA_WSS_URL as string,
    connectionParams:
      /* istanbul ignore next */
      () => getHeaders(),
  }),
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wssLink,
  httpsLink,
)

const authLink = setContext((_, { headers }) => getHeaders(headers))

export const ApolloClient = new Apollo({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache(),
})
