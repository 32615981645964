import { FC } from 'react'

import * as Styled from './styles'
import { IModal } from './types'

export const Modal: FC<IModal> = ({ primaryButton, secondaryButton, children, footer, isVisible }) => {
  return (
    <Styled.Wrapper data-testid="modal-wrapper" aria-hidden={isVisible ? 'false' : 'true'}>
      <Styled.Shadow isVisible={isVisible} data-testid="modal-shadow" />
      <Styled.ModalContainer isVisible={isVisible} data-testid="modal-container">
        <Styled.ContentContainer data-testid="modal-content-container">{children}</Styled.ContentContainer>
        {footer ? (
          footer
        ) : (
          <Styled.ButtonContainer data-testid="modal-footer-container">
            {!!primaryButton && primaryButton}
            {!!secondaryButton && secondaryButton}
          </Styled.ButtonContainer>
        )}
      </Styled.ModalContainer>
    </Styled.Wrapper>
  )
}
