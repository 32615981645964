import { FC } from 'react'

import { TIconProps } from '.'

export const Camera: FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      data-testid="icon-camera"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g id="05-Symbols">
        <g id="Icons/camera" fill={color}>
          <path
            d="M13.2,4 C14.3032,4 15.0030365,4.935 15.0030365,6.08333333 L15.0030365,6.998703 L17.6,6.998703 C18.9232,6.998703 20,8.12036967 20,9.498703 L20,17.4966125 C20,18.8749459 18.9232,19.9966125 17.6,19.9966125 L6.4,19.9966125 C5.0768,19.9966125 4,18.8749459 4,17.4966125 L4,9.498703 C4,8.12036967 5.0768,6.998703 6.4,6.998703 L8.99955933,6.998703 L8.99955933,6.08333333 C8.99955933,4.935 9.6968,4 10.8,4 Z M17.25,9.003387 L6.75,9.003387 C6.336,9.003387 6,9.36419057 6,9.80695843 L6,17.1998156 C6,17.6425834 6.336,18.003387 6.75,18.003387 L17.25,18.003387 C17.664,18.003387 18,17.6425834 18,17.1998156 L18,9.80695843 C18,9.36419057 17.664,9.003387 17.25,9.003387 Z M12,10.5 C13.6542857,10.5 15,11.8457143 15,13.5 C15,15.1542857 13.6542857,16.5 12,16.5 C10.3457143,16.5 9,15.1542857 9,13.5 C9,11.8457143 10.3457143,10.5 12,10.5 Z M12,12 C11.173,12 10.5,12.673 10.5,13.5 C10.5,14.327 11.173,15 12,15 C12.827,15 13.5,14.327 13.5,13.5 C13.5,12.673 12.827,12 12,12 Z M12.7567807,5.2 L11.2635064,5.2 C10.778332,5.2 10.5,5.62628774 10.5,6.1 L10.5,7 L13.5,7 L13.5,6.1 C13.5,5.55862762 13.1837017,5.2 12.7567807,5.2 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </svg>
  )
}
