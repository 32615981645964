import { IGetImageWidthUsingRatioParams } from './types'

export const getImageWidthUsingRatio = ({
  size,
  returnAsPixel = false,
}: IGetImageWidthUsingRatioParams): string | number => {
  const ratio = window.devicePixelRatio
  const additionalResolutionRatio = 1
  const roundedRatio = Math.round(ratio + additionalResolutionRatio)
  const result = size * roundedRatio

  return returnAsPixel ? `${result}px` : result
}
