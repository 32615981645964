import { useCallback, useEffect, useRef, useState } from 'react'

import { useSubscription } from '@apollo/client'

import { AxiosClient } from '~/clients/axios'
import { ITryon } from '~/entities'

import { GET_TRYON } from './graphql'
import {
  IDataPost,
  IFetchParameters,
  IQueryData,
  IQueryVars,
  IUseQueryTryon,
  TTryonCallbackOnError,
  TTryonCallbackOnProcessing,
  TTryonCallbackOnSuccess,
} from './types'

export const useQueryTryon = (): IUseQueryTryon => {
  const callbackSuccess = useRef<{ call: TTryonCallbackOnSuccess }>()
  const callbackError = useRef<{ call: TTryonCallbackOnError }>()
  const callbackProcessing = useRef<{ call?: TTryonCallbackOnProcessing }>()
  const timeoutKey = useRef<NodeJS.Timeout>()
  const [tryonUuid, setTryonUuid] = useState<string>()

  const timeoutInSeconds = 60

  const { data, error, loading } = useSubscription<IQueryData, IQueryVars>(GET_TRYON, {
    variables: { tryon_uuid: tryonUuid },
    skip: !tryonUuid,
  })

  const unsubscribe = useCallback(() => {
    setTryonUuid(undefined)

    clearTimeout(timeoutKey.current as NodeJS.Timeout)

    callbackProcessing.current?.call && callbackProcessing.current?.call(false)
  }, [])

  const startTimer = useCallback(
    timerValue => {
      timeoutKey.current = setTimeout(() => {
        callbackError.current?.call && callbackError.current.call(new Error('[ERROR][PROCESSING]: Timeout.'))

        unsubscribe()
      }, timerValue)
    },
    [unsubscribe],
  )

  const fetch = useCallback(
    async ({ payload, callbackOnSuccess, callbackOnError, callbackOnProcessing }: IFetchParameters) => {
      const dataPost: IDataPost = {
        model_id: payload.avatarUuid,
        product_top_id: payload.outfits.full?.uuid ? undefined : payload.outfits.top?.uuid,
        product_bottom_id: payload.outfits.full?.uuid ? undefined : payload.outfits.bottom?.uuid,
        product_full_id: payload.outfits.full?.uuid,
        upscale: payload?.upscale,
      }
      const initialTime = new Date().getTime()
      const timerValue = timeoutInSeconds * 1000

      callbackSuccess.current = { call: callbackOnSuccess }
      callbackError.current = { call: callbackOnError }
      callbackProcessing.current = { call: callbackOnProcessing }

      try {
        if (!dataPost.product_top_id && !dataPost.product_bottom_id && !dataPost.product_full_id) {
          throw new Error(
            '[ERROR][PARAMETER][PAYLOAD]: é necessário informar pelomenos 1 outfit(outfits.top.uuid ou outfits.bottom.uuid) para realizar o tryon.',
          )
        }

        callbackProcessing.current?.call && callbackProcessing.current.call(true)

        const { data } = await AxiosClient().post<ITryon>('/tryon', dataPost, {
          timeout: timerValue,
          timeoutErrorMessage: '[ERROR][PROCESSING]: Timeout.',
        })

        if (data.status === 'PROCESSED_ERROR') {
          throw new Error('[ERROR][PROCESSING]: não foi possível gerar o tryon.')
        }

        if (data.status === 'PROCESSED') {
          callbackSuccess.current.call(data)
          callbackProcessing.current?.call && callbackProcessing.current.call(false)

          return
        }

        startTimer(timerValue + (initialTime - new Date().getTime()))

        setTryonUuid(data.tryon_uuid)
      } catch (e) {
        callbackError.current.call(e)
        callbackProcessing.current?.call && callbackProcessing.current.call(false)
      }
    },
    [startTimer],
  )

  useEffect(() => {
    if (loading) return

    if (error || data?.tryon[0]?.status === 'PROCESSED_ERROR') {
      callbackError.current?.call &&
        callbackError.current.call(error || new Error('[ERROR][PROCESSING]: não foi possível gerar o tryon.'))

      unsubscribe()
    }

    if (data?.tryon[0]?.status === 'PROCESSED') {
      callbackSuccess.current?.call && callbackSuccess.current.call(data.tryon[0])

      unsubscribe()
    }
  }, [data, error, loading, unsubscribe])

  return {
    fetch,
  }
}
