import { useCallback, useEffect } from 'react'

import { useAvatar } from '~/hooks/useAvatar'
import { useTryon } from '~/hooks/useTryon'

import { useAvatarContext } from '~/context/Avatar'
import { useNavigation } from '~/context/Navigation'
import { useTryonContext } from '~/context/Tryon'

import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { TimeoutError } from '~/components/TimeoutError'

import theme from '~/theme'

import { IAvatar } from '~/entities'
import Tracking from '~/utils/tracking'

import * as Styled from './styles'

export const Timeout = () => {
  const { params, navigate } = useNavigation()
  const { resetAvatar, updateAvatarInfos } = useAvatar()
  const { stateAvatar, setAvatarState, clearStates: clearAvatarStates } = useAvatarContext()
  const { stateTryon, setTryonState, clearStates: clearTryonStates } = useTryonContext()
  const { startTryon, resetTryon } = useTryon()

  const callback = useCallback(() => {
    Tracking.logEvent('ERROR', { type: 'reload', widget: true })

    if (params?.type === 'avatar') {
      const currentAvatarFormInfos: Partial<IAvatar> = {
        age: stateAvatar?.data?.age,
        weight: stateAvatar?.data?.weight,
        height: stateAvatar?.data?.height,
        gender: stateAvatar?.data?.gender,
        age_group: stateAvatar?.data?.age_group,
      }

      resetAvatar({ data: ['stateAvatar', 'statePositions'], setState: clearAvatarStates })

      updateAvatarInfos({
        data: currentAvatarFormInfos,
        setState: setAvatarState,
      })

      navigate('TimeoutProcessingScreen', params)

      return
    }

    if (params?.type === 'tryon' && stateTryon?.data) {
      startTryon({ data: stateTryon.data, setState: setTryonState })
    }

    navigate('Home')
  }, [
    navigate,
    resetAvatar,
    updateAvatarInfos,
    setAvatarState,
    clearAvatarStates,
    stateAvatar?.data,
    params,
    startTryon,
    stateTryon?.data,
    setTryonState,
  ])

  const handleClose = () => {
    if (params?.type === 'tryon') {
      resetTryon({ data: ['stateTryon'], setState: clearTryonStates })
    }

    navigate(params?.type === 'avatar' ? 'Models' : 'Home')
  }

  useEffect(() => Tracking.logEvent('ERROR', { type: 'open', widget: true }), [])

  return (
    <Styled.Container data-testid="timeout-screen">
      <Styled.Header>
        <IconButton
          onClick={handleClose}
          icon={<Icon name="close" size="16px" color={theme.colors.primary} />}
          size={32}
          testID="close-button"
        />
      </Styled.Header>
      <Styled.Content>
        <TimeoutError onPrimaryButtonClick={callback} />
      </Styled.Content>
    </Styled.Container>
  )
}
