import styled from 'styled-components'

const List = styled.ul`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0;
  padding: 0;
`

export { List }
