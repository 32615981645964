import { TAgeGroup, TGender } from '~/entities'

type TAgeOption = {
  label: string
  id: TAgeGroup
}

type TGenderOption = {
  label: string
  name: TGender
}

interface IParams {
  genderList: TGenderOption[]
  ageOptionList: TAgeOption[]
}

type TOutput = {
  label: string
  value: number
  gender: TGender
  age_option: TAgeGroup
}

export const buildCollectionOptions = ({ genderList, ageOptionList }: IParams): TOutput[] => {
  const result: TOutput[] = []
  let counter = 1

  ageOptionList.forEach(ageItem => {
    genderList.forEach(genderItem => {
      result.push({
        label: `${ageItem.label} ${genderItem.label}`,
        value: counter,
        gender: genderItem.name,
        age_option: ageItem.id,
      })

      counter++
    })
  })

  return result
}
