import PostHogUtil from '~/utils/postHog'
import { Singleton } from '~/utils/singleton'

class Tracking extends Singleton() {
  userId = null

  init() {
    PostHogUtil.init()
  }

  logEvent(eventName, eventData) {
    PostHogUtil.captureEvent(eventName, eventData)
  }

  /**
   * @param {Object} data An object to attach as user data
   */

  setUserProperties(data) {
    PostHogUtil.setUserProperties(data)
  }

  /**
   * Sets an identifier to an user. It's required
   * if you're going to attach user properties in PostHog.
   *
   * ### Usage:
   *
   *     // Set "27b60b12d5e6" as user identifier
   *     Tracking.setUserId('27b60b12d5e6');
   *
   * @param {String} userIdentifier The user identifier. Should be a string.
   */

  setUserId(userIdentifier) {
    PostHogUtil.identify(userIdentifier)
    this.userId = userIdentifier
  }

  updateTermsRead() {
    this.setUserProperties({ terms_read: new Date().toLocaleString('pt-br') })
  }
}

export default Tracking.getInstance()
