export const getTryonProducts = (skus = [], products = []) => {
  const tryonProducts = {}

  skus.forEach(sku => {
    const productsData = products.filter(product => product.identifier === sku)

    if (!productsData.length) return

    const lastProduct = productsData[productsData.length - 1]

    tryonProducts[lastProduct.category.type.toLowerCase()] = lastProduct
  })

  return tryonProducts
}

export const getTaxonomyFromCurrentProducts = products => {
  const topProduct = products.find(product => product.category.type === 'TOP')

  if (topProduct && topProduct.gender !== 'UNISEX') {
    return { gender: topProduct.gender, age_group: topProduct.age_group }
  }

  const bottomProduct = products.find(product => product.category.type === 'BOTTOM')

  if (bottomProduct && bottomProduct.gender !== 'UNISEX') {
    return { gender: bottomProduct.gender, age_group: bottomProduct.age_group }
  }

  const fullProduct = products.find(product => product.category.type === 'FULL')

  if (fullProduct && fullProduct.gender !== 'UNISEX') {
    return { gender: fullProduct.gender, age_group: fullProduct.age_group }
  }

  return { gender: 'MALE', age_group: 'ADULTS' }
}

export const checkLastEventTimestamp = () => {
  const TIME_DIFFERENCE_IN_SECONDS = Number(process.env.REACT_APP_AVATAR_EXPIRE_TIME_IN_MINUTES || 30)
  const lastEvent = localStorage.getItem('@doris:last-event')

  if (!lastEvent) {
    return false
  }

  const event = JSON.parse(lastEvent)
  const eventTimestamp = new Date(event.timestamp)
  const currentTime = new Date()
  const timeDifference = (currentTime.getTime() - eventTimestamp.getTime()) / (1000 * 60)

  return timeDifference > TIME_DIFFERENCE_IN_SECONDS
}

export const deleteLocalStorageAvatars = () => {
  localStorage.removeItem('@doris:avatars')
}

export const deleteTryonHistory = () => {
  localStorage.removeItem('@doris:tryon_history')
}

export const deleteCurrentTryon = () => {
  localStorage.removeItem('@doris:current_tryon')
}
