import { useCallback, useEffect, useState } from 'react'

import { useNavigation } from '~/context/Navigation'

import { Carousel } from '~/components/Carousel'
import { Icon } from '~/components/Icon'
import { ImageCard } from '~/components/ImageCard'
import { RoutesHeader } from '~/components/RoutesHeader'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { isTutorialViewed, setTutorialAsViewed } from '~/utils/firstTimePhotoFlow'
import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'
import { TTranslateKey } from '~/utils/translate/types'

import * as Styled from './styles'
import { tips } from './constants'

export const Tips = () => {
  const { navigate } = useNavigation()
  const [currentItem, setCurrentItem] = useState(0)
  const [clickedTextIndex, setClickedTextIndex] = useState<number | null>(null)
  const [buttonDisable, setButtonDisable] = useState(true)

  const flowAlreadyViewed = isTutorialViewed()

  const handleOnChange = (item: number) => {
    Tracking.logEvent('AVATAR_HELP', { page: item + 1, widget: true })

    setCurrentItem(item)
    setClickedTextIndex(null)
  }

  const handleOnClick = ({ direction }: { direction: 'prev' | 'next' }) => {
    const next = direction === 'next' ? currentItem + 1 : currentItem - 1

    if (next === tips.length) return setClickedTextIndex(0)

    if (next < 0) return setClickedTextIndex(tips.length - 1)

    setClickedTextIndex(next)
  }

  const handleNavigate = useCallback(() => {
    navigate('InformData')
  }, [navigate])

  useEffect(() => {
    Tracking.logEvent('AVATAR_HELP', { page: 1, widget: true })
  }, [])

  useEffect(() => {
    if (currentItem < tips.length - 1) return

    setTutorialAsViewed()
    setButtonDisable(false)
  }, [currentItem])

  return (
    <Styled.Container data-testid="tips-screen">
      <RoutesHeader title={translate('TIPS_SCREEN_HEADER')} prevButtonUrl="Models" closeButtonUrl="Models" />

      <Styled.Header>
        {tips.length > 0 && (
          <>
            <Styled.Label>{currentItem + 1}</Styled.Label>
            <Styled.Arrow testID="tips" direction="left" onClick={() => handleOnClick({ direction: 'prev' })} />
            <Carousel
              goToIndex={clickedTextIndex}
              onChange={handleOnChange}
              slides={tips.map((item, index) => (
                <ImageCard key={`image-item-${index}`} imageUrl={item.image} />
              ))}
            />
            <Styled.Arrow testID="tips" direction="right" onClick={() => handleOnClick({ direction: 'next' })} />
          </>
        )}
      </Styled.Header>

      <Styled.Content>
        <Styled.TextBox data-testid="tips-description">
          {translate(tips[currentItem].description as TTranslateKey)}
        </Styled.TextBox>
        <Styled.PrivacySpan data-testid="privacy-span">
          <Icon name="locked" color={theme.colors.black} size="24px" />
          {translate('TIPS_PRIVACY_NOTE')}
        </Styled.PrivacySpan>
      </Styled.Content>

      <Styled.Footer>
        <TextButton
          testID="send-photo-button"
          textTransform="uppercase"
          disabled={buttonDisable && !flowAlreadyViewed}
          onClick={handleNavigate}
        >
          {translate('TIPS_SCREEN_BUTTON_MAKE_PHOTO')}
        </TextButton>
      </Styled.Footer>
    </Styled.Container>
  )
}
