export const tips = [
  {
    image: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/tip-with-help.jpg`,
    description: 'TIPS_SCREEN_DESCRIPTION_W_HELP',
  },
  {
    image: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/tip-without-help.jpg`,
    description: 'TIPS_SCREEN_DESCRIPTION_WO_HELP',
  },
]
