import { useContextSelector } from 'use-context-selector'

import { NavigationContext } from '.'

export const useNavigation = () => {
  const navigate = useContextSelector(NavigationContext, state => state.navigate)
  const goBack = useContextSelector(NavigationContext, state => state.goBack)
  const route = useContextSelector(NavigationContext, state => state.route)
  const params = useContextSelector(NavigationContext, state => state.params)
  const previousRoute = useContextSelector(NavigationContext, state => state.previousRoute)
  const previousParams = useContextSelector(NavigationContext, state => state.previousParams)
  const resetNavigation = useContextSelector(NavigationContext, state => state.resetNavigation)

  return {
    navigate,
    goBack,
    route,
    params,
    previousRoute,
    previousParams,
    resetNavigation,
  }
}
