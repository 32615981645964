import { useContextSelector } from 'use-context-selector'

import { CombinedItemsContext } from './context'

export const useCombinedItems = () => {
  const selectedProducts = useContextSelector(CombinedItemsContext, state => state.selectedProducts)
  const handleSelectProduct = useContextSelector(CombinedItemsContext, state => state.handleSelectProduct)
  const handleRemoveProduct = useContextSelector(CombinedItemsContext, state => state.handleRemoveProduct)
  const handleSetSelectedProducts = useContextSelector(CombinedItemsContext, state => state.handleSetSelectedProducts)
  const handleSelectProductSize = useContextSelector(CombinedItemsContext, state => state.handleSelectProductSize)
  const handleRemoveProductSize = useContextSelector(CombinedItemsContext, state => state.handleRemoveProductSize)
  const selectedSizes = useContextSelector(CombinedItemsContext, state => state.selectedSizes)

  return {
    selectedProducts,
    handleSelectProduct,
    handleRemoveProduct,
    handleSetSelectedProducts,
    handleSelectProductSize,
    handleRemoveProductSize,
    selectedSizes,
  }
}
