import { FC } from 'react'

import { Icon } from '~/components/Icon'

import theme from '~/theme'

import * as Styled from './styles'
import { ISelectProps } from './types'

export const Select: FC<ISelectProps> = ({ disabled = false, options, onChange, value, initialOption, name }) => (
  <Styled.Container disabled={disabled}>
    <Styled.Label
      htmlFor={name ? `${name}-select` : 'select'}
      data-testid={name ? `${name}-select-label` : 'select-label'}
      selected={value > 0}
    >
      {options.find(option => option.value === value)?.label || initialOption}
    </Styled.Label>
    <Styled.Select
      data-testid={name ? `${name}-select` : 'select'}
      id={name ? `${name}-select` : 'select'}
      value={value}
      onChange={event => onChange(parseInt(event.target.value))}
      disabled={disabled}
      defaultValue={initialOption}
    >
      {initialOption && (
        <option data-testid={name ? `${name}-select-initial-option` : 'select-initial-option'} value={0}>
          {initialOption}
        </option>
      )}

      {options.map(option => (
        <option
          key={`${option.value}`}
          value={option.value}
          data-testid={name ? `${name}-select-option` : 'select-option'}
          disabled={option.disabled}
        >
          {option.label}
        </option>
      ))}
    </Styled.Select>
    <Icon name="arrowLeft" size="14px" color={theme.colors.primary} />
  </Styled.Container>
)
