export const getProduct = () => {
  const currentItem = localStorage.getItem('@doris:base_product')

  if (currentItem) {
    return JSON.parse(currentItem)
  }

  return null
}

export const setProduct = product => {
  if (!product?.category?.name || !product?.id) {
    return Error('The "category.name" and "id" keys are required')
  }

  localStorage.setItem('@doris:base_product', JSON.stringify(product))
}

export const removeProduct = () => {
  localStorage.removeItem('@doris:base_product')
}

export const extractProduct = products => {
  return products.top || products.bottom || products.full
}
