import React from 'react'

import { TIconProps } from '.'

const Download: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 17"
      data-testid="icon-download"
      style={{ width: size, height: size }}
    >
      <defs>
        <path
          id="download-icon-a"
          d="M19 16c.55 0 1 .45 1 1v2c0 .5107143-.3880102.9352041-.883801.9932398L19 20H5c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v1h12v-1c0-.55.45-1 1-1ZM12 3c.553 0 1 .448 1 1v8l2.4-1.8c.442-.333 1.069-.242 1.4.2.332.442.242 1.069-.2 1.4l-4 3c-.177.133-.389.2-.6.2-.201 0-.402-.061-.575-.182l-4-2.814c-.452-.318-.561-.942-.243-1.393.318-.452.941-.561 1.393-.243l2.4279783 1.7097792C11.0010049 12.052111 11 12.0261724 11 12V4c0-.552.447-1 1-1Z"
        />
      </defs>
      <use xlinkHref="#download-icon-a" fill={color} fillRule="evenodd" transform="translate(-4 -3)" />
    </svg>
  )
}

export default Download
