/* eslint-disable jsx-a11y/media-has-caption */
import * as Styled from './styles'
import { IVideoProps } from './types'

export const Video = ({
  preload = 'auto',
  muted = true,
  loop = true,
  playsInline = true,
  autoPlay = true,
  className,
  children,
  style,
  testId,
  ...props
}: IVideoProps) => (
  <Styled.VideoContainer className={className} style={style}>
    <video
      data-testid={testId}
      playsInline={playsInline}
      autoPlay={autoPlay}
      preload={preload}
      muted={muted}
      loop={loop}
      {...props}
    >
      {children}
    </video>
  </Styled.VideoContainer>
)
