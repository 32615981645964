import styled from 'styled-components'

import { Title as TitleBase } from '~/components/Typography/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled(TitleBase)`
  font-size: 25px;
  line-height: 26px;
  margin-bottom: 15px;
`

export const BoxButton = styled.div`
  display: flex;
  width: 100%;
  max-width: 330px;
  margin-top: 15px;
`
