export const MAX_BMI_FACTOR = 40

export const validateBMI = ({ age, weight, height }: { age: number; weight: number; height: number }): boolean => {
  const bmi = weight / (height / 100) / (height / 100) || 0
  const isBMIUnderLimits = bmi < MAX_BMI_FACTOR
  const isAgeValid = age > 0

  const result = isBMIUnderLimits && isAgeValid

  return result
}
