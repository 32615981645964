import { FC } from 'react'

import { TIconProps } from '.'

const Shop: FC<TIconProps> = ({ color, size }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 12 14"
    data-testid="icon-shop"
    style={{ width: size, height: size }}
  >
    <g fill="none">
      <g transform="translate(-326.000000, -13.000000)" fill="#FFFFFF">
        <g transform="translate(222.000000, 0.000000)">
          <g transform="translate(102.000000, 12.000000)">
            <path
              id="icon-shop"
              d="M12.6768,13.346 C12.5761333,13.4526667 12.4388,13.512 12.2894667,13.512 L3.71013333,13.512 C3.56146667,13.512 3.42413333,13.4526667 3.32346667,13.346 C3.2228,13.2393333 3.17413333,13.1006667 3.1868,12.956 L3.82413333,5.75133333 L3.82413333,5.752 C3.84746667,5.48466667 4.07213333,5.284 4.34746667,5.284 L5.41613333,5.284 L5.41613333,5.80333333 C5.41613333,6.122 5.6808,6.38066667 6.00813333,6.38066667 C6.33546667,6.38066667 6.6008,6.122 6.6008,5.80333333 L6.6008,5.284 L9.39946667,5.284 L9.39946667,5.80333333 C9.39946667,6.122 9.6648,6.38066667 9.99213333,6.38066667 C10.3188,6.38066667 10.5841333,6.122 10.5841333,5.80333333 L10.5841333,5.284 L11.6521333,5.284 C11.9268,5.284 12.1521333,5.48466667 12.1754667,5.75133333 L12.8128,12.956 C12.8261333,13.1006667 12.7774667,13.2393333 12.6768,13.346 L12.6768,13.346 Z M6.6008,3.06533333 C6.6008,2.74666667 6.8668,2.488 7.19346667,2.488 L8.8068,2.488 C9.13346667,2.488 9.39946667,2.74666667 9.39946667,3.06533333 L9.39946667,4.12933333 L6.6008,4.12933333 L6.6008,3.06533333 Z M13.9934667,12.8566667 L13.3561333,5.65266667 C13.3194667,5.236 13.1248,4.85066667 12.8081333,4.56733333 C12.4921333,4.28466667 12.0814667,4.12933333 11.6521333,4.12933333 L10.5841333,4.12933333 L10.5841333,3.06533333 C10.5841333,2.11 9.7868,1.33333333 8.8068,1.33333333 L7.19346667,1.33333333 C6.21346667,1.33333333 5.41613333,2.11 5.41613333,3.06533333 L5.41613333,4.12933333 L4.34746667,4.12933333 C3.91813333,4.12933333 3.50746667,4.28466667 3.1908,4.56733333 C2.8748,4.85066667 2.68013333,5.236 2.64346667,5.65266667 L2.00613333,12.8566667 C1.96546667,13.3206667 2.1268,13.7833333 2.45013333,14.1266667 C2.7728,14.47 3.23213333,14.6666667 3.71013333,14.6666667 L12.2894667,14.6666667 C12.7674667,14.6666667 13.2268,14.47 13.5501333,14.1266667 C13.8728,13.7833333 14.0348,13.3206667 13.9934667,12.8566667 L13.9934667,12.8566667 Z"
            />
            <use xlinkHref="#icon-shop" fill={color} />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Shop
