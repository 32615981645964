import React from 'react'

import { TIconProps } from '.'

export const DiscountStamp: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-discount-stamp"
      style={{
        width: size,
        height: size,
      }}
    >
      <path
        d="M11.1388 11.1386L10.6668 10.6666M17.3388 17.3386L16.8668 16.8666M10.6668 17.3333L17.3334 10.6666M10.9508 2.85327C11.7254 2.19327 12.1134 1.8626 12.5188 1.66927C12.9814 1.44854 13.4875 1.33398 14.0001 1.33398C14.5127 1.33398 15.0188 1.44854 15.4814 1.66927C15.8868 1.8626 16.2748 2.19327 17.0494 2.85327C17.8481 3.53327 18.6588 3.87727 19.7254 3.96127C20.7401 4.0426 21.2481 4.08394 21.6721 4.23327C22.6508 4.57994 23.4214 5.34927 23.7668 6.32927C23.9161 6.75194 23.9574 7.25994 24.0388 8.27594C24.1228 9.3426 24.4654 10.1519 25.1454 10.9506C25.8068 11.7253 26.1374 12.1133 26.3308 12.5186C26.7788 13.4559 26.7788 14.5453 26.3308 15.4813C26.1374 15.8866 25.8068 16.2746 25.1468 17.0493C24.486 17.7907 24.0955 18.7338 24.0388 19.7253C23.9574 20.7399 23.9161 21.2479 23.7668 21.6719C23.5956 22.1552 23.3185 22.5941 22.9559 22.9565C22.5932 23.3189 22.1542 23.5957 21.6708 23.7666C21.2481 23.9159 20.7401 23.9573 19.7241 24.0386C18.6574 24.1226 17.8481 24.4653 17.0494 25.1453C16.2748 25.8066 15.8868 26.1373 15.4814 26.3306C15.0188 26.5513 14.5127 26.6659 14.0001 26.6659C13.4875 26.6659 12.9814 26.5513 12.5188 26.3306C12.1134 26.1373 11.7254 25.8066 10.9508 25.1466C10.2094 24.4859 9.26626 24.0954 8.27478 24.0386C7.26012 23.9573 6.75211 23.9159 6.32811 23.7666C5.84484 23.5954 5.40594 23.3184 5.04353 22.9557C4.68111 22.5931 4.40433 22.154 4.23345 21.6706C4.08412 21.2479 4.04278 20.7399 3.96145 19.7239C3.90477 18.7331 3.51476 17.7905 2.85478 17.0493C2.19345 16.2746 1.86278 15.8866 1.66812 15.4813C1.44759 15.0185 1.33327 14.5124 1.3335 13.9998C1.33373 13.4872 1.44851 12.9811 1.66945 12.5186C1.86278 12.1133 2.19345 11.7253 2.85345 10.9506C3.54678 10.1359 3.87878 9.31994 3.96145 8.2746C4.04278 7.25994 4.08412 6.75194 4.23345 6.32794C4.40464 5.84466 4.68169 5.40576 5.04434 5.04335C5.40699 4.68093 5.84607 4.40416 6.32945 4.23327C6.75212 4.08394 7.26012 4.0426 8.27612 3.96127C9.26697 3.90459 10.2096 3.51325 10.9508 2.85327Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </svg>
  )
}
