import styled from 'styled-components'

import { Title as TitleBase } from '~/components/Typography/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate3d(0, 0, 0);
  padding-top: 20px;
  gap: 16px;
`

export const Title = styled(TitleBase)`
  font-size: 24px;
  line-height: 100%;
`
