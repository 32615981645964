import { gql } from '@apollo/client'

export const GET_CATEGORIES_TO_PARTNERS = gql`
  query category_to_partner($where: product_bool_exp) {
    category_to_partner(order_by: { category_order: asc }) {
      id
      category_order
      category {
        id
        name
        type
        products_aggregate(limit: 1, where: $where) {
          aggregate {
            count
          }
        }
      }
    }
  }
`
