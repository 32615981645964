import theme from '~/theme'

export const colors = {
  default: {
    description: theme.colors.white,
    background: 'rgba(0, 0, 0, 0.35)',
    border: theme.colors.white,
  },
  danger: {
    description: theme.colors.white,
    background: 'rgba(255, 0, 0, 0.15)',
    border: theme.colors.coralRed,
  },
  success: {
    description: theme.colors.white,
    background: 'rgba(91, 191, 34, 0.5)',
    border: theme.colors.growingNature,
  },
}
