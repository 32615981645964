import styled from 'styled-components'

import { UpscaleTag as HomeUpscaleTag } from '~/components/UpscaleTag'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  position: relative;
  padding: 16px 16px 8px;

  @media ${props => props.theme.media.devices.mobile.smallPortrait} {
    padding: 12px 12px 6px;
  }
`

export const SettingsButtonWrapper = styled.div`
  position: absolute;
  right: ${props => (props.theme.isWebview ? 16 : 61)}px;
  top: 16px;
  z-index: 3;

  @media ${props => props.theme.media.devices.mobile.smallPortrait} {
    right: ${props => (props.theme.isWebview ? 16 : 57)}px;
    top: 12px;
  }
`

export const UpscaleTag = styled(HomeUpscaleTag)`
  position: absolute;
  right: 16px;
  top: calc(48px + 16px);
  z-index: 3;

  & span {
    text-transform: uppercase;
  }
`
