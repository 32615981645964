import styled, { css } from 'styled-components'

export const Container = styled.div<{ disabled?: boolean }>`
  background: ${({ theme }) => theme.colors.palette.gray95};
  position: relative;
  width: 100%;
  margin: 0 auto;
  border-radius: 23px;

  > svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: rotate(-90deg) translateX(50%);
    z-index: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${props => props.theme.opacity.default};
    `};
`

export const Label = styled.label<{ selected: boolean }>`
  line-height: 40px;
  padding-left: 20px;
  font-size: 16px;
  font-weight: ${({ selected, theme }) =>
    selected ? theme.typography.fontWeight.bold : theme.typography.fontWeight.regular};
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.neutral.black400};
  border-radius: ${({ theme }) => theme.tokens.spacingUnit}px;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Select = styled.select`
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 40px;
  appearance: none;
`
