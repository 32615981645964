import { Icon } from '~/components/Icon'
import { SlickSlider } from '~/components/SlickSlider'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { settings, slides } from './constants'
import { ISlides } from './types'

export const CarouselContent = () => {
  const renderModel = (values: unknown) => {
    const item = values as ISlides

    return (
      <Styled.ContainerItem data-testid="item-carousel-how-to-measure">
        <Styled.ImageItem src={item.img} />

        <Styled.TitleHow>{translate(item.title)}</Styled.TitleHow>

        <Styled.Description align="center">{translate(item.text)}</Styled.Description>

        <Styled.Measurements>
          <Icon name="tape" color={theme.colors.growingNature} size={22} />
          <Styled.Text>{translate('HOW_TO_MEASURE_DESCRIPTION')}</Styled.Text>
        </Styled.Measurements>
      </Styled.ContainerItem>
    )
  }

  return (
    <Styled.Container data-testid="container-carousel-how-to-measure">
      <SlickSlider settings={settings} data={slides} renderItem={renderModel} />
    </Styled.Container>
  )
}
