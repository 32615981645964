import { FC } from 'react'

import { Icon } from '~/components/Icon'

import { translate } from '~/utils/translate'

import { Button } from './styles'

export type TCartButtonType = 'shop' | 'whatsapp' | 'email'

export interface ICartButtonProps {
  onClick: () => void
  disabled?: boolean
  type?: TCartButtonType | null
}

const CartButton: FC<ICartButtonProps> = ({ onClick, disabled, type }) => (
  <Button onClick={onClick} disabled={disabled} data-testid="cart-button">
    {type !== 'shop' ? translate('BACKDROP_CART_SEND_BUTTON') : translate('BACKDROP_CART_ADD_BUTTON')}
    <Icon name={type || 'shop'} size={14} />
  </Button>
)

export default CartButton
