import styled from 'styled-components'

const Container = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 0 0 35px;
  overflow-y: auto;
  scroll-behavior: smooth;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  margin-right: ${({ isMobileLastItem }) => isMobileLastItem && '0'};
`

export const EndScrollSpace = styled.li`
  padding: 0 0 0 ${({ size }) => size};
  width: ${({ size }) => size};
  height: 100%;
  text-indent: -999999px;
  &:before {
    content: 'a';
  }
`

export { Container, Item }
