import styled from 'styled-components'

import { IconButton } from '~/components/IconButton'
import { TextButton } from '~/components/TextButton'

import { CSSTransitionAll, CSSTransitionOpacity } from './constants'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 45px;
`

export const AudioButton = styled(IconButton)`
  cursor: default !important;

  ${CSSTransitionOpacity};
`

export const StartButton = styled(TextButton)`
  width: 124px;

  ${CSSTransitionAll};
`

export const SwitchButton = styled(IconButton)`
  ${CSSTransitionOpacity};
`
