import React from 'react'

import { TIconProps } from '.'

const Warning: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 20 20"
      data-testid="icon-warning"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <defs>
        <path
          id="a"
          d="M12 15c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1Zm0-8c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1s-1-.448-1-1V8c0-.552.448-1 1-1Zm0 13c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m0-18C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2"
        />
      </defs>
      <use xlinkHref="#a" fill={color} fillRule="evenodd" transform="translate(-2 -2)" />
    </svg>
  )
}

export default Warning
