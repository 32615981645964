import React from 'react'

import { TIconProps } from '.'

const Shirt: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 36 36"
      data-testid="icon-shirt"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M3.87 29.58 1 29.24a.57.57 0 0 0-.61.44L0 31.51a.58.58 0 0 0 .54.67l2.63.37a.63.63 0 0 0 .73-.44l.44-1.85a.55.55 0 0 0-.47-.68ZM19.1 8.29a.64.64 0 0 1 0-.86l2.28-2.53a7.44 7.44 0 0 0-6.75 0l2.28 2.53a.63.63 0 0 1 0 .86l-.39.43L18 10.84l1.43-2.18-.33-.37Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M16.3 9.77a.56.56 0 0 0-.86-.07l-1.51 1.52a.6.6 0 0 1-.44.18h-.09a.61.61 0 0 1-.46-.33l-2.49-4.9a.61.61 0 0 1 0-.57l1-1.82a8.08 8.08 0 0 0-6.95 6.46L.91 27.87a.56.56 0 0 0 .47.67l2.65.34a.56.56 0 0 0 .63-.44l2.68-12.77a.62.62 0 0 1 1.22.12l-.05 16.3a.58.58 0 0 0 .29.51 19.38 19.38 0 0 0 8.86 2.68V11.9a.51.51 0 0 0-.1-.32L16.3 9.77ZM35.57 29.68a.57.57 0 0 0-.61-.44l-2.83.34a.55.55 0 0 0-.48.68l.44 1.85a.63.63 0 0 0 .73.44l2.63-.37a.58.58 0 0 0 .54-.67l-.42-1.83Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="m11.55 6.38 1.85 3.34 2.35-2.17a1.09 1.09 0 0 1-.05-.17l-2.43-2.33a1.09 1.09 0 0 0-1.7.24 1.1 1.1 0 0 0-.02 1.09ZM20.3 7.38a1.09 1.09 0 0 1-.05.17l2.35 2.17 1.85-3.34a1.1 1.1 0 0 0 0-1.07 1.09 1.09 0 0 0-1.7-.24L20.3 7.38ZM18 2.83a7.87 7.87 0 0 1 4.36 1.26l.7-.84C22.38 1.78 20.33.72 18 .72c-2.33 0-4.39 1.06-5.07 2.53l.7.84A7.88 7.88 0 0 1 18 2.83Z"
          fill={color}
          fillRule="nonzero"
        />
        <path d="M-6-6h48v48H-6z" />
        <path
          d="M34.62 28.54a.56.56 0 0 0 .47-.67l-3.57-17.63a8.08 8.08 0 0 0-7-6.46l1 1.85a.56.56 0 0 1 0 .52l-2.5 4.92a.61.61 0 0 1-.46.33h-.09a.6.6 0 0 1-.44-.18L20.56 9.7a.56.56 0 0 0-.86.07l-1.26 1.81a.51.51 0 0 0-.1.32v22.78a.58.58 0 0 0 .6.57 19.73 19.73 0 0 0 8.26-2.65.58.58 0 0 0 .29-.51l-.05-16.3a.62.62 0 0 1 1.22-.12l2.68 12.77a.56.56 0 0 0 .63.44l2.65-.34Zm-14.57 3.51a.7.7 0 1 1 0-1.39.7.7 0 1 1 0 1.39Zm0-3.61a.7.7 0 1 1 0-1.4.7.7 0 0 1 0 1.4Zm0-3.62a.7.7 0 1 1 0-1.39.7.7 0 1 1 0 1.39Zm0-3.61a.7.7 0 1 1 0-1.4.7.7 0 0 1 0 1.4Zm0-3.62a.69.69 0 0 1-.7-.69.7.7 0 1 1 .7.69Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Shirt
