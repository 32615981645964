import { IProductCombine } from '~/context/CombinedItems/types'

interface IGetProductSizeInformationParams {
  product: IProductCombine
  cartSelectedSizes: {
    [key: string]: string
  }
}

interface IGetProductSizeInformationResponse {
  size: string | undefined
}

export const getProductSizeInformation = ({
  product,
  cartSelectedSizes,
}: IGetProductSizeInformationParams): IGetProductSizeInformationResponse => {
  const productIdentifier = product.identifier
  const uniqueCode = cartSelectedSizes[productIdentifier]

  if (!product.product_options?.length) {
    return { size: undefined }
  }

  const filteredProductOptions = product.product_options.filter(option => option.unique_code === uniqueCode)

  return {
    size: filteredProductOptions[0]?.partner_size?.name,
  }
}
