export const modelsWithInvertedCameraList = [
  // Samsung S22 / S22 Ultra / S22 Plus
  'SM-S906B/DS',
  'SM-S906E',
  'SM-S906E/DS',
  'SM-S906B',
  // Samsung S21 / S21 Ultra / S21 Plus
  'SM-G991B/DS',
  'SM-G998B',
  'SM-G996B',
  // Samsung S21 FE
  'SM-G9900',
  'SM-G990B/DS',
  'SM-G990B2/DS',
  'SM-G990E/DS',
  'SM-G990U',
  // Samsung S20 FE
  'SM-G780F/DS',
  'SM-G780G',
  // Samsung S20 FE 5G
  'SM-G781B',
  'SM-G781B/DS',
  'SM-G781U',
  'SM-G781V',
  'SM-G781W',
]
