import { IUrlParameters, TDeleteParams, TGetParameter, TRemoveItemParams } from './types'

let InstanceBrowserUrlApi: ClassBrowserUrlApi

class ClassBrowserUrlApi implements IUrlParameters {
  private externalAccess: string | null
  private externalUser: string | null
  private webviewRender: string | null
  private appUser: string | null
  private urlObj: URL
  private searchParams: URLSearchParams

  constructor() {
    this.urlObj = new URL(window.location.href)
    this.searchParams = this.urlObj.searchParams
    this.externalAccess = this.searchParams.get('dwoa')
    this.externalUser = this.searchParams.get('dwuser')
    this.webviewRender = this.searchParams.get('dwview')
    this.appUser = this.searchParams.get('dwappuser')
  }

  private deleteParameter({ item }: TRemoveItemParams) {
    this.searchParams.delete(item)
  }

  public getParameter({ parameter }: TGetParameter) {
    return this.searchParams.get(parameter)
  }

  public isExternalUser() {
    return !!this.externalUser
  }

  public isExternalAccess() {
    return !!(this.externalAccess && parseInt(this.externalAccess))
  }

  public removeParameters({ params }: TDeleteParams) {
    params.forEach(item => this.deleteParameter({ item }))
  }

  public refresh() {
    this.externalAccess = this.searchParams.get('dwoa')
    this.externalUser = this.searchParams.get('dwuser')
  }

  public isWebviewRendering() {
    return !!(this.webviewRender && parseInt(this.webviewRender))
  }

  public appUserId() {
    return this.appUser || ''
  }
}

const GetBrowserUrlApi = () => {
  InstanceBrowserUrlApi = InstanceBrowserUrlApi || new ClassBrowserUrlApi()

  return InstanceBrowserUrlApi
}

export const BrowserUrlApi = GetBrowserUrlApi()
