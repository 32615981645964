import React from 'react'

import { TIconProps } from '.'

const ArrowDown: React.FC<TIconProps> = ({ color, size }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    data-testid="icon-arrow-down"
    style={{ width: size, height: size }}
  >
    <defs>
      <path
        id="icon-arrow-down"
        d="M16,21.3333333 C15.696,21.3333333 15.3933333,21.2306667 15.1466667,21.024 L7.14666667,14.3573333 C6.58133333,13.8866667 6.504,13.0453333 6.976,12.48 C7.44666667,11.9146667 8.28666667,11.8386667 8.85333333,12.3093333 L16.0146667,18.2773333 L23.164,12.524 C23.7373333,12.0626667 24.5773333,12.1533333 25.0386667,12.7266667 C25.5,13.3 25.4093333,14.1386667 24.836,14.6013333 L16.836,21.0386667 C16.592,21.2346667 16.296,21.3333333 16,21.3333333"
      />
    </defs>
    <use xlinkHref="#icon-arrow-down" fill={color} fillRule="evenodd" data-testid="icon-arrow-down-mask" />
  </svg>
)

export default ArrowDown
