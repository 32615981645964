import { FC, useState } from 'react'

import { Icon } from '~/components/Icon'
import { SizingLabel } from '~/components/SizingLabel'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { ISizeSelectProps } from './types'

const SizeSelect: FC<ISizeSelectProps> = ({ initialOption, options, handleOnChange, disabled, padding }) => {
  const [value, setValue] = useState<string>('0')

  const onHandleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (handleOnChange) {
      handleOnChange(event)
      setValue(event?.target.value)
    }
  }

  const size = options.find(item => item.value === value)
  const valueInitial = value === '0'

  return (
    <Styled.Container disabled={disabled} data-testid="size-select-container">
      <Styled.SizeSelect
        isValueInitial={valueInitial}
        value={value}
        data-testid="size-select-input"
        padding={padding}
        onChange={onHandleOnChange}
        disabled={disabled}
        defaultValue={initialOption}
      >
        {initialOption && (
          <Styled.Option color={theme.colors.white} data-testid="initial-option" value={0}>
            {initialOption}
          </Styled.Option>
        )}
        {options.map(option => (
          <Styled.Option data-testid="option" key={`${option.value}`} value={option.value} disabled={option.disabled}>
            {option.label}
          </Styled.Option>
        ))}
      </Styled.SizeSelect>

      <Styled.IconWrapper isValueInitial={valueInitial} data-testid="icon" right={padding?.split(' ')[1] || padding}>
        {!valueInitial && (
          <Styled.WrapperInfo data-testid="size-select-label">
            <Styled.Title>{translate('SELECT_SIZE_SELECTED_SIZE')}:</Styled.Title>
          </Styled.WrapperInfo>
        )}

        <Styled.ContainerT>
          {!valueInitial && (
            <>
              <SizingLabel size="32px" color={theme.colors.black}>
                {size?.label}
              </SizingLabel>
              <Styled.Divider height="22px" width="2px" backgroundColor={theme.colors.neutral.black200} />
            </>
          )}
          <Icon name="arrowDown" size="16px" color={valueInitial ? theme.colors.white : theme.colors.black} />
        </Styled.ContainerT>
      </Styled.IconWrapper>
    </Styled.Container>
  )
}

export default SizeSelect
