import styled from 'styled-components'

import { IconButton } from '~/components/IconButton'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div<{ align: 'center' | 'flex-end' | 'space-between' }>`
  display: flex;
  justify-content: ${({ align }) => align};
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const BoxCombineButton = styled.div`
  width: 100%;
  max-width: 140px;
`

export const HeartButton = styled(IconButton)`
  transition: background-color 0.5s ease, color 0.5s ease;
  svg {
    path {
      transition: fill 0.5s ease;
    }
  }
`
