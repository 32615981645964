import { IScreenCaptureValues, IScreenInitialValues, IScreenPositionsStates, IScreenSuccessValues } from './types'

export const screenInitialValues: IScreenInitialValues = {
  audio: 'init-camera',
  message: 'CAMERA_INITIAL_MESSAGE',
  position: 'front-arms-closed',
}

export const screenPositionsValues: IScreenPositionsStates = {
  firstStep: {
    type: 'FRONT',
    audio: 'start-first-photo',
    message: 'CAMERA_FRONT_PHOTO_MESSAGE',
    position: 'front-arms-closed',
    error: {
      messages: {
        body: 'CAMERA_FRONT_PHOTO_ERROR_MESSAGE',
        members: 'CAMERA_FRONT_PHOTO_ERROR_MESSAGE',
      },
      audio: 'front-arms-closed-error',
    },
  },
  secondStep: {
    type: 'SIDE',
    audio: 'start-second-photo',
    message: 'CAMERA_SIDE_PHOTO_MESSAGE',
    position: 'side-arms-closed',
    error: {
      messages: {
        body: 'CAMERA_SIDE_PHOTO_ERROR_MESSAGE',
        members: 'CAMERA_SIDE_PHOTO_ERROR_MESSAGE',
      },
      audio: 'side-arms-closed-error',
    },
  },
}

export const screenSuccessValues: IScreenSuccessValues = {
  audio: 'correct-pose',
  message: 'CAMERA_CORRECT_POSE_MESSAGE',
}

export const screenCaptureValues: IScreenCaptureValues = {
  audio: 'capture-photo',
}
