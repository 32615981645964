import styled, { css } from 'styled-components'

import { keyframeImageAnimation } from './constants'
import { TContainerStyled, TImageStyled } from './types'

export const Container = styled.div<TContainerStyled>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: ${({ height }) => height || 'auto'};
  padding: 16px;
`

export const Image = styled.img<TImageStyled>`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${({ startAnimation }) =>
    startAnimation &&
    css`
      animation: ${keyframeImageAnimation} 1.7s ease infinite;
    `};
`

export const ContainerTags = styled.div`
  position: absolute;
  z-index: 2;
  top: 16px;
  left: 16px;
  display: grid;
  row-gap: 8px;
`

export const ContainerAnimation = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  transform: translate(0, -50%);
`

export const ArrowIcon = styled.div`
  display: flex;
  justify-content: center;
  transform: rotate(180deg);
`
