import React from 'react'

import { TIconProps } from '.'

const Panties: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      data-testid="icon-panties"
      style={{
        width: 'auto',
        height: size,
      }}
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-135.000000, -40.000000)">
          <g transform="translate(126.000000, 40.000000)">
            <rect id="icon-panties-rectangle" x="0" y="0" width="66" height="66" />
            <g id="calcinhas" transform="translate(9.000000, 0.000000)">
              <path
                d="M3.83358169,13.0281752 C3.3772932,12.9398225 2.90539137,13.0616793 2.54948137,13.3597619 C2.19357136,13.6578444 1.99173451,14.1002588 2,14.5636234 L2,17.6893571 C1.99894529,18.3328914 2.39115902,18.9122349 2.99025352,19.1516887 C15.8235083,24.2332909 17.5284978,29.0955433 18.7384904,32.3126728 C18.939167,32.9359614 19.177946,33.5464093 19.453486,34.1405873 C19.7202352,34.6728378 20.2684209,35.0064577 20.8651441,34.999707 L27.1717721,34.999707 C27.7713749,34.9986694 28.3184899,34.6586167 28.5834301,34.1223081 C28.850139,33.5640703 29.0827835,32.9902788 29.2800925,32.4040685 C30.4534187,29.1869391 32.2317411,24.3064075 45.0099962,19.2248053 C45.6090907,18.9853515 46.0013045,18.406008 46,17.7624737 L46,14.6001817 C45.994916,14.1296985 45.7880396,13.6838975 45.4316603,13.375479 C45.0783838,13.0862603 44.6186383,12.9600372 44.1666681,13.0281752 C30.9007013,16.1164003 17.0995485,16.1164003 3.83358169,13.0281752 Z"
                id="Path"
                fill={color}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Panties
