import { TListSizingOption } from '~/components/Backdrop/SelectSizingBackdrop/components/ListSizing/types'
import { selectSizingBackdropContents } from '~/components/Backdrop/SelectSizingBackdrop/constants'

import { TComponentProps, TProps } from './types'

export const getSortByOrder = (list: Array<TListSizingOption>) => list?.sort((a, b) => a.order - b.order)

export const getComponent = (props: TProps): JSX.Element => {
  const componentProps: TComponentProps = {
    slider: {
      onSizeChange: props.onSizeChange,
      onSizeSelected: props.onSizeSelected,
      onSliderInit: props.onSliderInit,
      sizingInfos: props.sizingInfos,
      loading: props.loading,
    },
    list: {
      data: {
        current: props.avatarInfos?.current as TListSizingOption[],
        original: props.avatarInfos?.original as TListSizingOption[],
      },

      onInnerContentChange: props.onInnerContentChange,
      innerContentType: props.innerContentType,
      onShowAlert: props.onShowAlert,
      onHandleSelectChange: props.onHandleSelectChange,
      formData: props.formData,
      onUpdateAvatarMeasurements: props.onUpdateAvatarMeasurements,
      formChanged: props.formChanged,
    },
  }

  const Slider = selectSizingBackdropContents.slider

  if (props.contentType === 'list') {
    const List = selectSizingBackdropContents.list

    return <List {...componentProps.list} />
  }

  return <Slider {...componentProps.slider} />
}
