import styled, { css } from 'styled-components'

import { IAnimationExpandStyled } from './types'

export const Container = styled.div<IAnimationExpandStyled>`
  border-radius: ${({ borderRadius }) => borderRadius || 0};

  ${({ enable }) =>
    enable
      ? css`
          width: 0px;
          overflow: hidden;
          transition: width 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
        `
      : css`
          visibility: hidden;
        `};

  ${({ enable, startAnimation, computedWidth }) =>
    enable &&
    startAnimation &&
    computedWidth &&
    css`
      width: ${computedWidth};
    `};
`

export const Content = styled.div<Pick<IAnimationExpandStyled, 'computedWidth'>>`
  width: ${({ computedWidth }) => computedWidth};
`
