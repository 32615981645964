import { FC, useCallback, useRef } from 'react'

import { IRecommendation } from '~/entities'

import {
  type IRecommendationHistoryContext,
  RecommendationHistoryContext,
  type TUpdateRecommendationHistoryItem,
} from './context'

const historyStorageKey = '@doris:recommendation_history'

export const RecommendationHistoryProvider: FC = ({ children }) => {
  const recommendationListRef = useRef<Array<IRecommendation>>([])

  const fetchHistoryFromStorage = useCallback(() => {
    const storage = localStorage.getItem(historyStorageKey)

    if (!storage) return

    recommendationListRef.current = JSON.parse(storage)
  }, [])

  const syncToLocalStorage = useCallback((history: IRecommendation | IRecommendation[]) => {
    let newStorageState = JSON.stringify(history)

    if (!Array.isArray(history)) {
      const storage = localStorage.getItem(historyStorageKey)
      const parsedStorage = storage ? JSON.parse(storage) : []
      newStorageState = JSON.stringify([...parsedStorage, history])
    }

    localStorage.setItem(historyStorageKey, newStorageState)
  }, [])

  const addRecommendationHistory = useCallback(
    (item: IRecommendation) => {
      const lastItem = recommendationListRef.current.at(-1)

      if (lastItem?.id === item.id) return

      recommendationListRef.current = [...recommendationListRef.current, item]

      syncToLocalStorage(item)
    },
    [syncToLocalStorage, recommendationListRef],
  )

  const updateRecommendationHistory = useCallback(
    (data: TUpdateRecommendationHistoryItem) => {
      const recommendationIndex = recommendationListRef.current.findIndex(item => item.id === data.id)

      if (recommendationIndex === -1) return

      recommendationListRef.current[recommendationIndex] = {
        ...recommendationListRef.current[recommendationIndex],
        ...data,
      }

      syncToLocalStorage(recommendationListRef.current)
    },
    [syncToLocalStorage],
  )

  const clearRecommendationHistory = useCallback(() => {
    localStorage.removeItem(historyStorageKey)
    recommendationListRef.current = []
  }, [])

  const value: IRecommendationHistoryContext = {
    recommendationListRef,
    addRecommendationHistory,
    updateRecommendationHistory,
    clearRecommendationHistory,
    fetchHistoryFromStorage,
  }

  return <RecommendationHistoryContext.Provider value={value}>{children}</RecommendationHistoryContext.Provider>
}
