export const getHeaders = (headers = {}) => {
  const userStorage = localStorage.getItem('@doris:user')
  const apiKey = localStorage.getItem('@doris:partner_apikey')
  const userUUID = userStorage ? JSON.parse(userStorage).uuid : 'empty'

  return {
    headers: {
      ...headers,
      'x-partner-api-key': apiKey,
      'x-hasura-role': 'widget',
      'x-hasura-user-uuid': userUUID,
    },
  }
}
