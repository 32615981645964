import { gql } from '@apollo/client'

export const GET_GENDERS = gql`
  query genders {
    male: pre_model_to_partner_aggregate(
      where: { pre_model: { model_to_tryon: { gender: { _eq: "MALE" } } } }
      limit: 1
    ) {
      aggregate {
        count
      }
    }
    female: pre_model_to_partner_aggregate(
      where: { pre_model: { model_to_tryon: { gender: { _eq: "FEMALE" } } } }
      limit: 1
    ) {
      aggregate {
        count
      }
    }
  }
`
