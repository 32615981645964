import styled, { css } from 'styled-components'

import { Title } from '~/components/Typography/styles'

import { IBackdropProps } from './types'

export const OuterWrapper = styled.section<Partial<IBackdropProps>>`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  transition: visibility linear 0 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: ${props => props.layerIndex};
  transition-delay: ${props => (props.visible ? '0' : '0.5s')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`

export const Shadow = styled.button<Partial<IBackdropProps>>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  transition: opacity ease 0.5s;
  z-index: 1;
  background: ${({ withBackground, theme }) => (withBackground ? theme.colors.darkOpaqueBackground : 'transparent')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  opacity: ${props => (props.visible ? '1' : '0')};
`

export const Container = styled.div<Partial<IBackdropProps>>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 25px 25px 0 0;
  box-shadow: ${({ theme }) => `0 2px 40px rgba(0, 0, 0, ${theme.opacity.default})`};
  width: 100%;
  padding-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: none;
  z-index: 2;
  min-height: auto;
  max-height: ${({ preventOverflow }) => (preventOverflow ? '100%' : 'none')};
  height: ${({ height }) => height || 'auto'};
  transition-property: transform, height;
  transition-duration: 0.5s;
  transition-timing-function: ${({ theme }) => theme.transitions.spring};
  transform: ${props =>
    props.minimized ? 'translate3d(0, 78%, 0)' : props.visible ? 'translate3d(0, 0, 0)' : 'translate3d(0, 100%, 0)'};
`

export const Name = styled(Title)<{ float?: boolean; $loading: boolean }>`
  width: 100%;
  text-align: center;
  line-height: 20px;
  pointer-events: none;
  color: ${({ $loading, theme }) => ($loading ? theme.colors.neutral.black500 : theme.colors.textPrimary)};

  ${({ float }) =>
    float &&
    css`
      position: absolute;
      left: 0;
    `};
`

export const ChildWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0 20px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
`

export const HeaderActions = styled.div<{ flexDirection: 'space-between' | 'flex-end' }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ flexDirection }) => flexDirection};
`

export const HeaderChild = styled.div`
  display: flex;
  margin-top: 22px;
`

export const FooterChild = styled.div`
  display: flex;
  margin-top: auto;
  padding: 20px 20px 0 20px;
`
