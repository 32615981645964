import { FC, useCallback, useState } from 'react'

import { Icon } from '~/components/Icon'

import { Input, Label, Svg } from './styles'

export interface ICheckBoxProps {
  visible?: boolean
  defaultChecked?: boolean
  handleOnChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
}

const CheckBox: FC<ICheckBoxProps> = ({ handleOnChange, visible = true, defaultChecked = false }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked)

  const handle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(!isChecked)
      handleOnChange && handleOnChange(event)
    },
    [handleOnChange, isChecked],
  )

  return (
    <>
      {visible && (
        <Label data-testid="checkbox-label">
          <>
            <Input type="checkbox" onChange={handle} data-testid="checkbox-input" defaultChecked={defaultChecked} />
            <Svg data-testid="checkbox-svg" isChecked={isChecked}>
              {isChecked && <Icon name="checked" size={14} />}
            </Svg>
          </>
        </Label>
      )}
    </>
  )
}

export default CheckBox
