import { FC, useCallback, useEffect, useRef, useState } from 'react'

import Typography from '~/components/Typography'

import * as Styled from './styles'
import { ITooltipProps } from './types'

export const Tooltip: FC<ITooltipProps> = ({
  id,
  title,
  content,
  borderRadius,
  padding,
  width,
  forceWidth = false,
  arrowWidth,
  offset,
  place,
  active,
  timer,
  onHidden,
  onVisible,
  children,
}) => {
  const [show, setShow] = useState(false)
  const timeoutKey = useRef<NodeJS.Timeout>()

  const clearTimer = useCallback(() => clearTimeout(timeoutKey.current as NodeJS.Timeout), [])

  useEffect(() => {
    setShow(active)

    if (active) {
      onVisible && onVisible()
    }

    if (show && !active) {
      onHidden && onHidden()
    }

    if (!timer) return

    clearTimer()

    if (!active) return

    timeoutKey.current = setTimeout(() => {
      setShow(false)

      onHidden && onHidden()
    }, timer)

    return () => clearTimer()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <>
      <Styled.Container data-testid={`tooltip-anchor-${id}`} id={`tooltip-anchor-${id}`}>
        {children}
      </Styled.Container>
      <Styled.ReactTooltip
        anchorId={`tooltip-anchor-${id}`}
        place={place}
        isOpen={show}
        offset={offset || 24}
        arrowWidth={arrowWidth}
        borderRadius={borderRadius}
        className={`tooltip-${id}`}
        classNameArrow={`tooltip-arrow-${id}`}
        width={width}
        forceWidth={forceWidth}
      >
        <Styled.Box data-testid={`tooltip-box-${id}`} width={width} padding={padding}>
          {title && (
            <Typography testID={`tooltip-title-${id}`} align="center">
              <b>{title}</b>
            </Typography>
          )}
          {content && (
            <Styled.Content data-testid={`tooltip-content-${id}`} hasSpace={!!(title && content)}>
              {content}
            </Styled.Content>
          )}
        </Styled.Box>
      </Styled.ReactTooltip>
    </>
  )
}
