import styled from 'styled-components'

import { ICamStylesProps } from './types'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Cam = styled.video<ICamStylesProps>`
  width: 100%;
  height: 100%;
  z-index: 0;

  object-fit: ${({ objectFit }) => objectFit};
  background-color: ${({ theme }) => theme.colors.black};
  transform: ${({ objectFit, isFrontMode }) => {
    if (objectFit === 'cover' && isFrontMode) return 'scaleX(-1)'
    if (objectFit === 'contain' && isFrontMode) return 'scaleX(-1) scale(1.45)'
    if (objectFit === 'cover' && !isFrontMode) return 'scale(1)'
    if (objectFit === 'contain' && !isFrontMode) return 'scale(1.45)'
  }};
`

export const Canvas = styled.canvas`
  display: none;
`

export const ButtonContainer = styled.div`
  position: absolute;
  top: 30%;
  right: 10px;
  z-index: 9;
`
export const Button = styled.button`
  font-size: 10px;
`
