import styled from 'styled-components'

import { Container as ContainerRoutes } from '~/components/RoutesHeader/styles'

import { TAnimationCaptureFeedback } from './types'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
`

export const ContentHeader = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 5;
  ${ContainerRoutes} {
    background-color: transparent;
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
`
export const ControlsWrapper = styled.div`
  margin-top: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 3;
`

export const PermissionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: calc(100% - 64px);
`

export const SlotListWrapper = styled.div`
  position: absolute;
  z-index: 4;
  top: 0;
  left: 20px;
  display: flex;
  align-items: center;
  height: 100%;
`

export const FeedbackMessageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 80px;
`

export const FeedbackMessageBox = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 60px;
`

export const ImagePreview = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const CaptureFeedback = styled.div<TAnimationCaptureFeedback>`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: ${({ animationStart }) => (animationStart ? 0.8 : 0)};
  transition: opacity ${({ animationTime }) => animationTime / 1000}s cubic-bezier(0, 0.99, 0.37, 0.96);
`
