import { FC, useMemo } from 'react'

import { useNavigation } from '~/context/Navigation'

import Box from '~/components/Box'
import { Icon } from '~/components/Icon'
import { SelfAvatarsList } from '~/components/SelfAvatarsList'
import { ITabUseMyPhotoUploadProps } from '~/components/TabUseMyPhotoUpload/types'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { MobileUtils } from '~/utils/mobileUtils'
import { translate } from '~/utils/translate'

import * as Styled from './styles'

export const TabUseMyPhotoUpload: FC<ITabUseMyPhotoUploadProps> = ({ listAvatar, openBackdrop, onAvatarSelected }) => {
  const isMobile = useMemo(() => MobileUtils.isMobile(), [])
  const { navigate } = useNavigation()

  const handleTakeNewPicture = () => {
    navigate(isMobile ? 'InformData' : 'HowToSendMyPhotoScreen')
  }

  const initialSlide = listAvatar.findIndex(item => item.active === true)

  return (
    <Styled.Row data-testid={'tab-user-my-photo-upload'}>
      <Styled.Column>
        <Box
          isLoading={false}
          padding="20px"
          borderRadius="16px"
          backgroundColor={theme.colors.neutral.black100}
          height={'auto'}
        >
          <Styled.Row>
            <Styled.Text align="center">{translate('USE_MY_PHOTO_NOTE')}</Styled.Text>

            <TextButton
              testID="btn-take-new-picture"
              onClick={handleTakeNewPicture}
              variant="outlined"
              textTransform="uppercase"
            >
              <Styled.ContainerButtonIcon>
                <Icon name="camera" size="20px" color={theme.colors.primary} />
                <Styled.TextBtn>{translate('CHANGE_BODY_NEW_PHOTO')}</Styled.TextBtn>
              </Styled.ContainerButtonIcon>
            </TextButton>
          </Styled.Row>
        </Box>
      </Styled.Column>

      <Box isLoading={false} height="100%">
        <Styled.ContainerAvatarList>
          <SelfAvatarsList
            handleTakeNewPicture={handleTakeNewPicture}
            onAvatarSelected={onAvatarSelected}
            data={listAvatar}
            onDelete={openBackdrop}
            initialSlide={initialSlide >= 0 ? initialSlide : 0}
          />
        </Styled.ContainerAvatarList>
      </Box>
    </Styled.Row>
  )
}
