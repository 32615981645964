import { keyframes } from 'styled-components'

export const keyframeImageAnimation = keyframes`
  0% {
    transform: translate(0, 0);
  }

  10% {
    transform: translate(0, 0);
  }

  30% {
    transform: translate(0, -60px);
  }

  40% {
    transform: translate(0, -60px);
  }

  50% {
    transform: translate(0, -60px);
  }

  60% {
    transform: translate(0, -60px);
  }

  80% {
    transform: translate(0, 0);
  }

  90% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
`
