import { css } from 'styled-components'

export const CSSTransitionOpacity = css`
  transition: opacity 0.15s linear;
`

export const CSSTransitionAll = css`
  transition: all 0.15s linear;
`

export const audioList = {
  'front-arms-closed-error': {
    pt: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/PE1-ERRO.mp3`,
    en: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/PE1-ERRO-EN.mp3`,
    es: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/PE1-ERRO-ES.mp3`,
  },
  'side-arms-closed-error': {
    pt: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/PE3-ERRO.mp3`,
    en: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/PE3-ERRO-EN.mp3`,
    es: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/PE3-ERRO-ES.mp3`,
  },
  'init-camera': {
    pt: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/START-PT.mp3`,
    en: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/START-ENGLISH.mp3`,
    es: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/START-ESPANHOL.mp3`,
  },
  'start-first-photo': {
    pt: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/START-PRIMEIRA-FOTO.mp3`,
    en: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/START-PRIMEIRA-FOTO-EN.mp3`,
    es: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/START-PRIMEIRA-FOTO-ES.mp3`,
  },
  'start-second-photo': {
    pt: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/START-TERCEIRA-FOTO.mp3`,
    en: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/START-TERCEIRA-FOTO-EN.mp3`,
    es: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/START-TERCEIRA-FOTO-ES.mp3`,
  },
  'correct-pose': {
    pt: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/POSE-CORRETA.mp3`,
    en: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/POSE-CORRETA-EN.mp3`,
    es: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/POSE-CORRETA-ES.mp3`,
  },
  'capture-photo': {
    pt: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/camera-dig-cam-pic.mp3`,
    en: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/camera-dig-cam-pic.mp3`,
    es: `${process.env.REACT_APP_MEDIA_STATIC_ASSETS}/camera-audios/camera-dig-cam-pic.mp3`,
  },
}
