import { FC } from 'react'

import * as Styled from './styles'
import { tagsMap } from './constants'
import { INewSizingTagProps } from './types'

export const NewSizingTag: FC<INewSizingTagProps> = ({ bodyPart, fit, gender = 'MALE', loading = false }) => (
  <Styled.Container
    fit={fit}
    aria-busy={loading ? 'true' : 'false'}
    data-testid={`sizing-tag-${bodyPart}-${fit}`}
    isLoading={loading}
  >
    <Styled.BodyPart>{tagsMap.labels[bodyPart][gender]}</Styled.BodyPart>
    <Styled.Fit>{tagsMap.messages[fit]}</Styled.Fit>
  </Styled.Container>
)
