import { useState } from 'react'

import { useQueryUser } from '~/hooks-queries/user'
import { useUser } from '~/hooks/useUser'

import { useUserContext } from '~/context/User'

import { IHandleSubmitEmailParams } from '~/components/NewsletterBackdrop/types'

import Tracking from '~/utils/tracking'

import { LOCAL_STORAGE_NEWSLETTER_KEY } from './constants'
import { IUseNewsletter } from './types'

export const useNewsletter = (): IUseNewsletter => {
  const [isLoading, setIsLoading] = useState(false)
  const { appendEmailToUser } = useQueryUser()
  const { updateUser } = useUser()
  const { setCurrentUserState } = useUserContext()

  const storeFlag = () => {
    localStorage.setItem(LOCAL_STORAGE_NEWSLETTER_KEY, String(1))
  }

  const hasFlag = () => !!localStorage.getItem(LOCAL_STORAGE_NEWSLETTER_KEY)

  const signUp = async ({ email }: IHandleSubmitEmailParams): Promise<boolean> => {
    setIsLoading(true)

    const { success } = await appendEmailToUser({ payload: { email } })

    if (!success) {
      return Promise.resolve(false)
    }

    updateUser({
      values: { email },
      setState: setCurrentUserState,
    })
    Tracking.logEvent('ACCOUNT_CONFIRM', { widget: true, email })
    Tracking.setUserProperties({ email })
    setIsLoading(false)
    return Promise.resolve(true)
  }

  return { isLoading, signUp, storeFlag, hasFlag }
}
