import { IProductCombine } from '~/context/CombinedItems/types'

interface IGenerateHandleBuyButtonPayloadParams {
  selectedSizes: {
    [key: string]: string
  }
  selectedProducts: IProductCombine[]
}

type TData = {
  identifier: string
  sellerId: string | null
}

export interface IGenerateHandleBuyButtonPayloadResult {
  skus: TData[]
}

type TProductOption = {
  unique_code: string
  identifier: string
  seller_ids?: string[]
}

export const generateHandleBuyButtonPayload = ({
  selectedSizes,
  selectedProducts,
}: IGenerateHandleBuyButtonPayloadParams): IGenerateHandleBuyButtonPayloadResult => {
  const selectedSizeUniqueCodes = Object.values(selectedSizes)
  const productOptions: TProductOption[] = []

  selectedProducts.forEach((prd: IProductCombine) => {
    prd.product_options?.forEach(po => {
      if (!po.unique_code) return

      productOptions.push({
        unique_code: po.unique_code,
        identifier: prd.identifier,
        seller_ids: prd.seller_ids,
      })
    })
  })

  return {
    skus: productOptions
      .map(po => {
        return selectedSizeUniqueCodes
          .filter(ssuc => ssuc === po.unique_code)
          .map(ssuc => {
            return {
              sellerId: po?.seller_ids?.length ? po.seller_ids[0] : null,
              identifier: ssuc,
            }
          })
      })
      .flat(),
  }
}
