import { useEffect, useRef, useState } from 'react'

import { ITryonProvider, useTryonContext } from '~/context/Tryon'

import { ICategoryItem, IProduct } from '~/entities'

const HIDE_TAGS_TIMEOUT = 4000

type TPrice = number

interface IGetPriceProps {
  sellingPrice?: TPrice
  listingPrice?: TPrice
}

interface ITagItem {
  id: number
  category: ICategoryItem
  price: number
  in_stock: boolean
}

const getPrice = ({ sellingPrice, listingPrice }: IGetPriceProps): number => {
  if (!sellingPrice && !listingPrice) {
    return 0
  }

  return (sellingPrice || listingPrice) as number
}

export const useTags = () => {
  const { stateCurrentTryon } = useTryonContext()
  const [items, setItems] = useState<Array<ITagItem>>([])
  const [shouldDisplayTags, setShouldDisplayTags] = useState<boolean>(false)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  const products: ITryonProvider & { [key: string]: IProduct | undefined } = { ...stateCurrentTryon?.products }

  const transformCurrentProductsToTags = (): void => {
    const currentUsedPositions = Object.keys(products).filter(category => products[category])

    const result = currentUsedPositions.map(position => {
      const product = products[position] as IProduct

      const price = getPrice({
        sellingPrice: product.selling_price,
        listingPrice: product.list_price,
      })

      return {
        id: product.id,
        category: product.category,
        price: price,
        in_stock: product.product_options?.length
          ? !!product.product_options.filter(option => option.has_stock).length
          : true,
      }
    })

    setItems(result)
  }

  const hideTags = () => {
    setShouldDisplayTags(false)
  }

  useEffect(() => {
    clearTimeout(timer.current as NodeJS.Timeout)

    timer.current = setTimeout(hideTags, HIDE_TAGS_TIMEOUT)

    if (!Object.keys(products).filter(category => products[category])?.length) {
      clearTimeout(timer.current)
      return
    }

    transformCurrentProductsToTags()
    setShouldDisplayTags(true)

    return () => clearTimeout(timer.current as NodeJS.Timeout)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCurrentTryon?.products])

  return {
    items,
    shouldDisplayTags,
    setShouldDisplayTags,
  }
}
