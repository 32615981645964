import React from 'react'

import { TIconProps } from '.'

const Vest: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 26 38"
      data-testid="icon-vest"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M25.38 16.92C24.45 16.61 23.88 15.03 23.54 13.13C23.12 10.76 23.07 7.91 23.11 6.42C23.11 6.34 23.08 6.27 23.03 6.21C22.98 6.15 22.9 6.12 22.82 6.12H18.38C18.1 6.12 17.88 5.9 17.88 5.62V2.02C17.88 2.02 17.88 1.98 17.88 1.96C17.82 1.71 17.3 0.5 12.99 0.5C8.68 0.5 8.2 1.63 8.11 1.92V1.95C8.11 1.95 8.1 1.97 8.1 1.98C8.1 1.99 8.1 2 8.1 2.01V5.61C8.1 5.89 7.88 6.11 7.6 6.11H3.16C3.08 6.11 3.01 6.14 2.95 6.2C2.9 6.25 2.87 6.33 2.87 6.41C2.92 7.9 2.87 10.76 2.44 13.12C2.11 14.97 1.57 16.52 0.670004 16.88C0.520004 16.94 0.410004 17.15 0.410004 17.32V36.99C0.410004 37.27 0.630004 37.49 0.910004 37.49H25.05C25.33 37.49 25.55 37.26 25.55 36.99V17.19C25.55 17.07 25.47 16.96 25.36 16.92H25.38ZM23.38 33.06C23.34 33.13 23.27 33.17 23.19 33.19L21.35 33.5C21.35 33.5 21.32 33.5 21.3 33.5C21.18 33.5 21.07 33.42 21.03 33.3L18.94 26.41C18.86 26.15 19.01 25.88 19.26 25.79L20.9 25.24C20.97 25.21 21.05 25.22 21.12 25.26C21.19 25.3 21.24 25.36 21.26 25.43L23.41 32.82C23.43 32.9 23.42 32.98 23.38 33.05V33.06ZM2.59 32.83L4.74 25.44C4.76 25.37 4.81 25.3 4.88 25.27C4.95 25.23 5.03 25.23 5.1 25.25L6.74 25.8C6.99 25.88 7.14 26.16 7.06 26.42L4.97 33.31C4.93 33.43 4.82 33.51 4.7 33.51C4.68 33.51 4.67 33.51 4.65 33.51L2.81 33.2C2.73 33.19 2.66 33.14 2.62 33.07C2.58 33 2.57 32.92 2.59 32.84V32.83ZM12.36 13.2V4.53C12.16 4.53 11.97 4.51 11.79 4.49C10.14 4.35 9.19 3.94 8.68 3.62V3.08C8.68 3.08 8.72 3.02 8.76 2.98C9.08 3.24 10 3.81 12.07 3.94C12.07 3.94 12.11 3.94 12.13 3.94C12.4 3.95 12.68 3.96 12.99 3.96C13.3 3.96 13.62 3.96 13.9 3.93H13.91C15.99 3.8 16.92 3.22 17.23 2.96C17.27 3.01 17.3 3.04 17.31 3.06V3.61C16.8 3.94 15.84 4.34 14.2 4.48C14.02 4.49 13.83 4.51 13.63 4.52V36.93H12.36V13.19V13.2Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Vest
