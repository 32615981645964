import { useContextSelector } from 'use-context-selector'

import { IRecommendationHistoryContext, RecommendationHistoryContext } from './context'

export const useRecommendationHistory = (): IRecommendationHistoryContext => {
  const recommendationListRef = useContextSelector(
    RecommendationHistoryContext,
    ({ recommendationListRef }) => recommendationListRef,
  )
  const fetchHistoryFromStorage = useContextSelector(
    RecommendationHistoryContext,
    ({ fetchHistoryFromStorage }) => fetchHistoryFromStorage,
  )
  const addRecommendationHistory = useContextSelector(
    RecommendationHistoryContext,
    ({ addRecommendationHistory }) => addRecommendationHistory,
  )
  const updateRecommendationHistory = useContextSelector(
    RecommendationHistoryContext,
    ({ updateRecommendationHistory }) => updateRecommendationHistory,
  )
  const clearRecommendationHistory = useContextSelector(
    RecommendationHistoryContext,
    ({ clearRecommendationHistory }) => clearRecommendationHistory,
  )

  return {
    recommendationListRef,
    addRecommendationHistory,
    updateRecommendationHistory,
    clearRecommendationHistory,
    fetchHistoryFromStorage,
  }
}
