import { FC, useCallback, useState } from 'react'

import { TClearStates, TCurrentUserState, TSetCurrentUserState, TSetUserState, TUserState, UserContext } from '.'

export const UserProvider: FC = ({ children }) => {
  const [stateUser, setUser] = useState<TUserState>()
  const [stateCurrentUser, setCurrentUser] = useState<TCurrentUserState>()

  const setUserState = useCallback((params: TSetUserState): void => {
    setUser(current => (typeof params === 'function' ? params(current) : params))
  }, [])

  const setCurrentUserState = useCallback((params: TSetCurrentUserState): void => {
    setCurrentUser(current => (typeof params === 'function' ? params(current) : params))
  }, [])

  const clearStates = useCallback((params: TClearStates) => {
    const states = {
      stateCurrentUser: setCurrentUser,
      stateUser: setUser,
    }

    params.forEach(state => states[state](undefined))
  }, [])

  const value = {
    stateUser,
    stateCurrentUser,
    setCurrentUserState,
    setUserState,
    clearStates,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
