import { FC } from 'react'

import { Icon } from '~/components/Icon'

import theme from '~/theme'

import { Button, Item, Link, List, Title } from './styles'

export interface IOption {
  title: string
  icon?: JSX.Element
  onClick?: () => void
  url?: string
  disabled?: boolean
  component?: JSX.Element
  testID?: string
}

export interface IOptionListProps {
  options: Array<IOption>
}

const OptionList: FC<IOptionListProps> = ({ options }) => (
  <>
    {!!options.length && (
      <List data-testid="option-list">
        {options.map(({ title, url, onClick, icon, disabled, testID, component: Component }) => (
          <Item data-testid="option" key={title}>
            {!!Component && Component}

            {!Component && url && (
              <Link data-testid="link" href={url} target="_blank" onClick={onClick}>
                <Title data-testid="title" color={theme.colors.textPrimary}>
                  {title}
                </Title>
                {icon || <Icon name="arrowRight" size="14px" color={theme.colors.quickSilver} />}
              </Link>
            )}

            {!Component && !url && (
              <Button disabled={disabled} data-testid={testID || 'button'} onClick={onClick}>
                <Title data-testid="title" color={theme.colors.textPrimary}>
                  {title}
                </Title>
                {icon || <Icon name="arrowRight" size="14px" color={theme.colors.quickSilver} />}
              </Button>
            )}
          </Item>
        ))}
      </List>
    )}
  </>
)

export default OptionList
