import styled from 'styled-components'

import Slider from 'react-slick'

import BaseArrow from '~/components/FlatList/components/Arrow'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledSlider = styled(Slider)`
  height: 100%;

  .slick-list {
    height: 100%;
  }

  .slick-slide {
    & > div {
      height: 100%;
    }

    img {
      aspect-ratio: 4 / 7;
    }
  }

  &:not(.slick-vertical) {
    .slick-track {
      height: 100%;
    }
  }
`
export const Item = styled.div`
  display: flex;
  height: 100%;
`

export const Arrow = styled(BaseArrow)<{ direction: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%) translate3d(0, 0, 0);
  margin: 0;

  ${({ direction }) => `${direction}: 20px`};
`
