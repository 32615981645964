import { useEffect, useMemo } from 'react'

import { useCurrentModel } from '~/hooks'
import { TryonStorageInstance } from '~/hooks/useTryon/storage'

import { useSyncContext } from '~/context/Sync'
import { useUserContext } from '~/context/User'

import InstructionalCard from '~/components/InstructionalCard'
import { QRCode } from '~/components/QRCode'
import { RoutesHeader } from '~/components/RoutesHeader'

import { slides } from '~/screens/Models/constants'
import { appendParameterToUrl } from '~/screens/Models/utils/appendParameterToUrl'
import { translate } from '~/utils/translate'

import * as Styled from './styles'

export const HowToSendMyPhotoScreen = () => {
  const { startSession, startSubscription } = useSyncContext()
  const { getCurrentModel } = useCurrentModel()
  const { stateCurrentUser } = useUserContext()

  const mobilePath = useMemo(() => {
    const payload = [
      { key: 'dwoa', value: '1' },
      { key: 'dwuser', value: stateCurrentUser?.uuid as string },
      { key: 'dwuuid', value: getCurrentModel().id },
      { key: 'dwgender', value: getCurrentModel().gender as string },
    ]
    const products: string[] = []
    const topProduct = TryonStorageInstance.get()?.top?.identifier
    const bottomProduct = TryonStorageInstance.get()?.bottom?.identifier

    if (topProduct) {
      products.push(topProduct)
    }
    if (bottomProduct) {
      products.push(bottomProduct)
    }
    if (products.length) {
      payload.push({
        key: 'dwskus',
        value: products.join(','),
      })
    }
    return appendParameterToUrl(payload)
  }, [getCurrentModel, stateCurrentUser?.uuid])

  useEffect(() => {
    startSession()
    startSubscription()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styled.Container data-testid="how-to-send-my-photo-screen">
      <RoutesHeader title={translate('HOW_TO_SEND_MY_PHOTO_HEADER_TITLE')} prevButtonUrl="Models" />
      <Styled.Content>
        <InstructionalCard
          title={translate('HOW_TO_SEND_MY_PHOTO_TITLE')}
          description={translate('HOW_TO_SEND_MY_PHOTO_DESCRIPTION')}
          actionComponent={<QRCode path={mobilePath} />}
          borderRadius="16px"
          slides={slides}
        />
      </Styled.Content>
    </Styled.Container>
  )
}
