import { FC } from 'react'

import { Backdrop } from '~/components/Backdrop'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'

import theme from '~/theme'

import { translate } from '~/utils/translate'
import { TTranslateKey } from '~/utils/translate/types'

import * as Styled from './styles'
import { settings } from './constants'
import { getComponent } from './helpers'
import { ISelectSizingBackdropProps } from './types'

export const SelectSizingBackdrop: FC<ISelectSizingBackdropProps> = ({
  handleClose,
  visible,
  loading = false,
  ...rest
}) => {
  const backdropTitleKey = rest.innerContentType === 'default' && loading ? 'loading' : rest.innerContentType

  const renderPrimaryButton = (): JSX.Element | undefined => {
    const { contentType, innerContentType } = rest

    if (contentType === 'list' && innerContentType === 'carousel') {
      return (
        <IconButton
          icon={<Icon name="arrowLeft" size="15px" color={theme.colors.primary} />}
          onClick={() => rest.onInnerContentChange('default')}
          testID="icon-go-back-button"
        />
      )
    }

    if (contentType === 'slider') {
      return (
        <Styled.LoadingIconWrapper
          data-testid="loading-wrapper"
          aria-busy={loading ? 'true' : 'false'}
          $loading={loading}
        >
          <Icon name="spinner-loading" size={16} color={theme.colors.palette.scorpion} />
        </Styled.LoadingIconWrapper>
      )
    }

    return
  }

  return (
    <Styled.Container withoutPadding={rest.contentType === 'list' && rest.innerContentType === 'carousel'}>
      <Backdrop
        testID="select-sizing-backdrop"
        height={settings[rest.contentType][rest.innerContentType]?.height}
        visible={visible}
        title={translate(settings[rest.contentType][backdropTitleKey]?.title as TTranslateKey)}
        handleClose={handleClose}
        primaryButton={renderPrimaryButton()}
        preventOverflow
        loading={loading}
      >
        {getComponent({ ...rest, loading })}
      </Backdrop>
    </Styled.Container>
  )
}
