import { func, number } from 'prop-types'

import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'

import theme from '~/theme'

import { Container, Label } from './styles'

const CombinedItemsButton = ({ onClick, combinedItemsCount }) => (
  <Container data-testid="combined-items-button-container">
    {Boolean(combinedItemsCount) && (
      <Label data-testid="combined-items-button-label" color={theme.colors.white}>
        <Icon name="cross" size={16} color={theme.colors.primary} />
      </Label>
    )}
    <IconButton
      disabled={!combinedItemsCount}
      testID="combined-items-button"
      size={40}
      icon={<Icon name="shop" color={theme.colors.white} size="22px" />}
      backgroundColor={theme.colors.primary}
      onClick={onClick}
    />
  </Container>
)

CombinedItemsButton.propTypes = {
  onClick: func.isRequired,
  combinedItemsCount: number,
}

export default CombinedItemsButton
