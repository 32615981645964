import styled from 'styled-components'

import { Paragraph } from '~/components/Typography/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 20px;
`

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 40px 20px;
`

export const TextDescription = styled(Paragraph)<{ align: string }>`
  margin-top: 20px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px 20px 0;
`

export const Image = styled.img`
  object-fit: cover;
  padding-bottom: 40px;
`
