import { FC } from 'react'

import { useNavigation } from '~/context/Navigation'
import { TRoutes } from '~/context/Navigation/types'

import { Icon } from '~/components/Icon'

import theme from '~/theme'

import * as Styled from './styles'
import { IRoutesHeaderProps } from './types'

export const RoutesHeader: FC<IRoutesHeaderProps> = ({
  closeButtonIconName = 'close',
  closeButtonUrl,
  contrast = false,
  prevButtonUrl,
  title,
  float,
}) => {
  const { navigate } = useNavigation()

  const handleClick = (url: TRoutes) => navigate(url)

  return (
    <Styled.Container data-testid="routes-header-container" contrast={contrast} float={float}>
      {prevButtonUrl && (
        <Styled.IconPrevButton
          onClick={() => handleClick(prevButtonUrl)}
          icon={<Icon name="arrowLeft" size="20px" color={contrast ? theme.colors.white : theme.colors.primary} />}
          size={32}
          testID="prev-button"
        />
      )}

      {title && (
        <Styled.Text data-testid="routes-header-title" align="center" contrast={contrast}>
          {title}
        </Styled.Text>
      )}

      {closeButtonUrl && (
        <Styled.IconButtonClose
          onClick={() => handleClick(closeButtonUrl)}
          icon={
            <Icon
              name={closeButtonIconName}
              size={closeButtonIconName === 'arrowDown' ? '25px' : '16px'}
              color={contrast ? theme.colors.white : theme.colors.primary}
            />
          }
          size={32}
          testID="close-button"
        />
      )}
    </Styled.Container>
  )
}
