import styled from 'styled-components'

import { Paragraph } from '~/components/Typography/styles'

import { IImageProps, IInjectButtonStyles } from './types'

export const Button = styled.button<IInjectButtonStyles>`
  text-transform: uppercase;
  padding: 0 24px;
  border-radius: 12px;
  cursor: pointer;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: fit-content;
  max-width: min-content;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  margin-bottom: ${({ hasBreak }) => (hasBreak ? '12px' : '0')};
`

export const ImageWrapper = styled.span`
  position: relative;
  width: 48px;
  height: 44px;
`

export const Image = styled.img<IImageProps>`
  display: block;
  height: 100%;
  width: auto;
  transition: opacity ease 0.25s;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  opacity: ${props => (props.visible ? '1' : '0')};
`

export const TextContainer = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.black};
`

export const BigText = styled.span`
  display: block;
  font-size: 12px;
  line-height: 12px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
`

export const BadgeNew = styled.span`
  position: absolute;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.status.error};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  top: 0;
  left: 0;
  font-family: ${({ theme }) => theme.fontFamily};
`
export const Container = styled.div<IInjectButtonStyles>`
  position: relative;
  margin: 12px 0;
  display: flex;
  gap: 12px;
  padding: ${({ showBadge }) => (showBadge ? '12px 0 12px 12px' : '0')};
  flex-direction: ${({ hasBreak }) => (hasBreak ? 'column' : 'row')};
`

export const Description = styled(Paragraph)`
  max-width: 340px;
  min-width: 220px;
  font-weight: 400;
  line-height: 14px;
  font-size: 12px;
`
