import styled from 'styled-components'

export const ContainerTags = styled.div`
  position: absolute;
  z-index: 2;
  top: 16px;
  left: 20px;
  display: grid;
  row-gap: 8px;
`
