import React from 'react'

import { TIconProps } from '.'

const Corner: React.FC<TIconProps> = ({ color, size, rotate }) => (
  <svg
    viewBox="0 0 44 44"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ width: size, height: size, transform: rotate ? `rotate(${rotate}deg)` : 'none' }}
    data-testid="icon-corner"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g transform="translate(-79.000000, -459.000000)" stroke={color} strokeWidth="2">
        <g transform="translate(81.000000, 11.000000)">
          <g>
            <path
              d="M0,490 L0,462 C-8.11624501e-16,455.372583 5.372583,450 12,450 L40,450 L40,450"
              transform="translate(20.000000, 470.000000) scale(1, -1) translate(-20.000000, -470.000000) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Corner
