import * as CONSTANTS from './constants'
import { IKeypoint, IPose, IValidationResponse, TPoseType } from './types'

export class PoseValidation {
  private _pose: IPose
  private _poseType: TPoseType = 'FRONT'
  private _height = 0
  private _width = 0

  constructor() {
    this._pose = { keypoints: [], score: 1 }
  }

  set poseType(value: TPoseType) {
    this._poseType = value
  }

  _checkLimit(distance: number) {
    return distance > this._height || distance < 0 ? false : true
  }

  validatePose(IPose: IPose, height: number, width: number): IValidationResponse {
    this._pose = IPose
    this._height = height
    this._width = width

    const poseMap = {
      FRONT: () => this._validateFrontPose(),
      FRONT_WITH_UP_ARMS: () => this._validateFrontWithUpArmsPose(),
      SIDE: () => this._validateSidePose(),
    }

    return poseMap[this._poseType]()
  }

  private _toDegrees(angle: number) {
    return angle * (180 / Math.PI)
  }

  private _calculateAngle(keypoint1: IKeypoint, keypoint2: IKeypoint, keypoint3: IKeypoint) {
    const { x: x1, y: y1 } = keypoint1
    const { x: x2, y: y2 } = keypoint2
    const { x: x3, y: y3 } = keypoint3

    return this._toDegrees(Math.atan2(y3 - y2, x3 - x2) - Math.atan2(y1 - y2, x1 - x2))
  }

  private _calculateDistance(keypoint1: IKeypoint, keypoint2: IKeypoint) {
    return Math.sqrt(Math.pow(keypoint1.x - keypoint2.x, 2) + Math.pow(keypoint1.y - keypoint2.y, 2))
  }

  private _checkIfDistanceBetweenWristAndHipIsValid() {
    const distanceFromWristAndHipLeft = this._calculateDistance(
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_WRIST],
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_HIP],
    )

    const distanceFromWristAndHipRight = this._calculateDistance(
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_WRIST],
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_HIP],
    )

    if (
      distanceFromWristAndHipLeft < CONSTANTS.DISTANCE_FROM_WRIST_AND_HIP_MIN_LIMIT ||
      distanceFromWristAndHipLeft > CONSTANTS.DISTANCE_FROM_WRIST_AND_HIP_MAX_LIMIT ||
      distanceFromWristAndHipRight < CONSTANTS.DISTANCE_FROM_WRIST_AND_HIP_MIN_LIMIT ||
      distanceFromWristAndHipRight > CONSTANTS.DISTANCE_FROM_WRIST_AND_HIP_MAX_LIMIT
    ) {
      return false
    }

    return true
  }

  private _checkIfDistanceBetweenArmsAndHipIsValid() {
    const leftArmsDistanceFromHip = this._calculateDistance(
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_WRIST],
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_HIP],
    )

    const rightArmsDistanceFromHip = this._calculateDistance(
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_WRIST],
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_HIP],
    )

    if (
      leftArmsDistanceFromHip < CONSTANTS.DISTANCE_ARMS_MIN_LIMIT ||
      leftArmsDistanceFromHip > CONSTANTS.DISTANCE_ARMS_MAX_LIMIT ||
      rightArmsDistanceFromHip < CONSTANTS.DISTANCE_ARMS_MIN_LIMIT ||
      rightArmsDistanceFromHip > CONSTANTS.DISTANCE_ARMS_MAX_LIMIT
    ) {
      return false
    }

    return true
  }

  private _checkIfDistanceFromShouldersIsValid(frontCheck: boolean) {
    const distanceFromShoulders = this._calculateDistance(
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_SHOULDER],
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_SHOULDER],
    )

    const correctDistance = frontCheck
      ? distanceFromShoulders > CONSTANTS.DISTANCE_SHOULDERS_LIMIT_FRONT
      : distanceFromShoulders < CONSTANTS.DISTANCE_SHOULDERS_LIMIT_SIDE

    return [correctDistance, distanceFromShoulders]
  }

  private _checkIfDistanceFromHipsIsValid() {
    const distanceFromHips = this._calculateDistance(
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_HIP],
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_HIP],
    )

    if (distanceFromHips < CONSTANTS.DISTANCE_HIPS_MIN_LIMIT || distanceFromHips > CONSTANTS.DISTANCE_HIPS_MAX_LIMIT) {
      return [false, distanceFromHips]
    }

    return [true, distanceFromHips]
  }

  private _checkIfDistanceFromKneesIsValid() {
    const distanceFromKnees = this._calculateDistance(
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_KNEE],
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_KNEE],
    )

    if (
      distanceFromKnees < CONSTANTS.DISTANCE_KNEES_MIN_LIMIT ||
      distanceFromKnees > CONSTANTS.DISTANCE_KNEES_MAX_LIMIT
    ) {
      return [false, distanceFromKnees]
    }

    return [true, distanceFromKnees]
  }

  private _checkIfDistanceFromAnklesIsValid() {
    const distanceFromAnkles = this._calculateDistance(
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_ANKLE],
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_ANKLE],
    )

    if (
      distanceFromAnkles < CONSTANTS.DISTANCE_ANKLES_MIN_LIMIT ||
      distanceFromAnkles > CONSTANTS.DISTANCE_ANKLES_MAX_LIMIT
    ) {
      return [false, distanceFromAnkles]
    }

    return [true, distanceFromAnkles]
  }

  private _checkIfLeftElbowAngleIsValid() {
    const leftElbowAngle = this._calculateAngle(
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_SHOULDER],
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_ELBOW],
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_WRIST],
    )

    if (
      leftElbowAngle < CONSTANTS.LEFT_ELBOW_ANGLE_MIN_LIMIT ||
      leftElbowAngle > CONSTANTS.LEFT_ELBOW_ANGLE_MAX_LIMIT
    ) {
      return [false, leftElbowAngle]
    }

    return [true, leftElbowAngle]
  }

  private _checkIfRightElbowAngleIsValid() {
    const rightElbowAngle = this._calculateAngle(
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_SHOULDER],
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_ELBOW],
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_WRIST],
    )

    if (
      rightElbowAngle < CONSTANTS.RIGHT_ELBOW_ANGLE_MIN_LIMIT ||
      rightElbowAngle > CONSTANTS.RIGHT_ELBOW_ANGLE_MAX_LIMIT
    ) {
      return [false, rightElbowAngle]
    }

    return [true, rightElbowAngle]
  }

  private _checkIfLeftKneeAngleIsValid() {
    const leftKneeAngle = this._calculateAngle(
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_HIP],
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_KNEE],
      this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_ANKLE],
    )

    if (leftKneeAngle < CONSTANTS.LEFT_KNEE_ANGLE_MIN_LIMIT || leftKneeAngle > CONSTANTS.LEFT_KNEE_ANGLE_MAX_LIMIT) {
      return [false, leftKneeAngle]
    }

    return [true, leftKneeAngle]
  }

  private _checkIfRightKneeAngleIsValid() {
    const rightKneeAngle = this._calculateAngle(
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_HIP],
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_KNEE],
      this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_ANKLE],
    )

    if (
      rightKneeAngle < CONSTANTS.RIGHT_KNEE_ANGLE_MIN_LIMIT ||
      rightKneeAngle > CONSTANTS.RIGHT_KNEE_ANGLE_MAX_LIMIT
    ) {
      return [false, rightKneeAngle]
    }

    return [true, rightKneeAngle]
  }

  private _validateFrontPose(): IValidationResponse {
    const response: IValidationResponse = {
      status: true,
      data: [],
    }

    if (this._pose.keypoints.filter(keypoint => keypoint.score && keypoint.score <= 0.1).length) {
      response.error = 'body'
      response.data.push('LOW_KEYPOINT_SCORE')

      return {
        ...response,
        status: false,
      }
    }

    const allowedPixelsAboveBar = this._height * 0.1
    const leftAnkleY = this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_ANKLE].y
    const rightAnkleY = this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_ANKLE].y
    const leftEyeY = this._pose.keypoints[CONSTANTS.poseLandmarks.LEFT_EYE].y
    const rightEyeY = this._pose.keypoints[CONSTANTS.poseLandmarks.RIGHT_EYE].y

    // BODY ERRORS
    const [isLeftKneeAngleValid, leftKneeAngle] = this._checkIfLeftKneeAngleIsValid()
    const [isRightKneeAngleValid, rightKneeAngle] = this._checkIfRightKneeAngleIsValid()
    const [isDistanceFromShouldersValid, distanceFromShoulders] = this._checkIfDistanceFromShouldersIsValid(true)

    // MEMBER ERRORS
    const isLeftEyeInsideLimits = this._checkLimit(leftEyeY)
    const isRightEyeYInsideLimits = this._checkLimit(rightEyeY)
    const isRightAnkleYInsideLimits = this._checkLimit(rightAnkleY + allowedPixelsAboveBar)
    const isLeftAnkleYInsideLimits = this._checkLimit(leftAnkleY + allowedPixelsAboveBar)
    const [isLeftElbowAngleValid, leftElbowAngle] = this._checkIfLeftElbowAngleIsValid()
    const [isRightElbowAngleValid, rightElbowAngle] = this._checkIfRightElbowAngleIsValid()

    if (!isLeftKneeAngleValid) response.data.push('INVALID_LEFT_KNEE_ANGLE_' + leftKneeAngle)
    if (!isRightKneeAngleValid) response.data.push('INVALID_RIGHT_KNEE_ANGLE_' + rightKneeAngle)
    if (!isDistanceFromShouldersValid) response.data.push('INVALID_DISTANCE_FROM_SHOULDERS_' + distanceFromShoulders)

    if (!isLeftEyeInsideLimits) response.data.push('LEFT_EYE_OUTSIDE_LIMIT')
    if (!isRightEyeYInsideLimits) response.data.push('RIGHT_EYE_OUTSIDE_LIMIT')
    if (!isRightAnkleYInsideLimits) response.data.push('RIGHT_ANKLE_OUTSIDE_LIMIT')
    if (!isLeftAnkleYInsideLimits) response.data.push('LEFT_ANKLE_OUTSIDE_LIMIT')
    if (!isLeftElbowAngleValid) response.data.push('LEFT_ELBOW_OUTSIDE_LIMIT_' + leftElbowAngle)
    if (!isRightElbowAngleValid) response.data.push('RIGHT_ELBOW_OUTSIDE_LIMIT_' + rightElbowAngle)

    // TODO: refactor this validations to Strategy pattern
    if (!isLeftKneeAngleValid || !isRightKneeAngleValid || !isDistanceFromShouldersValid) {
      response.error = 'body'
    } else if (
      !isLeftEyeInsideLimits ||
      !isRightEyeYInsideLimits ||
      !isRightAnkleYInsideLimits ||
      !isLeftAnkleYInsideLimits ||
      !isLeftElbowAngleValid ||
      !isRightElbowAngleValid
    ) {
      response.error = 'members'
    }

    return { ...response, status: !response?.error }
  }

  private _validateFrontWithUpArmsPose(): IValidationResponse {
    const response: IValidationResponse = {
      status: true,
      data: [],
    }

    const [isLeftKneeAngleValid, leftKneeAngle] = this._checkIfLeftKneeAngleIsValid()
    const [isRightKneeAngleValid, rightKneeAngle] = this._checkIfRightKneeAngleIsValid()

    if (!isLeftKneeAngleValid) response.data.push('INVALID_LEFT_KNEE_ANGLE_' + leftKneeAngle)
    if (!isRightKneeAngleValid) response.data.push('INVALID_RIGHT_KNEE_ANGLE_' + rightKneeAngle)

    const isDistanceBetweenArmsAndHipsValid = this._checkIfDistanceBetweenArmsAndHipIsValid()

    if (!isDistanceBetweenArmsAndHipsValid) response.data.push('INVALID_DISTANCE_BETWEEN_ARMS_AND_HIPS')

    if (!isLeftKneeAngleValid || !isRightKneeAngleValid) {
      response.error = 'body'
    } else if (!isDistanceBetweenArmsAndHipsValid) {
      response.error = 'members'
    }

    return { ...response, status: !response?.error }
  }

  private _validateSidePose(): IValidationResponse {
    const response: IValidationResponse = {
      status: true,
      data: [],
    }

    if (this._pose.keypoints.filter(keypoint => keypoint.score && keypoint.score <= 0.1).length) {
      response.error = 'body'
      response.data.push('LOW_KEYPOINT_SCORE')

      return {
        ...response,
        status: false,
      }
    }

    const [isDistanceFromKneesValid, distanceFromKnees] = this._checkIfDistanceFromKneesIsValid()
    const [isDistanceFromShouldersValid, distanceFromShoulders] = this._checkIfDistanceFromShouldersIsValid(false)
    const [isDistanceFromHipsValid, distanceFromHips] = this._checkIfDistanceFromHipsIsValid()

    if (!isDistanceFromKneesValid) response.data.push('INVALID_KNEES_DISTANCE_' + distanceFromKnees)
    if (!isDistanceFromShouldersValid) response.data.push('INVALID_SHOULDERS_DISTANCE_' + distanceFromShoulders)
    if (!isDistanceFromHipsValid) response.data.push('INVALID_HIPS_DISTANCE_' + distanceFromHips)

    const isDistanceBetweenWristAndHipsValid = this._checkIfDistanceBetweenWristAndHipIsValid()
    //const isDistanceFromAnklesInvalid = this._checkIfDistanceFromAnklesIsValid()

    if (!isDistanceBetweenWristAndHipsValid) response.data.push('INVALID_DISTANCE_BETWEEN_HIPS_AND_WRIST')
    //if (!isDistanceFromAnklesInvalid) response.data.push('INVALID_DISTANCE_FROM_ANKLES')

    if (!isDistanceFromKneesValid || !isDistanceFromShouldersValid || !isDistanceFromHipsValid) {
      response.error = 'body'
    } else if (!isDistanceBetweenWristAndHipsValid) {
      //|| !isDistanceFromAnklesInvalid) {
      response.error = 'members'
    }

    return { ...response, status: !response?.error }
  }
}
