import { FC, useCallback, useEffect } from 'react'

import { Backdrop } from '~/components/Backdrop'
import { ITermsProps } from '~/components/Terms/types'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { TermsDescription } from './components/TermsDescription'

export const Terms: FC<ITermsProps> = ({ active, onAccept }) => {
  const handleOnAccept = useCallback(() => {
    onAccept()
    Tracking.updateTermsRead()
    Tracking.logEvent('AGREED_PRIVACY_OR_TERMS', { widget: true })
  }, [onAccept])

  useEffect(() => {
    if (active) Tracking.logEvent('OPEN_PRIVACY_OR_TERMS', { widget: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Backdrop testID="terms" visible={active}>
      <Styled.Container>
        <Styled.Title data-testid="terms-title" align="center" color={theme.colors.black}>
          {translate('TERMS_TITLE')}
        </Styled.Title>

        <TextButton testID="terms-accept-button" onClick={handleOnAccept} background={theme.colors.primary}>
          {translate('TERMS_UNDERSTOOD_BUTTON')}
        </TextButton>

        <TermsDescription />
      </Styled.Container>
    </Backdrop>
  )
}
