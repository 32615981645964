import { FC } from 'react'

import { PositionsIllustrations } from '~/libs/illustrations'

import * as Styled from './styles'
import { positions, positionsErrors } from './constants'
import { IPositionsProps } from './types'

export const Positions: FC<IPositionsProps> = ({ position, error }) => {
  const IllustrationName = error ? positionsErrors[position][error] : positions[position]
  const IllustrationElement = PositionsIllustrations[IllustrationName]

  return (
    <Styled.Container data-testid="position-container">
      <IllustrationElement width="100%" height="100%" />
    </Styled.Container>
  )
}
