import styled from 'styled-components'

import { Video as VideoBase } from '~/components/Video'

export const Container = styled.div`
  opacity: 1;
`

export const ModalDescription = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  margin-top: 16px;
  line-height: 24px;
  z-index: 3;
  color: #000;
`
export const Video = styled(VideoBase)`
  height: 100%;
`
