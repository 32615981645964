import { useCallback, useState } from 'react'

import { useLazyQuery } from '@apollo/client'

import { Sentry } from '~/clients/sentry'
import { IGender } from '~/entities'

import { IQueryGenders, TFetch } from '.'
import { GET_GENDERS } from './graphql'

const gendersList = [
  { id: 1, name: 'MALE' },
  { id: 2, name: 'FEMALE' },
] as const

export const useQueryGenders = (): IQueryGenders => {
  const [genders, setGenders] = useState<Array<IGender>>([])

  const [fetch, { loading }] = useLazyQuery(GET_GENDERS)

  const fetchGenders = useCallback(
    (): TFetch =>
      new Promise(async resolve => {
        const { data, error } = await fetch()

        if (error) {
          resolve([])
          Sentry.captureException({
            errorName: error.name,
            errorMessage: `Error fetching genders: ${error}`,
            filePath: 'src/hooks-queries/genders/hook.ts',
            functionName: 'fetchGenders',
            payload: data,
          })
          return
        }

        const hasMale = !!data?.male?.aggregate?.count
        const hasFemale = !!data?.female?.aggregate?.count

        const filteredGenders = gendersList.filter(
          gender => (gender.name === 'MALE' && hasMale) || (gender.name === 'FEMALE' && hasFemale),
        )

        setGenders(filteredGenders)
        resolve(filteredGenders)
      }),
    [fetch],
  )

  return {
    data: genders,
    fetchGenders,
    loading,
  }
}
