import * as i18n from '~/libs/languages'

import { DORIS_CONFIG_STORAGE_KEY, LOCAL_TRANSLATION_STORAGE_KEY, languages } from './constants'
import { TLanguageKeys, TRegionalLanguage, TTranslateKey } from './types'

export const validateAndParseLanguage = (languageCode: string): TRegionalLanguage => {
  if (languageCode.includes('pt')) {
    localStorage.setItem(LOCAL_TRANSLATION_STORAGE_KEY, JSON.stringify(i18n.ptBr))
    return 'pt'
  }

  if (languageCode.includes('es')) {
    localStorage.setItem(LOCAL_TRANSLATION_STORAGE_KEY, JSON.stringify(i18n.esEs))
    return 'es'
  }

  localStorage.setItem(LOCAL_TRANSLATION_STORAGE_KEY, JSON.stringify(i18n.enUs))
  return 'en'
}

export const getSimplifiedLanguageCode = (): TRegionalLanguage => {
  const storageConfig = localStorage.getItem(DORIS_CONFIG_STORAGE_KEY)

  if (!storageConfig) {
    return 'en'
  }

  const { language } = JSON.parse(storageConfig)

  return languages[language as TLanguageKeys]
}

export const translate = (key: TTranslateKey) => {
  const lang = getSimplifiedLanguageCode()

  if (lang?.includes('pt')) {
    return i18n.ptBr[key]
  }

  if (lang?.includes('es')) {
    return i18n.esEs[key]
  }

  return i18n.enUs[key]
}

export const translateFromStorage = (key: string) => {
  const storage = localStorage.getItem(LOCAL_TRANSLATION_STORAGE_KEY)
  const defaultValue = key

  if (!storage) {
    return defaultValue
  }

  const translationSource = JSON.parse(storage)
  const translation = translationSource[key]

  return translation || defaultValue
}
