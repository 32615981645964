import styled from 'styled-components'

export const VideoContainer = styled.div`
  height: 100%;

  & video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    perspective: 1000;
  }
`
