import { IProductPositionsIds, TFilter } from '../types'

export const buildWhereFilter = ({ top, bottom, full }: IProductPositionsIds) => {
  const products = [top, bottom, full]
  const filter: TFilter = {
    order_by: { category_id: 'asc' },
    where: {
      _or: [],
      active: { _eq: true },
      status: { _eq: 'PROCESSED' },
      placeholder: { _eq: false },
    },
  }

  products.forEach(productId => {
    if (productId) filter.where._or.push({ id: { _eq: productId } })
  })

  return filter
}
