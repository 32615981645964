import { FC } from 'react'

import { TextButton } from '~/components/TextButton'

import { UpsideDownFace } from '~/libs/illustrations'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { ITimeoutErrorProps } from './types'

export const TimeoutError: FC<ITimeoutErrorProps> = ({
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  title,
  description,
}) => (
  <Styled.Container data-testid="timeout-error-component">
    <Styled.WrapperContent>
      <UpsideDownFace width="120px" />

      <Styled.Text align="center">{title || translate('TIMEOUT_SCREEN_TITLE')}</Styled.Text>

      <Styled.Description align="center">{description || translate('TIMEOUT_SCREEN_DESCRIPTION')}</Styled.Description>
    </Styled.WrapperContent>

    <Styled.Footer>
      {onSecondaryButtonClick && (
        <TextButton textTransform="uppercase" variant="outlined" testID="back-button" onClick={onSecondaryButtonClick}>
          {translate('RETURN')}
        </TextButton>
      )}
      {onPrimaryButtonClick && (
        <TextButton textTransform="uppercase" variant="default" testID="recharge-button" onClick={onPrimaryButtonClick}>
          {translate('RELOAD')}
        </TextButton>
      )}
    </Styled.Footer>
  </Styled.Container>
)
