import styled from 'styled-components'

import { getAnimation } from './helpers'
import { TAnimationFadeStyled } from './types'

export const Container = styled.div<TAnimationFadeStyled>`
  opacity: ${({ direction }) => (direction === 'fadeOut' ? 1 : 0)};

  ${({ startAnimation, direction }) => {
    if (direction === 'fadeInOut') {
      return getAnimation(startAnimation ? 'fadeIn' : 'fadeOut')
    }

    return startAnimation && getAnimation(direction)
  }};
`
