import { bool, func } from 'prop-types'

import { Button } from './styles'

export const TagDisplayTrigger = ({ onClick, active }) => {
  const label = active ? 'Esconder Preços' : 'Exibir Preços'

  return (
    <Button onClick={onClick} data-testid="tag-display-trigger-button" aria-label={label}>
      {label}
    </Button>
  )
}

TagDisplayTrigger.propTypes = {
  onClick: func.isRequired,
  active: bool.isRequired,
}
