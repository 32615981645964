import { gql } from '@apollo/client'

export const CATEGORY_TO_PARTNER = gql`
  query category_to_partner {
    category_to_partner {
      sizing_fit
      category {
        id
        type
        name
      }
    }
  }
`

export const SUBSCRIPTION_TRYON_FIT = gql`
  subscription tryOnFit($where: tryon_bool_exp) {
    tryon(where: $where) {
      id
      image_url
      sizing_suggestion_bottom_id
      sizing_suggestion_full_id
      sizing_suggestion_top_id
    }
  }
`
