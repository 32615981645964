import styled, { css } from 'styled-components'

const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  padding: 0 10px;
`

const Option = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  transition: border-color 0.3s ease;
  border-width: 3px;
  border-style: solid;
  border-color: ${({ active, backgroundColor, theme }) => (active ? theme.colors.primary : backgroundColor)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ active }) =>
    active &&
    css`
      pointer-events: none;
    `};

  svg {
    visibility: hidden;
    opacity: 0;
    flex-shrink: 0;
    transition: opacity 0.3s ease;

    ${({ active }) =>
      active &&
      css`
        visibility: visible;
        opacity: 1;
        pointer-events: none;
      `};
  }
`

export { List, Item, Option }
