import { useState } from 'react'

import { INotifyParams, IUseNotification } from './types'

export const useNotification = (): IUseNotification => {
  const [notification, setNotification] = useState<string | undefined>(undefined)

  const notify = ({ message }: INotifyParams): void => {
    setNotification(message)
  }

  const clear = (): void => {
    setNotification(undefined)
  }

  return {
    notification,
    notify,
    clear,
  }
}
