import styled, { css, keyframes } from 'styled-components'

import { IDotLineProps, IDotProps, TCarouselSlideItemProps } from './types'

const DotLineWidthAnimation = keyframes`
  from {
    width: 0;
  };

  to {
    width: 100%;
  };
`

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const Stage = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  &.loaded > * {
    position: absolute;
  }
`

export const CarouselSlideItem = styled.div<TCarouselSlideItemProps>`
  height: 100%;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;
  width: 100%;
`

export const DotsContainer = styled.div`
  bottom: 20px;
  display: flex;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  width: 90%;
`

export const Dot = styled.button<IDotProps>`
  background-color: transparent;
  border: none;
  cursor: default;
  height: 4px;
  margin: 0 1%;
  padding: 0;
  position: relative;
  width: ${({ length }) => length && 100 / length}%;

  ${({ isComplete }) =>
    isComplete &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
    `};

  &::after {
    background-color: ${({ theme }) => theme.colors.white};
    content: '';
    height: 4px;
    left: 0;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
`

export const DotLine = styled.span<IDotLineProps>`
  background-color: ${({ theme }) => theme.colors.white};
  display: block;
  height: 4px;
  width: 0;
  z-index: 2;

  ${({ isActive, speedAnimation }) =>
    isActive &&
    css`
      animation: ${DotLineWidthAnimation} ${speedAnimation}ms linear;
    `};
`
