import styled from 'styled-components'

import { Tooltip } from 'react-tooltip'

import { getArrowPlace, translateAnimationPlace } from './constants'
import { IBox, IContent, IReactTooltip } from './types'

export const Container = styled.div`
  display: flex;
`

export const Box = styled.div<IBox>`
  display: flex;
  flex-direction: column;

  padding: ${({ padding }) => padding || '20px'};
  ${({ width }) => width && `width: ${width}px;`};
`

export const Content = styled.div<IContent>`
  display: flex;

  ${({ hasSpace }) => hasSpace && `margin-top: 8px;`};
`

export const ReactTooltip = styled(Tooltip)<IReactTooltip>`
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition-property: opacity, visibility, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  visibility: hidden;
  width: auto;
  will-change: opacity, visibility, transform;
  filter: drop-shadow(0px 2px 40px rgba(0, 0, 0, 0.3));

  max-width: ${({ forceWidth, width }) => (forceWidth ? `${width}px` : '100%')};
  border-radius: ${({ borderRadius }) => borderRadius || '16px'};
  background-color: ${({ theme }) => theme.colors.white};
  transform: ${({ place }) => translateAnimationPlace[place]};

  &.styles-module_show__2NboJ {
    transition-delay: 0.2s;
    opacity: 1;
    visibility: visible;
    transform: translate3d(0px, 0px, 0px);
  }

  &.styles-module_fixed__7ciUi {
    position: fixed;
  }

  .styles-module_no-arrow__KcFZN {
    display: none;
  }

  .styles-module_arrow__K0L3T {
    background: inherit;
    position: absolute;
    transform: rotate(45deg);

    width: ${({ arrowWidth }) => (arrowWidth ? `${arrowWidth}px` : '20px')};
    height: ${({ arrowWidth }) => (arrowWidth ? `${arrowWidth}px` : '20px')};
    ${({ place, arrowWidth }) => getArrowPlace({ place, arrowWidth })};
  }
`
