import styled, { css } from 'styled-components'

const imageWidth = {
  product: '106px',
  cart: '100%',
  model: '210px',
}

const imageHeight = {
  product: '106px',
  cart: '44px',
  model: '306px',
}

export const Button = styled.button`
  position: relative;
  padding: 0;
  background-color: transparent;
  border-radius: 15px;
  overflow: hidden;
  transition: border 0.15s ease;
  border-style: solid;
  border-color: ${({ active, theme }) => (active ? theme.colors.primary : '#E7E7E7')};
  border-width: ${({ itemType }) => (itemType === 'product' ? '3px' : '4px')};
  max-width: 100%;
  max-height: 100%;
  width: ${({ itemType }) => imageWidth[itemType]};
  height: ${({ itemType }) => imageHeight[itemType]};
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ itemType }) =>
    itemType === 'cart' &&
    css`
      border: 0;
      border-radius: 5px;
    `};
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
export const Tag = styled.span`
  position: absolute;
  top: -1px;
  right: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: ${({ theme }) => theme.colors.primary};
`
