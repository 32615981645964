import toUpper from 'lodash/toUpper'

import { IConfigButton, TActiveTabName } from '~/components/SizingTabButtons/types'

import { animationsTime } from '~/screens/SizingScreen/constants'
import { tabOrder, tagOrder } from '~/screens/SizingScreen/constants'
import { TAnimationsOptions, TSizingInfos } from '~/screens/SizingScreen/types'

export const getScreenTimeout = (state: TAnimationsOptions): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, animationsTime[state]))

export const sortByTab = (array: Array<IConfigButton>) => {
  const result: Array<IConfigButton> = []

  tabOrder.forEach(tab => {
    array.forEach(itemSize => {
      if (String(itemSize.tabType) === tab) result.push(itemSize)
    })
  })

  return result
}

export const sortTabsByMeasurement = (array: Array<string>) => {
  const result: Array<TActiveTabName> = []

  tabOrder.forEach(tab => {
    array.forEach(itemSize => {
      const itemToUpper = toUpper(itemSize) as TActiveTabName
      if (itemToUpper === tab) result.push(itemToUpper)
    })
  })

  const setTabs =
    result.length > 1
      ? {
          name: result[0],
          next: result[1],
        }
      : {
          name: result[0],
          next: undefined,
        }

  return setTabs
}

export const sortByTag = (array: TSizingInfos['options']) => {
  const result: TSizingInfos['options'] = []

  tagOrder.forEach(tag => {
    array.forEach(itemSize => {
      if (String(itemSize.bodyPart) === tag) result.push(itemSize)
    })
  })

  return result
}
