import { FC } from 'react'

import { CombinedProduct } from '~/components/CombinedProduct'

import * as Styled from './styles'
import { ICombinedProductListProps } from './types'

export const CombinedProductList: FC<ICombinedProductListProps> = ({
  data,
  isLoading,
  skeletonsCount,
  hasCart,
  handleSizing,
}) => (
  <Styled.List data-testid="combined-products-list">
    {data.length
      ? data.map(product => (
          <CombinedProduct
            id={product.id}
            key={`product_${product.id}`}
            name={product.name}
            thumbnail={product.thumbnail}
            url={product.url}
            identifier={product.identifier}
            price={product.price}
            product_options={product.product_options}
            isLoading={isLoading}
            hasCart={hasCart}
            category={product.category}
            measurement={product.measurement}
            handleSizing={handleSizing}
          />
        ))
      : Array.from({ length: skeletonsCount || 1 }).map((_, index) => (
          <CombinedProduct
            id={0}
            key={index}
            name={''}
            thumbnail={''}
            url={''}
            identifier={''}
            price={0}
            product_options={[]}
            isLoading
            category={{ id: 0, type: 'TOP', name: 'shirt' }}
            handleSizing={handleSizing}
          />
        ))}
  </Styled.List>
)
