import styled, { css } from 'styled-components'

import { ITextAndIconButtonProps } from './types'

export const Button = styled.button<Pick<ITextAndIconButtonProps, 'iconDirection' | 'textTransform' | 'variant'>>`
  width: 100%;
  height: 40px;
  border: none;
  padding: 12px 20px;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.primary};

  ${({ variant }) =>
    variant === 'outlined' &&
    css`
      border: 1px solid ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.white};
    `};

  ${({ variant }) =>
    variant === 'phantom' &&
    css`
      border: 1px solid transparent;
      background-color: ${props => props.theme.colors.white};
    `};

  &[disabled] {
    opacity: ${props => props.theme.opacity.default};
  }
`

export const Content = styled.span<Pick<ITextAndIconButtonProps, 'iconDirection' | 'textTransform' | 'variant'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  gap: 10px;
  color: ${props => props.theme.colors.white};
  text-transform: ${props => props.textTransform};

  ${({ iconDirection }) =>
    iconDirection === 'right' &&
    css`
      flex-direction: row-reverse;
    `};

  ${({ variant }) =>
    variant !== 'default' &&
    css`
      color: ${props => props.theme.colors.primary};
    `};
`

export const Icon = styled.div`
  display: flex;
`
