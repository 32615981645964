import styled, { css } from 'styled-components'

import { ITypographyProps, TAlignType } from '.'

const getBaseStyles = ({ align, color, font }: { align?: TAlignType; color?: string; font?: string }) => css`
  margin: 0;

  ${font &&
  css`
    font-family: ${font};
  `}

  ${align &&
  css`
    text-align: ${align};
  `}

  ${color &&
  css`
    color: ${color};
  `}
`

const Title = styled.h2<Pick<ITypographyProps, 'align' | 'color'>>`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  ${({ align, color, theme }) =>
    getBaseStyles({
      align,
      color,
      font: theme.fontFamily,
    })};
`

const Paragraph = styled.p<Pick<ITypographyProps, 'align' | 'color'>>`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  ${({ align, color, theme }) =>
    getBaseStyles({
      align,
      color,
      font: theme.fontFamily,
    })};
`

const Prefix = styled.span<Pick<ITypographyProps, 'align' | 'color'>>`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  ${({ align, color, theme }) =>
    getBaseStyles({
      align,
      color,
      font: theme.fontFamily,
    })};
`

export { Title, Paragraph, Prefix }
