/*
  References:
  - https://www.ios-resolution.com/
  - https://www.link-assistant.com/seo-wiki/user-agent/#Mobile-Apple-Devices7
  - https://www.whatismybrowser.com/guides/the-latest-user-agent/ios
*/

export const isNewiPhone = (): boolean => {
  const { userAgent } = navigator
  const { width, height } = window.screen

  const isValidChrome =
    userAgent.includes('(iPhone; CPU iPhone') &&
    userAgent.includes('Mobile/15E148') &&
    userAgent.includes('(KHTML, like Gecko) CriOS/') &&
    width === 430 &&
    height === 932

  const isValidSafari =
    userAgent.includes('(iPhone; CPU iPhone') &&
    userAgent.includes('Mobile/15E148') &&
    userAgent.includes('KHTML, like Gecko) Version/') &&
    ((width === 414 && height === 896) || (width === 430 && height === 932))

  return isValidChrome || isValidSafari
}
