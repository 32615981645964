import { FC } from 'react'

import { CarouselContent } from '~/components/Backdrop/SelectSizingBackdrop/components/ListSizing/components/CarouselContent'
import { EditContent } from '~/components/Backdrop/SelectSizingBackdrop/components/ListSizing/components/EditContent'

import * as Styled from './styles'
import { IListSizingProps } from './types'

export const ListSizing: FC<IListSizingProps> = ({
  data,
  onInnerContentChange,
  innerContentType,
  onShowAlert,
  onHandleSelectChange,
  formData,
  onUpdateAvatarMeasurements,
  formChanged,
}) => (
  <Styled.Container data-testid="container-list-sizing">
    {innerContentType === 'carousel' ? (
      <CarouselContent />
    ) : (
      <EditContent
        formChanged={formChanged}
        formData={formData}
        onHandleSelectChange={onHandleSelectChange}
        onShowAlert={onShowAlert}
        data={data}
        onInnerContentChange={onInnerContentChange}
        onUpdateAvatarMeasurements={onUpdateAvatarMeasurements}
      />
    )}
  </Styled.Container>
)
