import styled, { css } from 'styled-components'

import { Paragraph, Prefix, Title } from '~/components/Typography/styles'

import { IBackgroundImageStyled, IInstructionalCardProps } from './types'

export const CardTitle = styled(Title)`
  font-size: 24px;
  line-height: 29px;
  color: ${props => props.theme.colors.white};
  z-index: 3;
`

export const CardDescription = styled(Paragraph)`
  margin-top: 16px;
  line-height: 17px;
  color: ${props => props.theme.colors.white};
  z-index: 3;
`

export const Container = styled.div<Pick<IInstructionalCardProps, 'borderRadius'>>`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 20px 25px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`};
  background-color: ${props => props.theme.colors.yellowSea};
`

export const ContainerImage = styled.div`
  background-color: ${props => props.theme.colors.white};
  position: absolute;
  transform: translate3d(0, 0, 0);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
`

export const BackgroundImage = styled.img<IBackgroundImageStyled>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`};
  object-fit: cover;
  object-position: center top;
  pointer-events: none;
  opacity: 0;
  transition: opacity 350ms linear;
  will-change: opacity;
  z-index: 1;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
    `};
`

export const BackgroundOverlay = styled.div<Pick<IInstructionalCardProps, 'borderRadius'>>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  z-index: 3;
  transform: translate3d(0, 0, 0);
`

export const ButtonContainer = styled.div`
  margin-top: 16px;
  z-index: 3;
  transform: translate3d(0, 0, 0);
`

export const PrefixText = styled(Prefix)`
  font-weight: 400;
  color: ${props => props.theme.colors.white};
`

export const LockedContainer = styled.div`
  margin-top: 20.5px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transform: translate3d(0, 0, 0);
`

export const ActionComponentWrapper = styled.div`
  z-index: 3;
  margin-top: ${({ theme }) => `${theme.tokens.spacingUnit * 2}px`};
`
