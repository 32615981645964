import styled from 'styled-components'

import { ITabDefaultHeaderProps } from '.'

const Item = styled.li<Pick<ITabDefaultHeaderProps, 'active'>>`
  display: flex;
  width: 100%;
  border-bottom: 4px solid ${({ active, theme }) => (active ? theme.colors.primary : 'transparent')};
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 0;
  background: none;
  border: 0;
`
export { Item, Button }
