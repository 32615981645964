import { FC, useCallback, useState } from 'react'

import { TryonContext } from './context'
import {
  TClearStates,
  TCurrentTryonState,
  TSetCurrentTryonState,
  TSetTryonState,
  TSetTryonUpscaleState,
  TTryonState,
  TTryonUpscaleState,
} from './types'

export const TryonProvider: FC = ({ children }) => {
  const [stateTryon, setTryon] = useState<TTryonState>()
  const [stateCurrentTryon, setCurrentTryon] = useState<TCurrentTryonState>()
  const [stateTryonUpscale, setUpscaleTryon] = useState<TTryonUpscaleState>()

  const setTryonState = useCallback((params: TSetTryonState): void => {
    setTryon(current => (typeof params === 'function' ? params(current) : params))
  }, [])

  const setCurrentTryonState = useCallback((params: TSetCurrentTryonState): void => {
    setCurrentTryon(current => (typeof params === 'function' ? params(current) : params))
  }, [])

  const setTryonUpscaleState = useCallback((params?: TSetTryonUpscaleState): void => {
    setUpscaleTryon(current => (typeof params === 'function' ? params(current) : params))
  }, [])

  const clearStates = useCallback((params: TClearStates) => {
    const states = {
      stateTryon: setTryon,
      stateCurrentTryon: setCurrentTryon,
      stateTryonUpscale: setUpscaleTryon,
    }

    params.forEach(state => states[state](undefined))
  }, [])

  const value = {
    stateTryon,
    stateCurrentTryon,
    stateTryonUpscale,
    setTryonState,
    setCurrentTryonState,
    setTryonUpscaleState,
    clearStates,
  }

  return <TryonContext.Provider value={value}>{children}</TryonContext.Provider>
}
