import { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'

import { GET_SKINS, IQueryData, IQuerySkins, IQueryVars, TFetch } from '.'

export const useQuerySkins = (): IQuerySkins => {
  const [fetch, { data, called, loading, error }] = useLazyQuery<IQueryData, IQueryVars>(GET_SKINS, {
    variables: { order_by: { order: 'asc' } },
  })

  const fetchSkins = useCallback(
    (): TFetch =>
      new Promise(async (resolve, reject) => {
        const { data, error } = await fetch()

        if (error) {
          reject(error)

          return
        }

        resolve(data?.skin_tone || [])
      }),
    [fetch],
  )

  return {
    data: data?.skin_tone || [],
    fetchSkins,
    called,
    loading,
    error,
  }
}
