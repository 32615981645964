import { css } from 'styled-components'

import theme from '~/theme'

import { TPinDirectionMap, TPinStatusColorMap } from './types'

export const statusColorMap: TPinStatusColorMap = { SUCCESS: theme.colors.growingNature, DANGER: theme.colors.error }

export const pinDirectionMap: TPinDirectionMap = {
  LEFT: css`
    left: -3px;
  `,
  RIGHT: css`
    right: -3px;
  `,
}
