import { FC, useCallback } from 'react'

import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'

import theme from '~/theme'

import * as Styled from './styles'
import { ISizingTabButtonsProps, TActiveTabName } from './types'

export const SizingTabButtons: FC<ISizingTabButtonsProps> = ({ activeTabName = 'TOP', configButtons }) => {
  const handleActiveTab = useCallback(
    (tabName: TActiveTabName) => {
      return {
        color: tabName === activeTabName ? theme.colors.white : theme.colors.silverChalice,
        backgroundColor: tabName === activeTabName ? theme.colors.primary : theme.colors.white,
      }
    },
    [activeTabName],
  )

  return (
    <Styled.Container data-testid="sizing-tab-buttons">
      {configButtons.map(config => (
        <IconButton
          key={config.iconName}
          pin={config.pin}
          icon={<Icon name={config.iconName} size={config.iconSize} color={handleActiveTab(config.tabType).color} />}
          disabled={config.disabled}
          backgroundColor={handleActiveTab(config.tabType).backgroundColor}
          size={40}
          onClick={config.onClick}
          testID={`sizing-tab-button-${config.testID}`}
        />
      ))}
    </Styled.Container>
  )
}
