import styled from 'styled-components'

import { Paragraph, Title } from '~/components/Typography/styles'

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Column = styled.div`
  display: flex;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
`

export const Text = styled(Paragraph)`
  padding: 0 20px 12px;
`

export const TextBtn = styled(Title)`
  font-size: 12px;
  font-weight: 700;
`

export const ContainerButtonIcon = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const ContainerAvatarList = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
`
