import { FC } from 'react'

import { IIllustrationProps } from '../types'

export const FrontArmsClosedErrorMembers: FC<IIllustrationProps> = ({ width, height }) => (
  <svg
    style={{ width, height }}
    data-testid="illustration-front-arms-closed-error-members"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="61.6 19 182 400"
  >
    <path
      style={{ fill: '#FFFFFF', fillOpacity: 0.75 }}
      d="M220.5,236.6c-0.8-3.9-0.4-10.8-3.3-18.5c-2.4-6.3,0.2-18.8,0.4-35c0.2-16.2-4.5-26.5-4.5-26.5
	c-1.1-5.8-4.9-33.9-4.9-33.9s3.5-25.8-11.3-34.8c-7.3-4.4-12.1-2.2-19.9-5.5c-7.8-3.3-14-5.8-14.2-7.3c-0.1-1.1-0.1-5-0.1-7.1
	c1.5-1.7,2.9-3.7,4.2-6c1.1-1.9,1.7-4.1,2.1-6.2c1.5-0.4,2.1-2.8,2.4-4.8c0.4-2.5,2.5-4.7,1.4-7.9c-0.7-2-1.8-2-2.5-1.8
	c1-5.8,0.8-13.8-5-18.4C159.9,18.9,152,19,152,19s-8-0.1-13.2,4.1c-5.8,4.6-6,12.6-5,18.4c-0.7-0.2-1.8-0.2-2.5,1.8
	c-1.1,3.1,1.1,5.4,1.4,7.9c0.3,2,0.9,4.3,2.4,4.8c0.4,2.1,1,4.2,2.1,6.2c1.2,2.2,2.5,4.1,3.9,5.8c0,2.2,0.1,6,0,7
	c-0.1,1.5-6.2,4.3-14,7.6c-7.8,3.3-12.6,1-19.9,5.5c-14.8,9-11.3,34.8-11.3,34.8s-3.8,28.2-4.9,33.9c0,0-4.8,10.3-4.5,26.5
	c0.3,16.2,2.8,28.8,0.4,35c-2.9,7.6-2.4,14.5-3.3,18.5c-1,4.8,8,20.5,11.8,18.1c2.4-1.5-4.6-10.9-3.8-15c0.8-4.1,3-9.7,4.2-7.9
	c1.2,1.8,1.2,5.1,3,7.4c1.8,2.3,4.7,1.9,2.9-4.8c-1.8-6.7-0.5-5.6-1.9-9.7c-1.4-4.1-3-3.3-1.9-9.9c1.1-6.5,11.7-31.6,12.3-55.8
	c0,0,7.1-20.8,7.6-24.2c0,0,1,4.9,3.2,12.4c0.8,2.8,0.4,6.7,0.1,10.3c-0.4,4.7-0.9,9.2-2.3,12.6c-2.5,6.1-2.1,4.7-4.1,11.7
	c-3.4,11.8-3,16-6.6,42.5c-2.3,16.9-1.5,28.4-1.3,37.7c0.5,17.1,8,22.6,6.6,41.2c-0.4,5.5-2.3,17.7-1.7,31.5
	c0.7,14.8,3.9,31.2,6.3,42.3c3,13.5-0.1,14.6-0.3,17.5c-0.3,4.9,0.3,10.4-2.3,13c-2.6,2.6-5.8,4.1-4.8,7.4c0.7,2.4,13,4.1,18.9,4.1
	c6.5,0,6.9-1.9,5-8.1c-1.9-6.2-2.7-8.7-1.8-12.7c1-4.2-0.9-5.4-1-12c-0.1-6.6,3-15.5,6.8-30.2c5-19.3-2.1-26.8-0.8-49.1
	c0.3-5,4.2-16.7,6.2-32.3c1.7-13.6,4.6-23,5.5-32.6c1.2-14,1.2-15,2.6-15.1c1.3,0.1,1.3,1.1,2.6,15.1c0.8,9.6,3.8,19,5.5,32.6
	c1.9,15.6,5.9,27.2,6.2,32.3c1.4,22.3-5.8,29.8-0.8,49.1c3.8,14.7,7,23.7,6.8,30.2c-0.1,6.6-2.1,7.8-1.1,12c0.9,4,0.1,6.5-1.8,12.7
	c-1.9,6.2-1.5,8.1,5,8.1c5.9,0,18.2-1.7,18.9-4.1c1-3.3-2.1-4.8-4.8-7.4c-2.6-2.6-2-8.1-2.3-13c-0.2-3-3.3-4-0.3-17.5
	c2.4-11,5.7-27.5,6.3-42.3c0.6-13.7-1.3-26-1.7-31.5c-1.4-18.5,6.1-24.1,6.6-41.2c0.3-9.3,1-20.8-1.3-37.7
	c-3.5-26.4-3.1-30.6-6.6-42.5c-2-7-1.6-5.6-4.1-11.7c-1.3-3.1-1.7-7.3-2.2-11.6c-0.4-4.1-0.9-8.3,0-11.3c2.2-7.5,3.2-12.4,3.2-12.4
	c0.5,3.4,7.6,24.2,7.6,24.2c0.6,24.3,11.2,49.3,12.3,55.8c1.1,6.5-0.5,5.8-1.9,9.9c-1.4,4.1-0.1,3-1.9,9.7c-1.8,6.7,1.1,7.1,2.9,4.8
	c1.8-2.3,1.9-5.6,3-7.4c1.2-1.8,3.4,3.8,4.2,7.9c0.8,4.1-6.2,13.5-3.8,15C212.5,257.1,221.5,241.5,220.5,236.6L220.5,236.6z"
    />
    <path
      style={{ fill: '#FF433D' }}
      d="M96.8,199.5L96.8,199.5c7.7,0,14,6.3,14,14l0,0c0,7.7-6.3,14-14,14l0,0c-7.7,0-14-6.3-14-14l0,0
	C82.8,205.8,89,199.5,96.8,199.5z"
    />
    <g style={{ opacity: 0.2 }}>
      <path
        style={{ fill: '#FF433D' }}
        d="M96.8,177.5L96.8,177.5c19.9,0,36,16.1,36,36l0,0c0,19.9-16.1,36-36,36l0,0c-19.9,0-36-16.1-36-36l0,0
		C60.8,193.6,76.9,177.5,96.8,177.5z"
      />
    </g>
    <path
      style={{ fill: '#FF433D' }}
      d="M207.2,199.5L207.2,199.5c7.7,0,14,6.3,14,14l0,0c0,7.7-6.3,14-14,14l0,0c-7.7,0-14-6.3-14-14l0,0
	C193.2,205.8,199.5,199.5,207.2,199.5z"
    />
    <g style={{ opacity: 0.2 }}>
      <path
        style={{ fill: '#FF433D' }}
        d="M207.2,177.5L207.2,177.5c19.9,0,36,16.1,36,36l0,0c0,19.9-16.1,36-36,36l0,0c-19.9,0-36-16.1-36-36l0,0
		C171.2,193.6,187.4,177.5,207.2,177.5z"
      />
    </g>
    <path
      style={{ fill: '#FF433D' }}
      d="M152,328L152,328c7.7,0,14,6.3,14,14l0,0c0,7.7-6.3,14-14,14l0,0c-7.7,0-14-6.3-14-14l0,0
	C138,334.3,144.3,328,152,328z"
    />
    <g style={{ opacity: 0.2 }}>
      <path
        style={{ fill: '#FF433D' }}
        d="M152,306L152,306c19.9,0,36,16.1,36,36l0,0c0,19.9-16.1,36-36,36l0,0c-19.9,0-36-16.1-36-36l0,0
		C116,322.1,132.1,306,152,306z"
      />
    </g>
  </svg>
)
