import { FC } from 'react'

import { Icon } from '~/components/Icon'
import { IIconButtonProps } from '~/components/IconButton/types'

import * as Styled from './styles'

export const IconButton: FC<IIconButtonProps> = ({
  backgroundColor = 'transparent',
  borderColor = '',
  className = '',
  disabled = false,
  icon,
  pin,
  onClick = () => ({}),
  size = 34,
  testID = 'icon-button',
  boxShadow,
}) => (
  <Styled.Button
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    className={className}
    data-testid={testID}
    disabled={disabled}
    onClick={onClick}
    size={size}
    boxShadow={boxShadow}
  >
    {icon}
    {pin && !disabled && (
      <Styled.Pin status={pin.status} direction={pin.direction} data-testid={`${testID}-pin`}>
        {pin.iconName && <Icon name={pin.iconName} size="8px" />}
      </Styled.Pin>
    )}
  </Styled.Button>
)
