import { FC } from 'react'

import { Wrapper } from './styles'

export interface ITabDefaultWrapperProps {
  height?: string
}

const TabDefaultWrapper: FC<ITabDefaultWrapperProps> = ({ children, height }) => (
  <Wrapper data-testid="tab-default-wrapper" height={height}>
    {children}
  </Wrapper>
)

export default TabDefaultWrapper
