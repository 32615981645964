import { FC, useCallback, useState } from 'react'

import {
  MeasurementsContext,
  TClearStates,
  TCurrentMeasurementsState,
  TMeasurementsState,
  TSetCurrentMeasurementsState,
  TSetMeasurementsState,
} from '.'

export const MeasurementsProvider: FC = ({ children }) => {
  const [stateMeasurements, setMeasurements] = useState<TMeasurementsState>()
  const [stateCurrentMeasurements, setCurrentMeasurements] = useState<TCurrentMeasurementsState>()

  const setMeasurementsState = useCallback((params: TSetMeasurementsState): void => {
    setMeasurements(current => (typeof params === 'function' ? params(current) : params))
  }, [])

  const setCurrentMeasurementsState = useCallback((params: TSetCurrentMeasurementsState): void => {
    setCurrentMeasurements(current => (typeof params === 'function' ? params(current) : params))
  }, [])

  const clearStates = useCallback((params: TClearStates) => {
    const states = {
      stateMeasurements: setMeasurements,
      stateCurrentMeasurements: setCurrentMeasurements,
    }

    params.forEach(state => states[state](undefined))
  }, [])

  const value = {
    stateMeasurements,
    stateCurrentMeasurements,
    setMeasurementsState,
    setCurrentMeasurementsState,
    clearStates,
  }

  return <MeasurementsContext.Provider value={value}>{children}</MeasurementsContext.Provider>
}
