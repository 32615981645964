export const getDownloadUrl = (tryOnUrl: string, watermarkUrl?: string | null) => {
  const pathname = new URL(tryOnUrl).pathname
  const tryOnPathReg = /(?!\/)[a-z0-9-_]+\.(?:png|jpg|jpeg)/gi
  const path = pathname.match(tryOnPathReg)?.[0]
  const IMGIXStorageUrl = process.env.REACT_APP_IMGIX_STORAGE_URL
  const baseURL = `${IMGIXStorageUrl}/${path}`

  if (!watermarkUrl || !process.env.REACT_APP_IMGIX_STORAGE_URL) return tryOnUrl

  return `${baseURL}?mark-pad=0&mark=${watermarkUrl}&mark-align=bottom&mark-w=1.0&mark-h=1.0`
}
