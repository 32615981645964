import { useCallback, useRef, useState } from 'react'

import { useSubscription } from '@apollo/client'

import { GET_TRYON_UPSCALE } from './graphql'
import {
  ITryonUpscaleQueryData,
  ITryonUpscaleQueryVars,
  ITryonUpscaleSubscribeParameters,
  IUseQueryTryonUpscale,
  TTryonOnUpscaleError,
  TTryonOnUpscaleProcessing,
  TTryonOnUpscaleSuccess,
} from './types'

export const useQueryTryonUpscale = (): IUseQueryTryonUpscale => {
  const TIMEOUT =
    (process.env.REACT_APP_UPSCALE_TIMEOUT_IN_SECONDS ? Number(process.env.REACT_APP_UPSCALE_TIMEOUT_IN_SECONDS) : 10) *
    1000

  const onSuccessRef = useRef<{ call: TTryonOnUpscaleSuccess }>()
  const onErrorRef = useRef<{ call?: TTryonOnUpscaleError }>()
  const onProcessingRef = useRef<{ call?: TTryonOnUpscaleProcessing }>()

  const timeoutKey = useRef<NodeJS.Timeout>()
  const [subscribedTryonId, setSubscribedTryonId] = useState<number>()

  useSubscription<ITryonUpscaleQueryData, ITryonUpscaleQueryVars>(GET_TRYON_UPSCALE, {
    variables: { tryon_id: subscribedTryonId },
    skip: !subscribedTryonId,
    onData: ({ data }) => {
      const tryon = data?.data?.tryon?.[0]

      if (!tryon?.upscaledImageUrl) return

      onSuccessRef.current?.call({
        tryonId: data.variables?.tryon_id,
        upscaledImageUrl: tryon.upscaledImageUrl,
      })

      clearSubscriptionTimeout()
    },
    onError: () => {
      onErrorRef.current?.call?.(new Error(`[ERROR][TRYON_UPSCALE]: Não foi possível gerar o upscale.`))
      unsubscribe()
    },
  })

  const unsubscribe = useCallback(() => {
    setSubscribedTryonId(undefined)

    onProcessingRef.current?.call?.({ isLoading: false, unsub: true })

    clearSubscriptionTimeout()
  }, [])

  const clearSubscriptionTimeout = () => {
    clearTimeout(timeoutKey.current as NodeJS.Timeout)
  }

  const startTimer = useCallback(
    timerValue => {
      timeoutKey.current = setTimeout(() => {
        onErrorRef.current?.call?.(new Error(`[ERROR][TRYON_UPSCALE]: Timeout.`))
        unsubscribe()
      }, timerValue)
    },
    [unsubscribe],
  )

  const subscribe = useCallback(
    ({ tryonId, onSuccess, onError, onProcessing }: ITryonUpscaleSubscribeParameters) => {
      unsubscribe()

      if (!tryonId) {
        onError?.(new Error(`[ERROR][TRYON_UPSCALE]: Não foi informado um tryon id.`))
        return
      }

      onSuccessRef.current = { call: onSuccess }
      onErrorRef.current = { call: onError }
      onProcessingRef.current = { call: onProcessing }

      onProcessing?.({ isLoading: true })

      startTimer(TIMEOUT)

      setTimeout(() => {
        setSubscribedTryonId(tryonId)
      }, 100)
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startTimer, unsubscribe],
  )

  return { subscribe, unsubscribe }
}
