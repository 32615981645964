import styled from 'styled-components'

import { ETimerSize, ITimerAnimation } from './types'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Svg = styled.svg<Pick<ITimerAnimation, 'size'>>`
  transform: rotate(270deg);
  width: ${({ size }) => Math.round(121 / size)}px;
  height: ${({ size }) => Math.round(121 / size)}px;
`

export const CircleTrack = styled.circle<Pick<ITimerAnimation, 'color' | 'size'>>`
  fill: transparent;
  stroke-linecap: round;
  cx: ${({ size }) => Math.round(60 / size)}px;
  cy: ${({ size }) => Math.round(60 / size)}px;
  r: ${({ size }) => Math.round(56 / size)}px;
  stroke-dasharray: ${({ size }) => Math.round(352 / size)}px;
  stroke-width: ${({ size }) => Math.round(8 / size) + 1}px;
  stroke: ${({ color }) => color};
`

export const CircleIndicator = styled.circle<ITimerAnimation>`
  fill: transparent;
  stroke-linecap: round;
  cx: ${({ size }) => Math.round(60 / size)}px;
  cy: ${({ size }) => Math.round(60 / size)}px;
  r: ${({ size }) => Math.round(56 / size)}px;
  stroke-dasharray: ${({ size }) => Math.round(352 / size)}px;
  stroke-width: ${({ size }) => Math.round(8 / size) + 1}px;
  stroke: ${({ color }) => color};
  stroke-dashoffset: ${({ animationStart, size }) => (animationStart ? Math.round(-352 / size) : 0)}px;
  transition: stroke-dashoffset ${({ animationTime }) => animationTime}s linear;
`

export const Text = styled.span<Pick<ITimerAnimation, 'color' | 'size'>>`
  position: absolute;
  font-size: ${({ size }) => Math.round(68 / size) - (size !== ETimerSize.LARGE ? 8 : 0)}px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ color }) => color};
`
