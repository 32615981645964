export enum ETimerSize {
  LARGE = 1,
  MEDIUM = 1.5,
  NORMAL = 2,
  SMALL = 2.5,
}

export interface ITimer {
  callback: () => void
  time?: number
  color?: string
  size?: ETimerSize
}

export interface ITimerAnimation {
  animationStart: boolean
  animationTime: number
  color: string
  size: ETimerSize
}
