import { useCallback, useRef, useState } from 'react'

const useImageLoader = () => {
  const [loaded, setLoaded] = useState(false)
  const imageRef = useRef<HTMLImageElement>()

  const handleOnLoad = useCallback(() => {
    setLoaded(Boolean(imageRef.current?.complete))
  }, [])

  return { imageRef, loaded, handleOnLoad }
}

export default useImageLoader
