import { gql } from '@apollo/client'

export const GET_MEASUREMENTS = gql`
  query measurements($avatar_uuid: String!) {
    model_to_tryon(where: { avatar_uuid: { _eq: $avatar_uuid } }) {
      height
      measurements_chest
      measurements_hip
      measurements_waist
      updated_height
      updated_measurements_chest
      updated_measurements_hip
      updated_measurements_waist
    }
  }
`

export const UPDATE_MEASUREMENTS = gql`
  mutation updateMeasurements($avatar_uuid: String!, $set: model_to_tryon_set_input) {
    update_model_to_tryon(where: { avatar_uuid: { _eq: $avatar_uuid } }, _set: $set) {
      affected_rows
    }
  }
`
