import { FC, useEffect } from 'react'

import { useUser } from '~/hooks/useUser'

import { useUserContext } from '~/context/User'

import { Backdrop } from '~/components/Backdrop'
import { TextButton } from '~/components/TextButton'
import Typography from '~/components/Typography'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { backgroundImage } from './constants'
import { IRecommendationProps } from './types'

export const RecommendationBackdrop: FC<IRecommendationProps> = ({ visible, onClose, onButtonClick }) => {
  const { setCurrentUserState } = useUserContext()
  const { updateUser } = useUser()

  useEffect(() => {
    if (!visible) return

    updateUser({
      values: { showRecommendationTooltip: false },
      setState: setCurrentUserState,
    })
  }, [visible, setCurrentUserState, updateUser])

  return (
    <Backdrop
      primaryButtonIconColor={theme.colors.white}
      testID="recommendation"
      layerIndex={5}
      height="100%"
      visible={visible}
      handleClose={onClose}
    >
      <Styled.Container>
        <Styled.Image src={backgroundImage} />
        <Styled.Content>
          <Styled.Title align="center" color={theme.colors.white}>
            {translate('RECOMMENDATION_BACKDROP_TITLE')}
          </Styled.Title>
          <Typography align="center" color={theme.colors.white}>
            {translate('RECOMMENDATION_BACKDROP_DESCRIPTION')}
          </Typography>
          <Styled.BoxButton>
            <TextButton testID="recommendation-button" onClick={onButtonClick}>
              {translate('RECOMMENDATION_BACKDROP_BUTTON')}
            </TextButton>
          </Styled.BoxButton>
        </Styled.Content>
      </Styled.Container>
    </Backdrop>
  )
}
