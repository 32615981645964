import * as Styled from './styles'
import { TIconProps } from '..'

const SpinnerLoading = ({ color, size }: TIconProps) => {
  return (
    <Styled.Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      data-testid="spinner-loading"
      width={size}
      height={size}
      color={color}
    >
      <g id="spinner-loading-icon">
        <rect x="11" y="1" width="2" height="6" transform="rotate(45 12 12)" opacity=".12" fill="currentColor" />

        <rect x="11" y="1" width="2" height="6" transform="rotate(90 12 12)" opacity=".25" fill="currentColor" />

        <rect x="11" y="1" width="2" height="6" transform="rotate(135 12 12)" opacity=".37" fill="currentColor" />

        <rect x="11" y="1" width="2" height="6" transform="rotate(180 12 12)" opacity=".50" fill="currentColor" />

        <rect x="11" y="1" width="2" height="6" transform="rotate(225 12 12)" opacity=".62" fill="currentColor" />

        <rect x="11" y="1" width="2" height="6" transform="rotate(270 12 12)" opacity=".75" fill="currentColor" />

        <rect x="11" y="1" width="2" height="6" transform="rotate(315 12 12)" opacity=".87" fill="currentColor" />

        <rect x="11" y="1" width="2" height="6" opacity="1" fill="currentColor" />
      </g>
    </Styled.Svg>
  )
}

export default SpinnerLoading
