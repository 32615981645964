import { BLACK_FRIDAY_STORAGE_KEY } from '~/utils/handleABTests'

interface IGetTooltipContentResult {
  title: string
  description: string
}

export const DEFAULT_VALUES = {
  title: 'Look completo com descontos',
  description: 'Toque no botão abaixo e veja as promoções para renovar seu estilo.',
}

export const getTooltipContent = (): IGetTooltipContentResult => {
  const storage = localStorage.getItem(BLACK_FRIDAY_STORAGE_KEY)

  if (storage) {
    return {
      title: JSON.parse(storage).title,
      description: JSON.parse(storage).description,
    }
  }

  return DEFAULT_VALUES
}
