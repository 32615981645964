import { ThemeProvider } from 'styled-components'

import { FC } from 'react'

import { ApolloProvider } from '@apollo/client'

import { AvatarProvider } from '~/context/Avatar'
import { HandleBuyButtonProvider } from '~/context/HandleBuyButton'
import { HistoryProvider } from '~/context/History'
import { MeasurementsProvider } from '~/context/Measurements'
import { NavigationProvider } from '~/context/Navigation'
import { RecommendationHistoryProvider } from '~/context/RecommendationHistory'
import { SyncProvider } from '~/context/Sync'
import { TermsProvider } from '~/context/Terms'
import { TryOnFitProvider } from '~/context/TryOnFit'
import { TryonProvider } from '~/context/Tryon'
import { UserProvider } from '~/context/User'
import { WidgetStateProvider } from '~/context/WidgetState'

import theme from '~/theme'

import { ApolloClient } from '~/clients/apollo'

const AppProvider: FC = ({ children }) => (
  <ApolloProvider client={ApolloClient}>
    <ThemeProvider theme={theme}>
      <WidgetStateProvider>
        <UserProvider>
          <NavigationProvider>
            <TermsProvider>
              <HistoryProvider>
                <RecommendationHistoryProvider>
                  <HandleBuyButtonProvider>
                    <TryonProvider>
                      <MeasurementsProvider>
                        <AvatarProvider>
                          <TryOnFitProvider>
                            <SyncProvider>{children}</SyncProvider>
                          </TryOnFitProvider>
                        </AvatarProvider>
                      </MeasurementsProvider>
                    </TryonProvider>
                  </HandleBuyButtonProvider>
                </RecommendationHistoryProvider>
              </HistoryProvider>
            </TermsProvider>
          </NavigationProvider>
        </UserProvider>
      </WidgetStateProvider>
    </ThemeProvider>
  </ApolloProvider>
)

export default AppProvider
