import { useCallback } from 'react'

import { IFetchParameters, useQueryMeasurements } from '~/hooks-queries/measurements'

import { IMeasurementsStateError, TCurrentMeasurementsState, TMeasurement } from '~/context/Measurements'

import { ICategoryItem } from '~/entities'

import { MeasurementsStorageInstance } from './storage'
import {
  IUseMeasurements,
  TCreateMeasurements,
  TResetMeasurements,
  TStartMeasurements,
  TUpdateMeasurements,
} from './types'

export const useMeasurements = (): IUseMeasurements => {
  const { fetch } = useQueryMeasurements()

  const updateMeasurements = useCallback(({ data, setState }: TUpdateMeasurements) => {
    if (data?.configs?.showTooltip !== undefined) {
      MeasurementsStorageInstance.update(data.avatar_uuid, { showTooltip: data.configs.showTooltip })
    }

    setState(current => ({
      ...current,
      ...data,
      products: {
        ...current?.products,
        ...data.products,
      },
      configs: {
        ...current?.configs,
        ...data.configs,
      },
    }))
  }, [])

  const createMeasurements = useCallback(({ data, setState }: TCreateMeasurements) => {
    const products: TCurrentMeasurementsState['products'] & {
      [key: string]: TMeasurement & { id: number; category: ICategoryItem }
    } = {}

    Object.keys(data.products).forEach(category => {
      const product = data.products[category]
      const productMeasurements = data.measurements.filter(item => item.product_id === product?.id)
      const measurements = product?.product_options
        ?.map(option => {
          const measurement = productMeasurements?.find(item => item?.label === option?.partner_size?.name)

          if (!measurement) return

          return {
            ...measurement,
            has_stock: !!option?.has_stock,
            selected: false,
          }
        })
        .filter(item => item?.id) as TMeasurement['measurements']

      if (!measurements?.length) return

      products[category] = {
        id: product?.id as number,
        category: product?.category as ICategoryItem,
        measurements,
      }
    })

    const measurements: TCurrentMeasurementsState = {
      avatar_uuid: data.avatar_uuid,
      products,
    }

    let showTooltip = true

    const measurementStorage = MeasurementsStorageInstance.get(data.avatar_uuid)

    if (measurementStorage) {
      showTooltip = !!measurementStorage.showTooltip
    } else {
      MeasurementsStorageInstance.set({ avatar_uuid: data.avatar_uuid, showTooltip: true })
    }

    setState(current => {
      const nextState: TCurrentMeasurementsState = {
        ...measurements,
        configs: { type: 'notification', showButton: true, showTooltip },
      }

      if (!current?.products) return nextState

      Object.keys(current.products).forEach(currentCategory => {
        if (current.products[currentCategory]?.id !== nextState.products[currentCategory]?.id) return

        nextState.products[currentCategory] = current.products[currentCategory]
      })

      let selectedCount = 0

      Object.keys(nextState.products).forEach(nextStateCategory => {
        if (nextState.products[nextStateCategory]?.measurements?.find(item => item.selected)) {
          selectedCount = selectedCount + 1
        }
      })

      if (selectedCount === Object.keys(nextState.products).length) {
        nextState.configs = { ...nextState.configs, type: 'checked' }
      }

      return nextState
    })
  }, [])

  const startMeasurements = useCallback(
    ({ data, setState }: TStartMeasurements) => {
      const Measurements: IFetchParameters = {
        payload: {
          avatar_uuid: data.avatar_uuid,
          products: data.products,
        },
        callbackOnSuccess: measurements =>
          setState(current => ({
            ...current,
            data: measurements,
          })),
        callbackOnError: error => {
          const response: IMeasurementsStateError = {
            name: error.message.includes('Timeout') ? 'PROCESSING_TIMEOUT' : 'PROCESSED_ERROR',
            message: error.message,
          }

          // eslint-disable-next-line no-console
          console.error(response)

          setState(current => ({
            ...current,
            error: response,
          }))
        },
        callbackOnProcessing: status =>
          setState(current => ({
            ...current,
            called: true,
            data: status ? undefined : current?.data,
            error: status ? undefined : current?.error,
            isLoading: status,
          })),
      }

      fetch(Measurements)
    },
    [fetch],
  )

  const resetMeasurements = useCallback(({ data, setState }: TResetMeasurements) => setState(data), [])

  return {
    startMeasurements,
    resetMeasurements,
    createMeasurements,
    updateMeasurements,
  }
}
