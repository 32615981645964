import styled from 'styled-components'

import { IContainerProps } from './types'

export const Container = styled.div<IContainerProps>`
  position: absolute;
  padding: 0 16px 16px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  background-color: transparent;

  z-index: ${({ active }) => (active ? 4 : 2)};
  height: ${({ active }) => (active ? '100%' : 'auto')};
  bottom: ${({ active }) => (active ? 'auto' : 0)};
  left: ${({ active }) => (active ? 'auto' : 0)};

  .tooltip-recommendation {
    /* TODO: reenable when the Black Friday feature is done
    left: -70px !important; */

    .tooltip-arrow-recommendation {
      left: calc(50% - 5px) !important;
    }
  }

  @media ${props => props.theme.media.devices.mobile.smallPortrait} {
    padding: 0 12px 12px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 22px;
  align-items: center;
  flex: 1;
`

export const ButtonContainer = styled.div<IContainerProps>`
  position: relative;

  &:nth-child(1),
  &:nth-child(3) {
    width: 100%;
  }

  z-index: ${({ active }) => (active ? '3' : '1')};

  button {
    opacity: 1 !important;
  }
`

export const TooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  h1 {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 8px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    margin: 0;
  }
`
