import { IUserStorageClass, TUserStorage } from './types'

class UserStorage implements IUserStorageClass {
  private storageKey: string

  constructor() {
    this.storageKey = '@doris:user'
  }

  public remove = () => {
    localStorage.removeItem(this.storageKey)
  }

  public get = (): TUserStorage | null => {
    const item = localStorage.getItem(this.storageKey)

    return item ? JSON.parse(item) : null
  }

  public set = (user: TUserStorage) => {
    localStorage.setItem(this.storageKey, JSON.stringify(user))
  }

  public update = (values: Partial<TUserStorage>) => {
    const user = this.get()

    if (!user) return

    this.set({ ...user, ...values })
  }
}

export const UserStorageInstance = new UserStorage()
