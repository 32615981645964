import styled from 'styled-components'

import { Arrow } from '~/components/SlickSlider/styles'
import { Prefix, Title } from '~/components/Typography/styles'

export const Container = styled.div`
  padding-top: 10px;
  height: 100%;
  width: 100%;

  ${Arrow} {
    top: 35%;

    &[direction='left'] {
      left: 40px;
    }

    &[direction='right'] {
      right: 40px;
    }
  }
`

export const ContainerItem = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
`

export const ImageItem = styled.img`
  height: 290px;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
`

export const TitleHow = styled(Title)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  margin-top: 16px;
  text-align: center;
`

export const Description = styled(Prefix)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  margin-top: 16px;
  font-size: 12px;
  display: flex;
`

export const Text = styled(Prefix)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  color: ${({ theme }) => theme.colors.growingNature};
`

export const Measurements = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
  text-align: center;
  margin-top: 20px;
`
