import { IMeasurementsStorageClass, TMeasurementsStorage } from './types'

class MeasurementsStorage implements IMeasurementsStorageClass {
  private storageKey: string

  constructor() {
    this.storageKey = '@doris:measurements'
  }

  public remove = () => {
    localStorage.removeItem(this.storageKey)
  }

  public get = (avatar_uuid: string): TMeasurementsStorage | undefined => {
    const storage = localStorage.getItem(this.storageKey)
    const list: Array<TMeasurementsStorage> = storage ? JSON.parse(storage) : []

    return list.find(item => item.avatar_uuid === avatar_uuid)
  }

  public getList = (): Array<TMeasurementsStorage> => {
    const storage = localStorage.getItem(this.storageKey)

    return storage ? JSON.parse(storage) : []
  }

  public set = (measurement: TMeasurementsStorage) => {
    const list = this.getList()

    list.push(measurement)

    localStorage.setItem(this.storageKey, JSON.stringify(list))
  }

  public update = (avatar_uuid: string, values: Omit<TMeasurementsStorage, 'avatar_uuid'>) => {
    const list = this.getList()
    const index = list.findIndex(item => item.avatar_uuid === avatar_uuid)

    if (index === -1) return

    list[index] = { ...list[index], ...values }

    localStorage.setItem(this.storageKey, JSON.stringify(list))
  }
}

export const MeasurementsStorageInstance = new MeasurementsStorage()
