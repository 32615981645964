import { FC } from 'react'

import { TIconProps } from '.'

export const Image: FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      data-testid="icon-image"
      style={{
        width: size,
        height: size,
      }}
      id="iconImage"
      data-name="icon-image"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <g id="_05-Symbols" data-name="05-Symbols">
        <g id="_02_Icons_image" data-name="02)Icons/image">
          <g id="_-Icon-Сolor" data-name="_-Icon-Сolor">
            <path
              id="path-1"
              fill={color}
              d="M5,7c.8280000687,0,1.5-.6719999313,1.5-1.5s-.6719999313-1.5-1.5-1.5-1.5,.6719999313-1.5,1.5,.6719999313,1.5,1.5,1.5m10,9H3.5609998703l7.0050001144-5.845000267c.2460002899-.2089996338,.6920003891-.2080001831,.9329996109-.0009994507l4.5010004044,3.8399991989v1.0060005188c0,.5520000458-.4479999542,1-1,1M3,2H15c.5520000458,0,1,.4479999542,1,1V11.3640003204l-3.2030000687-2.732000351c-.9899997711-.8420000076-2.5389995575-.8420000076-3.5199995041-.0059995651L2,14.6979999542V3c0-.5520000458,.4479999542-1,1-1M15,0H3C1.3460001945,0,0,1.3460001945,0,3V15c0,1.6539993286,1.3460001945,3,3,3H15c1.6539993286,0,3-1.3460006714,3-3V3c0-1.6539998055-1.3460006714-3-3-3"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
