import styled from 'styled-components'

import { IImageCardProps } from '~/components/ImageCard/types'
import { Title } from '~/components/Typography/styles'

export const Container = styled.button<Pick<IImageCardProps, 'borderRadius'>>`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ borderRadius }) => borderRadius && `border-radius:${borderRadius}`};
  border: 0;
`

export const Image = styled.img<Pick<IImageCardProps, 'borderRadius'>>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  ${({ borderRadius }) => borderRadius && `border-radius:${borderRadius}`};
  object-fit: cover;
  object-position: center;
  pointer-events: none;
`

export const Text = styled(Title)<{ align?: 'left' | 'center' | 'right'; color?: string }>`
  width: 100%;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`

export const Overlay = styled.div<Pick<IImageCardProps, 'borderRadius'>>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  border-bottom-left-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 'none')};
  border-bottom-right-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 'none')};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  z-index: 1;
`
export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  z-index: 2;
`
