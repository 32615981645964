import styled from 'styled-components'

import { Paragraph } from '~/components/Typography/styles'

const Container = styled.a<{ padding?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  svg {
    flex-shrink: 0;
  }

  padding: ${({ padding }) => padding || 0};
`

const Text = styled(Paragraph)`
  text-transform: uppercase;
  margin: 0 10px 0 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.primary};
`

export { Container, Text }
