import React from 'react'

import { TIconProps } from '.'

const Sweatshirt: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 34 34"
      data-testid="icon-sweatshirt"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M21 21.47L21.1 21.94C21.41 23.33 21.76 24.94 22.04 26.63H20.91L20 21.13H21V21.47ZM12.84 22.09L13.03 21.09H14L13.09 26.59H12C12.27 25.01 12.61 23.4 12.91 22.05L12.84 22.09ZM14.09 3.25H19.9L20.9 4.55C20.5732 4.9632 20.2119 5.34792 19.82 5.7C19.69 5.75 19.52 5.77 18.98 5.51L18.44 5.23C18.1533 5.07 17.8533 4.92 17.54 4.78C17.1887 4.62992 16.7913 4.62992 16.44 4.78C16.1333 4.92 15.8333 5.07 15.54 5.23L15 5.51C14.7757 5.64577 14.5217 5.72473 14.26 5.74V5.74C13.8198 5.38891 13.4236 4.986 13.08 4.54L14.09 3.25ZM32.4 23.55C29.81 9.6 28.94 6 28 5C27.52 4.54 26.71 4.3 24.69 3.77L23.6 3.47L21.65 1C21.39 0.667456 20.9921 0.472193 20.57 0.470001H13.43C13.0079 0.472193 12.61 0.667456 12.35 1L10.41 3.48L9.26998 3.78C7.26998 4.29 6.48998 4.52 6.00998 4.98C5.05998 5.91 4.18998 9.54 1.59998 23.49C1.17998 25.74 0.809984 27.74 0.529984 29.17C0.455402 29.5417 0.534786 29.9278 0.749984 30.24C0.971097 30.55 1.31207 30.7532 1.68998 30.8L5.80998 31.37C6.54265 31.4783 7.22724 30.9804 7.34998 30.25C8.22998 25.25 9.69998 17.15 9.94998 16.35C10.121 15.9936 10.3324 15.6581 10.58 15.35C10.733 16.2058 10.8265 17.0712 10.86 17.94L10.31 20.88L10.17 21.5C9.4031 24.5311 8.92761 27.6285 8.74998 30.75C8.73671 31.2768 9.02893 31.7638 9.49998 32C11.8614 33.0388 14.4207 33.5506 17 33.5C19.5793 33.5506 22.1386 33.0388 24.5 32C24.9612 31.7686 25.2517 31.296 25.25 30.78C25.0714 27.6079 24.5892 24.4601 23.81 21.38L23.15 17.97C23.1894 17.1183 23.283 16.2699 23.43 15.43C23.6705 15.7411 23.8782 16.0762 24.05 16.43C24.29 17.22 25.77 25.37 26.64 30.33C26.7676 31.0618 27.4551 31.5586 28.19 31.45L32.32 30.85C32.6947 30.7947 33.0306 30.5888 33.25 30.28C33.4703 29.9738 33.5503 29.5885 33.47 29.22C33.19 27.79 32.82 25.79 32.4 23.54V23.55Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Sweatshirt
