import React from 'react'

import { TIconProps } from '.'

const Coat: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 34 36"
      data-testid="icon-coat"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-7-6h48v48H-7z" />
        <path
          d="M7.56 19.25 4.15 29.87a.32.32 0 0 1-.4.21L1 29.26a.32.32 0 0 1-.23-.38l5-21.26a.33.33 0 0 1 .64 0l.64 6.2.54 5.26a.3.3 0 0 1-.03.17Zm10.91-6L17 9.89l-3.52-8.51a.4.4 0 0 1 0-.38.4.4 0 0 1 .34-.18h6.29a.43.43 0 0 1 .34.18.4.4 0 0 1 0 .38l-3 7.38a.36.36 0 0 0 0 .25l1.28 2.9a.33.33 0 0 0 .53.1l4.43-4.58a.32.32 0 0 0-.11-.52L21.7 6.1a.39.39 0 0 1-.24-.45.43.43 0 0 1 .28-.32l1.72-.57a.32.32 0 0 0 .2-.42l-.9-2.42a.36.36 0 0 0-.07-.1L21.19.11A.32.32 0 0 0 21 0h-7.9a.32.32 0 0 0-.24.11l-1.6 1.71a.36.36 0 0 0-.07.1l-.9 2.42a.32.32 0 0 0 .2.42l1.72.58a.4.4 0 0 1 0 .76l-1.88.81a.33.33 0 0 0-.11.53l10.4 10.68-2.15-4.88v.01Zm7.12 6v-.06l1.52-14.83a1 1 0 0 0-.68-1.09l-1.94-.7a.32.32 0 0 0-.41.41l.67 1.79a.41.41 0 0 1-.11.44.51.51 0 0 1-.16.09l-1.42.48 1.86.79c.087.037.16.1.21.18a.42.42 0 0 1-.06.49L19.48 13a.3.3 0 0 0-.06.35l2.86 6.52v.06a.2.2 0 0 0 0 .07v15.68c0 .177.143.32.32.32h3.71a.43.43 0 0 0 .3-.13.44.44 0 0 0 .11-.31l-1.17-16.3.04-.01Zm2.65-11.63a.33.33 0 0 0-.64 0l-.6 6.24-.54 5.26a.3.3 0 0 0 0 .13l3.39 10.54a.4.4 0 0 0 .5.27l2.66-.8a.32.32 0 0 0 .23-.38l-5-21.26ZM8.93 7.24l12.48 12.88a.31.31 0 0 1 .09.22v15.34a.33.33 0 0 1-.32.32H7.65a.43.43 0 0 1-.3-.13.44.44 0 0 1-.11-.31l1.17-16.3v-.06L6.89 4.36a1 1 0 0 1 .68-1.09l1.94-.7a.32.32 0 0 1 .41.41l-.67 1.79a.41.41 0 0 0 .11.44.51.51 0 0 0 .16.09l1.42.48-1.86.79a.46.46 0 0 0-.21.18.42.42 0 0 0 .06.49Zm9.71 13.62c0 .33.197.629.5.76a.82.82 0 0 0 1.07-1.07.82.82 0 0 0-1.57.31Zm0 5.73a.81.81 0 0 0 1.39.58.8.8 0 0 0 .18-.89.81.81 0 0 0-1.57.31Zm-4.91-5.73a.83.83 0 0 0 1.405.596.82.82 0 0 0-.585-1.406.81.81 0 0 0-.82.81Zm0 5.73a.81.81 0 0 0 1.39.58.8.8 0 0 0 .18-.89.81.81 0 0 0-1.57.31Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Coat
