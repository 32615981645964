import { FC, useCallback } from 'react'

import { useGesture } from '@use-gesture/react'

import { GrabHandleBar } from '~/components/GrabHandleBar'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'

import theme from '~/theme'

import * as Styled from './styles'
import { IBackdropProps } from './types'

export const Backdrop: FC<IBackdropProps> = ({
  visible,
  handleClose,
  children,
  title,
  withBackground = false,
  testID = 'backdrop',
  primaryButton = null,
  preventOverflow = false,
  layerIndex = 3,
  height,
  headerChild,
  footerChild,
  minimized,
  handleMinimize,
  primaryButtonIconColor = theme.colors.primary,
  loading = false,
}) => {
  const bind = useGesture({
    onDrag: ({ delta }) => {
      const movingUp = delta[1] < 0

      if (movingUp && minimized && handleMinimize) handleMinimize()
    },
  })

  const minimize = useCallback(() => minimized && handleMinimize && handleMinimize(), [handleMinimize, minimized])

  return (
    <Styled.OuterWrapper
      visible={visible}
      data-testid={`${testID}-wrapper`}
      layerIndex={layerIndex}
      aria-hidden={!visible}
    >
      <Styled.Shadow
        onClick={handleClose}
        visible={visible}
        withBackground={withBackground}
        data-testid={`${testID}-shadow`}
      />
      <Styled.Container
        height={height}
        visible={visible}
        preventOverflow={preventOverflow}
        data-testid={`${testID}-container`}
        minimized={minimized}
      >
        {(primaryButton || title || handleClose || headerChild) && (
          <Styled.HeaderWrapper {...bind()} data-testid={`${testID}-header-wrapper`} onClick={minimize}>
            {minimized && <GrabHandleBar onClick={minimize} />}

            <Styled.HeaderActions flexDirection={primaryButton ? 'space-between' : 'flex-end'}>
              {primaryButton && primaryButton}

              {title && (
                <Styled.Name
                  float={!!(primaryButton || handleClose)}
                  data-testid={`${testID}-title`}
                  $loading={loading}
                >
                  {title}
                </Styled.Name>
              )}

              {handleClose && (
                <IconButton
                  testID={`${testID}-minimize-button`}
                  onClick={handleClose}
                  icon={<Icon name="arrowDown" size="32px" color={primaryButtonIconColor} />}
                />
              )}
            </Styled.HeaderActions>

            {headerChild && <Styled.HeaderChild data-testid={`${testID}-header`}>{headerChild}</Styled.HeaderChild>}
          </Styled.HeaderWrapper>
        )}

        <Styled.ChildWrapper>{children}</Styled.ChildWrapper>
        {footerChild && <Styled.FooterChild data-testid={`${testID}-footer`}>{footerChild}</Styled.FooterChild>}
      </Styled.Container>
    </Styled.OuterWrapper>
  )
}
