import { FC, useCallback, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'

import { SizingButton } from '~/components/SizingButton'
import { ISizingButtonProps } from '~/components/SizingButton/types'
import { SlickSlider } from '~/components/SlickSlider'
import { TextButton } from '~/components/TextButton'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { sliderSettings } from './constants'
import { ISliderSizingProps } from './types'

export const SliderSizing: FC<ISliderSizingProps> = ({
  sizingInfos,
  onSizeChange,
  onSizeSelected,
  onSliderInit = () => ({}),
  loading = false,
}) => {
  const [data, setData] = useState<Array<ISizingButtonProps>>([])
  const sliderRef = useRef<Slider>()
  const preventClick = useRef(false)
  const preventInit = useRef(true)

  const onSliderChange = useCallback(
    (nextSlider: number) => {
      onSizeChange(data[nextSlider].size)

      preventClick.current = false
      preventInit.current = false
    },
    [data, onSizeChange],
  )

  const beforeSliderChange = useCallback(() => {
    /* istanbul ignore else: it was ignored because we can't simulate a touch screen to test the card swipe */
    if (preventInit.current) return

    /* istanbul ignore next: it was ignored because we can't simulate a touch screen to test the card swipe */
    preventClick.current = true
  }, [])

  const onButtonClick = useCallback(
    () => onSizeSelected(data.find(item => item.active)?.size as string),
    [onSizeSelected, data],
  )

  const onButtonSizeClick = useCallback(
    (size: string) => {
      const currentIndex = data.findIndex(item => item.size === size && !item.active)

      /* istanbul ignore if: was ignored because we cannot simulate two simultaneous clicks in the jest like we do in the real application navigation */
      if (preventClick.current || currentIndex === -1) return

      onSliderChange(currentIndex)
    },
    [onSliderChange, data],
  )

  const getButtonValidation = useCallback(() => !data.find(item => item.active)?.inStock, [data])

  const rerender = useCallback((sizingData: Array<ISizingButtonProps>) => {
    setData([])

    preventClick.current = false
    preventInit.current = true

    setTimeout(() => {
      setData(sizingData)
    }, 1)
  }, [])

  const renderItem = useCallback(
    (item: unknown) => (
      <Styled.Item>
        <SizingButton {...(item as ISizingButtonProps)} onClick={onButtonSizeClick} disabled={loading} />
      </Styled.Item>
    ),
    [onButtonSizeClick, loading],
  )

  const init = useCallback(() => onSliderInit({ rerender }), [onSliderInit, rerender])

  useEffect(() => {
    if (!sizingInfos.find(item => item.active)) return

    setData(sizingInfos)
  }, [sizingInfos])

  useEffect(() => {
    if (!data?.length) return

    sliderRef.current?.slickGoTo(data.findIndex(item => item.active))
  }, [data])

  useEffect(init, [init])

  return (
    <Styled.Container data-testid="container-slider-sizing">
      <Styled.BoxSlider>
        {!!data?.length && (
          <SlickSlider
            settings={{
              ...sliderSettings,
              afterChange: onSliderChange,
              beforeChange: beforeSliderChange,
              draggable: !loading,
            }}
            renderItem={renderItem}
            data={data}
            onSlickRender={slider => (sliderRef.current = slider)}
          />
        )}
      </Styled.BoxSlider>
      <Styled.BoxButton>
        <TextButton
          testID="container-slider-sizing-select-button"
          onClick={onButtonClick}
          disabled={getButtonValidation() || loading}
        >
          {translate('CONFIRM')}
        </TextButton>
      </Styled.BoxButton>
    </Styled.Container>
  )
}
