import { FC, useCallback, useEffect, useState } from 'react'

import { useAvatar } from '~/hooks/useAvatar'
import { useSubscriptionProcessingAvatar } from '~/hooks/useAvatar/useSubscriptionProcessingAvatar'
import { useSubscriptionProcessingInfos } from '~/hooks/useAvatar/useSubscriptionProcessingInfos'
import { useSubscriptionProcessingSizing } from '~/hooks/useAvatar/useSubscriptionProcessingSizing'
import { useMeasurements } from '~/hooks/useMeasurements'
import { MeasurementsStorageInstance } from '~/hooks/useMeasurements/storage'
import { usePartner } from '~/hooks/usePartner'
import { useTryon } from '~/hooks/useTryon'
import { TryonStorageInstance } from '~/hooks/useTryon/storage'

import { useAvatarContext } from '~/context/Avatar'
import { useMeasurementsContext } from '~/context/Measurements'
import { useNavigation } from '~/context/Navigation'
import { useTryonContext } from '~/context/Tryon'

import { removeFromLocalStorage } from '~/screens/InformData/utils/formDataStorage'
import { IProcessingScreenProps } from '~/screens/ProcessingScreen/types'
import { BrowserUrlApi } from '~/utils/browserUrlApi'
import Tracking from '~/utils/tracking'

import { sentences } from './constants'

export const withAvatarProcessingScreen = (ProcessingScreen: FC<IProcessingScreenProps>) => {
  const Component: React.FC = () => {
    const { stateAvatar, clearStates } = useAvatarContext()
    const { stateTryon, setTryonState } = useTryonContext()
    const { clearStates: clearMeasurementsState } = useMeasurementsContext()

    const { navigate } = useNavigation()
    const { startTryon } = useTryon()
    const { resetMeasurements } = useMeasurements()
    const { resetAvatar } = useAvatar()
    const { getPartner } = usePartner()

    const { subscriptionCreateAvatar, avatarProcessedStatus } = useSubscriptionProcessingAvatar()
    const { subscriptionSizing, sizingProcessedStatus } = useSubscriptionProcessingSizing()
    const { subscriptionInfos, infosProcessedStatus } = useSubscriptionProcessingInfos()

    const [allProcessed, setAllProcessed] = useState(false)

    const onProcessingFinished = useCallback(() => {
      const currentTryon = TryonStorageInstance.get()
      const wasTryonNotCalledOrExternalUser = currentTryon && (!stateTryon?.called || BrowserUrlApi.isExternalUser())

      if (!allProcessed) return

      if (wasTryonNotCalledOrExternalUser) {
        const start = async () => {
          const { data: partner } = await getPartner()

          startTryon({
            data: {
              idModel: stateAvatar?.data?.avatar_uuid as string,
              products: currentTryon,
              from: 'avatar',
              upscale: partner?.upscale,
            },
            setState: setTryonState,
          })
        }

        start()
      }

      if (sizingProcessedStatus.error || infosProcessedStatus.error) {
        MeasurementsStorageInstance.set({ avatar_uuid: stateAvatar?.data?.avatar_uuid as string, showAlert: true })

        Tracking.logEvent('AVATAR_ERROR', {
          error: 'A97',
          gender: stateAvatar?.data?.gender,
          height: stateAvatar?.data?.height,
          weight: stateAvatar?.data?.weight,
          age: stateAvatar?.data?.age,
          widget: true,
        })
      }
    }, [
      sizingProcessedStatus.error,
      infosProcessedStatus.error,
      stateTryon?.called,
      stateAvatar?.data,
      allProcessed,
      setTryonState,
      startTryon,
      getPartner,
    ])

    const onCreateAvatarProcessed = useCallback(() => {
      if (!avatarProcessedStatus) return

      resetMeasurements({ data: ['stateCurrentMeasurements'], setState: clearMeasurementsState })

      subscriptionSizing()
    }, [subscriptionSizing, avatarProcessedStatus, resetMeasurements, clearMeasurementsState])

    const onSizingProcessed = useCallback(() => {
      if (!sizingProcessedStatus.ended) return

      if (sizingProcessedStatus.error) {
        setAllProcessed(true)

        return
      }

      subscriptionInfos()
    }, [sizingProcessedStatus, subscriptionInfos])

    const onInfosProcessed = useCallback(() => {
      if (!infosProcessedStatus.ended) return

      setAllProcessed(true)
    }, [infosProcessedStatus.ended])

    useEffect(onCreateAvatarProcessed, [onCreateAvatarProcessed])

    useEffect(onSizingProcessed, [onSizingProcessed])

    useEffect(onInfosProcessed, [onInfosProcessed])

    useEffect(onProcessingFinished, [onProcessingFinished])

    useEffect(subscriptionCreateAvatar, [subscriptionCreateAvatar])

    useEffect(() => {
      if (!allProcessed) return

      const currentTryon = TryonStorageInstance.get()

      if (currentTryon && !stateTryon?.isLoading && stateTryon?.data?.from !== 'avatar') return

      resetAvatar({ data: ['stateAvatar'], setState: clearStates })
      navigate('Home', { isCombineActive: true })
      removeFromLocalStorage()
    }, [allProcessed, stateTryon, resetAvatar, clearStates, navigate])

    return <ProcessingScreen sentences={sentences} closeUrl="Models" />
  }

  Component.displayName = 'withAvatarProcessing'

  return Component
}
