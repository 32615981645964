import styled, { css } from 'styled-components'

import { IButtonStyles, IContentStyles } from './types'

export const Container = styled.button<IButtonStyles>`
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border: 0;
  border-radius: 35px;
  transition: opacity 0.3s ease;
  background-color: ${props => props.background || props.theme.colors.primary};
  text-transform: ${props => props.textTransform};

  ${({ variant }) =>
    variant === 'outlined' &&
    css`
      border: 1px solid ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.white};
    `};

  ${({ variant }) =>
    variant === 'phantom' &&
    css`
      color: ${props => props.theme.colors.primary};
      border: 1px solid transparent;
      background-color: ${props => props.theme.colors.white};
    `};

  &[disabled] {
    opacity: ${props => props.theme.opacity.default};
  }
`

export const Content = styled.span<IContentStyles>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.color};
  text-align: ${props => props.align};
`
