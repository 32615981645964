import { FC } from 'react'

import { Ornament, OrnamentContainer } from './styles'

export interface IStateProp {
  state: number
}

export const Ornaments: FC<IStateProp> = ({ state }) => {
  return (
    <OrnamentContainer state={state}>
      <Ornament type="a" />
      <Ornament type="b" />
      <Ornament type="c" />
    </OrnamentContainer>
  )
}
