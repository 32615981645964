import { IUseAlertSizing } from './types'

const storageKey = '@doris:alert_sizing'

export const useAlertSizing = (): IUseAlertSizing => {
  const checkAlertSizingModel = (avatar_id: string): boolean => {
    const storage = localStorage.getItem(storageKey)
    const models: Array<string> = storage ? JSON.parse(storage) : []

    return Boolean(models.filter(model => model === avatar_id).length)
  }

  const addModelToAlertSizingList = (avatar_id: string): Array<string> => {
    const storage = localStorage.getItem(storageKey)
    const models: Array<string> = storage ? JSON.parse(storage) : []

    if (checkAlertSizingModel(avatar_id)) return models

    models.push(avatar_id)
    localStorage.setItem(storageKey, JSON.stringify(models))

    return models
  }

  return { checkAlertSizingModel, addModelToAlertSizingList }
}
