import { IMeasurements } from '~/hooks-queries/sizing'
import { IListMeasurements } from '~/hooks/useAvatar/types'
import { IFilterAvatarsUsingPartnerKeyParams } from '~/hooks/useAvatar/types'

import { TListSizingOption } from '~/components/Backdrop/SelectSizingBackdrop/components/ListSizing/types'

import { IAvatar } from '~/entities'
import { getPartnerAPIKey } from '~/utils/getPartnerAPIKey'
import { translate } from '~/utils/translate'

export const transformedDataMeasurements = (measurements: Array<IMeasurements>) => {
  const originalList = measurements
    .map(item => {
      return [
        { label: translate('HEIGHT'), value: Math.round(item.height), unit: 'cm', order: 1, name: 'height' },
        {
          label: translate('CHEST_MALE'),
          value: Math.round(item.measurements_chest),
          unit: 'cm',
          order: 2,
          name: 'chest',
        },
        { label: translate('WAIST'), value: Math.round(item.measurements_waist), unit: 'cm', order: 3, name: 'waist' },
        { label: translate('HIP'), value: Math.round(item.measurements_hip), unit: 'cm', order: 4, name: 'hip' },
      ]
    })
    .flat() as TListSizingOption[]

  const updatedList = measurements
    .map(item => {
      return [
        { label: translate('HEIGHT'), value: Math.round(item.updated_height), unit: 'cm', order: 1, name: 'height' },
        {
          label: translate('CHEST_MALE'),
          value: Math.round(item.updated_measurements_chest),
          unit: 'cm',
          order: 2,
          name: 'chest',
        },
        {
          label: translate('WAIST'),
          value: Math.round(item.updated_measurements_waist),
          unit: 'cm',
          order: 3,
          name: 'waist',
        },
        {
          label: translate('HIP'),
          value: Math.round(item.updated_measurements_hip),
          unit: 'cm',
          order: 4,
          name: 'hip',
        },
      ]
    })
    .flat() as TListSizingOption[]

  return { original: originalList, current: updatedList } as unknown as IListMeasurements
}

export const filterAvatarsUsingPartnerKey = ({ items }: IFilterAvatarsUsingPartnerKeyParams): IAvatar[] => {
  const currentAPIKey = getPartnerAPIKey()

  return items.filter((i: IAvatar) => i.apiKey === currentAPIKey)
}
