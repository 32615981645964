import { useWidgetState } from '~/context/WidgetState'

import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'

import theme from '~/theme'

import { BrowserUrlApi } from '~/utils/browserUrlApi'
import Tracking from '~/utils/tracking'

import * as Styled from './styles'

export const AppButtonClose = () => {
  const { closeWidget, setFirstInteraction, setEnableFirstInteraction } = useWidgetState()

  const handleClose = () => {
    Tracking.logEvent('CLOSE', { widget: true })

    BrowserUrlApi.refresh()

    setFirstInteraction(false)
    setEnableFirstInteraction(true)

    closeWidget()
  }

  return (
    <Styled.CloseButtonWrapper data-testid="close-button-wrapper">
      <IconButton
        testID="close-widget-button"
        onClick={handleClose}
        backgroundColor={theme.colors.darkOpaqueBackground}
        icon={<Icon name="close" size="12px" color={theme.colors.white} />}
        boxShadow
      />
    </Styled.CloseButtonWrapper>
  )
}
