import styled from 'styled-components'

import { Paragraph } from '~/components/Typography/styles'

const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  display: flex;
  min-height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.palette.gray95};

  &:last-child {
    border: 0;
  }
`

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  background: none;
  border: 0;
  text-align: left;

  &[disabled] {
    opacity: 0.2;
  }
`

const Title = styled(Paragraph)`
  margin-right: 30px;
  font-weight: 500;
`

export { List, Item, Link, Button, Title }
