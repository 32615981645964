import styled from 'styled-components'

import { Prefix } from '~/components/Typography/styles'

export const WrapperWeightAndHeight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-bottom: 16px;
`

export const TextWeightAndHeight = styled(Prefix)`
  font-weight: 700;
`
