import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { translateFromStorage } from '~/utils/translate'

import * as Styled from './styles'
import { TextButton } from '../TextButton'
import { ISnackBarProps } from './types'

const DURATION_IN_S = 15
const DELAY_TO_COUNTDOWN_IN_MS = 500
const ANIMATION_DURATION_IN_MS = 400
const DELAY_TO_FADE_OUT = DELAY_TO_COUNTDOWN_IN_MS + ANIMATION_DURATION_IN_MS

export const SnackBar: FC<ISnackBarProps> = ({
  onClick,
  onClose,
  visible = false,
  durationInSeconds = DURATION_IN_S,
  animationOrientation = 'right-to-left',
}) => {
  const timerToFadeOut = useRef<NodeJS.Timeout | number>(0)
  const timerToHide = useRef<NodeJS.Timeout | number>(0)
  const [fadeOut, setFadeOut] = useState(false)

  const convertDurationToMs = durationInSeconds * 1000

  const startTimerToFadeOut = useCallback(() => {
    timerToFadeOut.current = setTimeout(() => {
      setFadeOut(true)
    }, convertDurationToMs)
  }, [convertDurationToMs])

  const startTimerToHide = useCallback(() => {
    timerToHide.current = setTimeout(() => {
      onClose()
      setFadeOut(false)
    }, DELAY_TO_FADE_OUT)
  }, [onClose])

  const handleClick = () => {
    onClick()
    setFadeOut(true)
  }

  useEffect(() => {
    visible && startTimerToFadeOut()
    return () => clearTimeout(timerToFadeOut.current as number)
  }, [visible, startTimerToFadeOut])

  useEffect(() => {
    fadeOut && startTimerToHide()
    return () => clearTimeout(timerToHide.current as number)
  }, [fadeOut, startTimerToHide])

  return (
    <Styled.Container
      visible={visible}
      data-testid="snackbar-container"
      aria-hidden={!visible}
      durationInSeconds={durationInSeconds}
      $animationDuration={ANIMATION_DURATION_IN_MS}
      $delayToCountDown={DELAY_TO_COUNTDOWN_IN_MS}
      className={fadeOut ? 'start-fade-out' : ''}
    >
      <Styled.SnackBarText>{translateFromStorage('SNACKBAR_TEXT')}</Styled.SnackBarText>
      <TextButton testID="newsletter-sign-up-button" onClick={handleClick}>
        {translateFromStorage('SNACKBAR_BUTTON')}
      </TextButton>
      <Styled.CountDown
        $timer={durationInSeconds}
        $delayToCountDown={DELAY_TO_COUNTDOWN_IN_MS}
        animationOrientation={animationOrientation}
      />
    </Styled.Container>
  )
}
