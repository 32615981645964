import { TTryonPayload } from '~/hooks-queries/tryon'
import { TStartTryon } from '~/hooks/useTryon/types'

import { IProduct, TCategoryTypeLowerCase } from '~/entities'

export const getTryonOutfits = (products: TStartTryon['data']['products']): TTryonPayload['outfits'] => {
  const current: TStartTryon['data']['products'] & { [key: string]: IProduct | undefined } = {
    ...products,
  }
  const outfits: { [key: string]: TTryonPayload['outfits'][TCategoryTypeLowerCase] } = {}

  Object.keys(current).forEach(categoryType => {
    if (!current[categoryType]) return

    outfits[categoryType] = {
      uuid: current[categoryType]?.outfit_uuid as string,
    }
  })

  return outfits
}
