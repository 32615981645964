import styled from 'styled-components'

import { Prefix } from '~/components/Typography/styles'

const Container = styled.div`
  position: relative;
`

const Label = styled(Prefix)`
  z-index: 1;
  position: absolute;
  top: -12px;
  left: -12px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 100%;
  border: 2px solid ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.24);
`

export { Label, Container }
