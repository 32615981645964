import { useCallback } from 'react'

import { AxiosClient } from '~/clients/axios'

import { IAppendEmailToUserParams, INewUser, IUseQueryUser, TAppendEmailToUserResponse, TNewUserPostResponse } from '.'

export const useQueryUser = (): IUseQueryUser => {
  const timeoutInSeconds = 20

  const newUser = useCallback(
    async ({ callbackOnError, callbackOnSuccess, callbackOnProcessing = () => ({}) }: INewUser) => {
      try {
        callbackOnProcessing(true)

        const { data } = await AxiosClient().post<TNewUserPostResponse>('/user', undefined, {
          timeout: timeoutInSeconds * 1000,
          timeoutErrorMessage: `[ERROR][USER] TIMEOUT_WHEN_CREATING_USER`,
        })

        callbackOnSuccess(data)
      } catch (error) {
        callbackOnError({
          name: error?.message === `Timeout.` ? 'PROCESSING_TIMEOUT' : 'PROCESSED_ERROR',
          message: `[ERROR][USER][NEW]: ${error?.message}`,
        })
      } finally {
        callbackOnProcessing(false)
      }
    },
    [],
  )

  const appendEmailToUser = useCallback(
    async ({ payload }: IAppendEmailToUserParams): Promise<TAppendEmailToUserResponse> => {
      try {
        await AxiosClient().put<TNewUserPostResponse>('/user', payload, {
          timeout: 3000,
          timeoutErrorMessage: `[ERROR][USER][LEAD] TIMEOUT`,
        })

        return { success: true }
      } catch (error) {
        return {
          success: false,
          name: error?.message === 'Timeout.' ? 'PROCESSING_TIMEOUT' : 'PROCESSED_ERROR',
          message: `[ERROR][USER][LEAD] ${error?.message}`,
        }
      }
    },
    [],
  )

  return {
    appendEmailToUser,
    newUser,
  }
}
