import { TTab } from './types'

type TTabName = {
  [key: string]: TTab
}

export const tabMap: TTabName = {
  MEASUREMENTS: 'MEASUREMENTS',
  TUTORIAL: 'TUTORIAL',
}
