import styled from 'styled-components'

import { Button } from '~/components/ImageItem/styles'
import { Prefix } from '~/components/Typography/styles'

import { hexToRgba } from '~/utils/colors'

const Container = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  /* TODO: É necessário pensar em uma possível solução para com que, 
  ao receber uma cor não HEX customizada pelo cliente na inicialização 
  do widget, não quebre a função hexToRgba fazendo ela lançar um throw
  e consequentemente, quebrando a propriedade background-color */
  background-color: rgba(${({ theme }) => hexToRgba(theme.colors.primary, 0.08)});
`

const Text = styled(Prefix)`
  margin-top: 10px;
  font-weight: 600;
`

export { Container, Text }
