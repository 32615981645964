import styled, { css } from 'styled-components'

import { Container as JumpingDotsContainer } from '~/libs/animations/JumpingDots/styles'

import { iconPositionMap } from './constants'
import { IContainerStyles } from './types'

export const Container = styled.div<IContainerStyles>`
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: ${({ iconPosition }) => iconPositionMap[iconPosition?.horizontal || 'CENTER']};
  align-items: ${({ iconPosition }) => iconPositionMap[iconPosition?.vertical || 'CENTER']};
  backdrop-filter: blur(2px);
  padding: 15px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 0.2s ease-in;

  ${({ iconPosition }) =>
    iconPosition?.vertical &&
    css`
      ${JumpingDotsContainer} {
        top: auto;
      }
    `};
`
