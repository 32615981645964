import styled from 'styled-components'

import { TextAndIconButton } from '~/components/TextAndIconButton'
import { TextButton } from '~/components/TextButton'

export const Item = styled.div`
  display: flex;
  height: 100%;
  padding: 0 4px;
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 16px;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
  opacity: 0.35;
  border-radius: 16px;
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  pointer-events: none;
`

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Container = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 20px;
`

export const ContainerDelete = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const ButtonDress = styled(TextAndIconButton)`
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonDressModel = styled(TextButton)`
  height: 32px;
`
