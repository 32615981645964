import { bool, func } from 'prop-types'

import { FC, useState } from 'react'

import { Icon } from '~/components/Icon'

import { Ornaments } from './Ornaments'
import { Button, Text, Wrapper } from './styles'

interface IAutoMixButtonProps {
  onClick: () => void
  disabled?: boolean
  usePromoIcon?: boolean
}

const AutoMixButton: FC<IAutoMixButtonProps> = ({ onClick, disabled, usePromoIcon = false }) => {
  const [state, setState] = useState(0)

  const startStates = () => {
    setState(1)

    setTimeout(() => {
      setState(2)
    }, 1000)

    setTimeout(() => {
      setState(3)
    }, 1200)

    setTimeout(() => {
      setState(0)
    }, 1500)
  }

  const handleOnClick = () => {
    onClick()
    startStates()
  }

  return (
    <Wrapper data-testid="automix-wrapper">
      <Ornaments state={state} />
      <Button onClick={handleOnClick} data-testid="automix-button" disabled={disabled} state={state}>
        <Icon name="bolt" size="20px" />
        {usePromoIcon ? <Icon name="discount-stamp" size="28px" /> : <Text>Mix</Text>}
      </Button>
    </Wrapper>
  )
}

AutoMixButton.propTypes = {
  onClick: func.isRequired,
  disabled: bool,
}

AutoMixButton.defaultProps = {
  disabled: false,
}

export default AutoMixButton
