import styled, { css } from 'styled-components'

import { IArea, TCorner } from './types'

const getPosition = (position: TCorner) =>
  ({
    'top-left': css`
      top: 0;
      left: 0;
    `,
    'top-right': css`
      top: 0;
      right: 0;
    `,
    'bottom-left': css`
      bottom: 0;
      left: 0;
    `,
    'bottom-right': css`
      bottom: 0;
      right: 0;
    `,
  }[position])

export const Container = styled.div<Omit<IArea, 'color'>>`
  position: absolute;
  max-width: calc(100% - 150px);
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`

export const Corner = styled.div<{ position: TCorner }>`
  position: absolute;
  display: flex;
  ${({ position }) => getPosition(position)};
`
