import React from 'react'

import { TIconProps } from '.'

const Body: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 20 38"
      data-testid="icon-body"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M16.75 20.68C16.75 20.2 19.75 12.53 19.55 9.73C19.39 7.73 15.77 3.55 13.55 1.17C13.3852 0.98177 13.1096 0.93761 12.8942 1.06489C12.6788 1.19218 12.5846 1.45481 12.67 1.69C15.1 9.49 10.54 8 11.86 13.81C13.03 18.87 11.08 20.6 10.3 21.09C10.1331 21.1999 9.91685 21.1999 9.74999 21.09C8.99999 20.58 6.99999 18.81 8.19999 13.81C9.51999 8 4.99999 9.48 7.37999 1.72C7.46535 1.48481 7.37113 1.22218 7.15573 1.09489C6.94033 0.96761 6.66482 1.01177 6.49999 1.2C4.28999 3.6 0.679987 7.79 0.499987 9.73C0.259987 12.61 3.29999 20.2 3.29999 20.73C2.41671 22.3395 1.41389 23.8805 0.299987 25.34C0.210752 25.4622 0.180377 25.6177 0.217074 25.7645C0.253771 25.9113 0.35375 26.0342 0.489987 26.1C4.58386 28.1392 7.40598 32.0692 8.02999 36.6C8.06472 36.8558 8.28186 37.0474 8.53999 37.05H11.39C11.6481 37.0474 11.8653 36.8558 11.9 36.6C12.5545 32.0756 15.3768 28.1558 19.46 26.1C19.5937 26.0316 19.6912 25.9088 19.7277 25.7632C19.7641 25.6175 19.7358 25.4632 19.65 25.34C18.5637 23.8644 17.5941 22.3063 16.75 20.68V20.68Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M9.00001 14.92H11.15C11.4593 14.92 11.71 14.6693 11.71 14.36C11.71 14.0507 11.4593 13.8 11.15 13.8H9.00001C8.85067 13.7973 8.70663 13.8554 8.60101 13.961C8.49539 14.0666 8.43727 14.2107 8.44001 14.36C8.36001 14.68 8.60001 14.92 9.00001 14.92Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Body
