import { useCallback } from 'react'

import { useMutation } from '@apollo/client'

import { AxiosClient } from '~/clients/axios'
import { IRecommendation } from '~/entities'

import {
  IGetRecommendation,
  IMutationData,
  IMutationVars,
  IUpdateRecommendation,
  IUseQueryRecommendation,
  TGetRecommendationBody,
  TGetRecommendationHeaders,
  TGetRecommendationResponse,
} from '.'
import { UPDATE_RECOMMENDATION } from './graphql'

export const useQueryRecommendation = (): IUseQueryRecommendation => {
  const timeoutInSeconds = 20

  const [updateRecommendationMutation] = useMutation<IMutationData, IMutationVars>(UPDATE_RECOMMENDATION)

  const getRecommendation = useCallback(
    async ({ payload, callbackOnError, callbackOnSuccess, callbackOnProcessing = () => ({}) }: IGetRecommendation) => {
      try {
        callbackOnProcessing(true)

        const body: TGetRecommendationBody = {
          model_uuid: payload.avatar_uuid,
          product_top_id: payload.products?.top?.outfit_uuid,
          product_bottom_id: payload.products?.bottom?.outfit_uuid,
          product_full_id: payload.products?.full?.outfit_uuid,
          new_recommendation_list: payload?.newList,
          upscale: payload?.upscale,
        }

        const headers: TGetRecommendationHeaders = {
          user_uuid: payload.user_uuid,
        }

        const { data } = await AxiosClient().post<TGetRecommendationResponse>('/recommendation', body, {
          headers,
          timeout: timeoutInSeconds * 1000,
          timeoutErrorMessage: `Timeout.`,
        })

        if (data?.tryon?.status === 'PROCESSED_ERROR') {
          throw new Error('Não foi possível gerar o tryon.')
        }

        const response: IRecommendation = {
          id: data.recommendation.id,
          cluster: data.recommendation.cluster,
          tryon: data.tryon,
          products: data.products,
          liked: false,
        }

        callbackOnSuccess(response)
      } catch (error) {
        callbackOnError({
          name: error?.message === `Timeout.` ? 'PROCESSING_TIMEOUT' : 'PROCESSED_ERROR',
          message: `[ERROR][RECOMMENDATION][GET]: ${error?.response?.data?.message || error?.message}`,
        })
      } finally {
        callbackOnProcessing(false)
      }
    },
    [],
  )

  const updateRecommendation = useCallback(
    async ({
      payload,
      callbackOnError,
      callbackOnSuccess,
      callbackOnProcessing = () => ({}),
    }: IUpdateRecommendation) => {
      try {
        callbackOnProcessing(true)

        const { data } = await updateRecommendationMutation({
          variables: {
            id: payload.idRecommendation,
            set: {
              liked: payload.liked,
            },
          },
        })

        if (!data?.update_recommendation.affected_rows) {
          throw new Error('Recomendação não encontrada')
        }

        callbackOnSuccess()
      } catch (error) {
        callbackOnError({
          name: 'PROCESSED_ERROR',
          message: `[ERROR][RECOMMENDATION][UPDATE]: ${error?.message}`,
        })
      } finally {
        callbackOnProcessing(false)
      }
    },
    [updateRecommendationMutation],
  )

  return {
    getRecommendation,
    updateRecommendation,
  }
}
