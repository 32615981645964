import { FC, useEffect, useState } from 'react'

import { NewSizingTag } from '~/components/NewSizingTag'
import { TSizingTagOption } from '~/components/NewSizingTag/types'

import * as Styled from './styles'
import { INewSizingTagContainerProps } from './types'

export const NewSizingTagContainer: FC<INewSizingTagContainerProps> = ({
  data,
  gender = 'MALE',
  loading: isLoadingExternally,
}) => {
  const [tags, setTags] = useState<TSizingTagOption[]>([])
  const [isAnimating, setIsAnimation] = useState(isLoadingExternally)

  useEffect(() => {
    setIsAnimation(true)

    setTimeout(() => {
      setTags(data)
    }, 250)

    if (isLoadingExternally) return

    setTimeout(() => {
      setIsAnimation(false)
      setTags(data)
    }, 500)
  }, [data, isLoadingExternally])

  return (
    <Styled.Container data-testid="sizing-tags-container">
      {tags.map((activeSizingItem: TSizingTagOption) => (
        <NewSizingTag
          key={`${activeSizingItem.bodyPart}-${activeSizingItem.type}`}
          fit={activeSizingItem.type}
          bodyPart={activeSizingItem.bodyPart}
          loading={isAnimating || isLoadingExternally}
          gender={gender}
        />
      ))}
    </Styled.Container>
  )
}
