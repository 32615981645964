import styled from 'styled-components'

import { TDots } from './types'

export const Container = styled.div`
  position: absolute;
  top: 160px;
`

export const Dots = styled.span<TDots>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background: ${({ color }) => color};
  display: inline-block;
  border-radius: 50%;
  bottom: 0px;
  margin: 0px 4px;
  position: relative;
  animation: jump 1s infinite;
  animation-delay: ${({ delay }) => delay};

  @keyframes jump {
    0% {
      bottom: 0px;
    }
    35% {
      bottom: 20px;
    }
    70% {
      bottom: 0px;
    }
  }
`
