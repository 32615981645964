import styled, { keyframes } from 'styled-components'

import { TIconProps } from '..'

const spinner = keyframes`
  12.5% { transform:rotate(45deg) }
  25% { transform:rotate(90deg) }
  37.5% { transform:rotate(135deg) }
  50% { transform:rotate(180deg) }
  62.5% { transform:rotate(225deg) }
  75% { transform:rotate(270deg) }
  87.5% { transform:rotate(315deg) }
  100% { transform:rotate(360deg) }
`

export const Svg = styled.svg<Pick<TIconProps, 'size' | 'color'>>`
  color: ${({ color }) => color};

  #spinner-loading-icon {
    transform-origin: center;
    animation: ${spinner} 1.25s step-end infinite;
  }
`
