import { FC, useCallback, useEffect, useState } from 'react'

import { ITermsContext } from '~/context/Terms/context'

import { TermsContext } from '.'

export const TermsProvider: FC = ({ children }) => {
  const [terms, setTerms] = useState(false)

  const acceptTerms = useCallback(() => {
    setTerms(true)
    localStorage.setItem('@doris:terms', JSON.stringify(true))
  }, [])

  useEffect(() => {
    setTerms(JSON.parse(localStorage.getItem('@doris:terms') || 'false'))
  }, [])

  const value = {
    terms,
    acceptTerms,
  } as unknown as ITermsContext

  return <TermsContext.Provider value={value}>{children}</TermsContext.Provider>
}
