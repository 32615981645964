import styled from 'styled-components'

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 4;
  display: ${props => (props.theme.isWebview ? 'none' : 'initial')};

  @media ${props => props.theme.media.devices.mobile.smallPortrait} {
    right: 12px;
    top: 12px;
  }
`
