import { IPositionsMap } from '../types'
import { FrontArmsClosed } from './FrontArmsClosed'
import { FrontArmsClosedErrorBody } from './FrontArmsClosedErrorBody'
import { FrontArmsClosedErrorMembers } from './FrontArmsClosedErrorMembers'
import { SideArmsClosed } from './SideArmsClosed'
import { SideArmsClosedErrorBody } from './SideArmsClosedErrorBody'
import { SideArmsClosedErrorMembers } from './SideArmsClosedErrorMembers'

const Map: IPositionsMap = {
  FrontArmsClosed,
  SideArmsClosed,
  FrontArmsClosedErrorBody,
  FrontArmsClosedErrorMembers,
  SideArmsClosedErrorBody,
  SideArmsClosedErrorMembers,
}

export default Map
