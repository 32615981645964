import { FC } from 'react'

import { AnimationFade, AnimationZoomOut } from '~/components/Animations'
import { Icon } from '~/components/Icon'
import { LoadingDots } from '~/components/LoadingDots'
import { Tag } from '~/components/Tag'
import Typography from '~/components/Typography'

import theme from '~/theme'

import { Actions } from '~/screens/Recommendation/components/actions'
import { hexToRgba } from '~/utils/colors'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { IRecommendationCardProps, TTag } from './types'

export const Card: FC<IRecommendationCardProps> = ({
  id,
  background,
  height,
  placeholder,
  tags,
  animation,
  loading,
  onLoad = () => ({}),
  animationActions,
  ...actions
}) => {
  const renderTag = (tag: TTag): JSX.Element | null => {
    if (tags?.hasCrawler && (!tag.price || !tag.in_stock)) {
      return (
        <AnimationZoomOut key={`${tag.category.name}`} visible={!animationActions}>
          <AnimationFade direction="fadeInOut" startAnimation={tags?.show}>
            <Tag
              testID={`unavailable-tag-${tag.category.type}`}
              icon={{ name: tag.category.name, color: theme.colors.palette.webOrange }}
              text={{ color: theme.colors.palette.webOrange }}
            >
              {translate('COMBINED_ITEMS_TOTAL_OUT_OF_STOCK_NOTE')}
            </Tag>
          </AnimationFade>
        </AnimationZoomOut>
      )
    }

    if (!tag.price) return null

    return (
      <AnimationZoomOut key={`${tag.category.name}`} visible={!animationActions}>
        <AnimationFade direction="fadeInOut" startAnimation={tags?.show}>
          <Tag icon={{ name: tag.category.name }}>{tag.price}</Tag>
        </AnimationFade>
      </AnimationZoomOut>
    )
  }

  return (
    <Styled.Container height={height} data-testid="recommendation-card" data-id={id}>
      <Styled.Image data-testid="recommendation-card-placeholder" src={placeholder} />
      {background && (
        <Styled.Image
          startAnimation={animation?.show}
          data-testid="recommendation-card-background"
          src={background}
          onLoad={() => onLoad({ id })}
        />
      )}
      {animation?.show && (
        <Styled.ContainerAnimation data-testid="container-animation">
          {animation.type === 'mobile' && (
            <Styled.ArrowIcon>
              <Icon name="arrowDown" size={24} />
            </Styled.ArrowIcon>
          )}
          <Typography testID="text-animation" align="center" color={theme.colors.white} type="prefix">
            <b>
              {animation?.type === 'desktop'
                ? translate('RECOMMENDATION_ANIMATION_DESKTOP')
                : translate('RECOMMENDATION_ANIMATION_MOBILE')}
            </b>
          </Typography>
        </Styled.ContainerAnimation>
      )}
      {tags?.data.length && (
        <Styled.ContainerTags data-testid="container-tags">{tags.data.map(tag => renderTag(tag))}</Styled.ContainerTags>
      )}
      <Actions
        cart={{
          ...actions.cart,
          onClick: () => actions.cart.onClick({ id }),
        }}
        combine={{
          ...actions.combine,
          onClick: () => actions.combine.onClick({ id }),
        }}
        like={{ ...actions.like, onClick: () => actions.like.onClick({ id }) }}
        animation={animationActions}
      />
      <LoadingDots
        color={theme.colors.white}
        backgroundColor={`rgba(${hexToRgba(theme.colors.black, 0.35)})`}
        isVisible={loading}
      />
    </Styled.Container>
  )
}
