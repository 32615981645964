import { useContextSelector } from 'use-context-selector'

import { IUserContext, UserContext } from '.'

export const useUserContext = (): IUserContext => {
  const clearStates = useContextSelector(UserContext, state => state.clearStates)
  const stateUser = useContextSelector(UserContext, state => state.stateUser)
  const stateCurrentUser = useContextSelector(UserContext, state => state.stateCurrentUser)
  const setUserState = useContextSelector(UserContext, state => state.setUserState)
  const setCurrentUserState = useContextSelector(UserContext, state => state.setCurrentUserState)

  return {
    stateUser,
    stateCurrentUser,
    setUserState,
    setCurrentUserState,
    clearStates,
  }
}
