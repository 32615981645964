import { FC } from 'react'

import { Icon } from '~/components/Icon'

import * as Styled from './styles'
import { IArea } from './types'

export const Area: FC<IArea> = ({ width, height, color }) => (
  <Styled.Container data-testid="area-container" width={width} height={height}>
    <Styled.Corner data-testid="area-corner" position="top-left">
      <Icon name="corner" rotate={90} size={55} color={color} />
    </Styled.Corner>
    <Styled.Corner data-testid="area-corner" position="top-right">
      <Icon name="corner" rotate={180} size={55} color={color} />
    </Styled.Corner>
    <Styled.Corner data-testid="area-corner" position="bottom-right">
      <Icon name="corner" rotate={270} size={55} color={color} />
    </Styled.Corner>
    <Styled.Corner data-testid="area-corner" position="bottom-left">
      <Icon name="corner" size={55} color={color} />
    </Styled.Corner>
  </Styled.Container>
)
