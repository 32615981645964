import { useCallback } from 'react'

export const useStartProducts = () => {
  const getStartProducts = useCallback(() => {
    const product = localStorage.getItem('@doris:start_products')
    return product ? JSON.parse(product) : null
  }, [])

  const setStartProducts = useCallback(products => {
    localStorage.setItem('@doris:start_products', JSON.stringify(products))
  }, [])

  const deleteStartProducts = useCallback(() => {
    localStorage.removeItem('@doris:start_products')
  }, [])

  return {
    getStartProducts,
    setStartProducts,
    deleteStartProducts,
  }
}
