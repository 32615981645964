import styled from 'styled-components'

import { IconButton } from '../IconButton'
import { INotificationProps } from './types'

export const Container = styled.div<INotificationProps>`
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: calc(-330px / 2);
  width: 330px;
  height: 60px;
  padding: 16px;
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(2px);

  display: flex;
  align-items: center;

  opacity: ${props => (props.show ? 1 : 0)};
  transform: ${props => (props.show ? 'translate3d(0,0,0)' : 'translate3d(0,-100%,0)')};
  transition: all 0.25s ease-in-out;

  z-index: 5;
`

export const Text = styled.p`
  flex: 1;
  margin: 3px 10px 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 30px;
`

export const CloseButton = styled(IconButton)``
