import React from 'react'

import { TIconProps } from '.'

const Shortall: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 14 37"
      data-testid="icon-shortall"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M12.16 7.31995C11.76 6.94995 10.72 5.51995 10.66 0.499953C10.6547 0.226045 10.4339 0.00527318 10.16 -4.69207e-05H9.57C9.32777 -0.00329376 9.11953 0.170952 9.08 0.409953C8.89 1.49995 8.28 4.32995 6.91 4.32995C5.54 4.32995 4.91 1.49995 4.75 0.409953C4.71047 0.170952 4.50223 -0.00329376 4.26 -4.69207e-05H3.67C3.39386 -4.69207e-05 3.17 0.223811 3.17 0.499953C3.1 5.49995 2.07 6.94995 1.67 7.31995C1.57131 7.41332 1.5168 7.54413 1.52 7.67995V14.48C1.52 14.7561 1.74386 14.98 2.02 14.98H11.83C11.9644 14.98 12.0931 14.9259 12.1871 14.83C12.2812 14.734 12.3327 14.6043 12.33 14.47V7.67995C12.3324 7.54005 12.2696 7.407 12.16 7.31995ZM9.55 10.07C9.57695 11.1223 9.10173 12.1247 8.27 12.77C7.50441 13.3286 6.46559 13.3286 5.7 12.77C4.86827 12.1247 4.39305 11.1223 4.42 10.07V7.99995C4.41359 7.90743 4.47852 7.82519 4.57 7.80995H9.41C9.45407 7.81151 9.49483 7.83375 9.52 7.86995C9.53619 7.91177 9.53619 7.95813 9.52 7.99995L9.55 10.07Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M12.31 16.41V15.85C12.3047 15.5761 12.0839 15.3553 11.81 15.35H2C1.72386 15.35 1.5 15.5739 1.5 15.85V16.41C1.5 16.6861 1.72386 16.91 2 16.91H11.77C11.9094 16.9212 12.0471 16.8735 12.1497 16.7785C12.2523 16.6835 12.3104 16.5498 12.31 16.41Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M11.5 19.89C11.7189 20.2246 12.1009 20.4156 12.5 20.39C12.5221 20.3793 12.5479 20.3793 12.57 20.39L12.36 17.27H11.13C10.9976 18.1607 11.1261 19.0708 11.5 19.89V19.89Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M9 34H12.5C12.6353 33.9974 12.7639 33.9411 12.8577 33.8436C12.9515 33.7461 13.0027 33.6153 13 33.48L12.51 20.74C12.4817 20.7543 12.4483 20.7543 12.42 20.74C11.943 20.7533 11.4943 20.5138 11.24 20.11C10.8474 19.4 10.68 18.5874 10.76 17.78C10.7627 17.6457 10.7112 17.5159 10.6171 17.42C10.5231 17.324 10.3944 17.27 10.26 17.27H8.53C8.07 17.27 7.85 17.5 7.85 17.77V21.46C7.86309 21.7348 7.80084 22.008 7.67 22.25C7.5537 22.4736 7.35726 22.645 7.12 22.73L8.54 33.52C8.55364 33.7717 8.74911 33.9757 9 34V34Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M2.58 20.13C2.37437 20.4417 2.05494 20.6607 1.69 20.74L1.32 21.2L0.930005 33.46C0.921578 33.5968 0.970854 33.7309 1.06587 33.8297C1.16089 33.9286 1.29295 33.9831 1.43 33.98H5C5.2505 33.9768 5.45996 33.7887 5.49001 33.54L6.81 22.59C6.81 22.59 6.81 22.59 6.81 22.53V17.79C6.80468 17.5161 6.58391 17.2953 6.31 17.29H3.56C3.42565 17.29 3.29693 17.344 3.20287 17.44C3.10881 17.5359 3.05732 17.6657 3.06 17.8C3.14001 18.6074 2.9726 19.42 2.58 20.13V20.13Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M2.34001 19.89C2.72209 19.0803 2.85104 18.1742 2.71001 17.29H1.53001L1.35001 20.39V20.39C1.75038 20.4223 2.13559 20.2297 2.35001 19.89H2.34001Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M7.54001 21.48V17.29H7.08001V22.37C7.40161 22.196 7.58406 21.843 7.54001 21.48Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M0.72 34.36H5C5.27614 34.36 5.5 34.5839 5.5 34.86V35.86C5.50599 35.9394 5.44892 36.0096 5.37 36.02H1.09C0.816092 36.0147 0.59532 35.7939 0.59 35.52V34.52C0.584012 34.4406 0.641076 34.3704 0.72 34.36Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M8.59999 34.36H12.91C13.1861 34.36 13.41 34.5839 13.41 34.86V35.86C13.416 35.9394 13.3589 36.0096 13.28 36.02H8.99999C8.72384 36.02 8.49999 35.7961 8.49999 35.52V34.52C8.4914 34.4498 8.53308 34.3831 8.59999 34.36Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M4.73 10.07C4.70535 10.9954 5.1255 11.8766 5.86 12.44C6.53041 12.9286 7.43959 12.9286 8.11 12.44C8.8445 11.8766 9.26465 10.9954 9.24 10.07V8.67C9.24 8.39386 9.01614 8.17 8.74 8.17H5.23C4.95386 8.17 4.73 8.39386 4.73 8.67V10.07Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Shortall
