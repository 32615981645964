import styled from 'styled-components'

const FullSize = styled.div`
  width: 100%;
  height: 100%;
`

export const Container = styled(FullSize)`
  position: relative;
`

export const Content = styled(FullSize)`
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  margin-bottom: 12px;

  span {
    line-height: 1;
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  }
`
