import { useEffect } from 'react'

import { useAvatar } from '~/hooks/useAvatar'

import { useAvatarContext } from '~/context/Avatar'
import { useNavigation } from '~/context/Navigation'

import { RoutesHeader } from '~/components/RoutesHeader'
import { TextButton } from '~/components/TextButton'
import { Title } from '~/components/Typography/styles'

import { IAvatar } from '~/entities'
import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'
import { TTranslateKey } from '~/utils/translate/types'

import * as Styled from './styles'
import { getContent } from './utils'

export const ProcessingFailure = () => {
  const { navigate, params } = useNavigation()
  const { resetAvatar, updateAvatarInfos } = useAvatar()
  const { stateAvatar, setAvatarState, clearStates } = useAvatarContext()

  const content = getContent(params?.error)

  const handleAvatarError = () => {
    Tracking.logEvent('AVATAR_ERROR_AGAIN', { widget: true })

    const currentAvatarFormInfos: Partial<IAvatar> = {
      age: stateAvatar?.data?.age,
      weight: stateAvatar?.data?.weight,
      height: stateAvatar?.data?.height,
      gender: stateAvatar?.data?.gender,
    }

    resetAvatar({ data: ['stateAvatar', 'statePositions'], setState: clearStates })

    updateAvatarInfos({
      data: currentAvatarFormInfos,
      setState: setAvatarState,
    })

    navigate('Camera')
  }

  useEffect(() => {
    Tracking.logEvent('AVATAR_ERROR', {
      error: content.title === 'PROCESSING_FAILURE_INVALID_FORMAT_TITLE' ? 'A98' : 'A99',
      gender: stateAvatar?.data?.gender,
      height: stateAvatar?.data?.height,
      weight: stateAvatar?.data?.weight,
      age: stateAvatar?.data?.age,
      widget: true,
    })
  }, [content.title, stateAvatar])

  return (
    <Styled.Container data-testid="processing-failure">
      <RoutesHeader closeButtonUrl="Models" />

      <Styled.WrapperContent>
        <Styled.Image src="https://doris-media-production.imgix.net/mix-static-assets/surprised-emoji.png" />
        <Title>{translate(content.title as TTranslateKey)}</Title>
        <Styled.TextDescription align="center">
          {translate(content.description as TTranslateKey)}
        </Styled.TextDescription>
      </Styled.WrapperContent>

      <Styled.Footer>
        <TextButton
          textTransform="initial"
          variant="outlined"
          testID="send-photo-button"
          onClick={() => navigate('Tips')}
        >
          {translate('SEE_TIPS_BUTTON')}
        </TextButton>
        <TextButton textTransform="initial" testID="send-photo-button" onClick={handleAvatarError}>
          {translate('PROCESSING_FAILURE_TAKE_PHOTO_BUTTON')}
        </TextButton>
      </Styled.Footer>
    </Styled.Container>
  )
}
