interface IGetModalVideoPathParams {
  gender: 'FEMALE' | 'MALE'
}

const SKIPPED_STATUS_KEY = '@doris:sizing_modal_skipped'

export const setSizingInfoModalAsSkipped = (): void => {
  localStorage.setItem(SKIPPED_STATUS_KEY, '1')
}

export const enableSizingInfoModal = (): void => {
  localStorage.removeItem(SKIPPED_STATUS_KEY)
}

export const getModalVideoPath = ({ gender }: IGetModalVideoPathParams): string => {
  const { lang } = document.documentElement
  const browserLanguage = /pt/i.test(lang) ? 'pt' : 'en'
  const modelGender = gender === 'MALE' ? 'male' : 'female'

  return `https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/sizing-video-${modelGender}-${browserLanguage}.mp4`
}
