import styled, { css } from 'styled-components'

import { Paragraph } from '~/components/Typography/styles'

import { colors } from './constants'
import { IContainer } from './types'

export const Container = styled.div<IContainer>`
  max-width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 9px;

  background-color: ${({ status }) => colors[status || 'default'].background};
  border: 1px solid ${({ status }) => colors[status || 'default'].border};
  backdrop-filter: blur(12px);

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
`

export const Text = styled(Paragraph)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  line-height: 20px;
`
