import { TCollectionOption } from './types'

export const fullData: TCollectionOption[] = [
  {
    value: 1,
    age_group: 'ADULTS',
    gender: 'MALE',
    label: 'ADULTS_MALE',
  },
  {
    value: 2,
    age_group: 'ADULTS',
    gender: 'FEMALE',
    label: 'ADULTS_FEMALE',
  },
  {
    value: 3,
    age_group: 'CHILDREN',
    gender: 'MALE',
    label: 'CHILDREN_MALE',
  },
  {
    value: 4,
    age_group: 'CHILDREN',
    gender: 'FEMALE',
    label: 'CHILDREN_FEMALE',
  },
]
