import { IProduct } from '~/entities'

export const PRODUCTS_PER_PAGE = 20

export const getSortProduct = (entry: IProduct, list: IProduct[]) => {
  const index = list?.findIndex(item => item.id === entry?.id)
  index >= 0 && list.splice(index, 1)
  return [entry, ...list]
}

export const uniqueProducts = (array?: IProduct[]) =>
  Array.from(new Set(array?.map(el => JSON.stringify(el)))).map(el => JSON.parse(el))
