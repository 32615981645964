import { Singleton } from '~/utils/singleton'

import { ANDROID_REGEX, IOS_REGEX, MOBILE_REGEX } from './constants'
import { IMobileUtilsSingleton } from './types'

class MobileUtilsSingleton extends Singleton<IMobileUtilsSingleton>() {
  isMobile() {
    return MOBILE_REGEX.test(navigator.userAgent) || window.matchMedia('(pointer:coarse)').matches
  }

  isIos() {
    return Boolean(IOS_REGEX.test(navigator.userAgent) || (navigator.platform && navigator.maxTouchPoints))
  }

  isAndroid() {
    return ANDROID_REGEX.test(navigator.userAgent)
  }

  getOS() {
    if (!this.isMobile()) return

    if (this.isAndroid()) return 'Android'

    if (this.isIos()) return 'iOS'
  }
}

export const MobileUtils = MobileUtilsSingleton.getInstance()
