import React from 'react'

import { TIconProps } from '.'

const Do: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 20 13"
      data-testid="icon-do"
      style={{
        width: size,
        height: size,
      }}
    >
      <path
        fill={color}
        d="M13.7460979.08360279h-.0100891c-1.4322372.0044328-2.818928.50962618-3.92515649 1.42999654-.9683362.80750061-1.67768683 1.8878808-2.03800657 3.10399658-.32834929-1.16871082-.96905732-2.22310775-1.85159768-3.04712724C4.91377522.63722824 3.6425434.08117247 2.34104415.00534669 2.28147017 0 2.22525924 0 2.17048961 0 .96459699 0 .09548952.7616603.0094916 1.89564464-.07938893 3.0476133.68834669 3.92835896 1.92114369 4.08584329c1.29717533.16671951 2.04665441 1.0027474 2.05530225 2.29421607.00864783 1.29146867-.74227255 2.138676-2.11391536 2.3695558-.66203985.11130836-1.39902762.67125263-1.67479748 1.27494254-.2940264.6416028-.24021766 1.325007.1441306 1.8742579.39539825.5643188 1.1050012.9011603 1.89387597.9011603.09128271 0 .18304586-.0043746.27336769-.0131237 2.44205267-.2367126 4.54635932-2.0832649 5.26076662-4.55732416.87356077 3.02113476 3.81178092 4.93105876 6.89087492 4.47925846C17.7298429 12.2569862 20.0106933 9.58125977 20 6.43352626 19.9903156 2.93193037 17.1855343.08360279 13.7460979.08360279Zm1.517752 8.02343714c-.4378664.4383852-1.0350019.68766647-1.6598965.69294348h-.0388417c-1.3238927-.01380366-2.3632584-1.0684029-2.3651328-2.40091572-.0018694-1.33251283 1.0295408-2.38803231 2.3520296-2.39907523h.0215267c1.3405812.00228393 2.4261785 1.07131751 2.4264346 2.38941267.0001077.6466933-.2653237 1.26604096-.7361199 1.7176348Z"
      />
    </svg>
  )
}

export default Do
