import styled from 'styled-components'

import { Paragraph, Title as TitleTypography } from '~/components/Typography/styles'

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  flex-direction: column;
`

export const WrapperContent = styled.div`
  padding: 0 28px 100px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const Title = styled(TitleTypography)`
  font-weight: 700;
`

export const Text = styled(Paragraph)`
  margin: 16px 0 16px 0;
`
