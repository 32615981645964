import { FC, useEffect, useState } from 'react'

import { useNavigation } from '~/context/Navigation'

import { Backdrop } from '~/components/Backdrop'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { PostHogUtil } from '~/utils/postHog'
import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from './styles'

const testID = 'privacy-disclaimer-backdrop'
const STORAGE_KEY = '@doris:privacy_modal_viewed'

export const PrivacyDisclaimerBackdrop: FC = () => {
  const shouldNotDisplayBackdrop = !!localStorage.getItem(STORAGE_KEY)
  const IS_FLAG_ENABLED = PostHogUtil.isFlagEnabled('PRIVACY_DISCLAIMER')

  const { navigate } = useNavigation()
  const [isVisible, setIsVisible] = useState(!shouldNotDisplayBackdrop && IS_FLAG_ENABLED)

  const markModalAsViewed = () => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(1))
  }

  const handleCancel = () => {
    Tracking.logEvent('TOOLTIP', { widget: true, origin: 'privacy', action: 'close' })
    markModalAsViewed()
    setIsVisible(false)
  }

  const handlePhoto = () => {
    Tracking.logEvent('AVATAR_ADD', { widget: true, origin: 'privacy' })
    markModalAsViewed()
    navigate('Models', { modelsTab: 'SELF' })
  }

  useEffect(() => {
    if (!isVisible) return

    Tracking.logEvent('TOOLTIP', { widget: true, origin: 'privacy', action: 'open' })
  }, [isVisible])

  return (
    <Backdrop visible={isVisible} testID={testID}>
      <Styled.Container>
        <Styled.ContainerIcon>
          <IconButton
            backgroundColor="transparent"
            borderColor="transparent"
            data-testid={`${testID}-icon-button`}
            icon={<Icon name="padlock" size="24px" data-testid={`${testID}-icon`} color={theme.colors.primary} />}
            size={40}
          />
        </Styled.ContainerIcon>
        <Styled.TitleSizing data-testid={`${testID}-title`} align="center">
          {translate('BACKDROP_PRIVACY_DISCLAIMER_TITLE')}
        </Styled.TitleSizing>
        <Styled.Text align="center" data-testid={`${testID}-content`}>
          {translate('BACKDROP_PRIVACY_DISCLAIMER_DESCRIPTION')}
        </Styled.Text>
        <Styled.ContainerButton>
          <TextButton variant="outlined" testID={`${testID}-dismiss`} onClick={handleCancel}>
            {translate('BACKDROP_PRIVACY_DISCLAIMER_DISMISS_BUTTON')}
          </TextButton>
          <TextButton testID={`${testID}-cta`} onClick={handlePhoto}>
            {translate('BACKDROP_PRIVACY_DISCLAIMER_CALL_TO_ACTION_BUTTON')}
          </TextButton>
        </Styled.ContainerButton>
      </Styled.Container>
    </Backdrop>
  )
}
