import { gql } from '@apollo/client'

export const GET_MODEL = gql`
  query getAvatar($where: model_to_tryon_bool_exp!) {
    model_to_tryon(where: $where) {
      id
      avatar_uuid
      gender
      type
      age
      height
      weight
      self_models {
        stage_image
      }
      pre_models {
        stage_image
      }
    }
  }
`
