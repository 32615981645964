import { FC } from 'react'

import { AppButtonClose } from '~/components/AppButtonClose'
import ImagePreview from '~/components/ImagePreview'
import Logo from '~/components/Logo'
import Typography from '~/components/Typography'

import theme from '~/theme'

import * as Styled from './styles'

export const Splash: FC = () => {
  const storage = localStorage.getItem('@doris:config')
  const defaultSplashImage = 'https://doris-media-production.s3.sa-east-1.amazonaws.com/media/splash.png'
  const splashImage = storage ? JSON.parse(storage)?.splash_image : defaultSplashImage

  return (
    <Styled.Container data-testid="splash">
      <AppButtonClose />
      <Styled.Content>
        <Typography type="prefix" color={theme.colors.white}>
          Empowered by
        </Typography>
        <Logo />
      </Styled.Content>
      <ImagePreview fullsize url={splashImage} />
    </Styled.Container>
  )
}
