import { DefaultTheme, StyledComponent } from 'styled-components'

import { FC } from 'react'

import theme from '~/theme'

import { Paragraph, Prefix, Title } from './styles'

interface ITypographyMapOptions {
  [key: string]:
    | StyledComponent<'h2', DefaultTheme, Pick<ITypographyProps, 'align' | 'color'>, never>
    | StyledComponent<'p', DefaultTheme, Pick<ITypographyProps, 'align' | 'color'>, never>
    | StyledComponent<'span', DefaultTheme, Pick<ITypographyProps, 'align' | 'color'>, never>
  title: StyledComponent<'h2', DefaultTheme, Pick<ITypographyProps, 'align' | 'color'>, never>
  paragraph: StyledComponent<'p', DefaultTheme, Pick<ITypographyProps, 'align' | 'color'>, never>
  prefix: StyledComponent<'span', DefaultTheme, Pick<ITypographyProps, 'align' | 'color'>, never>
}

type TTypographyType = 'title' | 'paragraph' | 'prefix'

export type TAlignType = 'left' | 'center' | 'right'

export interface ITypographyProps {
  type?: TTypographyType
  align?: TAlignType
  color?: string
  testID?: string
}

const typographyMap: ITypographyMapOptions = {
  title: Title,
  paragraph: Paragraph,
  prefix: Prefix,
}

const Typography: FC<ITypographyProps> = ({
  type = 'paragraph',
  align = 'left',
  color = theme.colors.textPrimary,
  testID = 'typography',
  children,
}) => {
  const Container = typographyMap[type]

  return (
    <Container data-testid={testID} align={align} color={color}>
      {children}
    </Container>
  )
}

export default Typography
