import React from 'react'
import ReactDOM from 'react-dom'

import { StartButtonComponent } from '~/utils/injectButton/components/StartButtonComponent'

import { IReactRenderParams } from './types'

export const ReactRender = ({ wrapper, ...props }: IReactRenderParams) => {
  ReactDOM.render(
    <React.StrictMode>
      <StartButtonComponent {...props} />
    </React.StrictMode>,
    wrapper,
  )
}
