import { ITryonStorageClass, TTryonStorage } from './types'

class TryonStorage implements ITryonStorageClass {
  private storageKey: string

  constructor() {
    this.storageKey = '@doris:current_tryon'
  }

  public remove = () => {
    localStorage.removeItem(this.storageKey)
  }

  public get = (): TTryonStorage | null => {
    const item = localStorage.getItem(this.storageKey)

    return item ? JSON.parse(item) : null
  }

  public set = (tryon: TTryonStorage) => {
    localStorage.setItem(this.storageKey, JSON.stringify(tryon))
  }
}

export const TryonStorageInstance = new TryonStorage()
