import styled from 'styled-components'

import { IAnimationZoomOutProps } from './types'

export const Container = styled.div<IAnimationZoomOutProps>`
  transition-property: opacity, visibility, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  will-change: opacity, visibility, transform;

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transform: ${({ visible }) => (visible ? 'scale3d(1,1,1)' : 'scale3d(0,0,0)')};
`
