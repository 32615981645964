import { useCallback } from 'react'

import { useQueryAutomix } from '~/hooks-queries/automix'
import { ICurrentModel } from '~/hooks/useCurrentModel/types'

import { IAutomix, TGender } from '~/entities'

import { IStorage, IUseAutomix, TModelStorage } from './types'

const storageKey = '@doris:automix'

export const useAutomix = (): IUseAutomix => {
  const { fetch } = useQueryAutomix()

  const getStorage = useCallback((): IStorage | null => {
    const storage = localStorage.getItem(storageKey)
    return storage ? JSON.parse(storage) : null
  }, [])

  const getModelStorage = useCallback(
    ({ model }: { model: ICurrentModel }): TModelStorage => {
      const storage = getStorage()

      if (!storage) return []

      return (storage[model.id] as Array<number>) || []
    },
    [getStorage],
  )

  const deleteModelStorage = useCallback(
    ({ model }: { model: ICurrentModel }) => {
      const storage = getStorage()

      /* istanbul ignore next */
      if (!storage) return

      delete storage[model.id]

      localStorage.setItem(storageKey, JSON.stringify(storage))
    },
    [getStorage],
  )

  const getCombinationList = useCallback((): Array<IAutomix> => {
    const storage = getStorage()

    return storage?.list || []
  }, [getStorage])

  const setCombinationList = useCallback(
    ({ list }: { list: Array<IAutomix> }) => {
      const storage = getStorage() || {}

      localStorage.setItem(
        storageKey,
        JSON.stringify({
          ...storage,
          list,
        }),
      )
    },
    [getStorage],
  )

  const deleteCombinationList = useCallback(() => {
    const storage = getStorage()

    if (!storage || !storage?.list.length) return

    localStorage.setItem(
      storageKey,
      JSON.stringify({
        ...storage,
        list: [],
      }),
    )
  }, [getStorage])

  const getFiltered = useCallback(
    ({ list, values }: { list: Array<IAutomix>; values: TModelStorage }): Array<IAutomix> => {
      const result = [...list]

      values.forEach(value => {
        const index = result.findIndex(item => item.id === value)

        if (index === -1) return

        result.splice(index, 1)
      })

      return result
    },
    [],
  )

  const getRandom = useCallback(
    ({ list }: { list: Array<IAutomix> }): IAutomix => list[Math.floor(Math.random() * list.length)],
    [],
  )

  const isInvalidCombinationList = (combinations: IAutomix[], gender?: string) => {
    return combinations.some(
      combination =>
        (combination.products.top.gender !== 'UNISEX' && combination.products.top.gender !== gender) ||
        (combination.products.bottom.gender !== 'UNISEX' && combination.products.bottom.gender !== gender),
    )
  }

  const getCombination = useCallback(
    ({ model }: { model: ICurrentModel }): Promise<IAutomix> => {
      return new Promise(async (resolve, reject) => {
        const modelStorage = getModelStorage({ model })

        let combinationList: Array<IAutomix> = getCombinationList()

        if (!combinationList.length || isInvalidCombinationList(combinationList, model.gender)) {
          let automixError
          let automixData: IAutomix[] = []

          try {
            automixData = await fetch({ gender: model.gender as TGender })
          } catch (error) {
            automixError = error
          }

          if (automixError) {
            reject(new Error(automixError.message))

            return
          }

          if (!automixData?.length) {
            reject(new Error('Não há combinações disponíveis'))

            return
          }

          combinationList = automixData

          setCombinationList({ list: combinationList })
        }

        if (modelStorage.length) {
          const combinationsFiltered = getFiltered({
            list: combinationList,
            values: modelStorage,
          })

          if (combinationsFiltered.length) {
            resolve(
              getRandom({
                list: combinationsFiltered,
              }),
            )

            return
          }

          deleteModelStorage({ model })
        }

        resolve(
          getRandom({
            list: combinationList,
          }),
        )
      })
    },
    [getModelStorage, getCombinationList, getRandom, setCombinationList, fetch, getFiltered, deleteModelStorage],
  )

  const setCombination = useCallback(
    ({ model, combination }: { model: ICurrentModel; combination: IAutomix }) => {
      const modelStorage = getModelStorage({ model })
      const storage = getStorage() || {}

      modelStorage.push(combination.id)

      localStorage.setItem(
        storageKey,
        JSON.stringify({
          ...storage,
          [model.id]: modelStorage,
        }),
      )
    },
    [getModelStorage, getStorage],
  )

  return {
    getCombination,
    setCombination,
    deleteCombinationList,
  }
}
