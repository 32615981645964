import { Settings } from 'react-slick'

export const sliderSettings: Settings = {
  dots: false,
  infinite: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  vertical: true,
  verticalSwiping: true,
  waitForAnimate: true,
  edgeFriction: 0,
  touchThreshold: 10,
}
