import debounce from 'lodash/debounce'
import { array, bool, func, number, object, shape, string } from 'prop-types'

import { useEffect, useMemo, useRef, useState } from 'react'

import { MobileUtils } from '~/utils/mobileUtils'

import Arrow from './components/Arrow'
import { Container, EndScrollSpace, Item } from './styles'

const FlatList = ({
  data,
  renderItem,
  isPagination,
  itemsPerPage,
  isLastPage,
  currentPage,
  renderPagination,
  styles,
  testID,
  arrowNavigationItems,
  showEndScrollSpace,
  endScrollSpaceSize,
  disabled,
}) => {
  const [disabledArrow, setDisabledArrow] = useState({ left: true, right: false })
  const flatListRef = useRef()

  const isMobile = useMemo(() => MobileUtils.isMobile(), [])

  /* istanbul ignore next */
  const handleScrollClick = direction => {
    const itemWidth = flatListRef.current?.getElementsByTagName('li')[0]?.clientWidth

    const valueToNavigate = itemWidth * arrowNavigationItems

    const scrollDirection = {
      left: -valueToNavigate,
      right: valueToNavigate,
    }

    flatListRef.current?.scroll({
      left: flatListRef.current?.scrollLeft + scrollDirection[direction],
      behavior: 'smooth',
    })
  }

  /* istanbul ignore next */
  const renderArrowLeft = () =>
    !isMobile && (
      <Arrow testID={testID} direction="left" disabled={disabledArrow.left} onClick={() => handleScrollClick('left')} />
    )
  /* istanbul ignore next */
  const renderArrowRight = () =>
    !isMobile && (
      <Arrow
        testID={testID}
        direction="right"
        disabled={disabledArrow.right}
        onClick={() => handleScrollClick('right')}
      />
    )

  /* istanbul ignore next */
  const handleOnScroll = debounce(({ target: { scrollLeft, scrollWidth, offsetWidth } }) => {
    const isStart = scrollLeft === 0
    const isEnd = offsetWidth + scrollLeft + 1 >= scrollWidth
    setDisabledArrow({ left: isStart, right: isEnd })
  }, 100)

  const resetArrowState = () => {
    const isStart = flatListRef.current?.scrollLeft === 0
    const isEnd = flatListRef.current?.offsetWidth + flatListRef.current?.scrollLeft >= flatListRef.current?.scrollWidth

    setDisabledArrow({ left: isStart, right: isEnd })
  }

  useEffect(() => {
    if (disabled) {
      setDisabledArrow({ left: disabled, right: disabled })
      return
    }

    resetArrowState()
  }, [disabled])

  const handlePagination = () => {
    setTimeout(resetArrowState, 1000)
  }

  return (
    <Container ref={flatListRef} onScroll={handleOnScroll} data-testid={testID} style={styles.container}>
      {renderArrowLeft()}
      {isPagination ? (
        <>
          {data.map(
            (item, index) =>
              index < currentPage * itemsPerPage && (
                <Item data-id={item.id} data-testid={`${testID}-item`} style={styles.item} key={`key-${index}`}>
                  {renderItem(item, index)}
                </Item>
              ),
          )}
          {renderPagination && !isLastPage && (
            <Item data-testid={`${testID}-pagination`} onClick={handlePagination} style={styles.pagination}>
              {renderPagination(currentPage)}
            </Item>
          )}
        </>
      ) : (
        data.map((item, index) => (
          <Item
            data-testid={`${testID}-item`}
            style={styles.item}
            key={`key-${index}`}
            isMobileLastItem={isMobile && data.length - 1 === index}
          >
            {renderItem(item, index)}
          </Item>
        ))
      )}
      {showEndScrollSpace && <EndScrollSpace size={endScrollSpaceSize} />}
      {renderArrowRight()}
    </Container>
  )
}

FlatList.propTypes = {
  data: array.isRequired,
  renderItem: func.isRequired,
  isPagination: bool,
  itemsPerPage: number,
  isLastPage: bool,
  currentPage: number,
  renderPagination: func,
  styles: shape({ container: object, item: object, pagination: object }),
  testID: string,
  arrowNavigationItems: number,
  showEndScrollSpace: bool,
  endScrollSpaceSize: string,
  disabled: bool,
}

FlatList.defaultProps = {
  isPagination: false,
  itemsPerPage: 6,
  isLastPage: false,
  currentPage: 1,
  styles: {
    container: {},
    item: {},
    pagination: {},
  },
  testID: 'flat-list',
  arrowNavigationItems: 3,
  showEndScrollSpace: true,
  endScrollSpaceSize: '35px',
  disabled: false,
}

export default FlatList
