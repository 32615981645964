import styled, { css } from 'styled-components'

import { IButtonStyles, IPinProps } from '~/components/IconButton/types'

import { pinDirectionMap, statusColorMap } from './constants'

export const Button = styled.button<IButtonStyles>`
  position: relative;
  border-radius: 100%;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  ${({ boxShadow }) => boxShadow && 'box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1)'};

  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ disabled, theme }) => (disabled ? theme.opacity.default : 1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};

  ${({ borderColor }) =>
    borderColor &&
    css`
      border: 1px solid ${borderColor};
    `};
`

export const Pin = styled.div<IPinProps>`
  z-index: 1;
  position: absolute;
  top: -3px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.24);

  border: 1px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ status }) => statusColorMap[status]};

  ${({ direction }) => pinDirectionMap[direction]};
`
