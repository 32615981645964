import { IForm } from '~/screens/InformData/types'

export const LAST_FORM_VALUES_KEY = '@doris:last-inform-data-values'
export const PREFILLED_FORM_STORAGE_KEY = '@doris:inform-data-fill-values'

export const fetchFromLocalStorage = (): IForm | null => {
  const storage = localStorage.getItem(PREFILLED_FORM_STORAGE_KEY)

  if (!storage) {
    return null
  }

  return JSON.parse(storage)
}

export const saveToLocalStorage = (params: IForm): void => {
  localStorage.setItem(LAST_FORM_VALUES_KEY, JSON.stringify(params))
  localStorage.setItem(PREFILLED_FORM_STORAGE_KEY, JSON.stringify(params))
}

export const removeFromLocalStorage = (): void => {
  localStorage.removeItem(PREFILLED_FORM_STORAGE_KEY)
}

export const migrateData = () => {
  const storage = localStorage.getItem(LAST_FORM_VALUES_KEY)

  if (!storage) return

  localStorage.setItem(PREFILLED_FORM_STORAGE_KEY, storage)
}
