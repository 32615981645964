import React from 'react'

import { TIconProps } from '.'

const Shorts: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 30 30"
      data-testid="icon-shorts"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-9-9h48v48H-9z" />
        <path
          d="m1.94 25.56 10.85 1.1a.93.93 0 0 1 .85.93v1.95a.48.48 0 0 1-.51.46L1.31 28.62a.46.46 0 0 1-.4-.46v-1.67a.94.94 0 0 1 1.03-.93Zm26.12 0a.94.94 0 0 1 1 .93v1.67a.46.46 0 0 1-.4.46L16.87 30h-.05a.47.47 0 0 1-.46-.46v-1.95a.93.93 0 0 1 .85-.93l10.85-1.1Zm-16-20.11a2.91 2.91 0 0 0-.24.27 3.61 3.61 0 0 0-.64 2.72.48.48 0 0 0 .37.38c.202.032.406.048.61.05a3.31 3.31 0 0 0 1.65-.41c-.55 1.32-1.34 2.24-2.13 2.24a.5.5 0 1 0 0 1 3.14 3.14 0 0 0 2.49-1.83.94.94 0 0 1 1.59 0 3.14 3.14 0 0 0 2.49 1.83.5.5 0 0 0 0-1c-.79 0-1.58-.92-2.13-2.24a3.31 3.31 0 0 0 1.65.41 4.18 4.18 0 0 0 .61-.05.48.48 0 0 0 .37-.38 3.61 3.61 0 0 0-.64-2.72c-.07-.09-.16-.18-.24-.27h7.94a1 1 0 0 1 .91.71 70.49 70.49 0 0 1 2.37 17.94l-12.45 1.27a.92.92 0 0 1-1-.73L15 21.89l-.63 2.76a.92.92 0 0 1-1 .72L1.76 24.19a.94.94 0 0 1-.84-.95A74.21 74.21 0 0 1 3 5.45h9.07-.01ZM25 0a.48.48 0 0 1 .44.35c.34 1.08.67 2.18 1 3.29H4.86A.94.94 0 0 1 4 2.45c.19-.71.4-1.42.62-2.1A.48.48 0 0 1 5 0h20Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Shorts
