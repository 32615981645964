import { FC, useCallback, useState } from 'react'

import { CombinedItemsContext } from './context'
import { IProductCombine, TSizeOptions } from './types'

export const CombinedItemsProvider: FC = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState<IProductCombine[]>([])
  const [selectedSizes, setSelectedSizes] = useState<{ [key: string]: string }>({})

  const handleSelectProduct = useCallback(
    (product: IProductCombine) => setSelectedProducts([...selectedProducts, product]),
    [selectedProducts],
  )

  const handleSetSelectedProducts = useCallback((products: IProductCombine[]) => {
    setSelectedProducts(products)
    setSelectedSizes({})
  }, [])

  const handleSelectProductSize = useCallback(
    (options: TSizeOptions[]) => {
      const temporarySizes: { [key: string]: string } = { ...selectedSizes }

      options.forEach(item => {
        temporarySizes[item.identifier] = item.unique_code
      })

      setSelectedSizes(temporarySizes)
    },
    [selectedSizes],
  )

  const handleRemoveProductSize = useCallback((identifier: string) => {
    setSelectedSizes(current => {
      const temporarySizes = { ...current }
      delete temporarySizes[identifier]
      return temporarySizes
    })
  }, [])

  const handleRemoveProduct = useCallback(
    (productId: number) =>
      setSelectedProducts(prevSelectedProducts => prevSelectedProducts.filter(({ id }) => id !== productId)),
    [],
  )

  const value = {
    selectedProducts,
    handleSelectProduct,
    handleRemoveProduct,
    handleSetSelectedProducts,
    handleSelectProductSize,
    handleRemoveProductSize,
    selectedSizes,
  }

  return <CombinedItemsContext.Provider value={value}>{children}</CombinedItemsContext.Provider>
}
