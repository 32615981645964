import { FC } from 'react'

import { ISkeletonLoaderProps } from '~/components/SkeletonLoader/types'

import * as Styled from './styles'

export const SkeletonLoader: FC<ISkeletonLoaderProps> = ({ width, height, borderRadius = '0px', styles = {} }) => (
  <Styled.Element
    style={styles}
    width={width}
    height={height}
    borderRadius={borderRadius}
    data-testid="skeleton-loader"
  />
)
