import { useCallback, useEffect, useState } from 'react'

import { useAvatar } from '~/hooks/useAvatar'
import { useCurrentModel } from '~/hooks/useCurrentModel'

import { useAvatarContext } from '~/context/Avatar'
import { useNavigation } from '~/context/Navigation'

import { Sentry } from '~/clients/sentry'
import { IAvatar } from '~/entities'
import Tracking from '~/utils/tracking'

import { IUseSubscriptionProcessingAvatar } from './types'

export const useSubscriptionProcessingAvatar: () => IUseSubscriptionProcessingAvatar = () => {
  const { setAvatar } = useAvatar()
  const { stateAvatar } = useAvatarContext()

  const { navigate, params } = useNavigation()
  const { setCurrentModel } = useCurrentModel()

  const [listen, setListen] = useState(false)
  const [avatarProcessedStatus, setAvatarProcessedStatus] = useState(false)

  const onAvatarCreateProcessed = useCallback(() => {
    if (stateAvatar?.isLoading || !listen) return

    if (stateAvatar?.errors?.create) {
      // eslint-disable-next-line no-console
      console.error(stateAvatar.errors.create.message)

      Sentry.captureException<IAvatar>({
        errorName: stateAvatar.errors.create.name,
        errorMessage: stateAvatar.errors.create.message,
        filePath: 'src/hooks/useAvatar/useSubscriptionProcessingAvatar/index.ts',
        functionName: 'onAvatarCreateProcessed',
        payload: stateAvatar.data as IAvatar,
      })

      stateAvatar.errors.create.name === 'PROCESSING_TIMEOUT'
        ? navigate('Timeout', { ...params, type: 'avatar' })
        : navigate('ProcessingFailure')

      return
    }

    const avatar = { ...(stateAvatar?.data as IAvatar) }

    setAvatar({
      id: avatar.id,
      avatar_uuid: avatar.avatar_uuid,
      image_url: avatar.image_url,
      gender: avatar.gender,
      age_group: avatar.age_group,
    })

    Tracking.logEvent('AVATAR_READY', {
      avatar: avatar.avatar_uuid,
      gender: stateAvatar?.data?.gender,
      age_group: avatar.age_group,
      height: stateAvatar?.data?.height,
      weight: stateAvatar?.data?.weight,
      age: stateAvatar?.data?.age,
      widget: true,
    })

    setCurrentModel({
      id: avatar.avatar_uuid,
      stage_image: avatar.image_url,
      gender: avatar.gender,
      age_group: avatar.age_group,
      type: 'SELF_MODEL',
    })

    setListen(false)
    setAvatarProcessedStatus(true)
  }, [stateAvatar, params, navigate, setAvatar, setCurrentModel, listen])

  const subscriptionCreateAvatar = useCallback(() => setListen(true), [])

  useEffect(onAvatarCreateProcessed, [onAvatarCreateProcessed])

  return {
    subscriptionCreateAvatar,
    avatarProcessedStatus,
  }
}
