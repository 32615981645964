import { FC, useCallback, useState } from 'react'

import { AvatarContext, TAvatarPositionsState, TAvatarState, TClearStates, TSetAvatarState, TSetPositionState } from '.'

export const AvatarProvider: FC = ({ children }) => {
  const [stateAvatar, setAvatar] = useState<TAvatarState>()
  const [statePositions, setPositions] = useState<TAvatarPositionsState>()

  const setAvatarState = useCallback((params: TSetAvatarState): void => {
    setAvatar(current => (typeof params === 'function' ? params(current) : params))
  }, [])

  const setPositionsState = useCallback((params: TSetPositionState): void => {
    setPositions(current => (typeof params === 'function' ? params(current) : params))
  }, [])

  const clearStates = useCallback((params: TClearStates) => {
    const states = {
      stateAvatar: setAvatar,
      statePositions: setPositions,
    }

    params.forEach(state => states[state](undefined))
  }, [])

  const value = {
    stateAvatar,
    statePositions,
    setAvatarState,
    setPositionsState,
    clearStates,
  }

  return <AvatarContext.Provider value={value}>{children}</AvatarContext.Provider>
}
