import styled from 'styled-components'

const Label = styled.label`
  position: relative;
  height: 24px;
  width: 24px;
`

const Input = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
`

const Svg = styled.svg.attrs({ ariaHidden: true, viewBox: '0 0 15 11', fill: 'none' })<{ isChecked: boolean }>`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.mischka};
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;

  box-shadow: ${props => (props.isChecked ? 'none' : '0 0 0 2px #edeff3 inset, 0 0 0 3px #8f9bb3 inset')};
  background-color: ${props => (props.isChecked ? props.theme.colors.primary : '#d6dae3')};
`

export { Input, Svg, Label }
