import React from 'react'

import { TIconProps } from '.'

const Pants: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 28 36"
      data-testid="icon-pants"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-10-6h48v48h-48z" />
        <path
          d="M21.68 0H6.31a1 1 0 0 0-1 .87L.78 33.69a1 1 0 0 0 .82 1.13L8.25 36a1 1 0 0 0 1.15-.78L13 17.76a1 1 0 1 1 2 0l3.6 17.44a1 1 0 0 0 1.15.78l6.65-1.16a1 1 0 0 0 .82-1.13L22.67.87a1 1 0 0 0-.99-.87ZM11.23 2.41h-5l.17-1.26h4.82l.01 1.26Zm4.64.18a.55.55 0 0 1-.57.51h-2.6a.53.53 0 0 1-.57-.51v-.43a1 1 0 0 1 1-1h1.74a1 1 0 0 1 1 1v.43Zm5.89-.18h-5V1.15h4.82l.18 1.26Zm-7.23 4.3v4.7h-1.06v-4.7h1.06Zm-9 5.41a.34.34 0 0 1-.23.1.35.35 0 0 1-.23-.6 11.49 11.49 0 0 0 2.82-5.79.32.32 0 0 1 .38-.29.34.34 0 0 1 .28.39 11.94 11.94 0 0 1-3 6.19h-.02Zm17.09.1a.39.39 0 0 1-.24-.1 12 12 0 0 1-3-6.19.35.35 0 0 1 .29-.39.34.34 0 0 1 .38.29 11.59 11.59 0 0 0 2.82 5.79.35.35 0 0 1 0 .49.32.32 0 0 1-.24.11h-.01Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Pants
