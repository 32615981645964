import styled, { css } from 'styled-components'

import { Arrow } from '~/components/SlickSlider/styles'

import { hexToRgba } from '~/utils/colors'

import { TContainerStyled } from './types'

export const Container = styled.div<TContainerStyled>`
  display: flex;
  width: 100%;
  height: 100%;

  ${Arrow} {
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => `rgba(${hexToRgba(theme.colors.black, 0.15)})`};

    transition-property: opacity, visibility, transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    will-change: opacity, visibility, transform;

    visibility: ${({ arrowAnimation }) => (!arrowAnimation ? 'visible' : 'hidden')};
    opacity: ${({ arrowAnimation }) => (!arrowAnimation ? '1' : '0')};
    transform: ${({ arrowAnimation }) => (!arrowAnimation ? 'scale3d(1,1,1)' : 'scale3d(0,0,0)')};

    &.carousel-arrow-right,
    &.carousel-arrow-left {
      right: 16px;
      left: auto;
      button {
        transform: rotate(90deg);
      }
    }

    &.carousel-arrow-left {
      top: calc(50% - 70px);
    }

    &.carousel-arrow-right {
      top: calc(50% + 70px);
    }

    svg use {
      fill: ${({ theme }) => theme.colors.white};
    }

    ${({ arrowDisabled }) =>
      arrowDisabled &&
      css`
        &.carousel-arrow-${arrowDisabled === 'prev' ? 'left' : 'right'} {
          button {
            pointer-events: none;
          }

          svg use {
            opacity: 0.15;
          }
        }
      `};
  }
`
