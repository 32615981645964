import { keyframes } from 'styled-components'

const spin = keyframes`
  from { transform: rotate(0); }
  to { transform: rotate(720deg); }
`

const firstOrnamentKeyframe = keyframes`
    0% { transform: translate(-50%, -50%); }
    50% { transform: translate(-55%, -45%); }
    100% { transform: translate(-50%, -50%); }
  `

const secondOrnamentKeyframe = keyframes`
    0% { transform: translate(-50%, -50%); }
    33% { transform: translate(-55%, -45%) scale(0.9); }
    66% { transform: translate(-45%, -55%); }
    100% { transform: translate(-50%, -50%); }
  `

const thirdOrnamentKeyframe = keyframes`
    0% { transform: translate(-50%, -50%) scale(1); }
    50% { transform: translate(-55%, -45%) scale(0.9); }
    100% { transform: translate(-50%, -50%) scale(1); }
  `

export { spin, firstOrnamentKeyframe, secondOrnamentKeyframe, thirdOrnamentKeyframe }
