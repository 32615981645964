import styled from 'styled-components'

import { Paragraph } from '~/components/Typography/styles'

export const Pin = styled.span`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 100%;
`

export const Button = styled.button`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 66px;
  border: 0;
  background-color: transparent;
  padding: 0;

  opacity: ${props => (props.disabled ? 0.35 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  svg path {
    transition: ${({ theme }) => theme.transitions.ease('fill')};
  }
`

export const Label = styled(Paragraph)`
  margin-top: 10px;
  font-size: 11px;
  font-weight: 600;
  transition: ${({ theme }) => theme.transitions.ease('color')};
  text-transform: capitalize;
`
