import { FC } from 'react'

import { TIconProps } from '.'

export const SuccessCheckMark: FC<TIconProps> = ({ color, size }) => (
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: size, width: size }}
    data-testid="success-checkmark-icon"
  >
    <path
      d="M30 0.5C13.44 0.5 0 13.94 0 30.5C0 47.06 13.44 60.5 30 60.5C46.56 60.5 60 47.06 60 30.5C60 13.94 46.56 0.5 30 0.5ZM30 54.5C16.77 54.5 6 43.73 6 30.5C6 17.27 16.77 6.5 30 6.5C43.23 6.5 54 17.27 54 30.5C54 43.73 43.23 54.5 30 54.5ZM45.8925 19.3775C44.7215 18.1982 42.8151 18.1949 41.64 19.37L24.8813 36.1287C24.3943 36.6157 23.605 36.6165 23.117 36.1305L18.347 31.3789C17.1772 30.2135 15.2847 30.2153 14.117 31.383V31.383C12.9478 32.5522 12.9478 34.4478 14.117 35.617L22.6364 44.1364C23.3895 44.8895 24.6105 44.8895 25.3636 44.1364L45.885 23.615C47.0543 22.4457 47.0576 20.5509 45.8925 19.3775V19.3775Z"
      fill={color}
    />
  </svg>
)
