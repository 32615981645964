/* eslint-disable no-console */
import { Sentry } from '~/clients/sentry'

interface IProductData {
  sku: string | null
  quantity: number | null
  total: number | null
}

export interface IDataOrder {
  id: number | null
  value: number | null
  products: IProductData[] | null
}

export const validationsDataOrder: (dataOrder: IDataOrder) => boolean = (
  // Foi ignorado o objeto abaixo, pois não é possível testar como undefined pelo fato de não ter controle de parâmetros recebidos de forma externa
  /* istanbul ignore next */
  dataOrder = { id: null, value: null, products: [] },
) => {
  if (!(typeof dataOrder === 'object')) {
    console.error(
      `[DORIS][ORDER] A função deve receber um objeto. O parâmetro recebido foi do tipo ${typeof dataOrder}`,
    )
    Sentry.captureException<IDataOrder>({
      errorName: 'INVALID_DATA_ORDER_FORMAT',
      errorMessage: `${typeof dataOrder} is not an object.`,
      filePath: 'src/utils/orderEvent/validations/index.ts',
      functionName: 'validationsDataOrder',
      payload: dataOrder,
    })
    return false
  }

  if (!dataOrder.id) {
    console.error('[DORIS][ORDER] O parâmetro "id" é obrigatório')
    Sentry.captureException({
      errorName: 'ID_NOT_FOUND',
      errorMessage: 'O parâmetro "id" é obrigatório',
      filePath: 'src/utils/orderEvent/validations/index.ts',
      functionName: 'validationsDataOrder',
    })
    return false
  }

  if (!dataOrder.value) {
    console.error('[DORIS][ORDER] O parâmetro "value" é obrigatório')
    Sentry.captureException({
      errorName: 'VALUE_NOT_FOUND',
      errorMessage: 'O parâmetro "value" é obrigatório',
      filePath: 'src/utils/orderEvent/validations/index.ts',
      functionName: 'validationsDataOrder',
    })
    return false
  }

  if (!dataOrder.products) {
    console.error('[DORIS][ORDER] O parâmetro "products" é obrigatório')
    Sentry.captureException({
      errorName: 'PRODUCTS_NOT_FOUND',
      errorMessage: 'O parâmetro "products" é obrigatório',
      filePath: 'src/utils/orderEvent/validations/index.ts',
      functionName: 'validationsDataOrder',
    })
    return false
  }

  if (!Array.isArray(dataOrder.products)) {
    console.error(
      `[DORIS][ORDER] O parâmetro deve receber um array de products. O parâmetro recebido foi do tipo ${typeof dataOrder.products}`,
    )
    Sentry.captureException({
      errorName: 'INVALID_FORMAT_FOR_PRODUCTS',
      errorMessage: `${typeof dataOrder.products} is not an products array.`,
      filePath: 'src/utils/orderEvent/validations/index.ts',
      functionName: 'validationsDataOrder',
    })
    return false
  }

  if (!dataOrder.products.length) {
    console.error('[DORIS][ORDER] O array de products recebido como parâmetro não pode ser vazio')
    Sentry.captureException({
      errorName: 'EMPTY_PRODUCTS_ARRAY',
      errorMessage: 'O array de products recebido como parâmetro não pode ser vazio',
      filePath: 'src/utils/orderEvent/validations/index.ts',
      functionName: 'validationsDataOrder',
    })
    return false
  }

  const products = dataOrder?.products
  const checkSku = (products: IProductData) => !products?.sku

  if (products.some(checkSku)) {
    console.error('[DORIS][ORDER] O parâmetro "sku" dentro do array de products é obrigatório')
    Sentry.captureException({
      errorName: 'SKU_NOT_FOUND',
      errorMessage: 'O parâmetro "sku" dentro do array de products é obrigatório',
      filePath: 'src/utils/orderEvent/validations/index.ts',
      functionName: 'validationsDataOrder',
    })
    return false
  }

  const checkQuantity = (products: IProductData) => !products?.quantity

  if (products.some(checkQuantity)) {
    console.error('[DORIS][ORDER] O parâmetro "quantity" dentro do array de products é obrigatório')
    Sentry.captureException({
      errorName: 'QUANTITY_NOT_FOUND',
      errorMessage: 'O parâmetro "quantity" dentro do array de products é obrigatório',
      filePath: 'src/utils/orderEvent/validations/index.ts',
      functionName: 'validationsDataOrder',
    })
    return false
  }

  const checkTotal = (products: IProductData) => !products?.total

  if (products.some(checkTotal)) {
    console.error('[DORIS][ORDER] O parâmetro "total" dentro do array de products é obrigatório')
    Sentry.captureException({
      errorName: 'TOTAL_NOT_FOUND',
      errorMessage: 'O parâmetro "total" dentro do array de products é obrigatório',
      filePath: 'src/utils/orderEvent/validations/index.ts',
      functionName: 'validationsDataOrder',
    })
    return false
  }

  return true
}
