export const positions = {
  'front-arms-closed': 'FrontArmsClosed',
  'side-arms-closed': 'SideArmsClosed',
}

export const positionsErrors = {
  'front-arms-closed': {
    body: 'FrontArmsClosedErrorBody',
    members: 'FrontArmsClosedErrorMembers',
  },
  'side-arms-closed': {
    body: 'SideArmsClosedErrorBody',
    members: 'SideArmsClosedErrorMembers',
  },
}
