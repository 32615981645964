import React from 'react'

import { TIconProps } from '.'

const Cross: React.FC<TIconProps> = ({ color, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 18"
    data-testid="icon-cross"
    style={{ width: size, height: size }}
  >
    <defs>
      <path
        d="M8 16.75a.857.857 0 0 1-.857-.857V9.626a.019.019 0 0 0-.02-.019H.858a.857.857 0 1 1 0-1.714h6.267c.01 0 .019-.009.019-.02V1.608a.857.857 0 0 1 1.714 0v6.267c0 .01.009.019.02.019h6.266a.857.857 0 0 1 0 1.714H8.876a.019.019 0 0 0-.019.02v6.266A.857.857 0 0 1 8 16.75Z"
        id="icon-cross"
      />
    </defs>
    <use fill={color} xlinkHref="#icon-cross" />
  </svg>
)

export default Cross
