import { FC } from 'react'

import { IIllustrationProps } from '../types'

export const FrontArmsClosedErrorBody: FC<IIllustrationProps> = ({ width, height }) => (
  <svg
    style={{ width, height }}
    data-testid="illustration-front-arms-closed-error-body"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="83.2 19 137 400"
  >
    <path
      style={{ opacity: 0.75, fill: '#FF433D' }}
      d="M220.5,236.6c-0.8-3.9-0.4-10.8-3.3-18.5c-2.4-6.3,0.2-18.8,0.4-35c0.2-16.2-4.5-26.5-4.5-26.5
	c-1.1-5.8-4.9-33.9-4.9-33.9s3.5-25.8-11.3-34.8c-7.3-4.4-12.1-2.2-19.9-5.5c-7.8-3.3-14-5.8-14.2-7.3c-0.1-1.1-0.1-5-0.1-7.1
	c1.5-1.7,2.9-3.7,4.2-6c1.1-1.9,1.7-4.1,2.1-6.2c1.5-0.4,2.1-2.8,2.4-4.8c0.4-2.5,2.5-4.7,1.4-7.9c-0.7-2-1.8-2-2.5-1.8
	c1-5.8,0.8-13.8-5-18.4C159.9,18.9,152,19,152,19s-8-0.1-13.2,4.1c-5.8,4.6-6,12.6-5,18.4c-0.7-0.2-1.8-0.2-2.5,1.8
	c-1.1,3.1,1.1,5.4,1.4,7.9c0.3,2,0.9,4.3,2.4,4.8c0.4,2.1,1,4.2,2.1,6.2c1.2,2.2,2.5,4.1,3.9,5.8c0,2.2,0.1,6,0,7
	c-0.1,1.5-6.2,4.3-14,7.6c-7.8,3.3-12.6,1-19.9,5.5c-14.8,9-11.3,34.8-11.3,34.8s-3.8,28.2-4.9,33.9c0,0-4.8,10.3-4.5,26.5
	c0.3,16.2,2.8,28.8,0.4,35c-2.9,7.6-2.4,14.5-3.3,18.5c-1,4.8,8,20.5,11.8,18.1c2.4-1.5-4.6-10.9-3.8-15c0.8-4.1,3-9.7,4.2-7.9
	c1.2,1.8,1.2,5.1,3,7.4c1.8,2.3,4.7,1.9,2.9-4.8c-1.8-6.7-0.5-5.6-1.9-9.7c-1.4-4.1-3-3.3-1.9-9.9c1.1-6.5,11.7-31.6,12.3-55.8
	c0,0,7.1-20.8,7.6-24.2c0,0,1,4.9,3.2,12.4c0.8,2.8,0.4,6.7,0.1,10.3c-0.4,4.7-0.9,9.2-2.3,12.6c-2.5,6.1-2.1,4.7-4.1,11.7
	c-3.4,11.8-3,16-6.6,42.5c-2.3,16.9-1.5,28.4-1.3,37.7c0.5,17.1,8,22.6,6.6,41.2c-0.4,5.5-2.3,17.7-1.7,31.5
	c0.7,14.8,3.9,31.2,6.3,42.3c3,13.5-0.1,14.6-0.3,17.5c-0.3,4.9,0.3,10.4-2.3,13c-2.6,2.6-5.8,4.1-4.8,7.4c0.7,2.4,13,4.1,18.9,4.1
	c6.5,0,6.9-1.9,5-8.1c-1.9-6.2-2.7-8.7-1.8-12.7c1-4.2-0.9-5.4-1-12c-0.1-6.6,3-15.5,6.8-30.2c5-19.3-2.1-26.8-0.8-49.1
	c0.3-5,4.2-16.7,6.2-32.3c1.7-13.6,4.6-23,5.5-32.6c1.2-14,1.2-15,2.6-15.1c1.3,0.1,1.3,1.1,2.6,15.1c0.8,9.6,3.8,19,5.5,32.6
	c1.9,15.6,5.9,27.2,6.2,32.3c1.4,22.3-5.8,29.8-0.8,49.1c3.8,14.7,7,23.7,6.8,30.2c-0.1,6.6-2.1,7.8-1.1,12c0.9,4,0.1,6.5-1.8,12.7
	c-1.9,6.2-1.5,8.1,5,8.1c5.9,0,18.2-1.7,18.9-4.1c1-3.3-2.1-4.8-4.8-7.4c-2.6-2.6-2-8.1-2.3-13c-0.2-3-3.3-4-0.3-17.5
	c2.4-11,5.7-27.5,6.3-42.3c0.6-13.7-1.3-26-1.7-31.5c-1.4-18.5,6.1-24.1,6.6-41.2c0.3-9.3,1-20.8-1.3-37.7
	c-3.5-26.4-3.1-30.6-6.6-42.5c-2-7-1.6-5.6-4.1-11.7c-1.3-3.1-1.7-7.3-2.2-11.6c-0.4-4.1-0.9-8.3,0-11.3c2.2-7.5,3.2-12.4,3.2-12.4
	c0.5,3.4,7.6,24.2,7.6,24.2c0.6,24.3,11.2,49.3,12.3,55.8c1.1,6.5-0.5,5.8-1.9,9.9c-1.4,4.1-0.1,3-1.9,9.7c-1.8,6.7,1.1,7.1,2.9,4.8
	c1.8-2.3,1.9-5.6,3-7.4c1.2-1.8,3.4,3.8,4.2,7.9c0.8,4.1-6.2,13.5-3.8,15C212.5,257.1,221.5,241.5,220.5,236.6L220.5,236.6z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M159.3,197.7c-0.4,0.4-0.4,1,0,1.4l6.6,6.1c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-5.8-5.5l5.5-5.8
	c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L159.3,197.7z M192.8,186.1c0.3-0.5,0.2-1.1-0.3-1.4c-0.5-0.3-1.1-0.2-1.4,0.3
	L192.8,186.1z M144.7,199.2c0.4-0.4,0.4-1,0-1.4l-6.1-6.6c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l5.5,5.8l-5.8,5.5
	c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0L144.7,199.2z M112.9,185.1c-0.2-0.5-0.8-0.7-1.3-0.4c-0.5,0.2-0.7,0.8-0.4,1.3
	L112.9,185.1z M160,199.4c0.2,0,0.5,0,0.7,0l-0.1-2c-0.2,0-0.5,0-0.7,0L160,199.4z M162.1,199.3c0.5,0,0.9,0,1.4-0.1l-0.1-2
	c-0.5,0-0.9,0.1-1.4,0.1L162.1,199.3z M164.9,199.1c0.5,0,0.9-0.1,1.4-0.1l-0.2-2c-0.5,0-0.9,0.1-1.4,0.1L164.9,199.1z M167.7,198.9
	c0.5-0.1,0.9-0.1,1.4-0.2l-0.3-2c-0.5,0.1-0.9,0.1-1.4,0.2L167.7,198.9z M170.5,198.5c0.5-0.1,0.9-0.1,1.4-0.2l-0.4-2
	c-0.4,0.1-0.9,0.2-1.3,0.2L170.5,198.5z M173.2,198c0.5-0.1,0.9-0.2,1.4-0.3l-0.5-1.9c-0.4,0.1-0.9,0.2-1.3,0.3L173.2,198z
	 M176,197.4c0.5-0.1,0.9-0.3,1.4-0.4l-0.6-1.9c-0.4,0.1-0.9,0.3-1.3,0.4L176,197.4z M178.7,196.5c0.4-0.2,0.9-0.3,1.3-0.5l-0.7-1.9
	c-0.4,0.2-0.8,0.3-1.3,0.5L178.7,196.5z M181.3,195.5c0.4-0.2,0.9-0.4,1.3-0.6l-0.9-1.8c-0.4,0.2-0.8,0.4-1.2,0.6L181.3,195.5z
	 M183.9,194.2c0.4-0.2,0.8-0.5,1.2-0.7l-1.1-1.7c-0.4,0.2-0.8,0.5-1.1,0.7L183.9,194.2z M186.3,192.7c0.4-0.3,0.8-0.6,1.2-0.9
	l-1.2-1.6c-0.3,0.3-0.7,0.5-1.1,0.8L186.3,192.7z M188.6,190.9c0.4-0.3,0.7-0.6,1.1-1l-1.4-1.4c-0.3,0.3-0.6,0.6-1,0.9L188.6,190.9z
	 M190.6,188.9c0.3-0.4,0.6-0.7,0.9-1.1l-1.6-1.2c-0.3,0.3-0.6,0.7-0.9,1L190.6,188.9z M192.4,186.7c0.1-0.2,0.3-0.4,0.4-0.6
	l-1.7-1.1c-0.1,0.2-0.2,0.4-0.4,0.6L192.4,186.7z M112.6,175.8c0.2-0.1,0.4-0.2,0.5-0.4l-1.1-1.7c-0.2,0.1-0.4,0.3-0.6,0.4
	L112.6,175.8z M114.2,174.8c0.4-0.2,0.8-0.4,1.2-0.6l-0.9-1.8c-0.4,0.2-0.8,0.4-1.2,0.7L114.2,174.8z M116.6,173.5
	c0.4-0.2,0.8-0.4,1.2-0.5l-0.8-1.8c-0.4,0.2-0.9,0.4-1.3,0.6L116.6,173.5z M119,172.5c0.4-0.2,0.8-0.3,1.3-0.5l-0.7-1.9
	c-0.4,0.2-0.9,0.3-1.3,0.5L119,172.5z M121.6,171.6c0.4-0.1,0.9-0.3,1.3-0.4l-0.6-1.9c-0.4,0.1-0.9,0.3-1.3,0.4L121.6,171.6z
	 M124.2,170.7c0.2-0.1,0.4-0.1,0.6-0.2l-0.6-1.9c-0.2,0.1-0.4,0.1-0.7,0.2L124.2,170.7z M178.7,170.5c0.2,0.1,0.4,0.1,0.6,0.2
	l0.6-1.9c-0.2-0.1-0.4-0.1-0.7-0.2L178.7,170.5z M180.6,171.1c0.4,0.1,0.9,0.3,1.3,0.4l0.7-1.9c-0.4-0.2-0.9-0.3-1.3-0.4
	L180.6,171.1z M183.2,172c0.4,0.2,0.8,0.3,1.3,0.5l0.7-1.9c-0.4-0.2-0.9-0.3-1.3-0.5L183.2,172z M185.7,173c0.4,0.2,0.8,0.4,1.2,0.5
	l0.8-1.8c-0.4-0.2-0.9-0.4-1.3-0.6L185.7,173z M188.1,174.1c0.4,0.2,0.8,0.4,1.2,0.6l1-1.7c-0.4-0.2-0.8-0.5-1.2-0.7L188.1,174.1z
	 M190.4,175.5c0.2,0.1,0.4,0.2,0.5,0.4l1.2-1.6c-0.2-0.1-0.4-0.3-0.6-0.4L190.4,175.5z M111.1,186c0.1,0.2,0.3,0.5,0.4,0.7l1.7-1.1
	c-0.1-0.2-0.2-0.3-0.3-0.5L111.1,186z M112.5,187.8c0.3,0.3,0.7,0.7,1.1,1l1.3-1.5c-0.3-0.3-0.6-0.6-0.9-0.9L112.5,187.8z
	 M114.7,189.7c0.4,0.3,0.8,0.5,1.2,0.8l1.1-1.7c-0.4-0.2-0.7-0.5-1.1-0.7L114.7,189.7z M117,191.3c0.4,0.2,0.8,0.5,1.2,0.7l0.9-1.8
	c-0.4-0.2-0.8-0.4-1.2-0.6L117,191.3z M119.5,192.6c0.4,0.2,0.8,0.4,1.2,0.6l0.8-1.8c-0.4-0.2-0.8-0.4-1.2-0.6L119.5,192.6z
	 M122,193.7c0.4,0.2,0.8,0.4,1.3,0.5l0.8-1.8c-0.4-0.2-0.8-0.3-1.2-0.5L122,193.7z M124.6,194.8c0.4,0.2,0.9,0.3,1.3,0.5l0.7-1.9
	c-0.4-0.2-0.8-0.3-1.3-0.5L124.6,194.8z M127.1,195.8c0.4,0.2,0.9,0.3,1.3,0.4l0.6-1.9c-0.4-0.1-0.8-0.3-1.3-0.4L127.1,195.8z
	 M129.7,196.6c0.4,0.1,0.9,0.3,1.3,0.4l0.6-1.9c-0.4-0.1-0.9-0.3-1.3-0.4L129.7,196.6z M132.4,197.4c0.4,0.1,0.9,0.2,1.3,0.4
	l0.5-1.9c-0.4-0.1-0.9-0.2-1.3-0.3L132.4,197.4z M135.1,198.1c0.5,0.1,0.9,0.2,1.3,0.3l0.4-2c-0.4-0.1-0.9-0.2-1.3-0.3L135.1,198.1z
	 M137.7,198.7c0.5,0.1,0.9,0.2,1.4,0.2l0.3-2c-0.4-0.1-0.9-0.2-1.3-0.2L137.7,198.7z M140.5,199.2c0.5,0.1,0.9,0.1,1.4,0.2l0.2-2
	c-0.4,0-0.9-0.1-1.3-0.2L140.5,199.2z M143.3,199.4c0.2,0,0.5,0,0.7,0l0.1-2c-0.2,0-0.4,0-0.6,0L143.3,199.4z"
    />
  </svg>
)
