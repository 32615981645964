import { FC, useMemo } from 'react'

import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { ModelCard } from '~/components/ModelCard'
import { settings } from '~/components/ModelsList/constants'
import { SlickSlider } from '~/components/SlickSlider'

import theme from '~/theme'

import { getImageWidthUsingRatio } from '~/utils/getImageWidthUsingRatio'
import { MobileUtils } from '~/utils/mobileUtils'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { ISelfAvatarsListItemProps, ISelfAvatarsListProps } from './types'

export const SelfAvatarsList: FC<ISelfAvatarsListProps> = ({
  onAvatarSelected,
  onDelete,
  testID = 'photos-list',
  data,
  handleTakeNewPicture,
  initialSlide,
}) => {
  const isMobile = useMemo(() => MobileUtils.isMobile(), [])

  const ItemLastSlide = () => (
    <Styled.Item data-testid={`${testID}-slide-empty`}>
      <Styled.Content>
        <IconButton
          testID={`${testID}-add-photo`}
          onClick={handleTakeNewPicture}
          icon={<Icon name={'cameraAdd'} color={theme.colors.silverChaliceDark} size={'33px'} />}
        />
        <Styled.Text color={theme.colors.silverChaliceDark}>
          {translate('SELF_AVATAR_LIST_NEW_PHOTO_PLACEHOLDER')}
        </Styled.Text>
      </Styled.Content>
    </Styled.Item>
  )

  const renderModel = (values: unknown) => {
    const item = values as ISelfAvatarsListItemProps
    const itemImageSuggestedWidth = getImageWidthUsingRatio({ size: 286 })
    const itemImageUrl = `${item.image_url}?w=${itemImageSuggestedWidth}`

    return (
      <ModelCard
        onClick={onAvatarSelected}
        active={item.active}
        id={item.avatar_uuid}
        testID={testID}
        url={itemImageUrl}
        onDelete={onDelete}
      />
    )
  }

  return (
    <Styled.ContainerSlick data-testid={testID}>
      <SlickSlider
        settings={{ ...settings, arrows: !isMobile, initialSlide }}
        data={data}
        renderItem={renderModel}
        renderLastItem={<ItemLastSlide />}
      />
    </Styled.ContainerSlick>
  )
}
