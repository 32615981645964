import { FC } from 'react'

import theme from '~/theme'

import { toCurrency } from '~/utils/toCurrency'
import { translate } from '~/utils/translate'

import { Container, LabelMessage, LabelSubtotal, Price } from './styles'

export interface ITotalPriceProps {
  value: number
  showMessage?: boolean
}

const TotalPrice: FC<ITotalPriceProps> = ({ value, showMessage }) => (
  <Container data-testid="total-price">
    {showMessage ? (
      <LabelMessage color={theme.colors.textSecondary}>{translate('COMBINED_ITEMS_TOTAL_PRICE_MESSAGE')}</LabelMessage>
    ) : (
      <>
        <LabelSubtotal color={theme.colors.textSecondary}>{translate('TOTAL_PRICE')}</LabelSubtotal>
        <Price color={theme.colors.textPrimary}> {toCurrency({ value })}</Price>
      </>
    )}
  </Container>
)

export default TotalPrice
