import styled from 'styled-components'

import { IDividerProps } from '~/components/SizeSelect/types'
import { Prefix } from '~/components/Typography/styles'

export const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 100%;
  min-width: 100px;
  height: 100%;
  margin: 0;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
`

export const SizeSelect = styled.select<{ disabled?: boolean; padding?: string; isValueInitial: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  appearance: none;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme, isValueInitial }) => (isValueInitial ? theme.colors.white : theme.colors.black)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding: ${({ padding }) => padding || 0};
  background-color: ${({ theme }) => theme.colors.primary};
  opacity: ${({ isValueInitial }) => (isValueInitial ? 1 : 0)};
`

export const Option = styled.option`
  font-size: 12px;
`

export const IconWrapper = styled.span<{ right?: string; isValueInitial: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
  justify-content: ${({ isValueInitial }) => (isValueInitial ? 'flex-end' : `space-between`)};
  width: 100%;
  padding-right: 18px;
  border: ${({ theme, isValueInitial }) => (isValueInitial ? 'none' : `1px solid ${theme.colors.primary}`)};
  background-color: ${({ theme, isValueInitial }) => (isValueInitial ? 'transparent' : theme.colors.white)};
  border-radius: 20px;
`

export const Title = styled(Prefix)`
  font-weight: 600;
`

export const WrapperInfo = styled.div`
  display: flex;
  padding-left: 16px;
`

export const ContainerT = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const Divider = styled.div<IDividerProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.white};
`
