import { useContextSelector } from 'use-context-selector'

import { SyncContext } from '.'

export const useSyncContext = () => {
  const startSession = useContextSelector(SyncContext, state => state.startSession)
  const startSubscription = useContextSelector(SyncContext, state => state.startSubscription)

  return {
    startSession,
    startSubscription,
  }
}
