import styled, { keyframes } from 'styled-components'

import { IElementStyledProps } from './types'

const gradientKeyframe = keyframes`
    0% {
        background-position: 100% 100%;
    }

    100% {
        background-position: 0% 0%;
    }
`

export const Element = styled.div<IElementStyledProps>`
  background-image: linear-gradient(133deg, #e8e8e8 0%, transparent 100%);
  background-size: 150% 150%;
  animation: ${gradientKeyframe} 1s ease infinite alternate;

  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: ${props => props.borderRadius};
`
