import styled from 'styled-components'

import BaseArrow from '~/components/FlatList/components/Arrow'
import { Paragraph, Prefix } from '~/components/Typography/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 20px;
`

export const CarouselContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
`

export const TextBox = styled(Paragraph)`
  display: flex;
  padding: 20px;
  height: 94px;
  align-items: center;
  text-align: center;
  line-height: 20px;
  font-weight: 700;
`

export const Footer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  padding: 20px 20px 0;
`

export const Header = styled.div`
  top: 0;
  position: sticky;
  height: 100%;
`

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Label = styled(Prefix)`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  font-weight: 700;
  z-index: 1;
`

export const PrivacySpan = styled(Paragraph)`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
`

export const Arrow = styled(BaseArrow)<{ direction: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translate(0, -50%) translate3d(0, 0, 0);
  margin: 0;

  ${({ direction }) => `${direction}: 20px`};
`
