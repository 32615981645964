import styled from 'styled-components'

const Container = styled.div`
  z-index: 3;
  position: absolute;
  right: 16px;
  bottom: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin: -12.5px 0;

  @media ${props => props.theme.media.devices.mobile.smallPortrait} {
    right: 12px;
  }
`

const ButtonContainer = styled.div`
  margin: 12.5px 0;
`

export { Container, ButtonContainer }
