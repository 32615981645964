import styled from 'styled-components'

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border: none;
  padding: 0 20px;
  font-size: 12px;
  border-radius: 999px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  flex-shrink: 0;

  svg {
    margin-left: 10px;
  }

  &[disabled] {
    opacity: 0.2;
  }
`

export { Button }
