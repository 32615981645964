import { ICategoryItem } from '~/entities'
import Tracking from '~/utils/tracking'

import { ITrackDispatch, TEvent } from './types'

export const AmplitudeDispatch = ({ payload }: ITrackDispatch) => {
  const {
    type,
    values: { model, recommendation },
  } = payload

  const event = {
    type,
  } as TEvent

  switch (event.type) {
    case 'timeout_open':
      event.name = 'ERROR'
      event.values = {
        type: 'open',
      }
      break

    case 'timeout_reload':
      event.name = 'ERROR'
      event.values = {
        type: 'reload',
      }
      break

    case 'get_recommendation':
      event.name = 'TRY_ON'
      event.values = {
        id_avatar: model.id,
        type_avatar: model.type === 'SELF_MODEL' ? 'self' : 'pre',
        sku_top: (recommendation?.products.top?.identifier ?? recommendation?.products.full?.identifier) as string,
        sku_bottom: recommendation?.products.bottom?.identifier as string,
        top_categ: (recommendation?.products.top?.category.name ??
          recommendation?.products.full?.category.name) as ICategoryItem['name'],
        bot_categ: recommendation?.products.bottom?.category.name as ICategoryItem['name'],
        automix: true,
        cluster: recommendation?.id as number,
        widget: true,
      }
      break

    case 'update_recommendation':
      event.name = 'TRY_ON_LIKE'
      event.values = {
        id_avatar: model.id,
        type_avatar: model.type === 'SELF_MODEL' ? 'self' : 'pre',
        sku_top: recommendation?.products.top?.identifier as string,
        sku_bottom: recommendation?.products.bottom?.identifier as string,
        top_categ: recommendation?.products.top?.category.name as ICategoryItem['name'],
        bot_categ: recommendation?.products.bottom?.category.name as ICategoryItem['name'],
        origin: 'automix',
        cluster: recommendation?.id as number,
        widget: 'true',
      }
      break
    case 'cart_open':
      event.name = 'CART_OPEN'
      event.values = {
        origin: 'automix',
        widget: 'true',
      }
      break
    case 'combine_open':
      event.name = 'COMBINE_GALLERY'
      event.values = {
        origin: 'automix',
        start: 'false',
        widget: 'true',
      }
      break
    case 'navigation_undo':
      event.name = 'RECOMMENDER_UNDO'
      event.values = {
        widget: 'true',
      }
      break
    case 'navigation_redo':
      event.name = 'RECOMMENDER_REDO'
      event.values = {
        widget: 'true',
      }
      break
  }

  if (!event.name) {
    // eslint-disable-next-line no-console
    console.error('[ERROR][RECOMMENDATION][TRACKING]: event name is required')
    return
  }

  Tracking.logEvent(event.name, { ...event.values, widget: true })
}
