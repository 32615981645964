import { FC, useCallback, useState } from 'react'

import { IParams, NavigationContext, TRoutes } from '.'

const INITIAL_ROUTE = 'SplashScreen'

export const NavigationProvider: FC = ({ children }) => {
  const [route, setRoute] = useState<TRoutes>(INITIAL_ROUTE)
  const [previousRoute, setPreviousRoute] = useState<TRoutes>()
  const [params, setParams] = useState<IParams>()
  const [previousParams, setPreviousParams] = useState<IParams>()

  const navigate = useCallback(
    (navigateRoute: TRoutes, navigateParams?: IParams) => {
      setPreviousRoute(route)
      setPreviousParams(params)
      setParams(navigateParams)
      setRoute(navigateRoute)
    },
    [params, route],
  )

  const goBack = useCallback(() => {
    if (previousRoute) {
      navigate(previousRoute, previousParams)
    }
  }, [navigate, previousParams, previousRoute])

  const resetNavigation = useCallback(() => {
    setRoute(INITIAL_ROUTE)
    setPreviousRoute(undefined)
    setPreviousParams(undefined)
    setParams(undefined)
  }, [])

  const value = {
    navigate,
    goBack,
    route,
    params,
    previousRoute,
    previousParams,
    resetNavigation,
  }

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>
}
