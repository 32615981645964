import { FC } from 'react'

import { ICategoryButtonProps } from '~/components/CategoryButton/types'
import { Icon } from '~/components/Icon'

import theme from '~/theme'

import { categoryIconsMap } from '~/libs/icons'
import { translate } from '~/utils/translate'

import * as Styled from './styles'

export const CategoryButton: FC<ICategoryButtonProps> = ({ name, onClick, isActive, hasPin, disabled = false }) => {
  const label = translate(`CATEGORY_${name as keyof typeof categoryIconsMap}`)

  return (
    <Styled.Button data-testid="category-button" onClick={onClick} disabled={disabled}>
      {hasPin && <Styled.Pin data-testid="button-pin" />}
      <Icon name={name} color={isActive ? theme.colors.primary : theme.colors.silverChalice} size="36px" />
      <Styled.Label
        data-testid="label"
        align="center"
        color={isActive ? theme.colors.primary : theme.colors.silverChalice}
      >
        {label}
      </Styled.Label>
    </Styled.Button>
  )
}
