import { FC, useEffect, useRef } from 'react'
import Slider from 'react-slick'

import * as Styled from './styles'
import { IArrow, ISlickSlider } from './types'

const NextArrow = ({ onClick }: IArrow) => <Styled.Arrow direction="right" onClick={onClick} />

const PrevArrow = ({ onClick }: IArrow) => <Styled.Arrow direction="left" onClick={onClick} />

export const SlickSlider: FC<ISlickSlider> = ({
  data,
  settings,
  renderItem,
  renderLastItem,
  onSlickRender = () => ({}),
}) => {
  const slickRef = useRef<Slider>(null)

  useEffect(() => {
    onSlickRender(slickRef.current as Slider)
  }, [onSlickRender])

  return (
    <Styled.Container data-testid="container-slick-slider">
      <Styled.StyledSlider ref={slickRef} {...settings} nextArrow={<NextArrow />} prevArrow={<PrevArrow />}>
        {data.map((item, index) => (
          <Styled.Item key={`item-${index}`}>{renderItem(item, index)}</Styled.Item>
        ))}
        {renderLastItem}
      </Styled.StyledSlider>
    </Styled.Container>
  )
}
