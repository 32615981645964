import React from 'react'

import { TIconProps } from '.'

const Bermuda: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      data-testid="icon-bermuda"
      viewBox="0 0 28 28"
      style={{
        width: size,
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-19-10h66v66h-66z" />
        <path d="M-10-10h48v48h-48z" />
        <path
          fill={color}
          fillRule="nonzero"
          d="m27.63 9.52-.94 18.06c-.0207998.2383117-.220784.420906-.46.42H16.05c-.2241111.0085271-.4202914-.14927-.46-.37l-1.14-6.24c-.0456342-.2176739-.2375941-.3735635-.46-.3735635-.2224059 0-.4143658.1558896-.46.3735635l-1.16 6.24c-.0441462.2222635-.2435843.379268-.47.37H1.77c-.239216.000906-.4392002-.1816883-.46-.42L.4 10c-.0116563-.2468403.1737968-.4587868.42-.48 3.008167-.3544441 5.4249819-2.6451288 5.94-5.63.0297928-.2266145.2214554-.3969812.45-.4h13.62c.2319144.0036712.4248178.1794277.45.41.5191529 3.146913 3.1643364 5.5046671 6.35 5.66v-.04ZM5.83 3.45H.54c-.1291022.002577-.2517211.0570614-.3401621.1511476C.1113969 3.6952338.064594 3.8209853.07 3.95L.3 8.1c.0047879.1276693.0624559.2475966.1591896.331053C.5559232 8.5145095.6830078 8.5539766.81 8.54c2.6315242-.3596581 4.6873403-2.4524789 5-5.09h.02Zm21.84 5.14L27.93 4c.005406-.1290147-.0413969-.2547662-.1298379-.3488524C27.7117211 3.5570614 27.5891022 3.502577 27.46 3.5h-4.74c-.1380921.0009225-.2687908.062529-.3573865.1684586-.0885957.1059296-.1261221.245462-.1026135.3815414.5608059 2.5755984 2.7774491 4.4562624 5.41 4.59v-.05ZM27.53 0H.47C.2104262 0 0 .2104262 0 .47v1.58c0 .2595738.2104262.47.47.47h27.06c.2595738 0 .47-.2104262.47-.47V.47c.0057512-.1263272-.041917-.2492443-.1313363-.3386637C27.7792443.041917 27.6563272-.00575121 27.53 0Z"
        />
      </g>
    </svg>
  )
}

export default Bermuda
