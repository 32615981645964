import { FC } from 'react'

import * as Styled from './styles'
import { ITextInput } from './types'

export const TextInput: FC<ITextInput> = ({
  handleOnFocus,
  handleOnBlur,
  handleOnChange,
  type,
  name,
  error,
  min,
  max,
  placeholder,
  defaultValue = '',
}) => (
  <Styled.Label htmlFor={`field-${name}`}>
    <Styled.Input
      data-testid={`field-${name}`}
      error={error}
      type={type}
      id={`field-${name}`}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onBlur={event => {
        handleOnBlur(event.target.value)
      }}
      onFocus={handleOnFocus}
      onChange={event => {
        handleOnChange(event.target.value)
      }}
      min={min}
      max={max}
    />
    <Styled.ErrorMessage data-testid={`error-${name}`}>{error}&nbsp;</Styled.ErrorMessage>
  </Styled.Label>
)
