import styled from 'styled-components'

import theme from '~/theme'

import { ITextInput } from './types'

export const Label = styled.label`
  display: block !important;
  overflow: hidden;
  width: 100%;
`

export const Input = styled.input<Pick<ITextInput, 'error'>>`
  display: block;
  height: 40px;
  width: 100%;
  outline: none;
  font-size: 16px;
  color: ${theme.colors.black};
  border-radius: ${theme.tokens.spacingUnit}px;
  padding: 0 ${theme.tokens.spacingUnit * 2.5}px;
  border: 1px solid ${props => (props.error ? theme.colors.status.error : theme.colors.neutral.black400)};

  &::placeholder {
    color: ${theme.colors.black};
  }

  &:focus {
    border-color: ${({ error }) => (error ? theme.colors.status.error : theme.colors.primary)};
  }

  &:not(:placeholder-shown):not(:focus) {
    font-weight: ${theme.typography.fontWeight.bold};
  }
`

export const ErrorMessage = styled.span`
  font-size: 10px;
  line-height: 20px;
  height: 20px;
  display: block;
  padding: 0 ${theme.tokens.spacingUnit * 2.5}px;
  color: ${theme.colors.status.error};
`
