import { elementType, string } from 'prop-types'

import { useNavigation } from '~/context/Navigation'

const Route = ({ path, component: Component }) => {
  const { route } = useNavigation()

  if (path !== route) return null

  return <Component />
}

Route.propTypes = {
  path: string.isRequired,
  component: elementType.isRequired,
}

export default Route
