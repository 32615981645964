import { IRegionalLanguages } from './types'

export const languages: IRegionalLanguages = {
  en: 'en',
  pt: 'pt',
  es: 'es',
}

export const LOCAL_TRANSLATION_STORAGE_KEY = '@doris:i18n'

export const DORIS_CONFIG_STORAGE_KEY = '@doris:config'
