import { FC } from 'react'

import { useCurrentModel } from '~/hooks/useCurrentModel'
import { useMeasurements } from '~/hooks/useMeasurements'
import { usePartner } from '~/hooks/usePartner'
import { useTryon } from '~/hooks/useTryon'

import { IHistoryItem, useHistory } from '~/context/History'
import { useMeasurementsContext } from '~/context/Measurements'
import { useTryonContext } from '~/context/Tryon'

import { AnimationZoomOut } from '~/components/Animations'
import CombinedItemsButton from '~/components/CombinedItemsButton'
import { RedoButton } from '~/components/RedoButton'
import { SizingInfoButton } from '~/components/SizingInfoButton'
import { UndoButton } from '~/components/UndoButton'

import Tracking from '~/utils/tracking'

import { ISizingButtonInfoProps } from '../SizingInfoButton/types'
import { ButtonContainer, Container } from './styles'

type TAsideActionsContainerProps = {
  onHangerClick: () => void
  combinedItemsCount: number
  sizingInfoConfigs: ISizingButtonInfoProps
  showSizingButton?: boolean
  visibleButtons?: boolean
}

const AsideActionsContainer: FC<TAsideActionsContainerProps> = ({
  onHangerClick,
  combinedItemsCount,
  sizingInfoConfigs,
  showSizingButton,
  visibleButtons,
}) => {
  const { items, index, getPreviousItem, getNextItem } = useHistory()
  const { setTryon, startTryonUpscale, finishTryonUpscale } = useTryon()
  const { setCurrentTryonState, setTryonUpscaleState } = useTryonContext()
  const { setCurrentModel } = useCurrentModel()
  const { setMeasurementsState, clearStates } = useMeasurementsContext()
  const { startMeasurements, resetMeasurements } = useMeasurements()
  const { getPartner } = usePartner()

  const handleTryonUpscale = async (result: IHistoryItem) => {
    const { data: partner } = await getPartner()
    if (partner?.upscale) {
      finishTryonUpscale()

      if (!result?.upscaledImageUrl) {
        startTryonUpscale({
          tryonId: result.tryonId,
          setState: setTryonUpscaleState,
        })
      }
    }
  }

  const getHistory = async (action: 'UNDO' | 'REDO') => {
    const result = action === 'UNDO' ? getPreviousItem() : getNextItem()

    if (!result) return

    setCurrentModel(result.model)

    setTryon({
      data: {
        imageUrl: result.imageUrl,
        upscaledImageUrl: result?.upscaledImageUrl,
        tryonId: result.tryonId,
        origin: 'history',
        products: { top: result?.top, bottom: result?.bottom, full: result?.full },
      },
      setState: setCurrentTryonState,
    })

    handleTryonUpscale(result)

    if (result.model.type === 'SELF_MODEL') {
      resetMeasurements({ data: ['stateMeasurements', 'stateCurrentMeasurements'], setState: clearStates })

      startMeasurements({
        data: {
          avatar_uuid: result.model.id,
          products: [result?.top?.id, result?.bottom?.id, result?.full?.id].filter(Number) as Array<number>,
        },
        setState: setMeasurementsState,
      })
    }

    Tracking.logEvent(action, { avatar: result?.model, widget: true })
  }

  return (
    <Container data-testid="aside-actions-container">
      <ButtonContainer>
        <AnimationZoomOut visible={visibleButtons}>
          <CombinedItemsButton combinedItemsCount={combinedItemsCount} onClick={onHangerClick} />
        </AnimationZoomOut>
      </ButtonContainer>

      {showSizingButton && (
        <ButtonContainer>
          <AnimationZoomOut visible={visibleButtons}>
            <SizingInfoButton {...sizingInfoConfigs} />
          </AnimationZoomOut>
        </ButtonContainer>
      )}

      <ButtonContainer>
        <AnimationZoomOut visible={visibleButtons}>
          <RedoButton onClick={() => getHistory('REDO')} disabled={items.length <= index} />
        </AnimationZoomOut>
      </ButtonContainer>

      <ButtonContainer>
        <AnimationZoomOut visible={visibleButtons}>
          <UndoButton onClick={() => getHistory('UNDO')} disabled={index <= 1} />
        </AnimationZoomOut>
      </ButtonContainer>
    </Container>
  )
}

export default AsideActionsContainer
