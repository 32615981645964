import { historyStorageKey } from './constants'
import { THistoryStorageItem } from './types'

export const skuHasTryOn = (sku: string): boolean => {
  const storage = localStorage.getItem(historyStorageKey)

  if (!storage) {
    return false
  }

  const parsedStorage = JSON.parse(storage)

  return !!parsedStorage.find(
    (storageItem: THistoryStorageItem) =>
      storageItem?.top?.identifier === sku ||
      storageItem?.bottom?.identifier === sku ||
      storageItem?.full?.identifier === sku,
  )
}
