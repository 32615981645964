import { useContextSelector } from 'use-context-selector'

import { ITryonContext, TryonContext } from '.'

export const useTryonContext = (): ITryonContext => {
  const stateTryon = useContextSelector(TryonContext, state => state.stateTryon)
  const setTryonState = useContextSelector(TryonContext, state => state.setTryonState)
  const clearStates = useContextSelector(TryonContext, state => state.clearStates)
  const stateCurrentTryon = useContextSelector(TryonContext, state => state.stateCurrentTryon)
  const setCurrentTryonState = useContextSelector(TryonContext, state => state.setCurrentTryonState)
  const stateTryonUpscale = useContextSelector(TryonContext, state => state.stateTryonUpscale)
  const setTryonUpscaleState = useContextSelector(TryonContext, state => state.setTryonUpscaleState)

  return {
    stateTryon,
    stateCurrentTryon,
    setTryonState,
    setCurrentTryonState,
    stateTryonUpscale,
    setTryonUpscaleState,
    clearStates,
  }
}
