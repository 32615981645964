import { FC, useCallback, useEffect, useState } from 'react'

import { useCurrentModel } from '~/hooks'
import { useQuerySizing } from '~/hooks-queries'
import { useSubscriptionProcessingUpdateInfo } from '~/hooks/useAvatar/useSubscriptionProcessingUpdateInfo'

import { Backdrop } from '~/components/Backdrop'
import { CarouselContent } from '~/components/Backdrop/SelectSizingBackdrop/components/ListSizing/components/CarouselContent'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { Select } from '~/components/Select'
import { TextAndIconButton } from '~/components/TextAndIconButton'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { Sentry } from '~/clients/sentry'
import { chestOptions, heightOptions, hipOptions, waistOptions } from '~/screens/InformData/constants'
import { TOptionType } from '~/screens/SizingScreen/types'
import { translate } from '~/utils/translate'
import { TTranslateKey } from '~/utils/translate/types'

import * as Styled from './styles'
import { tabMap } from './constants'
import { ISetMeasurementsBackdrop, TFormData, TTab } from './types'

const FIELDS = [
  {
    name: 'height',
    label: 'HEIGHT' as TTranslateKey,
    options: heightOptions,
  },
  {
    name: 'chest',
    label: 'CHEST_MALE' as TTranslateKey,
    options: chestOptions,
  },
  {
    name: 'waist',
    label: 'WAIST' as TTranslateKey,
    options: waistOptions,
  },
  {
    name: 'hip',
    label: 'HIP' as TTranslateKey,
    options: hipOptions,
  },
]

const initialFormData = {
  waist: 0,
  hip: 0,
  height: 0,
  chest: 0,
}

export const SetMeasurementsBackdrop: FC<ISetMeasurementsBackdrop> = ({ visible, handleClose, onSuccessCallback }) => {
  const [formData, setFormData] = useState<TFormData>(initialFormData)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<TTab>(tabMap.MEASUREMENTS)

  const { setMeasurements } = useQuerySizing()
  const { getCurrentModel } = useCurrentModel()
  const { subscription: subscriptionUpdateAvatarInfos } = useSubscriptionProcessingUpdateInfo({
    avatarInfosOriginal: undefined,
  })

  const DESCRIPTION_TRANSLATE_KEY = `SET_MEASUREMENTS_BACKDROP_DESCRIPTION_${
    getCurrentModel().age_group
  }` as TTranslateKey

  const onChangeValue = ({ name, value }: { name: string; value: number }) => {
    setFormData({ ...formData, [name]: value })
  }

  const resetFormData = () => {
    setFormData(initialFormData)
  }

  const fillUserDataFromLocalStorage = () => {
    const savedData = localStorage.getItem('@doris:last-inform-data-values')

    if (savedData) {
      const savedDataParsed = JSON.parse(savedData)
      setFormData({ ...formData, height: savedDataParsed.height })
    }
  }

  const handleOnClose = () => {
    resetFormData()
    handleClose()
  }

  const onSucccess = () => {
    setIsLoading(false)
    onSuccessCallback()
    subscriptionUpdateAvatarInfos(formData, 'update')
    handleOnClose()
  }

  const onError = (errorName: string, errorMessage: string) => {
    setIsLoading(false)
    Sentry.captureException({
      errorName: errorName,
      errorMessage: errorMessage,
      filePath: 'src/modules/Sizing/components/SetMeasurementsBackdrop/index.tsx',
      functionName: 'onError',
    })
  }

  const onSubmit = () => {
    setIsLoading(true)
    setMeasurements({
      payload: { ...formData, avatar_uuid: getCurrentModel().id },
      callbackOnSuccess: onSucccess,
      callbackOnError: error => onError(error.name, error.message),
    })
  }

  const renderPrimaryButton = (): JSX.Element | undefined => {
    if (currentTab === tabMap.MEASUREMENTS) return undefined

    return (
      <IconButton
        icon={<Icon name="arrowLeft" size="15px" color={theme.colors.primary} />}
        onClick={() => setCurrentTab('MEASUREMENTS')}
        testID="set-measurements-back-button"
      />
    )
  }

  const isValidForm = useCallback(() => {
    return formData.chest !== 0 || formData.height !== 0 || formData.hip !== 0 || formData.waist !== 0
  }, [formData])

  useEffect(() => {
    fillUserDataFromLocalStorage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Backdrop
      testID="set-measurements-backdrop"
      title={translate('SET_MEASUREMENTS_BACKDROP_TITLE')}
      visible={visible}
      handleClose={handleOnClose}
      primaryButton={renderPrimaryButton()}
    >
      {currentTab === 'MEASUREMENTS' ? (
        <Styled.Container>
          <Styled.Description data-testid="set-measurements-description">
            {translate(DESCRIPTION_TRANSLATE_KEY)}.
          </Styled.Description>
          <Styled.Fieldset>
            {FIELDS.map(field => (
              <Styled.FieldContainer key={`field-${field.name}`}>
                <span>{translate(field.label)}</span>
                <Select
                  name={field.name}
                  initialOption="0"
                  options={field.options}
                  value={formData[field.name as TOptionType]}
                  onChange={value => onChangeValue({ name: field.name, value })}
                />
              </Styled.FieldContainer>
            ))}
          </Styled.Fieldset>
          <TextAndIconButton
            testID="how-to-measure-me-button"
            variant="phantom"
            icon="shape"
            onClick={() => setCurrentTab('TUTORIAL')}
          >
            {translate('HOW_TO_MEASURE_MYSELF')}
          </TextAndIconButton>
          <Styled.ActionContainer>
            <TextButton testID="set-measurements-cancel-button" onClick={handleOnClose} variant="outlined">
              {translate('CANCEL')}
            </TextButton>
            <TextButton testID="set-measurements-save-button" onClick={onSubmit} disabled={isLoading || !isValidForm()}>
              {translate('SAVE_MEASUREMENTS')}
            </TextButton>
          </Styled.ActionContainer>
        </Styled.Container>
      ) : (
        <CarouselContent />
      )}
    </Backdrop>
  )
}
