import { FC } from 'react'

import theme from '~/theme'

import * as Styled from './styles'
import { Area } from './components/Area'
import { IPhotoFeedbackArea } from './types'

export const PhotoFeedbackArea: FC<IPhotoFeedbackArea> = ({ children, hasOverlay, hideArea, areaColor }) => (
  <Styled.Container data-testid="photo-feedback-area-container">
    {hasOverlay && <Styled.Overlay data-testid="photo-feedback-area-overlay" />}
    {!hideArea && (
      <Area width="calc(100% - 50px)" height="calc(100% - 100px)" color={areaColor || theme.colors.white} />
    )}
    <Styled.Content data-testid="photo-feedback-area-content" width="auto" height="calc(100% - 80px)">
      {children && (
        <Styled.ChildrenWrapper data-testid="photo-feedback-area-children">{children}</Styled.ChildrenWrapper>
      )}
    </Styled.Content>
  </Styled.Container>
)
