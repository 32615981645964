import { Settings } from 'react-slick'

export const sliderSettings: Settings = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  arrows: false,
  speed: 400,
  variableWidth: true,
  edgeFriction: 0,
  waitForAnimate: true,
  touchThreshold: 7,
  focusOnSelect: process.env.REACT_APP_CI === 'true',
}
