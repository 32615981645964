import { ListSizing } from '~/components/Backdrop/SelectSizingBackdrop/components/ListSizing'
import { SliderSizing } from '~/components/Backdrop/SelectSizingBackdrop/components/SliderSizing'

import { TSelectSizingBackdropContentMap, TSelectSizingBackdropSettingsMap } from './types'

export const selectSizingBackdropContents: TSelectSizingBackdropContentMap = {
  slider: SliderSizing,
  list: ListSizing,
}

export const settings: TSelectSizingBackdropSettingsMap = {
  slider: {
    default: {
      title: 'SELECT_SIZING_BACKDROP_SLIDER_TITLE',
      height: '210px',
    },
    loading: {
      title: 'SELECT_SIZING_BACKDROP_SLIDER_TITLE_LOADING',
      height: '210px',
    },
  },
  list: {
    default: {
      title: 'SELECT_SIZING_BACKDROP_LIST_TITLE',
      height: '494px',
    },
    carousel: {
      title: 'SELECT_SIZING_BACKDROP_CAROUSEL_TITLE',
      height: '514px',
    },
  },
}
