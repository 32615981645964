import React from 'react'

import { TIconProps } from '.'

const Hanger: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 30 24"
      data-testid="icon-hanger"
      style={{
        width: size,
        height: 'auto',
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-2-5h34v34H-2z" />
        <path
          fill={color}
          fillRule="nonzero"
          d="m28.6 20.7833333-12.1833333-9.1375v-1.2891666c2.3375-.6941667 3.9666666-3.0741667 3.4425-5.73750003-.3683334-1.85583334-1.8416667-3.4-3.6975-3.825-3.23-.73666667-6.12 1.71416666-6.12 4.83083333H12.875c0-1.17583333.9491667-2.125 2.125-2.125 1.1758333 0 2.125.94916667 2.125 2.125 0 1.19-.9775 2.1533333-2.1675 2.125-.765-.0141667-1.3741667.6375-1.3741667 1.4025v2.4933333L1.4 20.7833333c-1.09083333.8216667-.51 2.55.85 2.55h25.5c1.36 0 1.9408333-1.7283333.85-2.55ZM6.5 20.5l8.5-6.375 8.5 6.375h-17Z"
        />
      </g>
    </svg>
  )
}

export default Hanger
