import { bool, func, oneOf, string } from 'prop-types'

import { Icon } from '~/components/Icon'

import theme from '~/theme'

import { Button, Container } from './styles'

const Arrow = ({ direction, onClick, disabled, testID, className }) => (
  <Container
    className={`${testID}-arrow-${direction} ${className}`}
    data-testid={`${testID}-arrow-${direction}`}
    direction={direction}
  >
    <Button className="" data-testid={`${testID}-arrow-${direction}-button`} disabled={disabled} onClick={onClick}>
      <Icon
        name={direction === 'left' ? 'arrowLeft' : 'arrowRight'}
        size={'14px'}
        color={disabled ? '#A6A6A6' : theme.colors.primary}
      />
    </Button>
  </Container>
)

Arrow.propTypes = {
  testID: string,
  direction: oneOf(['left', 'right']).isRequired,
  onClick: func.isRequired,
  disabled: bool,
  className: string,
}

Arrow.defaultProps = {
  testID: 'carousel',
  disabled: false,
  className: '',
}

export default Arrow
