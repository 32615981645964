import { FC } from 'react'

import { Backdrop } from '~/components/Backdrop'
import { IBackdropSizingProps } from '~/components/BackdropSizing/types'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import * as Styled from './styles'

export const BackdropSizing: FC<IBackdropSizingProps> = ({
  content = translate('BACKDROP_SIZING_CONTENT'),
  outlinedButtonClick,
  outlinedButtonText = translate('LATER'),
  primaryButtonClick,
  primaryButtonText = translate('REMAKE_PHOTOS_BUTTON'),
  title = translate('BACKDROP_SIZING_TITLE'),
  visible,
  testID = 'backdrop-sizing',
}) => (
  <Backdrop visible={visible} testID={testID}>
    <Styled.Container>
      <Styled.ContainerIcon>
        <IconButton
          backgroundColor={theme.colors.primary}
          borderColor="transparent"
          data-testid={`${testID}-icon-button`}
          icon={<Icon name="tape" size="22px" data-testid={`${testID}-icon`} />}
          size={40}
        />
      </Styled.ContainerIcon>

      <Styled.TitleSizing data-testid={`${testID}-title`} align="center">
        {title}
      </Styled.TitleSizing>

      <Styled.Text align="center" data-testid={`${testID}-content`}>
        {content}
      </Styled.Text>

      <Styled.ContainerButton>
        <TextButton variant="outlined" testID={`${testID}-outlined-button`} onClick={outlinedButtonClick}>
          {outlinedButtonText}
        </TextButton>

        <TextButton testID={`${testID}-primary-button`} onClick={primaryButtonClick}>
          {primaryButtonText}
        </TextButton>
      </Styled.ContainerButton>
    </Styled.Container>
  </Backdrop>
)
