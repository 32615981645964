import { createContext } from 'use-context-selector'

import { IProductCombine, TSizeOptions } from './types'

export interface ICombinedItemsContext {
  selectedProducts: IProductCombine[] | []
  handleSelectProduct: (product: IProductCombine) => void
  handleRemoveProduct: (productId: number) => void
  handleSetSelectedProducts: (products: IProductCombine[]) => void
  handleSelectProductSize: (options: TSizeOptions[]) => void
  handleRemoveProductSize: (identifier: string) => void
  selectedSizes: { [key: string]: string }
}

export const CombinedItemsContext = createContext<ICombinedItemsContext>({} as ICombinedItemsContext)
