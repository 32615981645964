import { FC } from 'react'

import * as Styled from './styles'
import { ISizingLabelProps } from './types'

export const SizingLabel: FC<ISizingLabelProps> = ({ size, color, strike, children }) => (
  <Styled.SizeLabel data-testid="sizing-label" size={size} color={color} strike={strike}>
    {children}
  </Styled.SizeLabel>
)
