import styled from 'styled-components'

import { Paragraph, Title as TitleTypography } from '~/components/Typography/styles'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 4;
`

export const WrapperContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 20px;
`

export const WrapperFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
`

export const Title = styled(TitleTypography)`
  font-weight: 700;
  font-size: 16px;
`

export const Emoji = styled(Paragraph)`
  font-size: 80px;
  height: 44px;
  margin-bottom: 16px;
`

export const Text = styled(Paragraph)`
  margin: 16px 0 68px 0;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
`
