import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const CategoriesSkeletonLoader = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 64px;
  padding: 0 15px;
`

export const ProductsSkeletonLoader = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 106px;
  padding: 0 15px;
`

export const ContentActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`
