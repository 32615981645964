import styled, { css, keyframes } from 'styled-components'

import { hexToRgba } from '~/utils/colors'

import { IAnimation } from './types'

const animation = keyframes`
    0% {
      transform: scale(1);
    }

    10% {
      transform: scale(1.4);
    }

    20% {
      transform: scale(1);
    }

    100% {
      transform: scale(1);
    }
`

export const Container = styled.div`
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Animation = styled.div<IAnimation>`
  z-index: -1;
  position: absolute;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;

  background-color: ${({ color, theme }) => `rgba(${hexToRgba(color || theme.colors.white, 0.5)})`};
  opacity: ${({ startAnimation }) => (startAnimation ? '1' : '0')};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: ${({ borderRadius }) => borderRadius};

  ${({ startAnimation }) =>
    startAnimation &&
    css`
      animation-name: ${animation};
    `};
`
