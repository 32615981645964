import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { PoseDetector } from '@tensorflow-models/pose-detection'
import '@tensorflow/tfjs-backend-webgl'

import { Sentry } from '~/clients/sentry'
import { isNewiPhone } from '~/utils/isNewiPhone'
import { removeMediaStreamTrack } from '~/utils/removeMediaStreamTrack'

import * as Styled from './styles'
import { ErrorMessages } from './constants'
import { ICameraHandler, ICameraProps, TFacingMode, TStream } from './types'
import { drawImageToCanvas } from './utils/drawImageToCanvas'
import { MovenetDetector } from './utils/movenet-detector'

const poseTimerCapture = 500

const CAMERA_VIDEO_WIDTH = parseInt(process.env.REACT_APP_CAMERA_VIDEO_WIDTH as string) || 2048
const LOG_CAMERA_IMAGE_WIDTH = 320

const Component: ForwardRefRenderFunction<ICameraHandler, ICameraProps> = (
  {
    facingMode = 'user',
    numberOfCamerasCallback = () => null,
    onError = () => null,
    onPoseChange = () => ({}),
    runDetector = false,
    deviceCameras,
    initDetector = false,
  },
  ref,
) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const detectorRef = useRef<PoseDetector>()
  const intervalRef = useRef<NodeJS.Timer>()

  const [numberOfCameras, setNumberOfCameras] = useState(0)
  const [stream, setStream] = useState<TStream>(null)
  const [currentFacingMode, setFacingMode] = useState<TFacingMode>(facingMode)

  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(undefined)

  const CAMERA_OBJECT_FIT = isNewiPhone() ? 'contain' : 'cover'

  const handleSuccess = async (stream: TStream) => {
    const mediaDeviceInfo = await navigator.mediaDevices.enumerateDevices()
    const { length: camerasCount } = mediaDeviceInfo.filter(({ kind }) => kind === 'videoinput')

    numberOfCamerasCallback(camerasCount)
    setNumberOfCameras(camerasCount)
    setStream(stream)
  }

  const handleError = (error: Error) => {
    // eslint-disable-next-line no-console
    console.error(error.message)

    if (error.name === 'NotAllowedError') {
      onError(ErrorMessages.permissionDenied)

      return
    }

    onError(ErrorMessages[error.message])
  }

  const initCameraStream = () => {
    const frontCameraDeviceID = deviceCameras?.user ? deviceCameras.user : undefined
    const backCameraDeviceID = deviceCameras?.environment ? deviceCameras.environment : undefined
    const constraints = {
      audio: false,
      video: {
        deviceId:
          facingMode === 'user'
            ? {
                exact: frontCameraDeviceID,
              }
            : { exact: backCameraDeviceID },
        facingMode: currentFacingMode,
        width: { ideal: CAMERA_VIDEO_WIDTH },
      },
    }

    const getWebcam =
      navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia

    removeMediaStreamTrack(stream)

    if (navigator?.mediaDevices?.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => handleSuccess(stream))
        .catch(err => {
          Sentry.captureException({
            errorName: 'GET_USER_MEDIA_ERROR',
            errorMessage: 'getUserMedia error',
            filePath: 'src/components/Camera/index.tsx',
            functionName: 'getUserMedia',
          })
          handleError(err as Error)
        })

      return
    }

    if (getWebcam) {
      getWebcam(
        constraints,
        stream => handleSuccess(stream),
        err => {
          Sentry.captureException({
            errorName: 'GET_WEBCAM_STREAM_ERROR',
            errorMessage: 'getWebcam error',
            filePath: 'src/components/Camera/index.tsx',
            functionName: 'getWebcam',
          })
          handleError(err as Error)
        },
      )

      return
    }

    handleError(new Error('noCameraAccessible'))
  }

  useImperativeHandle(ref, () => ({
    takePhoto: () => {
      if (!videoRef?.current || !canvasRef.current || !canvasRef.current.getContext('2d')) {
        return handleError(new Error('canvas'))
      }

      const canvas = drawImageToCanvas({ video: videoRef.current, width: 1280, mirrored: facingMode === 'user' })

      return canvas.toDataURL('image/jpeg')
    },

    switchCamera: () => {
      setActiveDeviceId(undefined)

      const newFacingMode = currentFacingMode === 'user' ? 'environment' : 'user'

      if (numberOfCameras < 2) {
        handleError(new Error('switchCamera'))

        return
      }

      setFacingMode(newFacingMode)

      return newFacingMode
    },

    getNumberOfCameras: () => numberOfCameras,
  }))

  const initializeDetector = useCallback(async () => {
    if (detectorRef.current) return

    detectorRef.current = await MovenetDetector.initialize()
  }, [])

  const startDetector = useCallback(async (): Promise<boolean> => {
    const firstPose = await detectorRef.current?.estimatePoses(videoRef.current as HTMLVideoElement)

    return !!firstPose?.length
  }, [])

  const getPose = useCallback(async () => {
    if (!videoRef.current || !detectorRef.current || !MovenetDetector.isReady) return

    const poses = await detectorRef.current.estimatePoses(videoRef.current)
    const canvas = drawImageToCanvas({
      video: videoRef.current,
      width: LOG_CAMERA_IMAGE_WIDTH,
      mirrored: facingMode === 'user',
    })
    const imageBase64 = canvas.toDataURL('image/jpeg')

    onPoseChange(poses, videoRef.current.videoHeight, videoRef.current.videoWidth, imageBase64)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPoseChange])

  useEffect(() => {
    clearInterval(intervalRef.current as NodeJS.Timer)

    if (!runDetector) return

    intervalRef.current = setInterval(getPose, poseTimerCapture)

    return () => clearInterval(intervalRef.current as NodeJS.Timer)
  }, [getPose, runDetector])

  useEffect(() => {
    if (!initDetector) return

    const intervalDetector = setInterval(async () => {
      if (MovenetDetector.isDownloading) return

      initializeDetector()

      const hasStarted = await startDetector()

      if (!hasStarted) return

      MovenetDetector.isReady = true

      clearInterval(intervalDetector as NodeJS.Timer)
    }, 500)

    return () => clearInterval(intervalDetector as NodeJS.Timer)
  }, [initDetector, initializeDetector, startDetector])

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream
    }

    return () => {
      removeMediaStreamTrack(stream)
    }
  }, [stream])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initCameraStream, [currentFacingMode, activeDeviceId])

  return (
    <Styled.Container data-testid="camera-container">
      <Styled.Wrapper>
        <Styled.Cam
          isFrontMode={facingMode === 'user'}
          ref={videoRef}
          data-testid="video"
          id="video"
          muted
          autoPlay
          playsInline
          objectFit={CAMERA_OBJECT_FIT}
        />
        <Styled.Canvas ref={canvasRef} />
      </Styled.Wrapper>
    </Styled.Container>
  )
}

export const Camera = forwardRef(Component)
