import { FC } from 'react'

import { TIconProps } from '.'

export const Locked: FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      data-testid="icon-locked"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <path
        d="M9.96935 9.90826H8.64087H8.64054C8.36939 9.90826 8.09691 9.94023 7.83028 10.003L7.49157 10.0829V7.85482C7.49157 5.17756 9.73816 3 12.4997 3C15.2612 3 17.5078 5.17772 17.5078 7.85482V10.0829L17.1691 10.003C16.9025 9.94036 16.63 9.90826 16.3586 9.90826H15.0301V7.85459C15.0301 6.50167 13.8949 5.40154 12.4999 5.40154C11.1042 5.40154 9.96935 6.5018 9.96935 7.85459V9.90826ZM8.64118 10.3861H16.3592L16.3591 10.3862C18.094 10.3862 19.5003 11.7494 19.5 13.4308V17.6507C19.5 19.5005 17.9531 21 16.0449 21H8.95506C7.04686 21 5.5 19.5004 5.5 17.6507V13.4311C5.5 11.7494 6.9063 10.3861 8.64118 10.3861ZM13.695 17.8101V16.0531H13.695C14.2216 15.6903 14.5659 15.0946 14.5659 14.4204C14.5659 13.3145 13.641 12.4173 12.5002 12.4173C11.3593 12.4173 10.4344 13.3145 10.4344 14.4204C10.4344 15.0944 10.7784 15.6905 11.3048 16.0531V17.8101C11.3048 18.4475 11.8431 18.9684 12.5001 18.9684C13.1573 18.9684 13.695 18.4471 13.695 17.8101Z"
        fill={color}
      />
    </svg>
  )
}
