import styled from 'styled-components'

import { Paragraph, Prefix } from '~/components/Typography/styles'

export const Container = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 360px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const LabelSubtotal = styled(Prefix)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};

  @media (max-width: 375px) {
    font-size: 10px;
  }
`

export const LabelMessage = styled(LabelSubtotal)`
  @media (max-width: 425px) {
    font-size: 10px;
  }
`

export const Price = styled(Paragraph)`
  font-weight: 600;
  margin-left: 5px;

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 360px) {
    margin: 0;
  }
`
