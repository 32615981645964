import theme from '~/theme'

import { translate } from '~/utils/translate'

import { TSizingBodyPartMap, TSizingTagMapItem, TSizingTagsMap } from './types'

export const sizingTagMessagesMap: TSizingTagMapItem = {
  TOO_SMALL: translate('SIZING_TAG_TOO_SMALL'),
  TIGHT: translate('SIZING_TAG_TIGHT'),
  SOME_TIGHT: translate('SIZING_TAG_SOME_TIGHT_DESCRIPTION'),
  FIT: translate('SIZING_TAG_FIT_PERFECT'),
  SOME_LOOSE: translate('SIZING_TAG_SOME_LOOSE_DESCRIPTION'),
  LOOSE: translate('SIZING_TAG_LOOSE'),
  TOO_BIG: translate('SIZING_TAG_TOO_BIG'),
}

export const sizingBodyPartMap: TSizingBodyPartMap = {
  chest: {
    MALE: translate('CHEST_MALE'),
    FEMALE: translate('CHEST_FEMALE'),
  },
  waist: {
    MALE: translate('WAIST'),
    FEMALE: translate('WAIST'),
  },
  hip: {
    MALE: translate('HIP'),
    FEMALE: translate('HIP'),
  },
}

export const sizingTagMessagesColorsMap: TSizingTagMapItem = {
  TOO_SMALL: theme.colors.status.error,
  TIGHT: theme.colors.status.error,
  SOME_TIGHT: theme.colors.status.warning,
  FIT: theme.colors.status.success,
  SOME_LOOSE: theme.colors.status.warning,
  LOOSE: theme.colors.status.error,
  TOO_BIG: theme.colors.status.error,
}

export const tagsMap: TSizingTagsMap = {
  labels: sizingBodyPartMap,
  messages: sizingTagMessagesMap,
  colors: sizingTagMessagesColorsMap,
}
