import { FC } from 'react'

import { IIconProps } from '~/components/Icon/types'

import theme from '~/theme'

import { iconMap } from '~/libs/icons'

export const Icon: FC<IIconProps> = ({ name, color = theme.colors.white, inverse = false, size = '24px', rotate }) => {
  const IconComponent = iconMap[name as keyof typeof iconMap]

  return <IconComponent color={color} inverse={inverse} size={size} rotate={rotate} />
}
