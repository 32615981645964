import { useContextSelector } from 'use-context-selector'

import { IMeasurementsContext, MeasurementsContext } from '.'

export const useMeasurementsContext = (): IMeasurementsContext => {
  const stateMeasurements = useContextSelector(MeasurementsContext, state => state.stateMeasurements)
  const setMeasurementsState = useContextSelector(MeasurementsContext, state => state.setMeasurementsState)
  const clearStates = useContextSelector(MeasurementsContext, state => state.clearStates)
  const stateCurrentMeasurements = useContextSelector(MeasurementsContext, state => state.stateCurrentMeasurements)
  const setCurrentMeasurementsState = useContextSelector(
    MeasurementsContext,
    state => state.setCurrentMeasurementsState,
  )

  return {
    stateMeasurements,
    stateCurrentMeasurements,
    setMeasurementsState,
    setCurrentMeasurementsState,
    clearStates,
  }
}
