import React, { FC } from 'react'

import { TListSizingOption } from '~/components/Backdrop/SelectSizingBackdrop/components/ListSizing/types'
import { getSortByOrder } from '~/components/Backdrop/SelectSizingBackdrop/helpers'
import Box from '~/components/Box'
import { Select } from '~/components/Select'
import { TextAndIconButton } from '~/components/TextAndIconButton'
import { TextButton } from '~/components/TextButton'
import Typography from '~/components/Typography'

import { optionsMap } from '~/screens/InformData/constants'
import { TOptionType } from '~/screens/SizingScreen/types'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { IEditContent } from './types'

export const EditContent: FC<IEditContent> = ({
  data,
  onInnerContentChange,
  onShowAlert,
  onHandleSelectChange,
  formData,
  onUpdateAvatarMeasurements,
  formChanged,
}) => {
  const isReset = !!data.current?.filter(item => item.value).length

  const currentOptions = Object.keys(formData).map(key => {
    const originalOption = data.original?.find(item => item.name === key)
    const currentOption = data.current.find(item => item.name === key)

    const option: Partial<TListSizingOption> = {
      name: key,
      label: originalOption?.label,
      unit: originalOption?.unit,
      order: originalOption?.order,
      value: formData[key] || currentOption?.value,
    }

    if (!option.value) {
      option.value = originalOption?.value
    }

    return {
      ...option,
      list: optionsMap[key as TOptionType],
    }
  })

  getSortByOrder(currentOptions as TListSizingOption[])

  const getLabel = (label?: string) => {
    if (!label) return null

    if (!label.includes('/')) return label

    const [firstLabel, secondLabel] = label.split('/')
    return (
      <>
        {firstLabel}/ <br />
        {secondLabel}
      </>
    )
  }

  return (
    <Styled.Container data-testid="container-edit-sizing">
      <Box padding="10px 0px 0px 0px">
        <Typography type="paragraph" align="center">
          {translate('BACKDROP_MEASUREMENTS_DESCRIPTION')}
        </Typography>
      </Box>
      <Styled.List>
        {currentOptions.map(item => (
          <Styled.ListItem key={item.label} data-testid="container-list-sizing-item">
            <Styled.ListItemLabel>{getLabel(item.label)}</Styled.ListItemLabel>
            <Styled.ListItemValue>
              <Select
                name={item.name}
                initialOption={item.value as unknown as string}
                options={item.list}
                value={item.value as number}
                onChange={value => onHandleSelectChange(item.name as string, value)}
              />
            </Styled.ListItemValue>
          </Styled.ListItem>
        ))}
      </Styled.List>

      <Styled.WrapperButton>
        <TextAndIconButton
          testID="how-to-measure-me-button"
          variant="phantom"
          icon="shape"
          onClick={() => onInnerContentChange('carousel')}
        >
          {translate('HOW_TO_MEASURE_MYSELF')}
        </TextAndIconButton>
      </Styled.WrapperButton>

      <Styled.FooterButtons>
        <TextButton
          testID="reset-measurements-button"
          disabled={!isReset}
          variant="outlined"
          onClick={() => onShowAlert('reset')}
        >
          {translate('RESET_MEASUREMENTS')}
        </TextButton>

        <TextButton testID="save-measurements-button" disabled={!formChanged} onClick={onUpdateAvatarMeasurements}>
          {translate('SAVE_MEASUREMENTS')}
        </TextButton>
      </Styled.FooterButtons>
    </Styled.Container>
  )
}
