import styled, { css } from 'styled-components'

import { ITabDefaultContentProps } from '.'

const Container = styled.div<Pick<ITabDefaultContentProps, 'active' | 'index'>>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: ${({ index }) => index};
  opacity: ${({ active }) => (active ? 1 : 0)};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};

  ${({ active }) =>
    !active &&
    css`
      overflow: hidden;
    `};
`

export { Container }
