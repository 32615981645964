export type TErrorType = 'InvalidFormat' | 'default'

const processingFailureErrors = {
  default: {
    title: 'PROCESSING_FAILURE_DEFAULT_TITLE',
    description: 'PROCESSING_FAILURE_DEFAULT_DESCRIPTION',
  },
  InvalidFormat: {
    title: 'PROCESSING_FAILURE_INVALID_FORMAT_TITLE',
    description: 'PROCESSING_FAILURE_INVALID_FORMAT_DESCRIPTION',
  },
}

export const getContent = (error?: TErrorType) => {
  if (!error || !processingFailureErrors[error]) return processingFailureErrors['default']
  return processingFailureErrors[error]
}
