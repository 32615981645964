import React from 'react'

import { TIconProps } from '..'
import { Svg } from './styles'

const Spinner: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <Svg xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 28 28" data-testid="icon-spinner" size={size}>
      <defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-22.000000, -70.000000)">
            <g transform="translate(7.000000, 55.000000)">
              <g>
                <g transform="translate(15.199979, 15.000000)">
                  <g>
                    <g>
                      <path
                        d="M9.32206068,0.662240225 L11.168405,0 L12.4933359,3.69143343 L10.6469915,4.35367365 C6.64737955,5.78824025 3.92318639,9.5953542 3.92318639,13.9199295 C3.92318639,19.5306971 8.46659793,24.0781472 14.0699744,24.0781472 C18.4147608,24.0781472 22.2357244,21.3188883 23.6491427,17.2798698 L24.2968623,15.4289303 L28,16.7239293 L27.3522804,18.5748687 C25.3926334,24.1748035 20.095582,28 14.0699744,28 C6.29880229,28 0,21.6955988 0,13.9199295 C0,7.9276969 3.77521113,2.6517645 9.32206068,0.662240225 Z"
                        id="spinner-icon"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </defs>
      <use xlinkHref="#spinner-icon" fill={color} fillRule="evenodd" />
    </Svg>
  )
}

export default Spinner
