/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef } from 'react'

import { Container } from './styles'

export interface ITabDefaultContentProps {
  index: number
  active?: boolean
  onActive?: (index: number) => void
  onInactive?: (index: number) => void
}

const TabDefaultContent: FC<ITabDefaultContentProps> = ({ children, index, active, onActive, onInactive }) => {
  const isRendered = useRef<boolean>(false)

  useEffect(() => {
    if (active) {
      onActive && onActive(index)
      isRendered.current = true
      return
    }

    if (isRendered.current && !active) {
      onInactive && onInactive(index)
    }
  }, [active])

  return (
    <Container data-testid="tab-default-content" active={active} index={index}>
      {children}
    </Container>
  )
}

export default TabDefaultContent
