import { FC, useEffect, useState } from 'react'

import { useNewsletter } from '~/hooks/useNewsletter'

import { useHistory } from '~/context/History'

import { NewsletterBackdrop } from '~/components/NewsletterBackdrop'
import { SnackBar } from '~/components/SnackBar'

import Tracking from '~/utils/tracking'

import { INewsletterContainerProps } from './types'

export const NewsletterContainer: FC<INewsletterContainerProps> = ({ blockingState, onClickSnackBar }) => {
  const [visibleSnackBar, setVisibleSnackBar] = useState<boolean>(false)
  const [isNewsletterBackDropVisible, setIsNewsletterBackDropVisible] = useState<boolean>(false)
  const { isLoading, signUp, storeFlag, hasFlag } = useNewsletter()
  const { items } = useHistory()

  const onCloseNewsletter = () => {
    setIsNewsletterBackDropVisible(false)
  }

  const onCloseSnackBar = () => {
    storeFlag()
    setVisibleSnackBar(false)
  }

  const openNewsletterBackdrop = () => {
    onClickSnackBar()
    setIsNewsletterBackDropVisible(true)
    Tracking.logEvent('ACCOUNT_OPEN', { widget: true })
  }

  useEffect(() => {
    const userInteractedWithNewsletter = hasFlag()

    if (items.length >= 3 && !visibleSnackBar && !userInteractedWithNewsletter && !blockingState) {
      setVisibleSnackBar(true)
      Tracking.logEvent('ACCOUNT_SNACK', { widget: true })
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, blockingState, visibleSnackBar])

  return (
    <>
      <SnackBar onClose={onCloseSnackBar} visible={visibleSnackBar} onClick={openNewsletterBackdrop} />
      <NewsletterBackdrop
        loading={isLoading}
        visible={isNewsletterBackDropVisible}
        handleClose={onCloseNewsletter}
        onSubmitEmail={signUp}
        onSuccess={storeFlag}
      />
    </>
  )
}
