import { TAnimationsOptionsMap } from './types'

export const tabOrder = ['TOP', 'BOTTOM', 'FULL', 'MEASUREMENTS']

export const tagOrder = ['chest', 'waist', 'hip']

export const animationsTime: TAnimationsOptionsMap = {
  open: 250,
  close: 600,
}

export const categoryKeyMap: { TOP: 'top'; BOTTOM: 'bottom'; FULL: 'full' } = {
  TOP: 'top',
  BOTTOM: 'bottom',
  FULL: 'full',
}
