import { FC } from 'react'

import { Backdrop } from '~/components/Backdrop'
import { TextButton } from '~/components/TextButton'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { IBackdropResetMeasurements } from './types'

export const BackdropResetMeasurements: FC<IBackdropResetMeasurements> = ({
  visibleBackdrop,
  closeBackdrop,
  onClick,
}) => (
  <Backdrop layerIndex={5} visible={visibleBackdrop} testID="backdrop-reset-measurements" withBackground>
    <Styled.Title align="center">{translate('BACKDROP_RESET_MEASUREMENTS_TITLE')}</Styled.Title>

    <Styled.Text align="center">{translate('BACKDROP_RESET_MEASUREMENTS_DESCRIPTION')}</Styled.Text>

    <Styled.Footer>
      <TextButton
        textTransform="uppercase"
        variant="outlined"
        testID="cancel-button"
        onClick={() => closeBackdrop(null)}
      >
        {translate('CANCEL')}
      </TextButton>

      <TextButton textTransform="uppercase" testID="restart-measurements-button" onClick={onClick}>
        {translate('RESET')}
      </TextButton>
    </Styled.Footer>
  </Backdrop>
)
