import { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'

import { IAutomix } from '~/entities'

import { GET_AUTOMIX, IQueryAutomix, IQueryData, IQueryVars, TFetchParams } from './'

export const useQueryAutomix = (): IQueryAutomix => {
  const [fetchAutomix] = useLazyQuery<IQueryData, IQueryVars>(GET_AUTOMIX)

  const fetchCombination = useCallback(
    (params: TFetchParams): Promise<IAutomix | null> =>
      new Promise(async (resolve, reject) => {
        const variables: IQueryVars = {
          limit: 1,
          where: {
            id: { _nin: params.filteredCombinations },
            productByBottomProductId: {
              _or: [{ gender: { _eq: params.gender } }, { gender: { _eq: 'UNISEX' } }],
              active: { _eq: true },
              product_options: { has_stock: { _eq: true } },
            },
            productByTopProductId: {
              _or: [{ gender: { _eq: params.gender } }, { gender: { _eq: 'UNISEX' } }],
              active: { _eq: true },
              product_options: { has_stock: { _eq: true } },
            },
          },
        }

        const { data, error } = await fetchAutomix({ variables })

        if (error) reject(error)

        const combinationExists = data && data.automix && data.automix.length > 0

        const response: IAutomix | null = combinationExists
          ? data?.automix?.map(combination => ({
              id: combination.id,
              products: {
                top: combination.top,
                bottom: combination.bottom,
              },
            }))[0]
          : null

        resolve(response)
      }),
    [fetchAutomix],
  )

  return {
    fetchCombination,
  }
}
