import { FC } from 'react'

import * as Styled from './styles'
import { IAnimationZoomOutProps } from './types'

export const AnimationZoomOut: FC<IAnimationZoomOutProps> = ({ children, visible }) => {
  return (
    <Styled.Container data-testid="animation-zoom-out" visible={visible}>
      {children}
    </Styled.Container>
  )
}
