import { IReactTooltip } from './types'

const invertPlace = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
}

export const translateAnimationPlace = {
  top: 'translate3d(0px, 20px, 0px)',
  bottom: 'translate3d(0px, -20px, 0px)',
  left: 'translate3d(20px, 0px, 0px)',
  right: 'translate3d(-20px, 0px, 0px)',
}

export const getArrowPlace = (values: IReactTooltip): string =>
  `${invertPlace[values.place]}: ${values.arrowWidth ? `${-(values.arrowWidth / 2)}px` : '-10px'} !important`
