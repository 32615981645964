import { FC } from 'react'

import { TIconProps } from '.'

export const Balance: FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 15 16"
      data-testid="icon-balance"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <path
        fill={color}
        d="M10.3 14H4.7a.2.2 0 0 1-.2-.2v-.6a.2.2 0 0 1 .133-.188l2.8-1a.2.2 0 0 1 .134 0l2.8 1a.2.2 0 0 1 .133.188v.6a.2.2 0 0 1-.2.2Zm-5.4-.4h5.2v-.26l-2.6-.928-2.6.929v.259Z"
      />
      <path
        fill={color}
        d="M7.5 12.4a.2.2 0 0 1-.2-.2V5.6a.2.2 0 1 1 .4 0v6.6a.2.2 0 0 1-.2.2ZM7.5 3.2a.2.2 0 0 1-.2-.2v-.8a.2.2 0 1 1 .4 0V3a.2.2 0 0 1-.2.2Z"
      />
      <path
        fill={color}
        d="M7.5 5.6c-.772 0-1.4-.628-1.4-1.4 0-.772.628-1.4 1.4-1.4.772 0 1.4.628 1.4 1.4 0 .772-.628 1.4-1.4 1.4Zm0-2.4a1.001 1.001 0 0 0 0 2 1.001 1.001 0 0 0 0-2ZM3.7 10.4c-1.343 0-2.2-1.445-2.2-2.2 0-.11.09-.2.2-.2h4c.11 0 .2.09.2.2 0 .755-.857 2.2-2.2 2.2Zm-1.775-2c.133.597.795 1.6 1.775 1.6.98 0 1.642-1.003 1.775-1.6h-3.55Z"
      />
      <path
        fill={color}
        d="M6.3 4.4H1.7a.2.2 0 1 1 0-.4h4.6a.2.2 0 1 1 0 .4ZM13.3 4.4H8.7a.2.2 0 1 1 0-.4h4.6a.2.2 0 1 1 0 .4Z"
      />
      <path fill="#00A2FF" d="M1.7 8.4a.2.2 0 0 1-.179-.29l2-4a.2.2 0 1 1 .358.18l-2 4a.2.2 0 0 1-.18.11Z" />
      <path
        fill={color}
        d="M5.7 8.4a.2.2 0 0 1-.179-.11l-2-4a.2.2 0 0 1 .358-.18l2 4a.2.2 0 0 1-.179.29ZM11.3 10.4c-1.343 0-2.2-1.445-2.2-2.2 0-.11.09-.2.2-.2h4c.11 0 .2.09.2.2 0 .755-.857 2.2-2.2 2.2Zm-1.775-2c.133.597.795 1.6 1.775 1.6.98 0 1.641-1.003 1.775-1.6h-3.55Z"
      />
      <path fill={color} d="M9.3 8.4a.2.2 0 0 1-.179-.29l2-4a.2.2 0 0 1 .358.18l-2 4a.2.2 0 0 1-.18.11Z" />
      <path fill={color} d="M13.3 8.4a.2.2 0 0 1-.179-.11l-2-4a.2.2 0 1 1 .358-.18l2 4a.2.2 0 0 1-.179.29Z" />
    </svg>
  )
}
