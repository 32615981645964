import { IDrawImageToCanvasParams } from './types'

export const drawImageToCanvas = ({
  video,
  width = 1280,
  mirrored = false,
}: IDrawImageToCanvasParams): HTMLCanvasElement => {
  const canvas = document.createElement('canvas')

  canvas.width = width
  canvas.height = (width / video.videoWidth) * video.videoHeight

  const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D

  if (mirrored) {
    canvasContext.translate(canvas.width, 0)
    canvasContext.scale(-1, 1)
  }

  canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height)

  return canvas
}
