import { FC } from 'react'

import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'

import theme from '~/theme'

import * as Styled from './styles'
import { IModelAssignmentsProps } from './types'

export const ModelAssignments: FC<IModelAssignmentsProps> = ({ modelHeight, modelWeight }) => {
  return (
    <Styled.WrapperWeightAndHeight data-testid={'model-assignments'}>
      {modelHeight && (
        <>
          <IconButton
            testID="height-button"
            size={24}
            backgroundColor={theme.colors.white}
            icon={<Icon name="height" color={theme.colors.primary} size="14px" />}
          />

          <Styled.TextWeightAndHeight color={theme.colors.white} align="center">
            {modelHeight}
          </Styled.TextWeightAndHeight>
        </>
      )}

      {modelWeight && (
        <>
          <IconButton
            testID="undo-button"
            size={24}
            backgroundColor={theme.colors.white}
            icon={<Icon name="balance" color={theme.colors.primary} size="14px" />}
          />

          <Styled.TextWeightAndHeight color={theme.colors.white} align="center">
            {modelWeight}
          </Styled.TextWeightAndHeight>
        </>
      )}
    </Styled.WrapperWeightAndHeight>
  )
}
