import React from 'react'

import { TIconProps } from '.'

const Jumpsuit: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 16 36"
      data-testid="icon-jumpsuit"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M14.64 19.19V19.19C12.3398 18.9597 10.4377 17.2983 9.89999 15.05C9.80999 14.46 10.05 14.39 10.05 14.39H10.81C11.02 14.39 11.07 14.76 11.12 15.03C11.12 15.03 11.12 15.03 11.12 15.09V15.09V15.09C11.6065 16.5934 12.8766 17.7101 14.43 18C14.29 17.24 14.14 16.55 14 15.92V15.92L13.93 15.65C13.87 15.39 13.81 15.14 13.75 14.9L13.61 14.41C13.4265 13.8839 13.355 13.3253 13.4 12.77C13.4 11.13 14 9.77 14 8.39V8.39V7.2C14.0001 6.97484 13.8721 6.76922 13.67 6.67C12.7534 6.27441 12.0531 5.50117 11.75 4.55C11.7032 4.40372 11.7292 4.24388 11.82 4.12C12.5161 3.08142 12.8661 1.84941 12.82 0.6C12.82 0.268629 12.5514 0 12.22 0C11.8886 0 11.62 0.268629 11.62 0.6C11.6725 1.5756 11.4209 2.54344 10.9 3.37C10.805 3.51466 10.6431 3.60127 10.47 3.6H5.56999C5.39692 3.60127 5.23501 3.51466 5.13999 3.37C4.61207 2.54573 4.35347 1.57773 4.39999 0.6C4.39999 0.268629 4.13136 0 3.79999 0C3.46862 0 3.19999 0.268629 3.19999 0.6C3.15392 1.84941 3.50392 3.08142 4.19999 4.12C4.29078 4.24388 4.3168 4.40372 4.26999 4.55C3.96686 5.50117 3.26658 6.27441 2.34999 6.67C2.14787 6.76922 2.01985 6.97484 2.01999 7.2V8.38C2.01999 9.72 2.61999 11.12 2.61999 12.76C2.66497 13.3153 2.59344 13.8739 2.40999 14.4L2.27999 14.87C2.20999 15.13 2.13999 15.41 2.06999 15.7L2.01999 15.91V15.91C1.87999 16.54 1.72999 17.23 1.58999 17.99C3.14112 17.7139 4.41799 16.6134 4.91999 15.12V15.12V15.12C4.91999 15.12 4.91999 15.12 4.91999 15.06C4.91999 14.79 5.01999 14.42 5.22999 14.42H5.99999C5.99999 14.42 6.23999 14.49 6.14999 15.07C5.61289 17.3209 3.70366 18.9809 1.39999 19.2V19.2C0.576108 24.5602 0.174872 29.9769 0.199993 35.4C0.199993 35.7314 0.468622 36 0.799993 36H5.59999C5.897 36.0027 6.14973 35.7843 6.18999 35.49L7.49999 25.41C7.49999 25.1339 7.72385 24.91 7.99999 24.91C8.27614 24.91 8.49999 25.1339 8.49999 25.41L9.80999 35.49C9.85025 35.7843 10.103 36.0027 10.4 36H15.2C15.5314 36 15.8 35.7314 15.8 35.4C15.8392 29.9746 15.4514 24.5545 14.64 19.19V19.19Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Jumpsuit
