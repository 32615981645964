import { gql } from '@apollo/client'

export const GET_PARTNER = gql`
  query partners($api_key: String!) {
    partner(where: { api_key: { _eq: $api_key } }) {
      email
      whatsapp
      watermark_url
      crawler_api_key
      size_chart_url
      cart_url
      upscale
      currency
      locale
    }
    age_group_adults: pre_model_to_partner_aggregate(
      limit: 1
      where: {
        partner: { api_key: { _eq: $api_key } }
        pre_model: { model_to_tryon: { age_group: { _eq: "ADULTS" } } }
      }
    ) {
      aggregate {
        count
      }
    }
    age_group_children: pre_model_to_partner_aggregate(
      limit: 1
      where: {
        partner: { api_key: { _eq: $api_key } }
        pre_model: { model_to_tryon: { age_group: { _eq: "CHILDREN" } } }
      }
    ) {
      aggregate {
        count
      }
    }
    taxonomy_gender_male: pre_model_to_partner_aggregate(
      limit: 1
      where: { partner: { api_key: { _eq: $api_key } }, pre_model: { model_to_tryon: { gender: { _eq: "MALE" } } } }
    ) {
      aggregate {
        count
      }
    }
    taxonomy_gender_female: pre_model_to_partner_aggregate(
      limit: 1
      where: { partner: { api_key: { _eq: $api_key } }, pre_model: { model_to_tryon: { gender: { _eq: "FEMALE" } } } }
    ) {
      aggregate {
        count
      }
    }
  }
`
