import { node } from 'prop-types'

import { useMemo, useRef, useState } from 'react'

import { MobileUtils } from '~/utils/mobileUtils'

import { WidgetStateContext } from '.'

export const WidgetStateProvider = ({ children }) => {
  const [state, setState] = useState('closed')
  const [ready, setStateReady] = useState(false)
  const [firstInteraction, setFirstInteraction] = useState(false)
  const [shouldCallTryonFit, setShouldCallTryonFit] = useState(null)

  const enableFirstInteraction = useRef(true)

  const isMobile = useMemo(() => MobileUtils.isMobile(), [])

  const closeWidget = () => {
    if (isMobile) document.querySelector('html').classList.remove('doris-widget-overflow-hidden')

    setState('closed')
  }

  const openWidget = () => {
    if (isMobile) {
      document.querySelector('html').classList.add('doris-widget-overflow-hidden')
    }

    setState('open')
    setShouldCallTryonFit(true)
  }

  const setReady = () => setStateReady(true)

  const isEnableFirstInteraction = () => enableFirstInteraction.current

  const setEnableFirstInteraction = status => {
    enableFirstInteraction.current = status
  }

  window.addEventListener('popstate', closeWidget)

  const value = {
    state,
    closeWidget,
    openWidget,
    setReady,
    ready,
    firstInteraction,
    setFirstInteraction,
    isEnableFirstInteraction,
    setEnableFirstInteraction,
    shouldCallTryonFit,
    setShouldCallTryonFit,
  }

  return <WidgetStateContext.Provider value={value}>{children}</WidgetStateContext.Provider>
}

WidgetStateProvider.propTypes = {
  children: node.isRequired,
}
