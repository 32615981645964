import { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'

import { IAutomix } from '~/entities'

import { GET_AUTOMIX, IQueryAutomix, IQueryData, IQueryVars, TFetchParams } from './'

export const useQueryAutomix = (): IQueryAutomix => {
  const [fetchAutomix, { refetch, called }] = useLazyQuery<IQueryData, IQueryVars>(GET_AUTOMIX)

  const fetch = useCallback(
    (params: TFetchParams): Promise<IAutomix[]> =>
      new Promise(async (resolve, reject) => {
        const call = called ? refetch : fetchAutomix
        const variables: IQueryVars = {
          where: {
            productByBottomProductId: {
              _or: [{ gender: { _eq: params.gender } }, { gender: { _eq: 'UNISEX' } }],
              active: { _eq: true },
            },
            productByTopProductId: {
              _or: [{ gender: { _eq: params.gender } }, { gender: { _eq: 'UNISEX' } }],
              active: { _eq: true },
            },
          },
        }

        const { data, error } = await call(called ? variables : { variables })

        if (error) reject(error)

        const response: IAutomix[] =
          data?.automix?.map(combination => ({
            id: combination.id,
            products: {
              top: combination.top,
              bottom: combination.bottom,
            },
          })) || []

        resolve(response)
      }),
    [called, fetchAutomix, refetch],
  )

  return {
    fetch,
  }
}
