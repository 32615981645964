import styled from 'styled-components'

import { Prefix } from '~/components/Typography/styles'

export const ContainerSlick = styled.div`
  height: 100%;
  width: 100%;
`

export const Item = styled.div`
  display: flex;
  height: 100%;
  padding: 0 4px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  background-color: #f6f6f6;
  align-items: center;
  justify-content: center;
`

export const Text = styled(Prefix)`
  font-weight: 700;
  padding-top: 19px;
  text-transform: uppercase;
`
