import { FC, useCallback } from 'react'

import { useCurrentModel } from '~/hooks/useCurrentModel'
import { usePartner } from '~/hooks/usePartner'

import { useTryonContext } from '~/context/Tryon'

import { Backdrop } from '~/components/Backdrop'
import OptionList, { IOption } from '~/components/OptionList'
import Typography from '~/components/Typography'

import { Sentry } from '~/clients/sentry'
import { downloadFile } from '~/utils/downloadFile'
import Tracking from '~/utils/tracking'
import { getSimplifiedLanguageCode, translate } from '~/utils/translate'

import { Container, Link, UpscaleTag } from './styles'
import { getDownloadUrl } from './utils'

interface ISettingsBackdropProps {
  active: boolean
  handleState: (state: boolean) => void
  handleWalkthrough: () => void
}

const SettingsBackdrop: FC<ISettingsBackdropProps> = ({ active, handleState, handleWalkthrough }) => {
  const { getCurrentModel } = useCurrentModel()
  const { getPartner } = usePartner()
  const { stateCurrentTryon, stateTryonUpscale } = useTryonContext()

  const termsUrl =
    getSimplifiedLanguageCode() === 'pt'
      ? 'https://www.doris.mobi/termos-e-condicoes-e-politica-de-privacidade'
      : 'https://www.doris.mobi/en/privacy-policy'

  const handleDownload = useCallback(async () => {
    const currentModel = getCurrentModel()
    const { data: partner } = await getPartner()

    const downloadUrl = getDownloadUrl(
      (partner?.upscale ? stateCurrentTryon?.upscaledImageUrl : stateCurrentTryon?.imageUrl) as string,
      partner?.watermark_url,
    )

    try {
      await downloadFile(downloadUrl)

      Tracking.logEvent('TRY_ON_SAVE', {
        avatar: currentModel?.id,
        avatar_type: currentModel?.type === 'PRE_MODEL' ? 'pre' : 'self',
        top: stateCurrentTryon?.products?.top?.identifier ?? stateCurrentTryon?.products?.full?.identifier,
        top_categ: stateCurrentTryon?.products?.top?.category.name ?? stateCurrentTryon?.products?.full?.category.name,
        bottom: stateCurrentTryon?.products?.bottom?.identifier,
        bot_categ: stateCurrentTryon?.products?.bottom?.category.name,
        widget: true,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      Sentry.captureException({
        errorName: 'FAILED_TO_DOWNLOAD_IMAGE',
        errorMessage: 'Falha ao realizar download do TryOn',
        filePath: 'src/components/SettingsBackdrop/index.tsx',
        functionName: 'handleDownload',
      })
    }
  }, [getCurrentModel, getPartner, stateCurrentTryon])

  const getOptions = useCallback(() => {
    const Options: Array<IOption> = [
      {
        title: translate('BACKDROP_SETTINGS_DOWNLOAD_IMAGE'),
        onClick: handleDownload,
        disabled: !stateCurrentTryon?.imageUrl,
        component: stateTryonUpscale?.isLoading ? (
          <UpscaleTag label={translate('BACKDROP_SETTINGS_UPSCALE_TAG_LABEL')} testId="setting-backdrop-upscale-tag" />
        ) : undefined,
      },
      {
        title: translate('BACKDROP_SETTINGS_HELP'),
        onClick: handleWalkthrough,
      },
    ]

    return Options
  }, [handleDownload, handleWalkthrough, stateCurrentTryon, stateTryonUpscale?.isLoading])

  return (
    <Backdrop
      visible={active}
      title={translate('BACKDROP_SETTINGS_TITLE')}
      testID="backdrop-settings"
      handleClose={() => handleState(false)}
      layerIndex={4}
      withBackground
    >
      <Container>
        <OptionList options={getOptions()} />
        <Link
          data-testid="terms-link"
          href={termsUrl}
          target="_blank"
          rel="noreferrer"
          onClick={() => Tracking.logEvent('READY_PRIVACY_OR_TERMS', { widget: true })}
        >
          <Typography type="prefix" color="#A6A6A6">
            {translate('TERMS_LINK_COPY')}
          </Typography>
        </Link>
      </Container>
    </Backdrop>
  )
}

export default SettingsBackdrop
