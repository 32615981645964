import { IExtendTimeInMinutesParams, ITimeDifferenceParams } from './types'

export const getTimeDifference = ({ date1, date2 }: ITimeDifferenceParams): number => {
  const timeDifference = Math.abs(date1.getTime() - date2.getTime())
  const minutesDifference = timeDifference / (1000 * 60)

  return minutesDifference
}

export const extendTimeInMinutes = ({ date, minutes = 20 }: IExtendTimeInMinutesParams): string => {
  const newDate = new Date(date)

  newDate.setMinutes(newDate.getMinutes() + minutes)

  return newDate.toISOString()
}
