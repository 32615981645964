import { v7 } from 'uuid'

import { Sentry } from '~/clients/sentry'
import { getUserUuid } from '~/utils/getUserUuid'
import { skuHasTryOn } from '~/utils/orderEvent/utils/skuHasTryon'

import { ICaptureEventParams, ICaptureOrderEventParams, IEventPayload } from './types'

const getDistinctIdFromPosthog = () => {
  const keyName = `ph_${process.env.REACT_APP_POSTHOG_PUBLIC_KEY}_posthog`
  const storage = localStorage.getItem(keyName)

  if (!storage) return null

  const parsedStorage = JSON.parse(storage)

  return parsedStorage.distinct_id
}

const captureEvent = async ({ event, properties }: ICaptureEventParams) => {
  const url = `${process.env.REACT_APP_POSTHOG_PUBLIC_HOST}/capture` as string
  const api_key = process.env.REACT_APP_POSTHOG_PUBLIC_KEY as string
  const headers = {
    'Content-Type': 'application/json',
  }

  const userUuid = getUserUuid()
  const distinctIdFromPosthog = getDistinctIdFromPosthog()

  const now = new Date()
  const timestamp = now.toISOString()
  const uuid = v7()

  const payload: IEventPayload = {
    event,
    api_key,
    properties,
    timestamp,
    uuid,
  }

  payload.distinct_id = userUuid.length ? userUuid : distinctIdFromPosthog

  if (userUuid.length) {
    payload.properties.$user_id = userUuid
    payload.properties.distinct_id = userUuid
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    })

    return response.json()
  } catch (error) {
    Sentry.captureException({
      errorName: 'FAILED_TO_CAPTURE_ORDER_EVENT',
      errorMessage: error.message,
      filePath: 'src/utils/orderEvent/index.ts',
      functionName: 'order',
      payload: error,
    })
  }
}

export const captureOrderEvent = async ({ appuser, orderId, products, total, currency }: ICaptureOrderEventParams) => {
  products.forEach(async product => {
    const tryon = skuHasTryOn(product.sku)

    const properties = {
      appuser,
      tryon,
      order: orderId,
      total,
      sku: product.sku,
      quantity: product.quantity,
      skuTotal: product.total,
      identifier: product.identifier || product.sku,
      currency,
      widget: true,
      $host: window.location.host,
      $current_url: window.location.href,
    }

    await captureEvent({ event: 'PURCHASE', properties })
  })
}
