import styled from 'styled-components'

import { Paragraph } from '~/components/Typography/styles'

export const Link = styled.a`
  text-decoration: underline !important;
  font-size: 12px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.primary};
`
export const Text = styled(Paragraph)`
  font-size: 12px;
  line-height: 120%;
`
export const ParagraphContainer = styled.div`
  text-align: center;
`
