import { FC } from 'react'

import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { IRedoButtonProps } from '~/components/RedoButton/types'

import theme from '~/theme'

export const RedoButton: FC<IRedoButtonProps> = ({ onClick, disabled = false }) => (
  <IconButton
    testID="redo-button"
    onClick={onClick}
    size={40}
    backgroundColor={theme.colors.primary}
    disabled={disabled}
    icon={<Icon name="historyarrow" size="18px" inverse />}
  />
)
