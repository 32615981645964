import { bool, func, number, oneOf, oneOfType, string } from 'prop-types'

import useImageLoader from '~/hooks/useImageLoader'

import { Icon } from '~/components/Icon'
import { SkeletonLoader } from '~/components/SkeletonLoader'

import { Button, Image, Tag } from './styles'

const ImageItem = ({ url, id, order, active, tag, onClick, itemType, disabled }) => {
  const { imageRef, loaded, handleOnLoad } = useImageLoader()

  return (
    <Button
      data-testid="image-item-button"
      data-id={id}
      data-order={order}
      onClick={onClick}
      active={active}
      itemType={itemType}
      disabled={disabled}
    >
      {!loaded && <SkeletonLoader width="100%" height="100%" />}

      {tag && (
        <Tag data-testid="button-tag">
          <Icon name="close" size={12} />
        </Tag>
      )}
      <Image ref={imageRef} data-testid="image-item-image" src={url} onLoad={handleOnLoad} />
    </Button>
  )
}

ImageItem.propTypes = {
  url: string.isRequired,
  onClick: func.isRequired,
  order: number,
  active: bool,
  tag: bool,
  itemType: oneOf(['product', 'model', 'cart']),
  disabled: bool,
  id: oneOfType([number, string]).isRequired,
}

ImageItem.defaultProps = {
  itemType: 'product',
}

export default ImageItem
