import { useCallback, useRef } from 'react'

import { AxiosClient } from '~/clients/axios'
import { IAvatar } from '~/entities'

import {
  IFetch,
  IUseQueryAvatar,
  TAvatarCallbackOnError,
  TAvatarCallbackOnProcessing,
  TAvatarCallbackOnSuccess,
} from '.'

export const useQueryAvatar = (): IUseQueryAvatar => {
  const callbackSuccess = useRef<{ call: TAvatarCallbackOnSuccess }>({ call: /* istanbul ignore next */ () => ({}) })
  const callbackError = useRef<{ call: TAvatarCallbackOnError }>({ call: /* istanbul ignore next */ () => ({}) })
  const callbackProcessing = useRef<{ call: TAvatarCallbackOnProcessing }>({
    call: /* istanbul ignore next */ () => ({}),
  })

  const timeoutInSeconds = 30

  const fetch = useCallback(
    async ({ payload, callbackOnSuccess, callbackOnError, callbackOnProcessing = () => ({}) }: IFetch) => {
      callbackSuccess.current.call = callbackOnSuccess
      callbackError.current.call = callbackOnError
      callbackProcessing.current.call = callbackOnProcessing

      try {
        callbackProcessing.current.call(true)

        const { data } = await AxiosClient().post<Partial<IAvatar>>('/avatar', payload, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: timeoutInSeconds * 1000,
          timeoutErrorMessage: '[ERROR][AVATAR][PROCESSING]: Timeout.',
        })

        callbackSuccess.current.call(data)
      } catch (error) {
        callbackError.current.call({
          name: error?.message === '[ERROR][AVATAR][PROCESSING]: Timeout.' ? 'PROCESSING_TIMEOUT' : 'PROCESSED_ERROR',
          message: error?.response?.data?.message || error?.message,
        })
      } finally {
        callbackProcessing.current.call(false)
      }
    },
    [],
  )

  return {
    fetch,
  }
}
