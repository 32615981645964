import { FC } from 'react'

import Typography from '~/components/Typography'

import theme from '~/theme'

import { Button, Item } from './styles'

export interface ITabDefaultHeaderProps {
  index: number
  active?: boolean
  onClick: (index: number) => void
}

const TabDefaultHeader: FC<ITabDefaultHeaderProps> = ({ children, index, active, onClick }) => (
  <Item data-testid="tab-header-default" aria-selected={active ? 'true' : 'false'} active={active}>
    <Button data-testid="tab-header-default-button" onClick={() => onClick(index)}>
      <Typography testID="tab-header-default-text" color={active ? theme.colors.primary : theme.colors.quickSilver}>
        <strong>{children}</strong>
      </Typography>
    </Button>
  </Item>
)

export default TabDefaultHeader
