import { FC } from 'react'

import { IBoxProps } from '~/components/Box/types'

import { Container, Content, SkeletonLoader } from './styles'

const Box: FC<IBoxProps> = ({
  isLoading,
  children,
  borderRadius,
  backgroundColor,
  height,
  padding,
  testID = 'box',
  borderWidth,
  borderColor,
}) => (
  <Container height={height} data-testid={`${testID}-container`}>
    {isLoading && (
      <SkeletonLoader
        data-testid={`${testID}-skeleton-loader`}
        borderRadius={borderRadius}
        width="100%"
        height="100%"
      />
    )}
    <Content
      borderColor={borderColor}
      borderWidth={borderWidth}
      data-testid={`${testID}-content`}
      backgroundColor={isLoading ? 'transparent' : backgroundColor}
      borderRadius={borderRadius}
      padding={padding}
      isLoading={isLoading}
    >
      {children}
    </Content>
  </Container>
)

export default Box
