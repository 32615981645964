import { FC, FormEvent, useRef, useState } from 'react'

import { Backdrop } from '~/components/Backdrop'
import { TextInput } from '~/components/Form/TextInput'
import { Icon } from '~/components/Icon'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import Tracking from '~/utils/tracking'
import { translateFromStorage } from '~/utils/translate'

import * as Styled from './styles'
import { emailErrorMessage, termsUrl } from './constants'
import { INewsletterBackdrop } from './types'

const isEmailValid = (email: string): boolean => {
  const regexp = /^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/gi

  return regexp.test(email)
}

export const NewsletterBackdrop: FC<INewsletterBackdrop> = ({
  visible,
  handleClose,
  onSubmitEmail,
  onSuccess,
  loading,
}) => {
  const emailFieldRef = useRef<string>('')
  const [contentState, setContentState] = useState<'form' | 'feedback'>('form')
  const [error, setError] = useState<undefined | string>(undefined)

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!isEmailValid(emailFieldRef?.current)) {
      setError(emailErrorMessage())
      return
    }

    const result = await onSubmitEmail({ email: emailFieldRef?.current })

    if (!result) {
      return
    }

    setContentState('feedback')
    onSuccess()
  }

  const onUpdateFieldValue = (value: string) => {
    emailFieldRef.current = value
  }

  const resetError = () => {
    setError(undefined)
  }

  const onCloseBackdrop = () => {
    handleClose()
    Tracking.logEvent('ACCOUNT_CLOSE', { widget: true })
  }

  const onFocus = () => {
    resetError()
  }

  const renderForm = (): JSX.Element => (
    <Styled.Container onSubmit={onSubmit} aria-hidden={Boolean(contentState === 'form')}>
      <Styled.CustomTitle>{translateFromStorage('NEWSLETTER_BACKDROP_FORM_TITLE')}</Styled.CustomTitle>
      <Styled.Text align="center">{translateFromStorage('NEWSLETTER_BACKDROP_FORM_DESCRIPTION')}</Styled.Text>
      <TextInput
        type="text"
        name="email"
        placeholder="Digite seu e-mail"
        error={error}
        handleOnChange={onUpdateFieldValue}
        handleOnFocus={onFocus}
        handleOnBlur={onUpdateFieldValue}
      />
      <Styled.ContainerButton>
        <TextButton
          testID="newsletter-backdrop-close-button"
          onClick={onCloseBackdrop}
          variant="outlined"
          disabled={loading}
        >
          {translateFromStorage('NEWSLETTER_BACKDROP_FORM_SKIP_BUTTON')}
        </TextButton>
        <TextButton testID="newsletter-backdrop-submit-button" type="submit" disabled={loading}>
          {translateFromStorage('NEWSLETTER_BACKDROP_FORM_SUBMIT_BUTTON')}
        </TextButton>
      </Styled.ContainerButton>
      <Styled.Disclaimer>
        Não enviamos spam. Ao se cadastrar, você concorda com nossos{' '}
        <Styled.Link data-testid="terms-link" href={termsUrl} target="_blank" rel="noreferrer">
          Termos de Uso e Política de Privacidade.
        </Styled.Link>
      </Styled.Disclaimer>
    </Styled.Container>
  )

  const renderFeedbackMessage = (): JSX.Element => (
    <Styled.FeedbackContainer
      data-testid="newsletter-feedback-container"
      aria-hidden={Boolean(contentState === 'feedback')}
    >
      <Icon name="successCheckMark" color={theme.colors.status.success} size={60} />
      <Styled.FeedbackTitle data-testid="feedback-title">
        {translateFromStorage('NEWSLETTER_BACKDROP_FEEDBACK_TITLE')}
      </Styled.FeedbackTitle>
      <Styled.FeedbackDescription data-testid="feedback-description">
        {translateFromStorage('NEWSLETTER_BACKDROP_FEEDBACK_DESCRIPTION')}
      </Styled.FeedbackDescription>
      <TextButton testID="newsletter-close-after-success-button" onClick={handleClose}>
        {translateFromStorage('NEWSLETTER_BACKDROP_FEEDBACK_BUTTON')}
      </TextButton>
    </Styled.FeedbackContainer>
  )

  return (
    <Backdrop visible={visible} testID="newsletter-backdrop">
      {contentState === 'form' ? renderForm() : renderFeedbackMessage()}
    </Backdrop>
  )
}
