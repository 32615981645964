import styled from 'styled-components'

import { Paragraph, Title } from '~/components/Typography/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

export const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Text = styled(Title)`
  font-weight: 700;
  margin-top: 16px;
`

export const Description = styled(Paragraph)`
  font-weight: 400;
  margin-top: 16px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
`
