import styled from 'styled-components'

import { Paragraph } from '~/components/Typography/styles'

export const Container = styled.div`
  width: 100%;
`

export const Label = styled(Paragraph)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 106px;
  margin: 30px 0;
`
