import { FC, useEffect, useState } from 'react'

import { stepsMap } from '~/hooks/useWalkthrough'

import { IWalkthroughProps } from '~/components/Walkthrough/types'

import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from './styles'

export const Walkthrough: FC<IWalkthroughProps> = ({ step = 0, onNavigate, onSkip }) => {
  const { title, description } = stepsMap[step]
  const [startAnimation, setStartAnimation] = useState(false)

  useEffect(() => {
    Tracking.logEvent('WALKMEIN', { step: 1, widget: true })

    setTimeout(() => {
      setStartAnimation(true)
    }, 300)
  }, [])

  return (
    <Styled.Container data-testid="walkthrough">
      <Styled.Overlay data-testid="walkthrough-overlay" startAnimation={startAnimation} />

      <Styled.Balloon data-testid="walkthrough-ballon" startAnimation={startAnimation}>
        <Styled.TipTitle align="center">{title}</Styled.TipTitle>
        <Styled.Description align="center" data-testid="walkthrough-ballon-text">
          {description}
        </Styled.Description>
        <Styled.DotList>
          {Array.from({ length: Object.keys(stepsMap).length }).map((_, index) => (
            <Styled.Dot data-testid="walkthrough-dot" key={index} active={index === step} />
          ))}
        </Styled.DotList>
        <Styled.Actions>
          <Styled.TextButtonStyled
            testID="walkthrough-skip-button"
            variant="outlined"
            onClick={onSkip}
            disabled={step === 2}
          >
            {translate('WALKTHROUGH_SKIP_BUTTON_TEXT')}
          </Styled.TextButtonStyled>
          <Styled.TextButtonStyled testID="walkthrough-next-button" onClick={onNavigate} textTransform="uppercase">
            {translate('WALKTHROUGH_CONTINUE_BUTTON_TEXT')}
          </Styled.TextButtonStyled>
        </Styled.Actions>
        <Styled.ArrowDown data-testid="walkthrough-balloon-arrow" position={step} />
      </Styled.Balloon>
    </Styled.Container>
  )
}
