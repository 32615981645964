import { FC } from 'react'

import { TIconProps } from '.'

export const CameraAdd: FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 36 36"
      data-testid="icon-camera-add"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <path
        fill={color}
        d="M28.987 11.975V8.75h-3.225V6.5h3.225V3.237h2.25V6.5H34.5v2.25h-3.263v3.225h-2.25ZM3.525 33.5c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V12.012c0-.575.225-1.093.675-1.556.45-.462.975-.694 1.575-.694h5.512L11.775 6.5h10.5v2.25h-9.45l-2.738 3.262H3.525V31.25h25.5V15.5h2.25v15.75c0 .6-.231 1.125-.694 1.575-.462.45-.981.675-1.556.675h-25.5Zm12.75-5.475c1.8 0 3.312-.613 4.537-1.838s1.838-2.75 1.838-4.575c0-1.8-.613-3.306-1.838-4.518-1.225-1.213-2.737-1.82-4.537-1.82-1.825 0-3.344.607-4.556 1.82-1.213 1.212-1.82 2.718-1.82 4.518 0 1.825.607 3.35 1.82 4.575 1.212 1.225 2.73 1.838 4.556 1.838Zm0-2.25c-1.2 0-2.188-.394-2.963-1.181-.775-.788-1.162-1.782-1.162-2.982 0-1.175.387-2.15 1.162-2.925.775-.775 1.763-1.162 2.963-1.162 1.175 0 2.156.387 2.944 1.162.787.775 1.18 1.75 1.18 2.925 0 1.2-.393 2.194-1.18 2.982-.788.787-1.77 1.18-2.944 1.18Z"
      />
    </svg>
  )
}
