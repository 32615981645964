import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 70px);
  margin-top: 70px;
`

export const BoxSlider = styled.div`
  width: 100%;
`

export const Item = styled.div`
  padding: 0 5px;
`

export const BoxButton = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
`
