import * as SentryLib from '@sentry/react'

import { getPartnerAPIKey } from '~/utils/getPartnerAPIKey'
import { getUserUuid } from '~/utils/getUserUuid'
import { Singleton } from '~/utils/singleton'
import { WidgetError } from '~/utils/widgetError'

import { ISentry, TCaptureExceptionParams, TTracesRateLabels, TTracesRateMap } from './types'

class SentrySingleton extends Singleton<ISentry>() {
  private tracesRateMap: TTracesRateMap = {
    local: 0.0,
    develop: 0.25,
    staging: 0.5,
    production: 1.0,
  }

  public init(): void {
    SentryLib.init({
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: integrations => {
        return integrations
          .filter(i => i.name !== 'GlobalHandlers')
          .filter(i => i.name !== 'Breadcrumbs')
          .filter(i => i.name !== 'TryCatch')
      },
      tracesSampleRate: this.tracesRateMap[process.env.REACT_APP_SENTRY_ENVIRONMENT as TTracesRateLabels],
    })
  }

  public captureException<TGenericPayload>(params: TCaptureExceptionParams<TGenericPayload>): void {
    const error = new WidgetError(params.errorName, params.errorMessage)

    SentryLib.captureException(error, {
      tags: {
        SDK: 'Widget - MIX',
        filePath: params.filePath,
      },
      user: {
        id: getUserUuid(),
      },
      level: SentryLib.Severity.Error,
      extra: {
        partner: {
          apiKey: getPartnerAPIKey(),
        },
        function: params.functionName,
        payload: JSON.stringify(params.payload),
      },
    })
  }
}

export const Sentry = SentrySingleton.getInstance()
