import styled from 'styled-components'

export const Container = styled.div`
  z-index: 2;
  position: fixed;
  width: 34px;
  height: 34px;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  ${props => `margin-${props.direction}`}: 15px;
  ${props => props.direction}: 0;
`

export const Button = styled.button`
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
`
