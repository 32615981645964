import { IIsCurrentProductsWearable, IShouldRemoveProductsFromTryOnParams } from './types'

export const shouldRemoveProductsFromTryOn = ({
  model,
  currentModel,
  products = { top: undefined, bottom: undefined },
}: IShouldRemoveProductsFromTryOnParams): boolean => {
  const isSameGender = model.gender === currentModel.gender
  const isSameAgeGroup = model.age_group === currentModel.age_group
  const areProductsNonUnisex = products.top?.gender !== 'UNISEX' && products.bottom?.gender !== 'UNISEX'

  if (!isSameAgeGroup) return true

  if (!isSameGender && areProductsNonUnisex) return true

  return false
}

export const isCurrentProductsWearable = ({
  avatarGender,
  avatarAgeGroup,
  currentTryon,
}: IIsCurrentProductsWearable): boolean => {
  if (!currentTryon.top && !currentTryon.bottom && !currentTryon.full) return false

  if (currentTryon.top && avatarGender !== currentTryon.top?.gender && currentTryon.top?.gender !== 'UNISEX')
    return false

  if (currentTryon.bottom && avatarGender !== currentTryon.bottom?.gender && currentTryon.bottom?.gender !== 'UNISEX')
    return false

  if (currentTryon.full && avatarGender !== currentTryon.full?.gender && currentTryon.full?.gender !== 'UNISEX')
    return false

  if (currentTryon.top && currentTryon.top.age_group !== undefined && avatarAgeGroup !== currentTryon.top.age_group)
    return false
  if (
    currentTryon.bottom &&
    currentTryon.bottom.age_group !== undefined &&
    avatarAgeGroup !== currentTryon.bottom.age_group
  )
    return false
  if (currentTryon.full && currentTryon.full.age_group !== undefined && avatarAgeGroup !== currentTryon.full.age_group)
    return false

  return true
}
