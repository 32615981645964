import { func } from 'prop-types'

import { Icon } from '~/components/Icon'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import { Container, Text } from './styles'

const MoreProductsButton = ({ onClick }) => (
  <Container itemType="product" data-testid="more-products-button" onClick={onClick}>
    <Icon name="plus" color={theme.colors.primary} />
    <Text type="prefix" align="center" color={theme.colors.primary} data-testid="text-see-more">
      {translate('VIEW_MORE')}
    </Text>
  </Container>
)

MoreProductsButton.propTypes = {
  onClick: func.isRequired,
}

export default MoreProductsButton
