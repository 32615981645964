import React from 'react'

import { TIconProps } from '.'

const ArrowLeft: React.FC<TIconProps> = ({ color, size }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 7 14"
    data-testid="icon-arrow-left"
    style={{ width: size, height: size }}
  >
    <defs>
      <path
        d="M13.8286,19 C13.5366,19 13.2466,18.873 13.0486,18.627 L8.2206,12.627 C7.9226,12.256 7.9266,11.726 8.2316,11.36 L13.2316,5.36 C13.5846,4.936 14.2156,4.879 14.6406,5.232 C15.0646,5.585 15.1216,6.216 14.7676,6.64 L10.2926,12.011 L14.6076,17.373 C14.9536,17.803 14.8856,18.433 14.4546,18.779 C14.2706,18.928 14.0486,19 13.8286,19"
        id="icon-arrow-left"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2157.000000, -692.000000)">
        <g transform="translate(233.000000, 190.000000)">
          <g transform="translate(1896.000000, 287.000000)">
            <g transform="translate(15.000000, 205.000000)">
              <g transform="translate(5.000000, 5.000000)">
                <use fill={color} fillRule="evenodd" xlinkHref="#icon-arrow-left" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ArrowLeft
