import { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'

import { IPartner } from '~/entities'

import { GET_PARTNER, IQueryData, IQueryPartner, IQueryVars } from '.'
import { ITaxonomyMap } from './types'

export const useQueryPartner = (): IQueryPartner => {
  const [fetchPartner, { data, called, loading, error }] = useLazyQuery<IQueryData, IQueryVars>(GET_PARTNER)

  const fetch = useCallback(
    (): Promise<IPartner> =>
      new Promise(async (resolve, reject) => {
        const { data, error } = await fetchPartner({
          variables: { api_key: localStorage.getItem('@doris:partner_apikey') as string },
        })

        if (!data?.partner?.length || error) {
          reject(error || new Error('Partner not found'))

          return
        }

        const taxonomies: ITaxonomyMap = {
          gender: [],
          age_group: [],
        }

        if (data.age_group_adults.aggregate.count) {
          taxonomies.age_group.push('ADULTS')
        }

        if (data.age_group_children.aggregate.count) {
          taxonomies.age_group.push('CHILDREN')
        }

        if (data.taxonomy_gender_female.aggregate.count) {
          taxonomies.gender.push('FEMALE')
        }
        if (data.taxonomy_gender_male.aggregate.count) {
          taxonomies.gender.push('MALE')
        }

        resolve({ ...data.partner[0], taxonomies })
      }),
    [fetchPartner],
  )

  return {
    fetch,
    data,
    called,
    loading,
    error,
  }
}
