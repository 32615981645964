import React from 'react'

import { TIconProps } from '.'

const Skirt: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 24 29"
      data-testid="icon-skirt"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M19.48 6.75C16.893 7.13083 14.2844 7.34793 11.67 7.4C9.07404 7.3313 6.48526 7.09414 3.92001 6.69C1.76832 13.0959 0.545895 19.7772 0.290014 26.53C0.274776 26.8139 0.471013 27.0656 0.750014 27.12C1.11001 27.18 8.21001 29 13.26 29C13.8883 28.9915 14.5158 28.9515 15.14 28.88C15.4283 28.8333 15.6449 28.5917 15.66 28.3L15.31 22.07L17.48 28.07C17.5255 28.2122 17.6268 28.3298 17.7607 28.3958C17.8946 28.4618 18.0495 28.4705 18.19 28.42C18.31 28.36 21.24 27.66 22.77 27.13C22.8866 27.0826 22.9873 27.0028 23.06 26.9C25.29 23.2 21.24 11.52 19.48 6.76V6.75Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M5.00001 5.7C7.19267 6.04931 9.4097 6.22319 11.63 6.22C13.8535 6.22088 16.0737 6.04701 18.27 5.7C18.5308 5.65394 18.7255 5.43439 18.74 5.17L19 1.65C19.0151 1.4752 18.9529 1.30257 18.8298 1.17755C18.7067 1.05253 18.535 0.987654 18.36 0.999998C14.1019 1.70033 9.75807 1.70033 5.50001 0.999998C5.33354 0.974508 5.16387 1.01783 5.03001 1.12C4.89793 1.21721 4.81412 1.36661 4.80001 1.53L4.62001 5.05C4.58456 5.19986 4.61606 5.35774 4.70629 5.48253C4.79653 5.60732 4.93658 5.6867 5.09001 5.7H5.00001Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Skirt
