import { TSizingInfos } from '~/screens/SizingScreen/types'

import { ISelectedSize } from '../types'

export const getInitialItemByTab = ({
  sizes,
  selectedSize,
}: {
  sizes: TSizingInfos[]
  selectedSize?: ISelectedSize
}): TSizingInfos => {
  const initialItem =
    sizes?.find(item => selectedSize && selectedSize.size === item.size) || // FIRST_CASE
    sizes?.find(item => item.message === 'FIT') || // SECOND_CASE
    sizes?.find(item => item.message === 'SOME_TIGHT') || // THIRD_CASE
    sizes[0] // FOURTH_CASE

  initialItem.active = true

  return initialItem
}
