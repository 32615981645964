import React from 'react'

import { TIconProps } from '.'

const Checked: React.FC<TIconProps> = ({ color, size }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 16 12"
    data-testid="icon-checked"
    style={{ width: size, height: size }}
  >
    <defs>
      <path
        d="M9.8633,18 C9.5873,18 9.3233,17.886 9.1343,17.685 L4.2713,12.506 C3.8923,12.104 3.9133,11.471 4.3153,11.093 C4.7183,10.715 5.3513,10.735 5.7283,11.137 L9.8533,15.528 L18.2613,6.326 C18.6353,5.917 19.2673,5.89 19.6753,6.262 C20.0823,6.634 20.1103,7.267 19.7383,7.674 L10.6013,17.674 C10.4143,17.88 10.1483,17.998 9.8703,18 L9.8633,18 Z"
        id="icon-checked"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-290.000000, -711.000000)">
        <g transform="translate(7.000000, 220.000000)">
          <g transform="translate(93.000000, 481.000000)">
            <g transform="translate(107.023256, 16.500000) rotate(-270.000000) translate(-107.023256, -16.500000) translate(90.523256, -90.500000)">
              <g transform="translate(16.000000, 16.023256) rotate(-90.000000) translate(-16.000000, -16.023256) translate(4.000000, 4.023256)">
                <use fill={color} fillRule="evenodd" xlinkHref="#icon-checked" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Checked
