import axios, { AxiosInstance } from 'axios'

export const AxiosClient = (): AxiosInstance => {
  const apikey = localStorage.getItem('@doris:partner_apikey') as string
  const userDataFromStorage = localStorage.getItem('@doris:user')
  const userData = userDataFromStorage ? JSON.parse(userDataFromStorage as string) : undefined

  return axios.create({
    baseURL: process.env.REACT_APP_REST_API_URL,
    headers: {
      apikey,
      user_uuid: userData?.uuid || null,
    },
  })
}
