import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 20px;
`

export const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 20px;
`
