import { FC } from 'react'

import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { ModelAssignments } from './components/ModelAssignments'
import { IModelCardProps } from './types'

export const ModelCard: FC<IModelCardProps> = ({
  onClick,
  onDelete,
  id,
  testID = 'models-list',
  active,
  url,
  modelWeight,
  modelHeight,
}) => (
  <Styled.Item
    data-testid={`${testID}-item`}
    data-model-id={id}
    data-model-active={active ? 'selected' : 'not-selected'}
  >
    <Styled.Content>
      <Styled.Overlay />
      <Styled.Image src={url} data-testid={`${testID}-image`} />

      <Styled.Container>
        <Styled.ContainerDelete>
          {onDelete && (
            <IconButton
              onClick={() => onDelete(id)}
              testID={`${testID}-button-delete`}
              size={32}
              backgroundColor={theme.colors.coralRed}
              icon={<Icon name="trash" color={theme.colors.white} size="19px" />}
            />
          )}
        </Styled.ContainerDelete>

        <Styled.Footer>
          <ModelAssignments modelWeight={modelWeight} modelHeight={modelHeight} />
          {active ? (
            <Styled.ButtonDress
              onClick={() => onClick && onClick(id)}
              testID={`${testID}-dress`}
              icon="success"
              iconDirection="right"
            >
              {translate('SELECTED_MODEL')}
            </Styled.ButtonDress>
          ) : (
            <Styled.ButtonDressModel testID={`${testID}-select-button`} onClick={() => onClick && onClick(id)}>
              {translate('SELECT_MODEL')}
            </Styled.ButtonDressModel>
          )}
        </Styled.Footer>
      </Styled.Container>
    </Styled.Content>
  </Styled.Item>
)
