import { useLayoutEffect, useState } from 'react'

export const useAppScaling = () => {
  const [scale, setScale] = useState(1)

  const updateSize = () => {
    setScale((window.innerHeight - 30) / 780)
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
  }, [])

  return {
    scale,
  }
}
