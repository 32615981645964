import { FC, useCallback, useEffect, useRef, useState } from 'react'

import * as Styled from './styles'
import { ICarouselProps } from './types'

export const Carousel: FC<ICarouselProps> = ({
  autoplay = true,
  delay = 6000,
  hasIndicators = true,
  infiniteLoop = true,
  onChange,
  goToIndex = null,
  slides,
}) => {
  const stageRef = useRef<HTMLDivElement>(null)
  const autoplayRef = useRef<NodeJS.Timeout | number>(0)
  const maxItems = slides?.length
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)
  const [goToItemValue, setGoToValue] = useState<number | null>(null)
  const [autoPlayEnabled, setAutoPlayEnabled] = useState(autoplay)

  const goTo = useCallback(
    (index: number) => {
      setCurrentIndex(index)

      if (onChange) {
        onChange(index)
      }
    },
    [onChange],
  )

  const goNext = useCallback(() => {
    const isLastItem = currentIndex >= maxItems - 1

    if (infiniteLoop) {
      goTo(isLastItem ? 0 : currentIndex + 1)

      return
    }

    handleClearTimeout()
  }, [currentIndex, goTo, infiniteLoop, maxItems])

  const handleClearTimeout = () => clearTimeout(autoplayRef.current as number)

  useEffect(() => {
    if (stageRef?.current && isLoaded) {
      stageRef?.current?.classList?.add('loaded')
    }

    if (!isLoaded && stageRef?.current?.children?.length) {
      setIsLoaded(true)
    }
  }, [isLoaded, stageRef])

  useEffect(() => {
    if (!goToItemValue && autoplay && autoPlayEnabled) {
      handleClearTimeout()

      autoplayRef.current = setTimeout(goNext, delay)
    }

    return () => {
      handleClearTimeout()
    }
  }, [autoPlayEnabled, autoplay, delay, goNext, goToItemValue])

  useEffect(() => {
    if (typeof goToItemValue === 'number' && goToItemValue >= 0) {
      handleClearTimeout()

      goTo(goToItemValue)

      setGoToValue(null)
      setAutoPlayEnabled(true)
    }
  }, [goTo, goToItemValue])

  useEffect(() => {
    if (typeof goToIndex === 'number' && goToIndex >= 0) {
      setGoToValue(goToIndex)

      return
    }

    setGoToValue(null)
  }, [goToIndex])

  return (
    <Styled.Container data-testid="carousel-container">
      <Styled.Stage data-testid="carousel-stage" ref={stageRef}>
        {slides?.map((item, index) => (
          <Styled.CarouselSlideItem
            data-testid={`carousel-item-${index}`}
            isActive={index === currentIndex}
            key={index}
          >
            {item}
          </Styled.CarouselSlideItem>
        ))}
      </Styled.Stage>

      {hasIndicators && slides?.length > 0 && (
        <Styled.DotsContainer>
          {Array.from({ length: slides.length }, (_v, index) => (
            <Styled.Dot
              key={index}
              length={slides.length}
              isComplete={currentIndex > index}
              isActive={index === currentIndex}
              data-testid={`carousel-dot-${index}`}
            >
              <Styled.DotLine speedAnimation={delay} isActive={index === currentIndex} />
            </Styled.Dot>
          ))}
        </Styled.DotsContainer>
      )}
    </Styled.Container>
  )
}
