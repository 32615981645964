import { FC } from 'react'

import { IAlertSizingProps } from '~/components/AlertSizing/types'
import { Backdrop } from '~/components/Backdrop'
import { Icon } from '~/components/Icon'
import { TextButton } from '~/components/TextButton'

import { translate } from '~/utils/translate'

import * as Styled from './styles'

export const AlertSizing: FC<IAlertSizingProps> = ({ visible, onClick }) => (
  <Backdrop visible={visible} testID="alert-sizing-container">
    <Styled.Container>
      <Styled.ContainerIcon>
        <Icon name="sizing" size="88px" />
      </Styled.ContainerIcon>
      <Styled.TitleAlert>{translate('ALERT_SIZING_BACKDROP_TITLE')}</Styled.TitleAlert>
      <Styled.Text align="center">{translate('ALERT_SIZING_BACKDROP_DESCRIPTION')}</Styled.Text>
      <Styled.ContainerButton>
        <TextButton testID="understood-button" onClick={onClick}>
          {translate('ALERT_SIZING_BUTTON_TEXT')}
        </TextButton>
      </Styled.ContainerButton>
    </Styled.Container>
  </Backdrop>
)
