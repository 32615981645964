import { FC } from 'react'

import { useSyncSession } from '~/hooks/useSyncSession'

import { SyncContext } from '.'
import { ISyncContext } from './types'

export const SyncProvider: FC = ({ children }) => {
  const { startSession, startSubscription } = useSyncSession()

  const value = {
    startSession,
    startSubscription,
  } as unknown as ISyncContext

  return <SyncContext.Provider value={value}>{children}</SyncContext.Provider>
}
