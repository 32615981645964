import styled from 'styled-components'

import theme from '~/theme'

export const Container = styled.div`
  display: grid;
  gap: ${theme.tokens.spacingUnit * 3}px;
`

export const TutorialWrapper = styled.div``

export const Description = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 125%;
  margin: 0;
`

export const Fieldset = styled.div`
  display: grid;
  gap: ${theme.tokens.spacingUnit * 2}px;
`

export const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: center;
  gap: ${theme.tokens.spacingUnit * 2}px;

  span {
    font-size: 12px;
    font-weight: ${theme.typography.fontWeight.bold};
    color: ${theme.colors.neutral.black500};
  }
`

export const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.tokens.spacingUnit}px;
`
