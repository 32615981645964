import styled, { css } from 'styled-components'

import { IconButton } from '~/components/IconButton'
import { Title } from '~/components/Typography/styles'

import { IContainerProps, ITextProps } from './types'

export const Text = styled(Title)<ITextProps>`
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;

  ${({ contrast }) =>
    contrast &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `};
`

export const Container = styled.div<IContainerProps>`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 64px;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;

  ${({ contrast }) =>
    contrast &&
    css`
      background: rgba(0, 0, 0, 0.9);
    `};

  ${({ float }) =>
    float &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `};
`

export interface IIconButonProps {
  borderColor?: string
  className?: string
  icon: JSX.Element
  onClick: () => void
  size: number
  testID: string
}

export const IconButtonClose = styled(IconButton)<IIconButonProps>`
  margin-left: auto;
  z-index: 2;
`

export const IconPrevButton = styled(IconButtonClose)<IIconButonProps>`
  margin-left: 0;
`
