import styled from 'styled-components'

import { UpscaleTag as SettingUpscaleTag } from '~/components/UpscaleTag'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Link = styled.a`
  display: flex;
  margin-top: 15px;
`

const UpscaleTag = styled(SettingUpscaleTag)`
  width: 100%;

  & span {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    flex: 1;
    animation: none;
  }

  & div {
    padding: 0;
  }

  & span,
  & svg {
    color: ${({ theme }) => theme.colors.neutral.black500};
  }
`

export { Container, Link, UpscaleTag }
