import React, { FC } from 'react'

import { TIconProps } from '.'

const Shape: FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 12 12"
      data-testid="icon-shape"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <path
        d="M11.7876 1.87867L10.1207 0.212339C9.98444 0.0764134 9.79965 0 9.60724 0C9.41483 0 9.23004 0.0764134 9.09382 0.212339L0.212415 9.09724C0.0764407 9.23341 0 9.41813 0 9.61047C0 9.80282 0.0764407 9.98754 0.212415 10.1237L1.87935 11.79C2.01508 11.9245 2.19844 12 2.38943 12C2.58255 12.0012 2.76758 11.9243 2.90284 11.7867L11.7876 2.90514C11.9236 2.76897 12 2.58425 12 2.39191C12 2.19956 11.9236 2.01484 11.7876 1.87867ZM11.3342 2.43523L10.4607 3.30839L10.1273 2.97512C9.99301 2.84824 9.78084 2.8542 9.65391 2.98845C9.52675 3.12271 9.5327 3.33458 9.66725 3.46169L10.0006 3.79496L9.33386 4.41817L8.46039 3.54501C8.33037 3.41575 8.12034 3.41575 7.99032 3.54501C7.87244 3.67594 7.87673 3.8759 8.00032 4.00159L8.87379 4.87808L8.23369 5.51795L7.9003 5.18469C7.76671 5.05495 7.55334 5.05804 7.42356 5.19135C7.29378 5.3249 7.29663 5.53819 7.43023 5.66792L7.76361 6.00119L7.12351 6.64106L6.25004 5.76457C6.1193 5.63388 5.90737 5.63388 5.77663 5.76457C5.6459 5.89526 5.6459 6.10712 5.77663 6.23781L6.65344 7.11097L6 7.75084L5.66661 7.41758C5.53445 7.30426 5.33704 7.31188 5.21392 7.43495C5.09081 7.55802 5.08319 7.75513 5.19654 7.88724L5.52993 8.22051L4.88982 8.86038L3.99968 8.00055C3.86966 7.87129 3.65963 7.87129 3.52961 8.00055C3.4003 8.13053 3.4003 8.34049 3.52961 8.47046L4.41642 9.33362L3.77965 9.9735L3.44626 9.64023C3.38601 9.56334 3.29505 9.51668 3.19741 9.51264C3.09978 9.50883 3.00524 9.54763 2.93904 9.61952C2.8726 9.69117 2.84117 9.78853 2.85283 9.88542C2.8645 9.9823 2.91808 10.0694 2.99952 10.1235L3.33291 10.4567L2.43277 11.3332C2.40943 11.3556 2.37276 11.3556 2.34942 11.3332L0.682251 9.66713C0.659867 9.6438 0.659867 9.60714 0.682251 9.58381L9.56699 0.685578C9.58104 0.681055 9.59629 0.681055 9.61034 0.685578C9.62319 0.681055 9.63748 0.681055 9.65034 0.685578L11.3173 2.35191C11.3306 2.36072 11.3399 2.37453 11.3428 2.39024C11.3458 2.40595 11.3428 2.42214 11.3337 2.43523H11.3342Z"
        fill={color}
      />
    </svg>
  )
}

export default Shape
