import styled from 'styled-components'

import { ITabDefaultWrapperProps } from '.'

const Wrapper = styled.div<ITabDefaultWrapperProps>`
  position: relative;
  display: flex;
  width: 100%;
  height: ${({ height }) => height || 'auto'};
`

export { Wrapper }
