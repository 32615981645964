const fetchSkuFromUrl = () => {
  const { hostname, search } = window.location
  const params = new URLSearchParams(search)

  if (hostname.includes('usereserva') || hostname.includes('doris.services') || hostname.includes('localhost')) {
    const currentProductSku = params.get('skuId')

    if (currentProductSku) {
      return [currentProductSku]
    }
  }

  return []
}

export { fetchSkuFromUrl }
