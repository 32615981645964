import { TErrorMessages } from './types'

export const ErrorMessages: TErrorMessages = {
  canvas: 'Navegador e/ou dispositivo incompatível.',
  permissionDenied: 'Permissão negada. Favor recarregar a página e dar permissões de acesso a camera.',
  noCameraAccessible:
    'Camera não encontrada. Favor conectar sua camera ou tente acessar por outro navegador e/ou dispositivo.',
  switchCamera: 'Não foi possível trocar de camera, pois existe apenas uma opção disponível.',
}

export class NotAllowedError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'NotAllowedError'
  }
}
