import styled from 'styled-components'

import { Paragraph, Prefix } from '~/components/Typography/styles'

import { IColumnProps, IDividerProps, ITitleProps } from './types'

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Column = styled.div<IColumnProps>`
  display: flex;
  width: ${({ width }) => width || 'auto'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  padding: ${({ padding }) => padding || 0};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
`

export const Title = styled(Paragraph)<ITitleProps>`
  width: 100%;
  margin-bottom: 2px;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
`

export const Divider = styled.div<IDividerProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.white};
`

export const ContainerSizeSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WrapperSize = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const TitleSize = styled(Prefix)`
  font-weight: 600;
  text-transform: uppercase;
`

export const ButtonSelectSize = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
`
