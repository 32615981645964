import styled, { css } from 'styled-components'

export const Image = styled.img`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  object-fit: cover;
  display: ${({ loaded }) => (loaded ? 'block' : 'none')};
  height: ${({ fullsize }) => (fullsize ? '100%' : 'auto')};
  ${({ fullsize }) =>
    !fullsize &&
    css`
      bottom: 65px;
      height: calc(100% - 65px);
    `};
`
