import { useCallback, useEffect, useState } from 'react'

import { MobileUtils } from '~/utils/mobileUtils'
import { removeMediaStreamTrack } from '~/utils/removeMediaStreamTrack'
import { shouldUseSecondBackCamera } from '~/utils/shouldUseSecondBackCamera'
import { INavigatorObject } from '~/utils/shouldUseSecondBackCamera/types'

import { TCameraPermission, TDeviceCameras } from './types'
import { defineCameraType } from './utils/defineCameraType'

export const useCameraPermission = () => {
  const [cameraPermission, setCameraPermission] = useState<TCameraPermission>('NOT_REQUESTED')
  const [attempts, setAttempts] = useState(MobileUtils.isAndroid() ? 1 : 3)
  const [deviceCameras, setDeviceCameras] = useState<TDeviceCameras>()

  const updateDeviceInputs = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const cameras = devices.filter(device => device.kind === 'videoinput')

    const shouldSkipFirstBackCamera = await shouldUseSecondBackCamera({
      navigatorObject: navigator as INavigatorObject,
    })

    if (shouldSkipFirstBackCamera) {
      const backCamera = cameras.filter(input => defineCameraType(input.label))[1]
      setDeviceCameras({ environment: backCamera.deviceId })
    }
  }

  const requestCameraPermission = useCallback(async () => {
    /* istanbul ignore else */
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true }).then(removeMediaStreamTrack)
        await updateDeviceInputs()
        setCameraPermission('GRANTED')
      } catch (error) {
        setAttempts(attempts => --attempts)
        setCameraPermission('DENIED')
      }
    }
  }, [])

  useEffect(() => {
    !attempts && setCameraPermission('BLOCKED')
  }, [attempts])

  const checkIfHasPermission = useCallback(async () => {
    const hasPermission = await (await navigator.mediaDevices.enumerateDevices())
      .filter(device => device.kind === 'videoinput')
      .some(device => !!device.label)

    if (hasPermission) {
      await updateDeviceInputs()
    }

    setCameraPermission(oldValue => {
      if (hasPermission && oldValue !== 'GRANTED') return 'GRANTED'
      return 'NOT_REQUESTED'
    })
  }, [])

  useEffect(() => {
    checkIfHasPermission()
  }, [checkIfHasPermission])

  return { attempts, cameraPermission, requestCameraPermission, setCameraPermission, deviceCameras }
}
