import styled, { css } from 'styled-components'

import { ITagStyled } from './types'

const Container = styled.span<ITagStyled>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px 5px 12px;
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;

  background-color: ${({ background }) => background};
  font-size: ${({ size }) => size};
  line-height: ${({ size }) => parseInt(size.split('px')[0]) + (parseInt(size.split('px')[0]) / 100) * 40}px;
  text-transform: ${({ transform }) => transform};
  color: ${({ color }) => color};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};

  ${({ cursorPointer }) =>
    cursorPointer &&
    css`
      cursor: pointer;
    `};

  svg {
    margin-right: 8px;
  }
`

export { Container }
