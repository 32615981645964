import { FC } from 'react'

import * as Styled from './styles'
import { IJumpingDots } from './types'

export const JumpingDots: FC<IJumpingDots> = ({ size, color }) => {
  return (
    <Styled.Container data-testid="dots-container">
      {Array.from({ length: 3 }).map((_, index) => (
        <Styled.Dots key={index} data-testid="loading-dot" delay={`${100 * (index + 1)}ms`} size={size} color={color} />
      ))}
    </Styled.Container>
  )
}
