import { useCallback } from 'react'

import { useQueryPartner } from '~/hooks-queries'

import { IPartner } from '~/entities'

import { IUsePartner, TGetPartnerResponse } from './types'
import { getTemplate, getType } from './utils'

const storageKey = '@doris:partner'

export const usePartner = (): IUsePartner => {
  const { fetch: fetchPartner } = useQueryPartner()

  const getPartnerStorage = useCallback((): IPartner | null => {
    const storage = localStorage.getItem(storageKey)
    return storage ? JSON.parse(storage) : null
  }, [])

  const setPartner = useCallback(({ partner }: { partner: IPartner }) => {
    localStorage.setItem(storageKey, JSON.stringify(partner))
  }, [])

  const getPartner = useCallback((): Promise<TGetPartnerResponse> => {
    return new Promise(async (resolve, reject) => {
      const response: TGetPartnerResponse = { status: true, message: 'Success' }

      try {
        response.data = await fetchPartner()

        resolve(response)
      } catch (error) {
        response.message = error.message
        response.status = false

        reject(response)
      }
    })
  }, [fetchPartner])

  const sendMessage = useCallback(
    ({ products }) => {
      const partner = getPartnerStorage()
      const type = getType(partner || {})

      if (!products.length || !partner || !type) return

      window.open(getTemplate({ partner, products, type }), '_blank')
    },
    [getPartnerStorage],
  )

  return { sendMessage, getPartner, setPartner }
}
