import { FC } from 'react'

import { Backdrop } from '~/components/Backdrop'
import { TextButton } from '~/components/TextButton'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { IBackdropExitSavingMeasurementsMeasurements } from './types'

export const BackdropExitSavingMeasurements: FC<IBackdropExitSavingMeasurementsMeasurements> = ({
  visibleBackdrop,
  closeBackdrop,
  onClick,
}) => (
  <Backdrop layerIndex={5} visible={visibleBackdrop} testID="backdrop-exit-saving" withBackground>
    <Styled.Title align="center">{translate('BACKDROP_EXIT_SAVING_TITLE')}</Styled.Title>

    <Styled.Text align="center">{translate('BACKDROP_EXIT_SAVING_DESCRIPTION')}</Styled.Text>

    <Styled.Footer>
      <TextButton variant="outlined" testID="go-back-button" onClick={() => closeBackdrop(null)}>
        {translate('GO_BACK')}
      </TextButton>

      <TextButton testID="exit-saving-button" onClick={onClick}>
        {translate('EXIT')}
      </TextButton>
    </Styled.Footer>
  </Backdrop>
)
