export const hexToRgba = (color, opacity) => {
  let current = color.replace('#', '')

  if (current.length != 6 && current.length != 3) throw `Color ${color} have incorrect format.`

  if (current.length === 3) current = [current[0], current[0], current[1], current[1], current[2], current[2]].join('')

  current = current.match(/.{1,2}/g)

  return [parseInt(current[0], 16), parseInt(current[1], 16), parseInt(current[2], 16), opacity || 1].join(',')
}
