import { FC } from 'react'

import * as Styled from './styles'
import { IQRCodeProps } from './types'

export const QRCode: FC<IQRCodeProps> = ({ path }) => (
  <Styled.QrCodeContainer>
    <Styled.QrCode data-testid="qrcode-svg" value={path} size={120} />
  </Styled.QrCodeContainer>
)
