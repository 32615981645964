import styled from 'styled-components'

import { Prefix, Title } from '~/components/Typography/styles'

export const Pin = styled.div`
  z-index: 1;
  position: absolute;
  top: -2px;
  left: -5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.growingNature};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`
export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const IconWrapper = styled.div`
  margin-top: 20px;
  width: auto;
  display: flex;
  position: relative;
`

export const TitleFeedback = styled(Title)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  margin-top: 16px;
`

export const Text = styled(Prefix)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  margin-top: 16px;
  text-align: center;
`

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`
