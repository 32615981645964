import React from 'react'

import { TIconProps } from '.'

const Close: React.FC<TIconProps> = ({ color, size }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 12 12"
    data-testid="icon-close"
    style={{ width: size, height: size }}
  >
    <defs>
      <path
        id="icon-close-a"
        d="m7.4141 6 4.293-4.293c.391-.391.391-1.023 0-1.414s-1.023-.391-1.414 0l-4.293 4.293L1.7071.293c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414L4.5861 6l-4.293 4.293c-.391.391-.391 1.023 0 1.414.195.195.451.293.707.293.256 0 .512-.098.707-.293l4.293-4.293 4.293 4.293c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.391.391-1.023 0-1.414L7.4141 6Z"
      />
    </defs>
    <use xlinkHref="#icon-close-a" fill={color} fillRule="evenodd" data-testid="icon-close-mask" />
  </svg>
)

export default Close
