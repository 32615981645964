import { QRCodeSVG } from 'qrcode.react'
import styled from 'styled-components'

export const QrCode = styled(QRCodeSVG)`
  display: block;
`

export const QrCodeContainer = styled.div`
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  width: fit-content;
  margin: 0 auto;
`
