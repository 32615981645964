import * as poseDetection from '@tensorflow-models/pose-detection'

import { Singleton } from '~/utils/singleton'

import { IMovenetDetectorClass } from './types'

class MovenetDetectorClass extends Singleton<IMovenetDetectorClass>() {
  private _poseDetector: poseDetection.PoseDetector | undefined
  private _modelConfig: poseDetection.MoveNetModelConfig = {
    modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING,
    modelUrl: 'https://doris-mix-pose-detector-model.s3.us-east-2.amazonaws.com/model.json',
    minPoseScore: 0.3,
  }
  public isDownloading = false
  public isReady = false

  async initialize() {
    if (this._poseDetector) return this._poseDetector

    this.isDownloading = true

    this._poseDetector = await poseDetection.createDetector(poseDetection.SupportedModels.MoveNet, this._modelConfig)

    this.isDownloading = false

    return this._poseDetector
  }
}

export const MovenetDetector = MovenetDetectorClass.getInstance()
