import { FC } from 'react'

import theme from '~/theme'

import { Icon } from '../Icon'
import { CloseButton, Container, Text } from './styles'
import { INotificationProps } from './types'

export const Notification: FC<INotificationProps> = ({ show = false, onClose, children }) => {
  return (
    <Container show={show} data-testid="notification-container">
      <Icon name="warning" size="24px" color={theme.colors.status.error} />
      <Text>{children}</Text>
      <CloseButton
        testID="close-notification-button"
        onClick={onClose}
        size={30}
        icon={<Icon name="close" size="12px" color={theme.colors.neutral.black500} />}
      />
    </Container>
  )
}
