import { FC } from 'react'

import theme from '~/theme'

import { JumpingDots } from '~/libs/animations/JumpingDots'

import { Container } from './styles'
import { ILoadingDots } from './types'

export const LoadingDots: FC<ILoadingDots> = ({
  color = theme.colors.white,
  size = '12px',
  backgroundColor = theme.colors.black,
  isVisible,
  iconPosition = { horizontal: 'CENTER' },
}) => (
  <Container
    data-testid="loading-dots-container"
    aria-hidden={!isVisible}
    isVisible={isVisible}
    backgroundColor={backgroundColor}
    iconPosition={iconPosition}
  >
    <JumpingDots size={size} color={color} />
  </Container>
)
