import { gql } from '@apollo/client'

export const SUBSCRIPTION_AVATAR_SYNC = gql`
  subscription AvatarSyncSubscription(
    $user_uuid: String!
    $start_date: timestamp
    $end_date: timestamp
    $excluded_avatars: [Int]!
  ) {
    self_model(
      limit: 1
      where: {
        id: { _nin: $excluded_avatars }
        user: { uuid: { _eq: $user_uuid } }
        created_at: { _gte: $start_date, _lte: $end_date }
        model_to_tryon: {
          age: { _is_null: false }
          height: { _is_null: false }
          weight: { _is_null: false }
          status: { _eq: "PROCESSED" }
        }
      }
    ) {
      id
      updated_at
      created_at
      stage_image
      thumbnail
      user {
        uuid
      }
      model_to_tryon {
        id
        age
        avatar_uuid
        height
        weight
        gender
        age_group
      }
    }
  }
`
