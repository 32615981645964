import styled from 'styled-components'

import { Title as BaseTitle, Paragraph } from '~/components/Typography/styles'

export const Title = styled(BaseTitle)`
  padding: 20px 20px 0;
  font-weight: bold;
`

export const Text = styled(Paragraph)`
  padding: 20px 0;
`

export const Footer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`
