import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 20px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 64px;
  padding: 0 20px;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
`
