import { createContext } from 'use-context-selector'

import { ICurrentModel } from '~/hooks/useCurrentModel/types'

import { ITryonProvider } from '~/context/Tryon/types'

import { IProduct, ITryon } from '~/entities'

export interface IHistoryItem {
  model: ICurrentModel
  top?: IProduct
  bottom?: IProduct
  full?: IProduct
  imageUrl: ITryon['image_url']
  upscaledImageUrl?: string
  tryonId: number
  apiKey?: string
}

export type THistoryUpdateItem = {
  products?: ITryonProvider
  imageUrl?: ITryon['image_url']
  upscaledImageUrl?: string
  tryonId: number
}

interface IHistoryContext {
  fetchHistoryFromStorage: () => void
  itemsRef: React.MutableRefObject<Array<IHistoryItem>>
  items: Array<IHistoryItem>
  addItem: (item: IHistoryItem) => void
  updateItem: (data: THistoryUpdateItem) => void
  index: number
  getPreviousItem: () => IHistoryItem | undefined
  getCurrentItem: () => IHistoryItem
  getNextItem: () => IHistoryItem | undefined
  restartHistory: () => void
}

export const HistoryContext = createContext<IHistoryContext>({} as IHistoryContext)
