import { IMeasurement, IMeasurementWithOrder } from '~/entities/Measurement'

export const appendOrderToMeasurements = (
  measurementsSuggestion: Array<IMeasurement>,
): Array<IMeasurementWithOrder> => {
  const result: Array<IMeasurementWithOrder> = []

  measurementsSuggestion.forEach(measurement => {
    const orderFromMatchingLabel = measurement.product?.product_options.find(
      productOption => productOption.partner_size.name === measurement.label,
    )

    result.push({
      ...measurement,
      order: orderFromMatchingLabel?.partner_size.order || 0,
    })
  })

  return result
}
