import { FC } from 'react'

import { TIconProps } from '.'

const Email: FC<TIconProps> = ({ color, size }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 14 12"
    data-testid="icon-email"
    style={{ width: size, height: size }}
  >
    <g fill="none">
      <g transform="translate(-350.000000, -700.000000)">
        <g transform="translate(7.000000, 232.000000)">
          <g transform="translate(20.000000, 454.000000)">
            <g transform="translate(245.000000, 0.000000)">
              <g transform="translate(77.000000, 12.000000)">
                <path
                  id="icon-email"
                  d="M12.6666667,12 L3.33333333,12 C2.966,12 2.66666667,11.7013333 2.66666667,11.3333333 L2.66666667,4.83333333 L7.6,8.53333333 C7.71866667,8.62266667 7.85933333,8.66666667 8,8.66666667 C8.14066667,8.66666667 8.28133333,8.62266667 8.4,8.53333333 L13.3333333,4.83333333 L13.3333333,11.3333333 C13.3333333,11.7013333 13.034,12 12.6666667,12 L12.6666667,12 Z M12.222,4 L8,7.16666667 L3.778,4 L12.222,4 Z M12.6666667,2.66666667 L3.33333333,2.66666667 C2.23066667,2.66666667 1.33333333,3.564 1.33333333,4.66666667 L1.33333333,11.3333333 C1.33333333,12.436 2.23066667,13.3333333 3.33333333,13.3333333 L12.6666667,13.3333333 C13.7693333,13.3333333 14.6666667,12.436 14.6666667,11.3333333 L14.6666667,4.66666667 C14.6666667,3.564 13.7693333,2.66666667 12.6666667,2.66666667 L12.6666667,2.66666667 Z"
                />
                <use xlinkHref="#icon-email" fill={color} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Email
