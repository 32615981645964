import React from 'react'

import { TIconProps } from '.'

interface IHistoryArrow extends TIconProps {
  inverse: boolean
}

const HistoryArrow: React.FC<IHistoryArrow> = ({ color, size, inverse }) => {
  const inverseTransformValue = `scaleX(${inverse ? -1 : 1})`

  return (
    <svg
      viewBox="0 0 21 10"
      data-testid="icon-history-arrow"
      style={{
        width: size,
        height: 'auto',
        transform: inverseTransformValue,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-2-6h23.9v23.9H-2z" />
        <path
          fill={color}
          fillRule="nonzero"
          data-testid="icon-history-path"
          d="M10.7842511 1.84021164c-2.6520344 0-5.0538768 1.00152381-6.905297 2.63026456L.27619048.82857143V9.9333333H9.2830995L5.66032056 6.2711958C7.05138763 5.0976931 8.8227464 4.3693122 10.7842511 4.3693122c3.5427175 0 6.5550282 2.3368889 7.6058343 5.5640211l2.3718194-.7890793c-1.3910671-4.2387725-5.3240841-7.30404236-9.9776537-7.30404236Z"
        />
      </g>
    </svg>
  )
}

export default HistoryArrow
