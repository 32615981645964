import styled, { css, keyframes } from 'styled-components'

import { ISnackBarProps, TSnackBarAnimationProps, TSnackBarCountDownProps } from './types'

const snackBarCountDownBorderToBorder = keyframes`
  from { width: 100%; }
  to { width: 0; }
`

const snackBarCountDownCenterToBorder = keyframes`
  from { transform: scaleX(0); height: 4px; }
  to { transform: scaleX(1); height: 4px; }
`

const snackBarCountDownBorderToCenter = keyframes`
  from { transform: scaleX(1); }
  to { transform: scaleX(0); }
`

const snackBarFadeIn = keyframes`
  from {
    transform: translateY(-80px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const snackBarFadeOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-80px);
    opacity: 0;
  }
`

export const Container = styled.div<TSnackBarAnimationProps & Pick<ISnackBarProps, 'visible' | 'durationInSeconds'>>`
  align-items: center;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  backdrop-filter: blur(24px);
  background: rgba(255, 255, 255, 0.75);
  grid-template-columns: 1fr 0.8fr;
  overflow: hidden;
  position: absolute;
  width: 382px;
  z-index: 5;
  animation-duration: ${({ $animationDuration }) => $animationDuration}ms;
  animation-name: ${snackBarFadeIn};
  border-radius: ${props => props.theme.measures.margin};
  display: ${({ visible }) => (visible ? 'grid' : 'none')};
  grid-column-gap: ${props => props.theme.measures.margin};
  left: ${props => props.theme.measures.margin};
  padding: ${props => props.theme.measures.margin};
  right: ${props => props.theme.measures.margin};
  top: ${props => props.theme.measures.margin};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  @media ${props => props.theme.media.devices.mobile.portrait} {
    width: calc(100vw - 52px);
  }

  &.start-fade-out {
    animation-name: ${snackBarFadeOut};
    animation-delay: ${({ durationInSeconds = 15, $delayToCountDown }) => durationInSeconds + $delayToCountDown}ms;
  }
`

export const CountDown = styled.span<TSnackBarCountDownProps & Pick<ISnackBarProps, 'animationOrientation'>>`
  background-color: ${props => props.theme.colors.primary};
  animation-duration: ${({ $timer }) => $timer}s;
  animation-delay: ${({ $delayToCountDown }) => $delayToCountDown}ms;
  animation-name: ${({ animationOrientation }) => {
    switch (animationOrientation) {
      case 'border-to-center':
        return snackBarCountDownBorderToCenter
      case 'center-to-border':
        return snackBarCountDownCenterToBorder
      default:
        return snackBarCountDownBorderToBorder
    }
  }};
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  border-radius: 12px;
  position: absolute;
  display: block;
  width: 100%;
  height: ${({ animationOrientation }) => (animationOrientation === 'center-to-border' ? 0 : '4px')};
  bottom: 0;
  right: 0;
  ${({ animationOrientation }) =>
    animationOrientation === 'left-to-right'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `};
`

export const SnackBarText = styled.p`
  font-size: 12px;
  font-weight: 600;
  margin: 0;
`
