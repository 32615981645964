import React from 'react'

import { TIconProps } from '.'

const Dress: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 26 36"
      data-testid="icon-dress"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M25.25 33.31C23.4435 27.6524 21.2414 22.1287 18.66 16.78C18.5455 16.5641 18.2918 16.4626 18.06 16.54C16.4249 17.0718 14.7192 17.3549 13 17.38V17.38C11.2808 17.3549 9.57511 17.0718 7.94001 16.54C7.70824 16.4626 7.45451 16.5641 7.34001 16.78C4.75867 22.1287 2.55658 27.6524 0.750014 33.31C0.66921 33.5391 0.772257 33.7924 0.990014 33.9C2.08001 34.46 5.69001 35.95 12.99 35.95C20.29 35.95 23.91 34.46 24.99 33.9C25.2155 33.7995 25.328 33.5443 25.25 33.31Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M8.20998 13.08L8.40998 13.14C8.80998 13.2733 9.21998 13.3933 9.63998 13.5C10.4569 13.7172 11.2894 13.871 12.13 13.96C12.44 13.96 12.73 14.01 13 14.01C13.27 14.01 13.56 14.01 13.88 13.96C14.7173 13.871 15.5464 13.7172 16.36 13.5C16.78 13.39 17.2 13.27 17.59 13.14L17.79 13.08C18.42 11.25 19.64 8.25 19.64 6.7C19.6492 5.4043 19.1032 4.16665 18.14 3.3C18.14 3.3 18.14 0.629997 16.88 0.0599969C16.43 -3.05055e-06 16.55 0.449997 16.55 0.449997V0.449997C16.94 1.82 17.6 5.57 13 5.57C8.39998 5.57 9.05998 1.82 9.44998 0.449997V0.449997C9.44998 0.449997 9.56998 -3.05055e-06 9.11998 0.0599969C7.86998 0.629997 7.85998 3.3 7.85998 3.3C6.89673 4.16665 6.35071 5.4043 6.35998 6.7C6.35998 8.25 7.57998 11.25 8.20998 13.08V13.08Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M8.33001 14.71V14.71L8.21001 15.02C8.17267 15.1193 8.17976 15.2299 8.2295 15.3236C8.27924 15.4174 8.36683 15.4852 8.47001 15.51L9.08001 15.68C10.0048 15.9278 10.9477 16.1016 11.9 16.2H12C12.1904 16.5775 12.5772 16.8156 13 16.8156C13.4228 16.8156 13.8096 16.5775 14 16.2H14.1C15.0523 16.1016 15.9953 15.9278 16.92 15.68L17.53 15.51C17.6332 15.4852 17.7208 15.4174 17.7705 15.3236C17.8203 15.2299 17.8274 15.1193 17.79 15.02L17.67 14.71V14.71C17.51 14.29 17 14.49 17 14.49V14.49L16.64 14.59C15.771 14.8229 14.8849 14.9868 13.99 15.08V15.08C13.7824 14.7563 13.4245 14.5604 13.04 14.56V14.56C12.6555 14.5604 12.2976 14.7563 12.09 15.08V15.08C11.1951 14.9868 10.3091 14.8229 9.44001 14.59L9.08001 14.49V14.49C9.08001 14.49 8.49001 14.29 8.33001 14.71Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Dress
