import styled from 'styled-components'

import { IModal } from './types'

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 0 20px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
`

export const ModalContainer = styled.div<Pick<IModal, 'isVisible'>>`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 32px;
  border-radius: 24px;
  z-index: 9999;
  transition: opacity ease 0.5s;
  z-index: 5;

  background-color: ${props => props.theme.colors.white};
  visibility: ${({ isVisible }) => (!isVisible ? 'hidden' : 'visible')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`

export const Shadow = styled.div<Pick<IModal, 'isVisible'>>`
  position: absolute;
  background-color: #000;
  visibility: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  transition: opacity ease 0.3s;
  z-index: 4;

  opacity: ${({ isVisible }) => (isVisible ? 0.35 : 0)};
  visibility: ${({ isVisible }) => (!isVisible ? 'hidden' : 'visible')};
`
