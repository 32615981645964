import { FC } from 'react'

import theme from '~/theme'

import * as Styled from './styles'
import { SizingLabel } from '../SizingLabel'
import { sizingDescriptionsColorsMap, sizingMessagesColorsMap, sizingMessagesMap } from './constants'
import { ISizingButtonProps } from './types'

export const SizingButton: FC<ISizingButtonProps> = ({
  size,
  message,
  inStock,
  active = false,
  onClick = () => ({}),
  disabled = false,
}) => (
  <Styled.Container
    active={active}
    inStock={inStock}
    data-testid="sizing-button"
    onClick={() => onClick(size)}
    disabled={disabled}
  >
    <Styled.TextWrapper>
      <Styled.FitMessage data-testid="sizing-message" color={sizingMessagesColorsMap[message]} inStock={inStock}>
        {sizingMessagesMap[message].title}
      </Styled.FitMessage>
      {sizingMessagesMap[message]?.description && (
        <Styled.DescriptionMessage
          data-testid="sizing-description"
          color={sizingDescriptionsColorsMap[message] as string}
        >
          {sizingMessagesMap[message].description}
        </Styled.DescriptionMessage>
      )}
    </Styled.TextWrapper>
    <SizingLabel strike={!inStock} color={inStock ? sizingMessagesColorsMap[message] : theme.colors.neutral.black400}>
      {size}
    </SizingLabel>
  </Styled.Container>
)
