import { FC } from 'react'

import { TIconProps } from '.'

export const Success: FC<TIconProps> = ({ size }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      data-testid="icon-success"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <rect width="16" height="16" rx="8" fill="#5BBF22" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.57567 11.9999C6.39167 11.9999 6.21567 11.9239 6.08967 11.7899L2.84767 8.33725C2.595 8.06925 2.609 7.64725 2.877 7.39525C3.14567 7.14325 3.56767 7.15658 3.819 7.42458L6.569 10.3519L12.1743 4.21725C12.4237 3.94458 12.845 3.92658 13.117 4.17458C13.3883 4.42258 13.407 4.84458 13.159 5.11591L7.06767 11.7826C6.943 11.9199 6.76567 11.9986 6.58034 11.9999H6.57567Z"
        fill="white"
      />
    </svg>
  )
}
