import styled, { css } from 'styled-components'

export const Container = styled.div<{ open: boolean; scale: number }>`
  position: fixed;
  width: ${props => (props.theme.isWebview ? '420px' : '414px')};
  height: ${props => (props.theme.isWebview ? '810px' : '780px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: ${props => (props.theme.isWebview ? 0 : 25)}px;
  background-color: white;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  transition: opacity ease 0.5s, transform ease 0.25s, bottom ease 0.5s;
  z-index: 2147483648;
  opacity: ${props => (props.open ? '1' : '0.5')};
  bottom: ${props => (props.open && !props.theme.isWebview ? '15px' : '0')};
  transform: ${props => `scale(${props.scale}) ${props.open ? 'translateY(0)' : 'translateY(calc(100% + 10px))'}`};

  ${props =>
    css`
      ${props.theme.position.x}: ${props.theme.isWebview ? 0 : 15}px
    `};
  transform-origin: ${props => (props.theme.position.x === 'left' ? 'left bottom' : 'right bottom')};

  @media ${props => props.theme.media.devices.mobile.portrait} {
    width: ${props => (props.theme.isWebview ? '100%' : 'calc(100vw - 20px)')};
    height: ${props => (props.theme.isWebview ? '100%' : 'calc(100% - 20px)')};
    box-shadow: ${props => (props.open ? '0 0 0 50px rgba(0, 0, 0, 0.6)' : '0')};
    transform: ${props => `${props.open ? 'translateY(0)' : 'translateY(calc(100% + 10px))'}`};
    bottom: ${props => (props.theme.isWebview ? 0 : 10)}px;
    right: ${props => (props.theme.isWebview ? 0 : 10)}px;
    left: auto;
  }

  @media ${props => props.theme.media.devices.mobile.landscape} {
    display: none !important;
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`
