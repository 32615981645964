import styled, { css } from 'styled-components'

import { ISizingLabelProps } from '~/components/SizingLabel/types'

export const SizeLabel = styled.span<ISizingLabelProps>`
  position: relative;
  display: block;
  border-radius: 100%;
  width: ${({ size }) => size || '40px'};
  height: ${({ size }) => size || '40px'};
  line-height: ${({ size }) => size || '40px'};
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.neutral.black100};
  color: ${props => props.color};

  ${({ strike, theme }) =>
    strike &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 2px;
        height: 100%;
        background-color: ${theme.colors.neutral.black400};
        transform: rotate(45deg);
      }
    `};
`
