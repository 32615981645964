interface IToCurrencyParams {
  value: number
}

export const defaultLocale = 'pt-BR'
export const defaultCurrency = 'BRL'

export const toCurrency = ({ value }: IToCurrencyParams): string => {
  const partner = JSON.parse(localStorage.getItem('@doris:partner') ?? '{}')
  const currency = partner.currency || defaultCurrency
  const locale = partner.locale || defaultLocale

  const inCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value)

  return inCurrency
}
