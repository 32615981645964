import React from 'react'

import { TIconProps } from '.'

const Tape: React.FC<TIconProps> = ({ color, size }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 23 16"
    data-testid="icon-tape"
    style={{ fill: color, width: size, height: size }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.413 4.45033C10.413 5.57775 9.19103 6.46358 7.63579 6.46358C6.08054 6.46358 4.85856 5.57775 4.85856 4.45033C4.85856 3.32291 6.08054 2.43709 7.63579 2.43709C9.19103 2.43709 10.413 3.32291 10.413 4.45033ZM9.0244 4.45033C9.0244 4.17308 8.48721 3.77925 7.63579 3.77925C6.78437 3.77925 6.24717 4.17308 6.24717 4.45033C6.24717 4.72758 6.80262 5.12141 7.63579 5.12141C8.46896 5.12141 9.0244 4.71876 9.0244 4.45033Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9508 7.55543H21.5968C21.9728 7.55543 22.2843 7.85752 22.2843 8.2221V15.3333C22.2843 15.6979 21.9728 16 21.5968 16H7.61777C3.51084 16 0.284302 14.0446 0.284302 11.5554V4.44457C0.284302 1.95543 3.5018 0 7.61777 0C11.7337 0 14.9508 1.95543 14.9508 4.44457V7.55543ZM13.5758 4.44457C13.5758 2.75543 10.8443 1.33333 7.61777 1.33333C4.39123 1.33333 1.6593 2.75543 1.6593 4.44457C1.6593 6.13371 4.39123 7.55543 7.61777 7.55543C10.8443 7.55543 13.5758 6.13333 13.5758 4.44457ZM18.6178 14.6667H20.9093V8.88876H7.61777C5.13373 8.88876 2.9793 8.16876 1.6593 7.05752V11.5554C1.6593 13.2446 4.39123 14.6667 7.61777 14.6667V12.6667C7.61777 12.3021 7.9293 12 8.30527 12C8.68123 12 8.99277 12.3021 8.99277 12.6667V14.6667H10.8258V12.6667C10.8258 12.3021 11.1374 12 11.5133 12C11.8893 12 12.2008 12.3021 12.2008 12.6667V14.6667H14.0343V12.6667C14.0343 12.3021 14.3458 12 14.7218 12C15.0978 12 15.4093 12.3021 15.4093 12.6667V14.6667H17.2428V12.6667C17.2428 12.3021 17.5543 12 17.9303 12C18.3062 12 18.6178 12.3021 18.6178 12.6667V14.6667Z"
    />
  </svg>
)

export default Tape
