import theme from '~/theme'

import { translate } from '~/utils/translate'

import { TSizingMapColors, TSizingMapItem } from './types'

export const sizingMessagesMap: TSizingMapItem = {
  TOO_SMALL: {
    title: translate('SIZING_TAG_TOO_SMALL'),
  },
  TIGHT: {
    title: translate('SIZING_TAG_TIGHT'),
  },
  SOME_TIGHT: {
    title: translate('SIZING_TAG_SOME_TIGHT'),
    description: translate('SIZING_TAG_SOME_TIGHT_DESCRIPTION'),
  },
  FIT: {
    title: translate('SIZING_TAG_FIT'),
  },
  SOME_LOOSE: {
    title: translate('SIZING_TAG_SOME_LOOSE'),
    description: translate('SIZING_TAG_SOME_LOOSE_DESCRIPTION'),
  },
  LOOSE: {
    title: translate('SIZING_TAG_LOOSE'),
  },
  TOO_BIG: {
    title: translate('SIZING_TAG_TOO_BIG'),
  },
}

export const sizingMessagesColorsMap: TSizingMapColors = {
  TOO_SMALL: theme.colors.status.error,
  TIGHT: theme.colors.status.error,
  SOME_TIGHT: theme.colors.status.success,
  FIT: theme.colors.status.success,
  SOME_LOOSE: theme.colors.status.success,
  LOOSE: theme.colors.status.error,
  TOO_BIG: theme.colors.status.error,
}

export const sizingDescriptionsColorsMap: Partial<TSizingMapColors> = {
  SOME_TIGHT: theme.colors.status.warning,
  SOME_LOOSE: theme.colors.status.warning,
}
