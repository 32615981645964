import { useCallback } from 'react'

import { getPartnerAPIKey } from '~/utils/getPartnerAPIKey'

import { LOCAL_STORAGE_MODELS_KEY } from './constants'
import { ICurrentModel, IUseCurrentModel, TDefaultModels, TStorage } from './types'

export const useCurrentModel = (): IUseCurrentModel => {
  const deleteCurrentModel = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_MODELS_KEY)
  }, [])

  const getStorage = useCallback((): TStorage | undefined => {
    const storage = localStorage.getItem(LOCAL_STORAGE_MODELS_KEY)

    return storage && JSON.parse(storage)
  }, [])

  const getCurrentModel = useCallback(
    (): ICurrentModel => (getStorage()?.current && getStorage()?.current[getPartnerAPIKey()]) as ICurrentModel,
    [getStorage],
  )

  const setCurrentModel = useCallback(
    (item: ICurrentModel) => {
      const modelsStorage = getStorage() || { current: null }

      modelsStorage.current = {
        ...modelsStorage.current,
        [getPartnerAPIKey()]: item,
      }

      localStorage.setItem(LOCAL_STORAGE_MODELS_KEY, JSON.stringify(modelsStorage))
    },
    [getStorage],
  )

  const setDefaultModels = useCallback(
    (items: Array<ICurrentModel>) => {
      const modelsStorage = getStorage() || ({ default: {} } as TStorage)

      items?.forEach(item => {
        modelsStorage.default = {
          ...modelsStorage?.default,
          [getPartnerAPIKey()]: {
            ...(modelsStorage?.default && modelsStorage?.default[getPartnerAPIKey()]),
            [item?.gender?.toLowerCase() as string]: item,
          },
        }
      })

      localStorage.setItem(LOCAL_STORAGE_MODELS_KEY, JSON.stringify(modelsStorage))
    },
    [getStorage],
  )

  const getDefaultModels = useCallback((): TDefaultModels => {
    const storage = getStorage()

    return storage?.default && storage?.default[getPartnerAPIKey()]
  }, [getStorage])

  return {
    getCurrentModel,
    setCurrentModel,
    setDefaultModels,
    getDefaultModels,
    deleteCurrentModel,
  }
}
