import { FC } from 'react'

import { Backdrop } from '~/components/Backdrop'
import { Icon } from '~/components/Icon'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { ICartFeedbackBackdropProps } from './types'

export const CartFeedbackBackdrop: FC<ICartFeedbackBackdropProps> = ({
  visible,
  onClickKeepBuying,
  onClickGoToCart,
  hasCartUrl = false,
}) => (
  <Backdrop testID="backdrop-cart-feedback" visible={visible} height="100%">
    <Styled.Container>
      <Styled.ContentContainer>
        <Styled.IconWrapper>
          <Icon name="shop" size="58px" color={theme.colors.primary} />
          <Styled.Pin>
            <Icon name="checked" size="12px" />
          </Styled.Pin>
        </Styled.IconWrapper>
        <Styled.TitleFeedback>{translate('CART_FEEDBACK_BACKDROP_TITLE')}</Styled.TitleFeedback>
        <Styled.Text>{translate('CART_FEEDBACK_BACKDROP_DESCRIPTION')}</Styled.Text>
      </Styled.ContentContainer>
      <Styled.ContainerButton>
        <TextButton
          testID="keep-buying-button"
          onClick={onClickKeepBuying}
          variant={hasCartUrl ? 'outlined' : 'default'}
        >
          {translate('CART_FEEDBACK_BACKDROP_KEEP_BUYING_BUTTON')}
        </TextButton>
        {hasCartUrl && (
          <TextButton testID="go-to-cart-button" onClick={onClickGoToCart}>
            {translate('CART_FEEDBACK_BACKDROP_GO_TO_CART_BUTTON')}
          </TextButton>
        )}
      </Styled.ContainerButton>
    </Styled.Container>
  </Backdrop>
)
