import Compressor from 'compressorjs'

export const getCompressedImage = (file: File): Promise<string> =>
  new Promise(
    async (resolve, reject) =>
      new Compressor(file, {
        checkOrientation: true,
        strict: true,
        quality: 0.7,
        maxWidth: 2048,
        success: compressedFile => {
          const reader = new FileReader()

          reader.onloadend = () => resolve(reader.result as string)
          reader.readAsDataURL(compressedFile)
        },
        error: error => reject(error),
      }),
  )
