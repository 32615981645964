import { FC } from 'react'

import { TIconProps } from '.'

export const Timer: FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      data-testid="icon-timer"
      style={{
        height: size,
        width: size,
      }}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          d="M14.3333333,12.3809524 L12.7777778,12.3809524 L12.7777778,10.8571429 C12.7777778,10.4358095 12.4293333,10.0952381 12,10.0952381 C11.5706667,10.0952381 11.2222222,10.4358095 11.2222222,10.8571429 L11.2222222,13.1428571 C11.2222222,13.5641905 11.5706667,13.9047619 12,13.9047619 L14.3333333,13.9047619 C14.7626667,13.9047619 15.1111111,13.5641905 15.1111111,13.1428571 C15.1111111,12.7215238 14.7626667,12.3809524 14.3333333,12.3809524 M12,18.2857143 C9.10511111,18.2857143 6.75,15.9786667 6.75,13.1428571 C6.75,10.3070476 9.10511111,8 12,8 C14.8948889,8 17.25,10.3070476 17.25,13.1428571 C17.25,15.9786667 14.8948889,18.2857143 12,18.2857143 M12.7684444,6.32990476 C12.77,6.31390476 12.7777778,6.30171429 12.7777778,6.28571429 L12.7777778,5.52380952 L13.5555556,5.52380952 C13.9848889,5.52380952 14.3333333,5.1832381 14.3333333,4.76190476 C14.3333333,4.34057143 13.9848889,4 13.5555556,4 L10.4444444,4 C10.0151111,4 9.66666667,4.34057143 9.66666667,4.76190476 C9.66666667,5.1832381 10.0151111,5.52380952 10.4444444,5.52380952 L11.2222222,5.52380952 L11.2222222,6.28571429 C11.2222222,6.30171429 11.23,6.31390476 11.2315556,6.32990476 C7.73233333,6.70628571 5,9.6167619 5,13.1428571 C5,16.9241905 8.14066667,20 12,20 C15.8593333,20 19,16.9241905 19,13.1428571 C19,9.6167619 16.2676667,6.70628571 12.7684444,6.32990476"
          id="path-1"
        />
      </defs>
      <g id="05-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <mask id="mask-2" fill={color}>
          <use xlinkHref="#path-1" />
        </mask>
        <use data-testid="icon-timer-color" id="timer-icon-color" fill={color} xlinkHref="#path-1" />
      </g>
    </svg>
  )
}
