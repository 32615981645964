import styled from 'styled-components'

import { TIconProps } from '..'

const Svg = styled.svg<Pick<TIconProps, 'size'>>`
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(720deg);
    }
  }

  width: ${({ size }) => size};
  height: ${({ size }) => size};
  animation: rotating 1.2s ease-in-out infinite;
`
export { Svg }
