import posthog from 'posthog-js'

import { BLACK_FRIDAY_TOOLTIP_POSTHOG_KEY } from '~/components/BottomActionsContainer'

import { PostHogUtil } from '~/utils/postHog'

import { LOCAL_TRANSLATION_STORAGE_KEY } from '../translate/constants'

export const BLACK_FRIDAY_STORAGE_KEY = '@doris:black-friday-tooltip-content'

export const handleABTests = () => {
  posthog.onFeatureFlags(function () {
    const NEWSLETTER_FLAG = 'NEWSLETTER_CONTENT'

    const newsletterFlagResult = PostHogUtil.getFeatureFlagPayload(NEWSLETTER_FLAG)
    const blackFridayFlagResult = PostHogUtil.getFeatureFlagPayload(BLACK_FRIDAY_TOOLTIP_POSTHOG_KEY)

    if (blackFridayFlagResult) {
      localStorage.setItem(BLACK_FRIDAY_STORAGE_KEY, JSON.stringify(blackFridayFlagResult))
    }

    /**
     * We're hard-coding the "pt" key below because we're only going to use the translation
     * in a controlled environent that uses brazilian portuguese as default language.
     *
     * An important thing to note is that i'm already saving the result inside the PT key so
     * when we retrieve the information from storage in another class we can already
     * implement the retrieval considering the language keys, despite it's hard-coded.
     */
    localStorage.setItem(LOCAL_TRANSLATION_STORAGE_KEY, JSON.stringify({ pt: newsletterFlagResult }))
  })
}
