import { TAgeGroup, TGender } from '~/entities'

import { fullData } from './data'
import { TCollectionOption } from './types'

export const getCollectionOptions = (): TCollectionOption[] => {
  const partnerDataOnStorage = localStorage.getItem('@doris:partner')
  const parsedData = partnerDataOnStorage ? JSON.parse(partnerDataOnStorage) : {}

  if (parsedData?.taxonomies) {
    return fullData.filter(item => {
      const matchesGender =
        parsedData.taxonomies.gender.length === 0 || parsedData.taxonomies.gender.includes(item.gender)
      const matchesAgeGroup =
        parsedData.taxonomies.age_group.length === 0 || parsedData.taxonomies.age_group.includes(item.age_group)

      return matchesGender && matchesAgeGroup
    })
  }

  return [fullData[0], fullData[1]]
}

export const doPartnerHave = (genderOrAgeGroup: TAgeGroup | TGender): undefined | boolean => {
  const partnerDataOnStorage = localStorage.getItem('@doris:partner')

  if (!partnerDataOnStorage) return undefined

  const parsedData = JSON.parse(partnerDataOnStorage)

  if (parsedData.taxonomies.gender.includes(genderOrAgeGroup)) return true

  if (parsedData.taxonomies.age_group.includes(genderOrAgeGroup)) return true

  return false
}
