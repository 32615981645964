export const injectFonts = () => {
  const addGoogleAPIsPreconnect = () => {
    if (document.querySelectorAll('link[rel="preconnect"][href="https://fonts.googleapis.com"]').length) return

    const preconnectGoogleAPIs = document.createElement('link')
    preconnectGoogleAPIs.rel = 'preconnect'
    preconnectGoogleAPIs.href = 'https://fonts.googleapis.com'

    document.head.appendChild(preconnectGoogleAPIs)
  }

  const addGoogleStaticPreconnect = () => {
    if (document.querySelectorAll('link[rel="preconnect"][href="https://fonts.gstatic.com"][crossorigin]').length)
      return

    const preconnectGoogleStatic = document.createElement('link')
    preconnectGoogleStatic.rel = 'preconnect'
    preconnectGoogleStatic.href = 'https://fonts.gstatic.com'
    preconnectGoogleStatic.crossOrigin = 'crossorigin'

    document.head.appendChild(preconnectGoogleStatic)
  }

  const addGoogleStylesheet = () => {
    if (
      document.querySelectorAll('link[rel="stylesheet"][href*="https://fonts.googleapis.com"][href*="Montserrat"]')
        .length
    )
      return

    const fontImport = document.createElement('link')
    fontImport.rel = 'stylesheet'
    fontImport.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap'

    document.head.appendChild(fontImport)
  }

  addGoogleAPIsPreconnect()
  addGoogleStaticPreconnect()
  addGoogleStylesheet()
}
