import { FC } from 'react'

import { Icon } from '~/components/Icon'

import theme from '~/theme'

import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import { Container, Text } from './styles'

export interface IGoToProductLinkProps {
  url: string
  sku: string
  padding?: string
}

const GoToProductLink: FC<IGoToProductLinkProps> = ({ url, sku, padding }) => {
  const handleOnClick = () => {
    Tracking.logEvent('CART_URL', {
      sku,
      url,
      widget: true,
    })
  }

  return (
    <Container
      data-testid="go-to-product-button"
      title={translate('BACKDROP_CART_GO_TO_PRODUCT_BUTTON')}
      href={url}
      target="_blank"
      rel="external"
      onClick={handleOnClick}
      padding={padding}
    >
      <Text data-testid="go-to-product-text">{translate('BACKDROP_CART_GO_TO_PRODUCT_BUTTON')}</Text>
      <Icon name="arrowRight" color={theme.colors.primary} size="10px" />
    </Container>
  )
}

export default GoToProductLink
