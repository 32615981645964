import { FC } from 'react'

import { IIllustrationProps } from '.'

export const UpsideDownFace: FC<IIllustrationProps> = ({ width, height }) => (
  <svg
    style={{ width, height }}
    data-testid="illustration-upside-down-face"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="10 10 61 61"
  >
    <radialGradient
      id="SVGID_1_"
      cx="-536.1987"
      cy="1291.0151"
      r="1"
      gradientTransform="matrix(-36.7639 0 0 36.7639 -19666.2852 -47409.9258)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: '#FFDF30' }} />
      <stop offset="1" style={{ stopColor: '#FFB82E' }} />
    </radialGradient>

    <path
      style={{
        fill: 'url(#SVGID_1_)',
      }}
      d="M10,40.5C10,23.6,23.6,10,40.5,10s30.5,13.6,30.5,30.5S57.3,70.9,40.5,70.9S10,57.3,10,40.5z"
    />

    <radialGradient
      id="SVGID_00000152984584228793510350000002935818193717683391_"
      cx="-540.0474"
      cy="1299.6931"
      r="1"
      gradientTransform="matrix(-28.9238 0 0 28.9238 -15573.7529 -37539.2383)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: '#FFE95F' }} />
      <stop offset="1" style={{ stopColor: '#FFBB47', stopOpacity: 0 }} />
    </radialGradient>

    <path
      style={{ opacity: 0.5, fill: 'url(#SVGID_00000152984584228793510350000002935818193717683391_)' }}
      d="
		M10,40.5C10,23.6,23.6,10,40.5,10s30.5,13.6,30.5,30.5S57.3,70.9,40.5,70.9S10,57.3,10,40.5z"
    />

    <radialGradient
      id="SVGID_00000046320216357909920250000008497266822214860474_"
      cx="-818.2782"
      cy="1196.504"
      r="1"
      gradientTransform="matrix(-1.1974 -4.136 -2.6759 0.7747 2273.2964 -4265.5659)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.325650e-03" style={{ stopColor: '#7A4400' }} />
      <stop offset="1" style={{ stopColor: '#643800' }} />
    </radialGradient>

    <path
      style={{ fill: 'url(#SVGID_00000046320216357909920250000008497266822214860474_)' }}
      d="M50.4,50.3c1.6,0,2.9-2,2.9-4.4
		s-1.3-4.4-2.9-4.4c-1.6,0-2.9,2-2.9,4.4S48.8,50.3,50.4,50.3z"
    />

    <linearGradient
      id="SVGID_00000093864713592471536820000004458370301895194037_"
      gradientUnits="userSpaceOnUse"
      x1="50.4244"
      y1="31.6404"
      x2="50.4244"
      y2="35.7763"
      gradientTransform="matrix(1 0 0 -1 0 82)"
    >
      <stop offset="1.325650e-03" style={{ stopColor: '#3C2200' }} />
      <stop offset="1" style={{ stopColor: '#512D00' }} />
    </linearGradient>

    <path
      style={{ fill: 'url(#SVGID_00000093864713592471536820000004458370301895194037_)' }}
      d="M50.4,48.8c-1.3,0-2.4-1.1-2.9-2.7
		c0,2.4,1.3,4.3,2.9,4.3c1.6,0,2.9-1.9,2.9-4.3C52.8,47.7,51.7,48.8,50.4,48.8z"
    />

    <path
      style={{
        fill: '#643800',
      }}
      d="M27,31.6c0.3,0.1,0.5,0,0.7-0.2c3.3-3.1,8.3-4.7,12.1-4.7h0.6h0.6c3.8,0,8.9,1.6,12.1,4.7
		c0.2,0.2,0.4,0.3,0.7,0.2c0.3-0.1,0.4-0.3,0.3-0.6c-2.8-4.8-8-7.8-13.6-7.8h-0.2h-0.2c-5.6,0-10.8,3-13.6,7.8
		C26.6,31.2,26.7,31.5,27,31.6z"
    />

    <radialGradient
      id="SVGID_00000039102468467430950370000009288542208581988766_"
      cx="-818.2782"
      cy="1196.5038"
      r="1"
      gradientTransform="matrix(-1.1974 -4.136 -2.6759 0.7747 2253.3838 -4265.563)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.325650e-03" style={{ stopColor: '#7A4400' }} />
      <stop offset="1" style={{ stopColor: '#643800' }} />
    </radialGradient>

    <path
      style={{ fill: 'url(#SVGID_00000039102468467430950370000009288542208581988766_)' }}
      d="M30.5,50.3c1.6,0,2.9-2,2.9-4.4
		s-1.3-4.4-2.9-4.4c-1.6,0-2.9,2-2.9,4.4S28.9,50.3,30.5,50.3z"
    />

    <linearGradient
      id="SVGID_00000132083819608292612600000008417035590473968046_"
      gradientUnits="userSpaceOnUse"
      x1="30.5257"
      y1="31.6402"
      x2="30.5257"
      y2="35.7761"
      gradientTransform="matrix(1 0 0 -1 0 82)"
    >
      <stop offset="1.325650e-03" style={{ stopColor: '#3C2200' }} />
      <stop offset="1" style={{ stopColor: '#512D00' }} />
    </linearGradient>

    <path
      style={{ fill: 'url(#SVGID_00000132083819608292612600000008417035590473968046_)' }}
      d="M30.5,48.8c1.3,0,2.4-1.1,2.9-2.7
		c0,2.4-1.3,4.3-2.9,4.3c-1.6,0-2.9-1.9-2.9-4.3C28.2,47.7,29.3,48.8,30.5,48.8z"
    />

    <linearGradient
      id="SVGID_00000107566336266633083040000015495666390643170714_"
      gradientUnits="userSpaceOnUse"
      x1="40.4675"
      y1="52.5918"
      x2="40.4675"
      y2="57.1412"
      gradientTransform="matrix(1 0 0 -1 0 82)"
    >
      <stop offset="1.325650e-03" style={{ stopColor: '#3C2200' }} />
      <stop offset="1" style={{ stopColor: '#512D00' }} />
    </linearGradient>

    <path
      style={{ fill: 'url(#SVGID_00000107566336266633083040000015495666390643170714_)' }}
      d="M27,31.6c0.3,0.1,0.5,0,0.7-0.2
		c3.3-3.1,8.3-4.7,12.1-4.7h0.6h0.6c3.8,0,8.9,1.6,12.1,4.7c0.2,0.2,0.4,0.3,0.7,0.2c0.3-0.1,0.4-0.3,0.3-0.6c0-0.1-0.1-0.1-0.1-0.1
		c-0.3,0.2-0.5,0.3-0.8,0c-3.1-3.5-7.7-5.6-12.6-5.6h-0.2h-0.2c-5,0-9.6,2.1-12.7,5.6c-0.3,0.3-0.6,0.2-0.8,0c0,0,0,0.1-0.1,0.1
		C26.6,31.2,26.7,31.5,27,31.6z"
    />
  </svg>
)
