import React from 'react'

import { TIconProps } from '.'

const TShirt: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 34 34"
      data-testid="icon-tshirt"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-7-7h48v48H-7z" />
        <path
          d="M33.79 10.9A19.73 19.73 0 0 0 24.05.09a1 1 0 0 0-1.22.3c-1.14 1.35-3.48 2-5.83 2s-4.69-.67-5.83-2A1 1 0 0 0 10 .09 19.73 19.73 0 0 0 .21 10.9a1.05 1.05 0 0 0 .51 1.32L6 14.83a1 1 0 0 0 1.25-.25l.4-.47v18.85a1 1 0 0 0 1 1h16.67a1 1 0 0 0 1-1V14.11l.4.47a1 1 0 0 0 1.25.25l5.26-2.61a1.05 1.05 0 0 0 .56-1.32Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default TShirt
