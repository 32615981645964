import { createContext } from 'use-context-selector'

import { TCallbackRef } from './types'

interface IHandleBuyButtonContext {
  handleBuyButtonRef: React.MutableRefObject<TCallbackRef | undefined>
}

export const HandleBuyButtonContext = createContext<IHandleBuyButtonContext | null>(null)

export type { TCallbackRef }
