export const getTryonProducts = (skus = [], products = []) => {
  const tryonProducts = {}

  skus.forEach(sku => {
    const productsData = products.filter(product => product.identifier === sku)

    if (!productsData.length) return

    const lastProduct = productsData[productsData.length - 1]

    tryonProducts[lastProduct.category.type.toLowerCase()] = lastProduct
  })

  return tryonProducts
}
