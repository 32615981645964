import { IModelsQueryData } from '~/hooks-queries/models/types'

import { IModel } from '~/entities'

export const modelsFormatted = (data: IModelsQueryData): Array<IModel> =>
  data.models.map(model => ({
    ...model,
    details: {
      id: model.details.model_to_tryon.avatar_uuid,
      gender: model.details.model_to_tryon.gender,
      age_group: model.details.model_to_tryon.age_group,
      skin_tone_id: model.details.skin_tone_id,
      stage_image: model.details.stage_image,
      thumbnail: model.details.thumbnail,
    },
  }))
