import { useCallback, useRef, useState } from 'react'

import { useSubscription } from '@apollo/client'

import { defaultErrorMessage } from './constants'
import { SUBSCRIPTION_AVATAR_SYNC } from './graphql'
import {
  IAvatarSyncQueryData,
  IAvatarSyncQueryVars,
  IAvatarSyncSubscribeParams,
  IUseAvatarSync,
  TAvatarSyncOnError,
  TAvatarSyncOnSuccess,
} from './types'

export const useAvatarSync = (): IUseAvatarSync => {
  const [variables, setVariables] = useState<IAvatarSyncQueryVars | null>(null)

  const onSuccessRef = useRef<{ call: TAvatarSyncOnSuccess }>()
  const onErrorRef = useRef<{ call: TAvatarSyncOnError }>()

  useSubscription<IAvatarSyncQueryData, IAvatarSyncQueryVars>(SUBSCRIPTION_AVATAR_SYNC, {
    variables: variables as IAvatarSyncQueryVars,
    skip: !variables,
    onData: ({ data }) => {
      const newAvatar = data?.data?.self_model?.[0]

      if (!newAvatar) return

      onSuccessRef.current?.call({ ...newAvatar })

      unsubscribe()
    },
    onError: () => {
      onErrorRef.current?.call?.(new Error(defaultErrorMessage))
    },
  })

  const unsubscribe = useCallback(() => {
    onSuccessRef.current = undefined
    onErrorRef.current = undefined

    setVariables(null)
  }, [])

  const subscribe = useCallback(
    ({ parameters, onSuccess, onError }: IAvatarSyncSubscribeParams) => {
      unsubscribe()

      setVariables(parameters)

      onSuccessRef.current = { call: onSuccess }
      onErrorRef.current = { call: onError }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unsubscribe],
  )

  return { subscribe, unsubscribe }
}
