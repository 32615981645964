import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { Icon } from '~/components/Icon'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { IInstructionalCardProps } from './types'

const InstructionalCard: FC<IInstructionalCardProps> = ({
  title,
  description,
  onClick,
  testID = 'instructional-card',
  hasOverlay = true,
  slides,
  borderRadius = '',
  actionComponent,
}) => {
  const timer = useRef<NodeJS.Timeout | number>(0)
  const [currentIndex, setCurrentIndex] = useState(0)

  const nextIndex = useCallback(() => {
    slides && setCurrentIndex(currentIndex === slides.length - 1 ? 0 : currentIndex + 1)
  }, [currentIndex, slides])

  const startCarousel = useCallback(() => {
    timer.current = setTimeout(() => {
      nextIndex()
    }, 2000)
  }, [nextIndex])

  useEffect(() => {
    startCarousel()
    return () => clearTimeout(timer.current as number)
  }, [startCarousel])

  return (
    <Styled.Container data-testid={testID} borderRadius={borderRadius}>
      {hasOverlay && <Styled.BackgroundOverlay data-testid={`${testID}-overlay`} borderRadius={borderRadius} />}

      {slides && slides.length > 0 && (
        <Styled.ContainerImage>
          {slides.map((item, index) => (
            <Styled.BackgroundImage
              key={`image-slider-${index}`}
              isActive={currentIndex === index}
              data-testid={`${testID}-image-${index}`}
              src={slides[index]}
              borderRadius={borderRadius}
            />
          ))}
        </Styled.ContainerImage>
      )}

      {title && (
        <Styled.CardTitle align="center" data-testid={`${testID}-title`}>
          {title}
        </Styled.CardTitle>
      )}

      {description && (
        <Styled.CardDescription align="center" data-testid={`${testID}-description`}>
          {description}
        </Styled.CardDescription>
      )}

      {actionComponent && (
        <Styled.ActionComponentWrapper data-testid={`${testID}-action-component-wrapper`}>
          {actionComponent}
        </Styled.ActionComponentWrapper>
      )}

      {onClick && (
        <Styled.ButtonContainer>
          <TextButton testID="send-photo-button" onClick={onClick}>
            {translate('MODELS_BACKDROP_USE_MY_PHOTO_TAB_BUTTON_TEXT')}
          </TextButton>
        </Styled.ButtonContainer>
      )}

      <Styled.LockedContainer>
        <Icon name="locked" size="24px" color={theme.colors.white} />
        <Styled.PrefixText align="center">{translate('INSTRUCTIONAL_CARD_PRIVACY_NOTE')}</Styled.PrefixText>
      </Styled.LockedContainer>
    </Styled.Container>
  )
}

export default InstructionalCard
