import { gql } from '@apollo/client'

export const GET_MEASURES = gql`
  subscription getMeasurements($avatar_uuid: String!, $products: [Int]!) {
    measurements: sizing_suggestion(
      where: { product_id: { _in: $products }, model_to_tryon: { avatar_uuid: { _eq: $avatar_uuid } } }
      order_by: { product_id: asc }
    ) {
      id
      product_id
      label
      fit_summary
      hip
      chest
      waist
      sizing_fit_weight
      product {
        product_options {
          partner_size {
            order
            name
          }
        }
      }
    }
  }
`
