import styled from 'styled-components'

import { Shadow as BackdropShadow } from '~/components/Backdrop/styles'
import { Image as ImagePreview } from '~/components/ImagePreview/styles'

import { TImagePreview } from './types'

export const Container = styled.div<{ imagePreview: TImagePreview }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 20px;

  ${BackdropShadow} {
    pointer-events: none;
  }

  ${ImagePreview} {
    transition: top 0.3s ease 0.1s;
    top: ${({ imagePreview }) => (imagePreview === 'bottom' ? '-160px' : '0')};
  }
`

export const SizingContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - 210px - 56px);
  padding: 20px 20px 20px 0;
`

export const TabContainer = styled.div<{ bottom?: string }>`
  position: absolute;
  z-index: 4;
  bottom: ${({ bottom }) => (bottom ? bottom : '-72px')};
  left: 0;
  padding: 16px 20px;
  transition: ${({ theme }) => `bottom 0.5s ${theme.transitions.spring}`};
`

export const ImageContainer = styled.div<{ active?: boolean }>`
  opacity: ${props => (props.active ? 1 : 0)};
  transition: all 0.5s ease-in-out;
`
