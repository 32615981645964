import { FC } from 'react'
import { useState } from 'react'

import { ITryOnFit } from '~/entities/TryOnFit'

import { TryOnFitContext } from '.'
import { ITryOnFitContext } from './types'

export const TryOnFitProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<ITryOnFit[]>([])

  const value = {
    isLoading,
    data,
    setData,
    setIsLoading,
  } as unknown as ITryOnFitContext

  return <TryOnFitContext.Provider value={value}>{children}</TryOnFitContext.Provider>
}
