import React from 'react'

import { TIconProps } from '.'

const Blazer: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg data-testid="icon-blazer" viewBox="0 0 38 30" style={{ width: 'auto', height: size }}>
      <path
        d="M14.96 2.01 9.3 3.9a.39.39 0 0 0-.24.23L1.04 23.99c-.12.3.02.65.32.77l4.5 1.93c.1.04.21.04.31 0s.18-.12.22-.22l2.81-7.1c.24-.61 1.15-.44 1.16.21 0 2.42.05 5.55.15 9.16 0 .21.17.37.38.38l6.98.22a.6.6 0 0 0 .62-.6v-11.7L15.51 1.45l-.55.55v.01Zm2.36 20.43c0 .22-.18.39-.39.39h-4.04a.4.4 0 0 1-.4-.39.4.4 0 0 1 .4-.39h4.04c.22 0 .39.18.39.39ZM36.96 23.99 28.95 4.12a.39.39 0 0 0-.24-.23L23.05 2l-.55-.55-2.98 15.59v11.7c0 .34.28.61.62.6l6.98-.22c.21 0 .37-.18.38-.38.11-3.61.15-6.74.15-9.16 0-.66.91-.83 1.16-.21l2.81 7.1c.04.1.12.18.22.22.1.04.21.04.31 0l4.5-1.93c.3-.13.44-.47.32-.77h-.01Zm-11.84-1.16h-4.04a.4.4 0 0 1-.4-.39.4.4 0 0 1 .4-.39h4.04c.22 0 .39.18.39.39 0 .21-.18.39-.39.39ZM21.85 1.71a.601.601 0 0 0-.47-.72C20.42.8 19.51.71 18.67.71c-.8 0-1.42.09-1.89.19-.31.07-.5.38-.44.69l2.03 10.63c.12.64 1.03.65 1.17.02l2.31-10.53Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default Blazer
