import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types'

import { Icon } from '~/components/Icon'

import { Item, List, Option } from './styles'

const ColorsRadio = ({ options, value, onChange, disabled }) => (
  <>
    {!!options.length && (
      <List data-testid="colors-radio">
        {options.map(option => (
          <Item key={option.color} data-testid={'color-item'}>
            <Option
              data-testid={'color-button'}
              data-active={option.value === value}
              active={option.value === value}
              backgroundColor={option.color}
              onClick={() => onChange(option.value)}
              disabled={disabled}
            >
              <Icon name="checked" size={14} />
            </Option>
          </Item>
        ))}
      </List>
    )}
  </>
)

ColorsRadio.propTypes = {
  options: arrayOf(shape({ color: string.isRequired, value: oneOfType([string, number]).isRequired })).isRequired,
  value: oneOfType([string, number]),
  onChange: func.isRequired,
  disabled: bool,
}

export default ColorsRadio
