import { FC, useEffect, useRef, useState } from 'react'

import theme from '~/theme'

import * as Styled from './styles'
import { ETimerSize, ITimer } from './types'

export const Timer: FC<ITimer> = ({
  callback,
  color = theme.colors.goldenYellow,
  size = ETimerSize.LARGE,
  time = 10,
}) => {
  const intervalId = useRef<NodeJS.Timeout>()

  const [count, setCount] = useState(time)
  const [animationStart, setAnimationStart] = useState(false)

  const stop = () => clearInterval(intervalId.current as NodeJS.Timeout)

  const start = () => {
    let count = time

    intervalId.current = setInterval(() => {
      count = count - 1

      if (count <= 0) {
        stop()
        callback()

        return
      }

      setCount(count)
    }, 1000)

    setAnimationStart(true)
  }

  useEffect(() => {
    start()

    return () => stop()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styled.Container data-testid="timer-container">
      <Styled.Text data-testid="timer-text" size={size} color={color}>
        {count}
      </Styled.Text>
      <Styled.Svg
        data-testid="timer-svg"
        size={size}
        viewBox={`0 0 ${Math.round(121 / size)} ${Math.round(121 / size)}`}
      >
        <Styled.CircleTrack data-testid="timer-track" size={size} color="transparent" />
        <Styled.CircleIndicator
          data-testid="timer-indicator"
          size={size}
          color={color}
          animationStart={animationStart}
          animationTime={time}
        />
      </Styled.Svg>
    </Styled.Container>
  )
}
