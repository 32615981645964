import { FC } from 'react'

import theme from '~/theme'

import { toCurrency } from '~/utils/toCurrency'
import { translate } from '~/utils/translate'

import { Container } from './styles'

export interface IPriceProps {
  value: number
  disabled?: boolean
}

const Price: FC<IPriceProps> = ({ value, disabled }) => (
  <Container
    disabled={disabled}
    data-testid="price"
    color={value ? theme.colors.textPrimary : theme.colors.palette.webOrange}
  >
    {value ? toCurrency({ value }) : translate('COMBINED_ITEMS_TOTAL_OUT_OF_STOCK_NOTE')}
  </Container>
)

export default Price
