import React from 'react'

import { TIconProps } from '.'

const External: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 12 12"
      data-testid="icon-external"
      style={{
        width: size,
        height: 'auto',
      }}
    >
      <g fill={color} fillRule="evenodd">
        <path d="M11.3333333 5.33333333c-.3686666 0-.6666666.29866667-.6666666.66666667v4c0 .3673333-.2986667.6666667-.6666667.6666667H2c-.368 0-.66666667-.2993334-.66666667-.6666667V2c0-.36733333.29866667-.66666667.66666667-.66666667h4c.36866667 0 .66666667-.29866666.66666667-.66666666S6.36866667 0 6 0H2C.89733333 0 0 .89733333 0 2v8c0 1.1026667.89733333 2 2 2h8c1.1026667 0 2-.8973333 2-2V6c0-.368-.298-.66666667-.6666667-.66666667" />
        <path d="m8.6634 1.33333333 1.05533333.00533334-4.19 4.19c-.26066666.26066666-.26066666.682 0 .94266666.13.13.30066667.19533334.47133334.19533334.17066666 0 .34133333-.06533334.47133333-.19533334l4.1946667-4.19466666L10.6667333 3.334c0 .368.2986667.666.6666667.666h.0006667c.368 0 .666-.29866667.666-.66733333l-.002-2.65333334c0-.36666666-.2966667-.664-.6633334-.666L8.67006667 0h-.00333334c-.36666666 0-.66466666.296-.66666666.66333333-.002.368.29466666.668.66333333.67" />
      </g>
    </svg>
  )
}

export default External
