import React from 'react'

import { TIconProps } from '.'

export const Bolt: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 15 22"
      data-testid="icon-bolt"
      style={{
        width: size,
        height: size,
      }}
    >
      <path
        d="M13.6823 5.29301L8.88605 6.86821L11.0462 0.335448C11.1402 0.0489099 10.743 -0.134534 10.5838 0.123198L5.80731 8.2069C5.2813 9.09683 6.14232 10.1611 7.12915 9.84122L10.9431 8.60563L9.59548 12.7293C9.59548 12.7293 9.59396 12.7354 9.59245 12.7384C8.62077 11.4907 7.09732 10.713 5.34649 10.713C2.32383 10.713 0 13.0295 0 16.0814C0 19.1332 2.32535 21.4286 5.34649 21.4286C7.53237 21.4286 9.36355 20.2278 10.2094 18.4086C10.2731 18.2767 10.3322 18.1341 10.3913 17.981C10.7339 17.0835 14.3962 5.98434 14.3962 5.98434C14.5418 5.54164 14.1264 5.14595 13.6823 5.29301ZM5.34497 18.9877C3.69873 18.9877 2.43904 17.7218 2.43904 16.0814C2.43904 14.441 3.69873 13.1554 5.34497 13.1554C6.99121 13.1554 8.27061 14.4213 8.27061 16.0814C8.27061 17.7415 6.98969 18.9877 5.34497 18.9877Z"
        fill={color}
      />
    </svg>
  )
}
