import classNames from 'classnames'

import { FC } from 'react'

import { Icon } from '~/components/Icon'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { TUpscaleTagProps } from './types'

export const UpscaleTag: FC<TUpscaleTagProps> = ({
  label = translate('UPSCALE_TAG_LABEL'),
  iconColor = theme.colors.white,
  testId = 'upscale-tag',
  iconSize = 18,
  className,
}) => {
  return (
    <Styled.UpscaleTag data-testid={testId} className={classNames('upscale-tag', className)}>
      <Styled.Label>{label}</Styled.Label>

      <Styled.LoadingIconWrapper>
        <Icon name="spinner-loading" size={iconSize} color={iconColor} />
      </Styled.LoadingIconWrapper>
    </Styled.UpscaleTag>
  )
}
