import { FC } from 'react'

import { TIconProps } from '.'

export const Trash: FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      data-testid="icon-trash"
      style={{
        width: size,
        height: size,
      }}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M16.8,17.6 C16.8,18.0408 16.4416,18.4 16,18.4 L8,18.4 C7.5584,18.4 7.2,18.0408 7.2,17.6 L7.2,8.8 L16.8,8.8 L16.8,17.6 Z M10.4,5.8624 C10.4,5.7384 10.5712,5.6 10.8,5.6 L13.2,5.6 C13.4288,5.6 13.6,5.7384 13.6,5.8624 L13.6,7.2 L10.4,7.2 L10.4,5.8624 Z M19.2,7.2 L18.4,7.2 L15.2,7.2 L15.2,5.8624 C15.2,4.8352 14.3032,4 13.2,4 L10.8,4 C9.6968,4 8.8,4.8352 8.8,5.8624 L8.8,7.2 L5.6,7.2 L4.8,7.2 C4.36,7.2 4,7.56 4,8 C4,8.44 4.36,8.8 4.8,8.8 L5.6,8.8 L5.6,17.6 C5.6,18.9232 6.6768,20 8,20 L16,20 C17.3232,20 18.4,18.9232 18.4,17.6 L18.4,8.8 L19.2,8.8 C19.64,8.8 20,8.44 20,8 C20,7.56 19.64,7.2 19.2,7.2 L19.2,7.2 Z"
          id="path-1"
        />
      </defs>
      <g id="icon-trash" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <mask id="mask-2" fill={color}>
          <use xlinkHref="#path-1" />
        </mask>
        <use id="icon-color" fill={color} xlinkHref="#path-1" />
      </g>
    </svg>
  )
}
