import { FC } from 'react'

import theme from '~/theme'

import * as Styled from './styles'
import { ITextButton } from './types'

export const TextButton: FC<ITextButton> = ({
  variant = 'default',
  disabled,
  textTransform = 'uppercase',
  testID = 'text-button',
  onClick = () => ({}),
  children,
  background,
  type = 'button',
  className = '',
  textColor = variant === 'default' ? theme.colors.white : theme.colors.primary,
}) => (
  <Styled.Container
    className={className}
    variant={variant}
    data-testid={testID}
    disabled={disabled}
    textTransform={textTransform}
    onClick={disabled ? undefined : onClick}
    background={background}
    type={type}
  >
    <Styled.Content color={textColor} align="center">
      {children}
    </Styled.Content>
  </Styled.Container>
)
