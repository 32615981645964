import styled from 'styled-components'

import { Paragraph, Prefix } from '~/components/Typography/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const Text = styled(Paragraph)`
  padding: 20px 0;
`

export const Footer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 16px 0 0 0;
`

export const Description = styled(Paragraph)``

export const ListItem = styled.li`
  display: flex;
  width: 100%;
  padding-top: 16px;
  gap: 8px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`

export const ListItemLabel = styled(Prefix)`
  display: flex;
  align-items: center;
  width: 20%;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  line-height: 18px;
  color: ${({ theme }) => theme.colors.neutral.black500};
  text-transform: uppercase;
`

export const ListItemValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0;
`

export const WrapperButton = styled.div`
  padding-top: 18px;
  padding-bottom: 22px;
`

export const FooterButtons = styled.div`
  display: flex;
  gap: 10px;
`
