import styled from 'styled-components'

import { Video as VideoBase } from '~/components/Video'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`
export const Video = styled(VideoBase)`
  height: calc(100% - 150px);
`
