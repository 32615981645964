import styled from 'styled-components'

import { IImageSlotHasImage } from './types'

export const ImageSlotList = styled.ol`
  list-style: none;
`

export const ImageSlotListItem = styled.li`
  border-radius: 8px;
  height: 56px;
  margin-top: 12px;
  overflow: hidden;
  position: relative;
  width: 28px;

  &:first-of-type {
    margin-top: 0;
  }
`

export const ImageSlotCounter = styled.span`
  bottom: 0;
  color: ${({ theme }) => theme.colors.white};
  height: 1em;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 2;
`

export const ImageSlotBorder = styled.span<IImageSlotHasImage>`
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  display: ${({ hasImage }) => (hasImage ? 'none' : 'block')};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
