import { useCallback } from 'react'

import { useQueryUser } from '~/hooks-queries/user'

import { TCurrentUserState } from '~/context/User'

import Tracking from '~/utils/tracking'

import { UserStorageInstance } from './storage'
import { IUseUser, TCreateUser, TRemoveUser, TResetUser, TSetUser, TUpdateUser } from './types'

export const useUser = (): IUseUser => {
  const { newUser } = useQueryUser()

  const setUser = useCallback(({ data, setState }: TSetUser) => {
    setState(data)

    Tracking.setUserId(data.uuid)

    UserStorageInstance.set(data)
  }, [])

  const createUser = useCallback(
    ({ setState }: TCreateUser) =>
      newUser({
        callbackOnError: error =>
          setState(current => ({
            ...current,
            error,
          })),
        callbackOnSuccess: response =>
          setState(current => ({
            ...current,
            data: {
              ...response,
              interactions: 0,
              showRecommendationTooltip: true,
              showRecommendationBackdrop: true,
              showRecommendationAnimation: true,
            },
          })),
        callbackOnProcessing: status =>
          setState(current => ({
            ...current,
            called: true,
            data: status ? undefined : current?.data,
            error: status ? undefined : current?.error,
            isLoading: status,
          })),
      }),
    [newUser],
  )

  const updateUser = useCallback(({ values, setState }: TUpdateUser) => {
    UserStorageInstance.update(values)

    setState(current => ({ ...current, ...values } as TCurrentUserState))
  }, [])

  const resetUser = useCallback(({ data, setState }: TResetUser) => setState(data), [])

  const removeUser = useCallback(
    ({ setState }: TRemoveUser) => {
      UserStorageInstance.remove()

      Tracking.setUserId(null)

      resetUser({ data: ['stateCurrentUser'], setState })
    },
    [resetUser],
  )

  return {
    createUser,
    setUser,
    removeUser,
    resetUser,
    updateUser,
  }
}
