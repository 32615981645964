import { FC } from 'react'

import { TIconProps } from '.'

const Heart: FC<TIconProps> = ({ color, size }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 21"
    data-testid="icon-heart"
    style={{ width: size, height: size }}
  >
    <path
      fill={color}
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20.84 2.61a5.5 5.5 0 0 0-7.78 0L12 3.67l-1.06-1.06a5.501 5.501 0 0 0-7.78 7.78l1.06 1.06L12 19.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
    />
  </svg>
)

export default Heart
